import React, { useState, useEffect, useRef } from "react";
import Layout from "../newlayout";
import styles from "./cockpitDesign.scss";
import CAdd from "../../assets/images/CAdd.svg";
import DArrow from "../../assets/images/DArrow.svg";
import Dsearch from "../../assets/images/Dsearch.svg";
import eye from "../../assets/images/eye.svg";
import PDF from "../../assets/images/PDF.svg";
import Plus from "./../../../src/assets/images/plus.svg";
import Search from "../../assets/images/search.svg";
import ICON1 from "../../assets/images/icon1.svg";
import DGArrow from "../../assets/images/DGArrow.svg";
import DGUpArrow from "../../assets/images/DGUpArrow.svg";
import { withStyles } from "@material-ui/core/styles";
import CreatecaseSuccess from "../Popup/CreatecaseSuccess";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import { Fragment } from "react";
import PatientRiskNotePopup from "../Popup/PatientRiskNotesPopup";
import DoctoreCaseNotes from "../Popup/DoctoreCaseNotes";
import CreateRefferal from "../Popup/CreateRefferal";
import PsychiatryCaseNotes from "../Popup/PsychiatryCaseNotes";
import { Dialog } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDoc } from "../../redux/actions/cockpitAction";
import LabPres from "./PrescriptionLab/labTestDialog";
import moment from "moment";
import useOnClickOutside from "../doctorDashboard/OutClickhandler";
import ReferralPost from "../Popup/ReferralPost";

import {
  extentAppointAction,
  getMedPrescription,
  changeStatusActionRequest,
} from "../../redux/actions/cockpitAction";
import { useLocation } from "react-router-dom";
import { changeStatusAction } from "../../redux/actions/cockpitAction";
import {
  getHistro,
  getRisknote,
  createRiskNote,
  SearchICD,
} from "../../redux/actions/caseNoteAction";
import axios from "axios";
import BloodPressure from "../Popup/BloodPressure";
import WeightPopUP from "../Popup/WeightPopUP";
import { Dropdownfn } from "./dmyDropdown";
import MedicalPrescription from "./MedicalPrescription/medicalPrescriptionDialog";
import { followupAction } from "../../redux/actions/cockpitAction";
import { message } from "antd";
import AppointmentStatusClose from "../Popup/AppointmentStatusClose";
import AppointmentStatusMissed from "../Popup/AppointmentStatusMissed";
import CreateFollowSuccess from "../Popup/followSuccess";
import CreateFollowFailure from "../Popup/followFailure";
import FailurePdf from "../Popup/failurePdf";
import ChaperonePop from "../Popup/chaperonePopup";
import PrescriptionChaperone from "../Popup/PrescriptionChaperonePopup";
import LabChaperonePop from "../Popup/prescriptionLabChaperone/LabChaperonePop";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FailureStatus from "../Popup/failureStatus";
import ExtendSuccess from "../Popup/ExtendSuccess";
import Closebutton from "../Popup/icons/close.svg";
import PatientRiskNotesPopupListing from "../Popup/PatientRiskNotesPopupListing";
import CountDown from "./Components/CountDown";
import Close from "../../assets/images/close.svg";
import { getExtendRequest } from "../../redux/actions/cockpitAction";
import { getFollowRequest } from "../../redux/actions/cockpitAction";
import SendIcon from "@material-ui/icons/Send";
import SendSms from "../Popup/SendSms";
import { json } from "body-parser";
import { date } from "yup";
import RefferalSuccPopup from "../Popup/RefferalSuccPopup";
import ReferralGet from "../Popup/ReferralGet";
import { Helmet } from "react-helmet";
// import Pdfviewer from "../doctorCockpit/Components/pdfViewer/pdfViewer"
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #66B5C3",
    padding: "0px 5px",
    flexGrow: 1,
  },
  indicator: {
    backgroundColor: "#04A9C8",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: "20%",
    fontWeight: "bold",
    fontSize: 18,
    marginRight: theme.spacing(4),
    fontFamily: ["GreycliffCFBold"].join(","),
    "&:hover": {
      color: "#013C44",
      opacity: 1,
    },
    "&$selected": {
      color: "#013C44",
    },
    "&:focus": {
      color: "#013C44",
    },
    color: "#013C44",
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const casedata = [
  "A00-B99. Certain infectious and parasitic diseases.",
  "C00-D49. Neoplasms.",
  "disorders involving the immune mechanism.",
];
const AntTab2 = withStyles((theme) => ({
  root: {
    textTransform: "none",
    width: "50%",
    fontWeight: "bold",
    fontSize: 18,
    marginRight: theme.spacing(4),
    fontFamily: ["GreycliffCFBold"].join(","),
    "&:hover": {
      color: "#013C44",
      opacity: 1,
    },
    "&$selected": {
      color: "#013C44",
    },
    "&:focus": {
      color: "#013C44",
    },
    color: "#013C44",
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const monthNames = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "1258px",
  },
  backDrop: {
    zIndex: "99",
    backgroundColor: "#013c4473",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  presPaper: {
    width: "1260px",
  },
}));

export const CockpitDesign = (props) => {


  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.loginReducer.payload);
  const routedata = history.location.state;
console.log(routedata, "routedata")
  const [tab, setTab] = React.useState(0);
  const [tab2, setTab2] = React.useState(0);
  const [tab3, setTab3] = React.useState(0);
  const [stData, setStData] = React.useState([]);
  const [searchcase, setSearchCase] = React.useState(false);
  const [appoint, setAppoint] = React.useState("");
  const [casesearch, setcasesearch] = React.useState("");
  const [addPopup, setAddPopup] = useState(false);
  const [addCasePopup, setCaseAddPopup] = useState(false);
  const [addReferral, setAddReferral] = useState(false);
  const [addPsychiatry, setAddPshychiatry] = useState(false);
  const [labPopup, setLabpopup] = useState(false);
  const [labAdviceId, setLabAdviceId] = useState('');

  const [medicineReportId, setMedicineReportId] = useState('');

  const [changeStatus, setChangeStatus] = useState("");
  const [extentpop, setExtentPop] = React.useState("");
  const [docCase, setdocCase] = useState(false);
  const [caseSuccess, setCaseSuccess] = useState(false);
  const [phySuccess, setPhySuccess] = useState(false);
  const [selectedPresc, setSelectedPresc] = useState(false);
  const [openpdf, setOpenPdf] = useState(false);
  const [closepdf, setClosePdf] = useState(false);
  const [sendSms, setSendSms] = useState(false);


  const [bookingdet, setbookingdet] = useState({
    bookingdate: "",
    slot_id: "",
    uuid: "",
  });
  const [followup, setFollowup] = React.useState({
    year:
      (routedata.status === "followup")
        ? routedata && routedata.followup_date.substr(0, 3)
        : new Date().getFullYear(),
    month: new Date().getMonth(),
    day: new Date().getDate(),
  });
  const [chapQueryPop, setChapQueryPop] = useState(false);
  const [chapReviewMedPop, setChapReviewMedPop] = useState(false);
  const [chapReviewLabPop, setChapReviewLabPop] = useState(false);
  const [internationalQueryPop, setInternationalQueryPop] = useState(false);
  const [medicalPrescriptionPopup, setMedicalPrescriptionPopup] =
    useState(false);
  const [phpData, setphpData] = useState(false);
  const [dialogopen, setdialogopen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [popupTitle, setpopupTitle] = useState("");
  const [dialogweignt, setDialogWeight] = useState(false);
  const [weightData, setWeightData] = useState("");
  const [closepopup, setClosepopup] = useState(false);
  const [appointmentClosedUpdated, setAppointmentClosedUpdated] = useState(true);

  const [missedpopup, setMissedpopup] = useState(false);
  const [followSuccessPopup, setFollowSuccessPopup] = useState(false);
  const [followFailurePopup, setFollowFailurePopup] = useState(false);
  const [extendFailure, setExtendFailure] = useState(false);
  const [extendSuccess, setExtendSuccess] = useState(false);
  const [patientRiskNote, setPatientRiskNote] = useState(false);
  const [readmore, setReadmore] = useState("");
  const [extenttext, setextenttext] = useState("");
  const [epresdet, setEpresdet] = useState({
    doctor_uuid: "",
    local_doctor_uuid: "",
    booking_uuid: "",
    patient_uuid: "",
    eprescriptions_uuid: "",
    created_by: "",
  });
  const [loader, setLoader] = useState(false);
  const [pdfview, setPdfview] = useState(false);
  const [followvalue, setFollowvalue] = useState(false);
  const [disablestatus, setDisableStatus] = useState(false);
  const messageRef = useRef();
  const scrollRef = useRef();
  const [encodedata, setEncode] = useState("");
  const [showextent, setShowextent] = useState("");
  const [followchange, setFollowchange] = useState(false);
  const userdata = useSelector((state) => state.loginReducer.payload);
  var caseN = useSelector((state) => state.getCaseNoteredu);
  var caseP = useSelector((state) => state.getPhyNoteredus);
  const risknoteget = useSelector((state) => state.getRiskNoteredus);
  const changeStatusReducer = useSelector((state) => state.changeStatusReducer);
  const chapMedPre = useSelector((state) => state.getMedPrescription.payload);
  const [reffPop, setReffPop] = useState(false);
  const [reffsucc, setreffsucc] = useState(false);
  const [reffListing, setReffListing] = useState([]);
  const [uniqReferral, setuniqReferral] = useState("");
  const [uniqreffpoup, setUniqReffopup] = useState(false);
  const [update, setUpdate] = useState(false);

  const [prescriptionList, setPrescriptionList] = useState([]);
  const [labHistoryList, setLabHistorylist] = useState([]);

  const [createPrescriptionDisable, setCreatePrescriptionDisable] = useState(false);
  const [createLabDisable, setCreateLabDisable] = useState(false);

  const [refresh, setRefresh] = useState(1);



  const followupState = useSelector((state) => {
    if (state.followupReducer !== undefined) {
      return state.followupReducer;
    }
  });
  const handleuniqreff = () => {
    setUniqReffopup(false);
  };
  const handlereferralSuccessPopup = () => {
    setreffsucc(true);
    setReffPop(false);
  };

  const hist = useSelector((state) =>
    state.getHIsreducer !== undefined && state.getHIsreducer !== ""
      ? state.getHIsreducer
      : ""
  );


  useEffect(() => {
    setPrescriptionList(hist && hist.payload && hist.payload.medicine)

    setLabHistorylist(hist && hist.payload && hist.payload.lab)

    hist && hist.payload && hist.payload.lab.map((item) => {
      if (item.booking_uuid === routedata.uuid) {
        setCreateLabDisable(true)
      }
    })

    hist && hist.payload && hist.payload.medicine.map((item) => {
      if (item.booking_uuid === routedata.uuid) {
        setCreatePrescriptionDisable(true)
      }
    })
  }, [hist])




  useEffect(() => { }, [followup]);
  //pdf handler doctor case note
  const handleHistoryCaseNote = (e) => {
    setLoader(true);
    const caseid = e.target.id;
    let dt;
    let url;
    async function getpdf(id) {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_URL + `/ep-lab/caseNotepreviewejs/${id}`
          )
          .then((res) => {
            if (res.data) {
              dt = res.data;
              setLoader(false);
            }
          });

        handlePdfOpen();
        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
      } catch (error) {
        failurePdfOpen();
      }
    }

    getpdf(caseid);
  };

  React.useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/epquery/fetchRefBasedBK/${routedata.uuid}`
      )
      .then((res) => {
        setReffListing(res.data.data);
        setUpdate(!update);
      });
  }, []);
  const handlereffSucc = () => {
    setreffsucc(false);
  };
  //pdf handler phy case note
  const handlePhYcaseNote = (e) => {
    setLoader(true);
    const caseid = e.target.id;
    let dt;
    let url;
    async function getpdf(id) {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_URL + `/ep-lab/psyNotePreviewejs/${id}`
          )
          .then((res) => {
            if (res.data) {
              dt = res.data;
              setLoader(false);
            }
          });
        handlePdfOpen();
        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
      } catch (error) {
        failurePdfOpen();
      }
    }

    getpdf(caseid);
  };

  const handleRiskReport = (id) => {
    setLoader(true);
    const caseid = id;
    let dt;
    let url;
    async function getpdf(id) {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_URL + `/ep-lab/riskotePreviewejs/${id}`
          )
          .then((res) => {
            if (res.data) {
              dt = res.data;
              setLoader(false);
            }
          });
        handlePdfOpen();
        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
      } catch (error) {
        failurePdfOpen();
      }
    }

    getpdf(caseid);
  };
  const handleMedicineReport = (e) => {
    setLoader(true);
    const caseid = e.target.id;
    let dt;
    let url;
    async function getpdf(id) {
      try {
        await axios
          .get(process.env.REACT_APP_API_URL + `/ep/preview/${id}`)
          .then((res) => {
            if (res.data) {
              dt = res.data;
              setLoader(false);
            }
          });
        handlePdfOpen();
        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
      } catch (error) {
        failurePdfOpen();
      }
    }

    getpdf(caseid);
  };

  // handle referral popup

  const handlereffpopup = () => {
    setReffPop(false);
  };

  //pdf handler lab case note
  const handleHistoryLabNote = (e) => {
    setLoader(true);
    const caseid = e.target.id;
    let dt;
    let url;
    async function getpdf(id) {
      try {
        await axios
          .get(process.env.REACT_APP_API_URL + `/ep-lab/preview/${id}`)
          .then((res) => {
            if (res.data) {
              dt = res.data;
              setLoader(false);
            }
          });
        handlePdfOpen();
        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
      } catch (error) {
        failurePdfOpen();
      }
    }

    getpdf(caseid);
  };

  var extentState = useSelector((state) => {
    if (state.extentReducer != undefined) {
      return state.extentReducer;
    }
  });


  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [internationalQueryPop, chapQueryPop]);

  // let uris = 'https://s3.eu-west-1.amazonaws.com/doctall.storage.com/health-records/a19e53a2dd2199469cfe8395a28be94c.docx?AWSAccessKeyId=AKIAUROLDTTBBUNRS7OL&Expires=1627648079&Signature=ZZIc3xfWYMAS4Gfy1%2FHcVyb4kbE%3D'
  // console.log('datasdasdasdasd dox',uris.split('?')[0])
  // useEffect(() => {
  //     if (chapMedPre != undefined && chapMedPre != "" && chapMedPre.length > 0) {
  //         chapMedPre.map((val, ind) => {
  //             setEpresdet({
  //                 ...epresdet,
  //                 "doctor_uuid": val.doctor_uuid,
  //                 "local_doctor_uuid": val.local_doctor_uuid ? val.local_doctor_uuid : val.doctor_uuid,
  //                 "patient_uuid": val.patient_uuid,
  //                 "eprescriptions_uuid": val.uuid,
  //                 "created_by": val.doctor_uuid
  //             })
  //         })
  //     }
  // }, [chapMedPre])
  useEffect(() => {
    if (changeStatusReducer.payload && changeStatusReducer.isError == false) {
      setChangeStatus(
        changeStatusReducer.payload == "booked"
          ? "open"
          : changeStatusReducer.payload
      );
    }
  }, [changeStatusReducer]);
  useEffect(() => {
    setbookingdet({
      bookingdate: location.state.booking_date,
      slot_id: location.state.slot,
      uuid: location.state.uuid,
    });
  }, [location]);

  useEffect(() => {
    try {
      dispatch(
        getHistro(routedata && routedata.uuid && routedata.uuid, routedata && routedata.patient.uuid && routedata.patient.uuid, routedata.clinic_uuid)
      );
    } catch (error) {
      console.log(error)
    }

  }, [refresh, labPopup, medicalPrescriptionPopup]);

  useEffect(() => {
    try {
      dispatch(getRisknote(routedata.uuid));
    } catch (error) {
      console.log(error)
    }

  }, []);
  useEffect(() => {
    try {
      if (
        (extentState.isError == true && extentState.status !== undefined) ||
        extentState.status == "failure"
      ) {
        setLoader(false);
        setExtendSuccess(false);
        setExtendFailure(true);
      } else if (extentState && extentState.status == "success") {
        setLoader(false);
        setExtendFailure(false);

        setExtendSuccess(true);

        setextenttext(
          moment(extentState.data.extended_date).format("ddd, DD MMM, YYYY")
        );
        setDisableStatus(true);
      }

    } catch (error) {
      console.log(error)
    }


  }, [extentState]);
  useEffect(() => {
    if (followupState.isError && followupState.status !== undefined) {
      setLoader(false);
      setFollowSuccessPopup(false);
      setFollowFailurePopup(true);
    }
    if (followupState && followupState.status == "success") {
      setLoader(false);
      setFollowFailurePopup(false);

      setFollowSuccessPopup(true);

      setDisableStatus(true);
    }
  }, [followupState]);

  useEffect(() => {
    try {
      dispatch(getMedPrescription(routedata && routedata.uuid && routedata.uuid));
    } catch (error) {
      console.log(error)
    }

  }, []);
  useEffect(() => {
    try {
      dispatch(getDoc(routedata.patient && routedata.patient.uuid && routedata.patient.uuid));
    } catch (error) {
      console.log(error)
    }

  },[routedata.patient]);

  useEffect(() => {
    try {
      var data = "";
      var config = {
        method: "get",
        url: process.env.REACT_APP_API_URL + `/patient/${routedata && routedata.patient.uuid && routedata.patient.uuid}`,
        headers: {
          "x-auth-token": user.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setphpData(response.data);
          setStData(response.data);
        })
        .catch(function (error) { });
    } catch (error) {
      console.log(error)
    }

  }, []);

  useEffect(() => {
    // dispatch(getExtendRequest(null, null, null, null));
    // dispatch(getFollowRequest(null, null, null, null));
    // dispatch(changeStatusActionRequest(null, null));
    setExtendFailure(false);
    setFollowFailurePopup(false);
    setChangeStatus("");
  }, []);
  useEffect(() => {
    try {
      if (
        routedata.booking_date != undefined &&
        routedata.extended_date != undefined
      ) {
        var start = moment(routedata.booking_date.substr(0, 10), "YYYY-MM-DD");
        var end = moment(routedata.extended_date.substr(0, 10), "YYYY-MM-DD");
        var dateres = moment.duration(start.diff(end)).asDays();
        let resulty = Math.abs(dateres);
        setShowextent(resulty);
      }
    } catch (error) {
      console.log(error)
    }

  }, []);
  const checkChaproene = () => {
    if (routedata.local_doctor_uuid) {
      if (routedata.local_doctor_uuid == userdata.uuid) {
        return "local";
      } else {
        return "international";
      }
    } else {
      return false;
    }
  };
  const handlePdfOpen = () => {
    setOpenPdf(true);
  };

  const handlePdfClose = () => {
    setOpenPdf(false);
  };
  const failurePdfOpen = () => {
    setClosePdf(true);
  };
  const failurePdfClose = () => {
    setClosePdf(false);
  };

  const handleExtendFailurePop = () => {
    setExtendFailure(true);
  };
  const handleExtendFailureClosePop = () => {
    setExtendFailure(false);
  };
  const handleExtendSuccessClosePop = () => {
    setExtendSuccess(false);
  };
  const handleClosepopup = () => {
    setClosepopup(true);
  };
  const handleSuccessPopup = () => {
    setCaseSuccess(true);
  };
  const handlePhySuccessPopup = () => {
    setPhySuccess(true);
  };

  const handleMissedpopup = () => {
    setMissedpopup(true);
  };
  const handleClosepopupClose = () => {
    setClosepopup(false);
  };
  const handleMissedpopupClose = () => {
    setMissedpopup(false);
  };
  const handleaddPsychiatry = () => {
    setAddPshychiatry(true);
  };
  const handleCloseaddPsychiatry = () => {
    setAddPshychiatry(false);
  };

  const handleCasePopup = () => {
    setCaseAddPopup(true);
  };
  const handleCloseCasePopup = () => {
    setCaseAddPopup(false);
  };
  const handleReferrelPopup = () => {
    setAddReferral(true);
  };
  const handleCloseReferralPopup = () => {
    setAddReferral(false);
  };
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const handleTabChange2 = (event, newValue) => {
    setTab2(newValue);
  };
  const handleTabChange3 = (event, newValue) => {
    setTab3(newValue);
  };
  const handleRiskPopup = () => {
    setAddPopup(true);
  };
  const handleClosePopup = () => {
    setAddPopup(false);
  };
  const handleLabOpen = (id) => {
    if (id) {
      setLabpopup(true);
      setLabAdviceId(id)
    } else {
      setLabpopup(true);
      setLabAdviceId('Add')
    }

  };
  const handleLabClose = () => {
    setLabpopup(false);
  };
  const handleMedicalPrescriptionOpen = (id) => {
    if (id) {
      setMedicineReportId(id)
      setMedicalPrescriptionPopup(true);
    } else {
      setMedicineReportId('Add')
      setMedicalPrescriptionPopup(true);
    }

  };
  const handleMedicalPrescriptionClose = () => {
    setMedicalPrescriptionPopup(false);
  };
  const handleChangeStatus = (val) => {
    if (val == "missed") {
      if (routedata.status == "booked") setMissedpopup(true);
    }
    if (val == "closed") {
      if (routedata.status == "booked") {
        setClosepopup(true);
      }
    }
  };
  const handleConfirmatonMissed = () => {
    setLoader(true);
    dispatch(changeStatusActionRequest(bookingdet.uuid, "missed"));

    handleMissedpopupClose();
    setAppoint("");
    if (changeStatusReducer && changeStatusReducer.isLoading == false) {
      setLoader(false);
      setChangeStatus("missed");
      setDisableStatus(true);
    }
    if (changeStatusReducer && changeStatusReducer.isError) {
      setLoader(false);
    }
  };
  const handleConfirmationMissedNo = () => {
    setMissedpopup(false);
  };
  const handleConfirmatonClosed = () => {
    setLoader(true);
    dispatch(changeStatusActionRequest(bookingdet.uuid, "closed"));

    handleClosepopupClose();
    setAppoint("");
    if (changeStatusReducer && changeStatusReducer.isLoading == false) {
      setLoader(false);
      setChangeStatus("closed");
      setDisableStatus(true);
    }
    if (changeStatusReducer && changeStatusReducer.isError == true) {
      setLoader(false);
    }
  };
  const handleConfirmationClosedNo = () => {
    setClosepopup(false);
  };
  const handleExtentAppointment = () => {
    setLoader(true);
    let dateconverterone = bookingdet.bookingdate.substring(0, 10);

    let dateconvertertwo = moment(dateconverterone)
      .add(extentpop, "days")
      .format("YYYY-MM-DD");
    dispatch(
      getExtendRequest(
        dateconvertertwo,
        bookingdet.bookingdate,
        bookingdet.slot_id,
        bookingdet.uuid
      )
    );
    if (
      (extentState.isError == true && extentState.status !== undefined) ||
      extentState.status == "failure"
    ) {
      setLoader(false);
      setExtendSuccess(false);
      setExtendFailure(true);
    }
    if (extentState && extentState.status == "success") {
      setLoader(false);
      setExtendFailure(false);
      setExtendSuccess(true);
      setextenttext(
        moment(extentState.data.extended_date).format("ddd, DD MMM, YYYY")
      );
      setDisableStatus(true);
    }
  };

  const handleFollowupStatus = () => {
    setLoader(true);
    let monthtemp = followup.month.toString();
    let yearFormat = followup.year.toString();
    let monthFormat =
      followup.month <= 8
        ? "0" + (Number(monthtemp) + 1)
        : Number(monthtemp) + 1;
    let dayFormat = followup.day.toString();
    let followupDate = yearFormat + "-" + monthFormat + "-" + dayFormat;
    dispatch(
      getFollowRequest(
        moment.utc(followupDate, "YYYY-MM-DD").format(),
        bookingdet.bookingdate,
        bookingdet.slot_id,
        bookingdet.uuid
      )
    );
    if (
      (followupState.isError && followupState.status != undefined) ||
      followupState.status == "failure"
    ) {
      setLoader(false);
      setFollowSuccessPopup(false);
      setFollowFailurePopup(true);
    }
    if (followupState && followupState.status == "success") {
      setLoader(false);
      setFollowFailurePopup(false);
      setFollowSuccessPopup(true);
      setDisableStatus(true);
    }
  };

  const handlepatientRiskNote = () => {
    setPatientRiskNote(false);
  };

  const handleBloodPressure = () => {
    setdialogopen(true);
    setpopupTitle("Blood Pressure");
    setDialogData(phpData[0].observation.bloodPressure);
  };

  const handleTemperature = () => {
    setdialogopen(true);
    setpopupTitle("Temperature");
    setDialogData(phpData[0].observation.temperature);
  };

  const handleHeartRate = () => {
    setdialogopen(true);
    setpopupTitle("Heart Rate / Pulse Rate");
    setDialogData(phpData[0].observation.heartRate);
  };

  const handleFasting = () => {
    setdialogopen(true);
    setpopupTitle("Blood Sugar Levels          Fasting (Before breakfast)");
    setDialogData(phpData[0].observation.sugarLevel.fasting);
  };

  const handlePostPandrial = () => {
    setdialogopen(true);
    setpopupTitle(
      "Blood Sugar Levels          Post Pandrial (2 hours post meal)"
    );
    setDialogData(phpData[0].observation.sugarLevel.postPandrial);
  };

  const handleRandom = () => {
    setdialogopen(true);
    setpopupTitle("Blood Sugar Levels          Random Blood Sugar");
    setDialogData(phpData[0].observation.sugarLevel.random);
  };

  const oxygenLevel = () => {
    setdialogopen(true);
    setpopupTitle("Oxygen Saturation Level");
    setDialogData(phpData[0].observation.oxygenLevel);
  };

  const peakExpiratoryFlow = () => {
    setdialogopen(true);
    setpopupTitle("Peak Expiratory Flow");
    setDialogData(phpData[0].observation.peakExpiratoryFlow);
  };

  const handleWeignt = () => {
    setDialogWeight(true);
    setpopupTitle("Weight");
    setWeightData(phpData[0].weight);
  };

  const handleCloseDialog = () => {
    setdialogopen(false);
  };

  const handleWeigntClose = () => {
    setDialogWeight(false);
  };

  const handlefollowSuccessPopupClose = () => {
    setFollowSuccessPopup(false);
  };

  const handlefollowFailurePopupClose = () => {
    setFollowFailurePopup(false);
  };
  const trimDate = (value) => {
    if (value.length == 65) {
      return value + "...";
    } else {
      return value;
    }
  };

  const phpDataValidation = (value) => {
    if (value && value.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const handlechapQueryPop = (val) => {
    setSelectedPresc(val);
    setChapQueryPop(true);
  };
  const handlechapQueryPopClose = () => {
    setChapQueryPop(false);
  };
  const handlechapReviewMedPop = (val) => {
    setSelectedPresc(val);
    setChapReviewMedPop(true);
  };
  const handlechapReviewLabPop = (val) => {
    setSelectedPresc(val);
    setChapReviewLabPop(true);
  };
  const handlechapReviewMedPopClose = () => {
    setChapReviewMedPop(false);
  };
  const handlechapReviewLabPopClose = () => {
    setChapReviewLabPop(false);
  };
  const handleinternationalQueryPop = (val) => {
    setSelectedPresc(val);
    setInternationalQueryPop(true);
  };
  const handleinternationalQueryPopClose = () => {
    setInternationalQueryPop(false);
  };
  useEffect(() => {
    var bookdata = {
      fullname: userdata.full_name,
      email: userdata.email,
      leaveurl: "",
      zoomtype: "doctor",
    };
    let encdata = window.btoa(JSON.stringify(bookdata));
    setEncode(encdata);
  }, []);
  useEffect(() => {
    console.log(followSuccessPopup, "followSuccessPopup");
  }, [followSuccessPopup]);
  useEffect(() => {
    console.log(extendSuccess, "extendSuccess");
  }, [extendSuccess]);

  return (
    <>
    <Helmet>
      <title>doctor.doctall.com | Cockpit
</title>
<meta 
            name='description' 
            content='Login to view dashboard on the doctors portal,  see number of appointments, create appointment slots and patient case notes. Attend to patients, write and deliver prescription notes 
            '
            />
    </Helmet>
      <Layout data={routedata} loading={loader}>
        <Dialog
          disableBackdropClick
          style={{ borderRadius: "0px" }}
          fullScreen
          maxWidth={"xl"}
          fullWidth={true}
          open={openpdf}
          scroll={"body"}
          onClose={handlePdfClose}
        >
          <div className="ContainerWrapper">
            <img
              src={Close}
              alt="Close"
              className="Closepopup"
              onClick={() => handlePdfClose()}
              crossorigin="anonymous"
            />
            <DialogContent>
              <iframe src="" className="pdfiframe" id="pdf"></iframe>
            </DialogContent>
          </div>
        </Dialog>
        <Dialog
          disableBackdropClick
          maxWidth={"sm"}
          open={closepdf}
          scroll={"body"}
          onClose={failurePdfClose}
        >
          <div className="ContainerWrapper">
            <img
              src={Close}
              alt="Close"
              className="Closepopup"
              onClick={() => failurePdfClose()}
              crossorigin="anonymous"
            />
            <FailurePdf
              text={"Something Went Wrong!"}
              subtext={"Please Contact admin"}
            />
          </div>
        </Dialog>

        {routedata.type !== "local" && routedata.type !== "international" ? (
          <div className="row-cont">
            {/* <div className="DCL_BCStyle">
              <div className="BC-ImgC">
                <img className="BC_Img" src={CAdd} crossorigin="anonymous" />
              </div>
              <span className="BC_Span">Invite</span>
            </div> */}
            {/* <div className="DCL_BCStyle1">
              <div className="BC-ImgC">
                <img className="BC_Img" src={Plus} crossorigin="anonymous" />
              </div>
              <span className="BC_Span">Add user to the call</span>
            </div> */}

            <CountDown routedata={routedata} />

            <div className="top_cont_text">
              <span className="font_one">Appointment ID:</span>{" "}
              <span className="font_two">{routedata.uuid}</span>{" "}
            </div>
            <div className="top_cont_text" style={{ marginLeft: "84px" }}>
              <span className="font_one"> Appointment Type:</span>{" "}
              <span style={{ marginLeft: "5px" }} className="font_two">
                {routedata.type}
              </span>{" "}
            </div>
            <div className="top_cont_text" style={{ marginLeft: "84px" }}>
              <span className="font_one"> Date Created:</span>{" "}
              <span style={{ marginLeft: "5px" }} className="font_two">
                {" "}
                {moment(routedata.createdAt).format("ddd, DD MMM YYYY")}
              </span>{" "}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="DCL_Container DCL_C_Row">
          <div className="DCLC_Right DCL_C_Column">
            {routedata.type == "local" || routedata.type == "international" ? (
              <div className="DCLCR_Top DCL_C_Row">
                <div className="DCLCRT_Left DCL_C_Row">
                  {/* <div className="DCL_BCStyle">
                    <div className="BC-ImgC">
                      <img
                        className="BC_Img"
                        src={CAdd}
                        crossorigin="anonymous"
                      />
                    </div>
                    <span className="BC_Span">Invite</span>
                  </div> */}
                  {/* <div className="DCL_BCStyle1">
                    <div className="BC-ImgC">
                      <img
                        className="BC_Img"
                        src={Plus}
                        crossorigin="anonymous"
                      />
                    </div>
                    <span className="BC_Span">Add user to the call</span>
                  </div> */}
                </div>
                <CountDown routedata={routedata} />
              </div>
            ) : (
              ""
            )}
{console.log(routedata && routedata.zoomData && routedata.zoomData.roomUrl, "urlll")}
            <div className="DCLCR_Bottom">
              {/* {encodedata && <iframe
                src={`https://zoom.doctall.com/meeting/${routedata.zoomData.meetingId}/${routedata.zoomData.password}/${encodedata}`}
                title="Zoom meeting"
              ></iframe>} */}
              <iframe
              
              src={`/meeting/${window.btoa(routedata?.zoomData?.roomUrl)}`}
                // src={`/meeting/${routedata && routedata.zoomData && routedata.zoomData.meetingId}/${routedata && routedata.zoomData && routedata.zoomData.password}`}
                title="W3Schools Free Online Web Tutorials"
              ></iframe>
            </div>
          </div>
          <div className="DCLC_Left">
            <div className="DCLCL_Top DCL_C_Row">
              <div className="DCLCLT_Container DCL_C_Column">
                <div className="DCLCLTC_Header">Patient Details</div>
                <div className="DCLCLTC_ContentWraper">
                  <div className="DCLCLTC_Content">
                    <span className="DCLCLTC_Name">Name: </span>
                    <span className="DCLCLTC_Value">
                      {routedata && routedata.patient && routedata.patient.full_name && routedata.patient.full_name}
                    </span>
                  </div>
                  <div className="DCLCLTC_Content">
                    <span className="DCLCLTC_Name">Phone: </span>
                    <span className="DCLCLTC_Value">
                      {routedata && routedata.patient && routedata.patient.mobile && routedata.patient.mobile}
                    </span>
                    <SendIcon
                      className="DCLCLTC_SMS"
                      onClick={() => setSendSms(true)}
                    />
                  </div>
                  <div className="DCLCLTC_Content">
                    <span className="DCLCLTC_Name">Gender: </span>
                    <span className="DCLCLTC_Value">
                      {routedata && routedata.patient && routedata.patient.gender && routedata.patient.gender}
                    </span>
                  </div>
                  <div className="DCLCLTC_Content">
                    <span className="DCLCLTC_Name">Date: </span>
                    <span className="DCLCLTC_Value">
                      {moment(routedata.booking_date).format(
                        "ddd, DD MMM YYYY"
                      )}
                    </span>
                  </div>
                  <div className="DCLCLTC_Content">
                    <span className="DCLCLTC_Name">Time: </span>
                    <span className="DCLCLTC_Value">
                      {moment(routedata && routedata.start_time && routedata.start_time).format("hh:mm A")}
                    </span>
                  </div>
                  <div className="DCLCLTC_Content_L">
                    <span className="DCLCLTC_Name">Age: </span>
                    <span className="DCLCLTC_Value">
                      {getAge(
                        moment(routedata && routedata.patient && routedata.patient.gender && routedata.patient.dob).format("YYYY/MM/DD")
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="DCLCLT_Container DCL_C_Column">
                <div className="DCLCLTC_Header">Documents shared</div>
                <div className="DCLCLTC_ContentWraper">
                  {routedata &&
                    routedata.documents &&
                    routedata.documents.map((doc, index) => {
                      return (
                        <div key={index} className="DS_Content DCL_C_Row">
                          <div className="DCL_C_Row">
                            <img
                              className="DS1_Img"
                              src={PDF}
                              crossorigin="anonymous"
                            />
                          </div>
                          <div className="DSC_Div DCL_C_Row">
                            <span className="DS_text">{doc.docName}</span>
                            <img
                              onClick={() => {
                                try {
                                  window.open(doc.location.split("?")[0]);
                                } catch (error) {
                                  console.log(error)
                                }

                              }}
                              className="DS2_Img"
                              src={eye}
                              crossorigin="anonymous"
                            />
                          </div>
                        </div>
                      );
                    })}
                  {routedata &&
                    routedata.documents &&
                    routedata.documents.length == 0 && (
                      <div style={{ textAlign: "center", marginTop: 30 }}>
                        No Documents
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="DCLCL_Bottom1">
              <div className="DCLCLB_Text">Current issues</div>
              <div className="DCLCLB_Iptitle">
                {routedata && routedata.reasons && routedata.reasons.join(",")}
              </div>
              <div className="DCLCLB_Ipnotes">{routedata && routedata.desc && routedata.desc}</div>
            </div>
          </div>
        </div>
      </Layout>
      <div className="CD_Container">
        <div className="CD_Subcontainer">
          <div className="CD_SCont_1">
            <div className="HA_Title">Historical Appointments</div>
            <div className="HA_Content DCL_C_Row">
              <div class="HA_Left">
                <div class="HAL_Line"></div>
              </div>
              <div className="HA_Right">
                <div className="HAR_Space"></div>
                <div className="HAs_Container_Wraper">
                  <div className="HAs_History">
                    {hist === undefined ||
                      hist === [] ||
                      hist === "" ||
                      hist.length === 0 ? (
                      "No History of apointment Found"
                    ) : (
                      <>
                        <div className="title">Doctor Case Notes </div>
                        <div class="HASC_Dot history"></div>
                        {hist && hist.payload && hist.payload.doctor_case_note && hist.payload.doctor_case_note.length !== 0 ? (
                          hist.payload.doctor_case_note
                            .concat()
                            .reverse()
                            .map((i) => (
                              <>
                                <div className="value">
                                  <div className="name">
                                    <div className="text">
                                      Case Notes
                                      <br />
                                      {moment(i.createdAt).format(
                                        "ddd, DD MMM YYYY"
                                      )}{" "}
                                    </div>
                                    <div
                                      className="btn"
                                      id={i.uuid}
                                      onClick={handleHistoryCaseNote}
                                    >
                                      View
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                        ) : (
                          <div className="value">
                            <div className="name">
                              <div className="text">No Past data found</div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="HAs_Container_Wraper">
                  <div className="HAs_History">
                    {hist === undefined ||
                      hist === [] ||
                      hist === "" ||
                      hist.length === 0 ? (
                      "No History of apointment Found"
                    ) : (
                      <>
                        <div className="title">Psychiatry case note</div>
                        <div class="HASC_Dot history"></div>
                        {hist && hist.payload && hist.payload.psychiatry_case_note && hist.payload.psychiatry_case_note.length !== 0 ? (
                          hist.payload.psychiatry_case_note
                            .concat()
                            .reverse()
                            .map((i) => (
                              <>
                                <div className="value">
                                  <div className="name">
                                    <div className="text">
                                      Notes
                                      <br />
                                      <small>
                                        {moment(i.createdAt).format(
                                          "ddd, DD MMM YYYY"
                                        )}
                                      </small>
                                    </div>
                                    <div
                                      className="btn"
                                      id={i.uuid}
                                      onClick={handlePhYcaseNote}
                                    >
                                      View
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                        ) : (
                          <div className="value">
                            <div className="name">
                              <div className="text">No Past data found</div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="HAs_Container_Wraper">
                  <div className="HAs_History">
                    {hist === undefined ||
                      hist === [] ||
                      hist === "" ||
                      hist.length === 0 ? (
                      "No History of apointment Found"
                    ) : (
                      <>
                        <div className="title">Medicine report</div>
                        <div class="HASC_Dot history"></div>
                        {hist.payload.medicine.length !== 0 ? (
                          hist.payload.medicine
                            .concat()
                            .reverse()
                            .map((i) => (
                              <>
                                <div className="value">
                                  <div className="name">
                                    <div className="text">
                                      Medicine report <br />
                                      <small>
                                        {moment(i.createdAt).format(
                                          "ddd, DD MMM YYYY"
                                        )}
                                      </small>
                                    </div>
                                    <div
                                      className="btn"
                                      id={i.uuid}
                                      onClick={handleMedicineReport}
                                    >
                                      View
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                        ) : (
                          <div className="value">
                            <div className="name">
                              <div className="text">No Past data found</div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="HAs_Container_Wraper">
                  <div className="HAs_History">
                    {hist === undefined ||
                      hist === [] ||
                      hist === "" ||
                      hist.length === 0 ? (
                      "No History of apointment Found"
                    ) : (
                      <>
                        <div className="title">Lab report</div>
                        <div class="HASC_Dot history"></div>
                        {hist.payload.lab.length !== 0 ? (
                          hist.payload.lab
                            .concat()
                            .reverse()
                            .map((i) => (
                              <>
                                <div className="value">
                                  <div className="name">
                                    <div className="text">
                                      Lab report
                                      <br />
                                      <small>
                                        {moment(i.createdAt).format(
                                          "ddd, DD MMM YYYY"
                                        )}
                                      </small>
                                    </div>
                                    <div
                                      className="btn"
                                      id={i.uuid}
                                      onClick={handleHistoryLabNote}
                                    >
                                      View
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                        ) : (
                          <div className="value">
                            <div className="name">
                              <div className="text">No Past data found</div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="HAR_Space"></div>
              </div>
            </div>
          </div>
          <div className="CD_SCont_1">
            <div className="HA_Title">Patient Risk Note</div>
            <div className="HA_Content DCL_C_Row">
              <div className="HA_Left">
                <div className="HAL_Line"></div>
              </div>
              <div className="HA_Right">
                <div className="HAR_Space1">
                  <div
                    className="BC_ImgC"
                    onClick={() => {
                      checkChaproene() != "local"
                        ? handleRiskPopup()
                        : console.log("");
                    }}
                  >
                    <img
                      className="BC_Img"
                      src={Plus}
                      crossorigin="anonymous"
                    />
                  </div>
                  <span>Add PatientRisk Note</span>
                  <Dialog
                    maxWidth={"lg"}
                    open={addPopup}
                    scroll={"body"}
                    onClose={handleClosePopup}
                  >
                    <div className="ContainerWrapper">
                      <img
                        src={Close}
                        alt="Close"
                        className="Closepopup"
                        onClick={() => setAddPopup(false)}
                        crossorigin="anonymous"
                      />
                      <PatientRiskNotePopup setLoader={setLoader} />
                    </div>
                  </Dialog>
                </div>
                <div className="HAs_Container_Wraper">
                  {risknoteget &&
                    risknoteget.payload.length > 0 &&
                    risknoteget.payload.map((item, index) => {
                      return (
                        <div className="HAs_Container">
                          <div className="HASC_Dot"></div>
                          <div className="HAS_Left">
                            <div className="HASL_Content">
                              <span className="HASLC_Name">Date: </span>
                              <span className="HASLC_Value">
                                {moment(item.createdAt).format(
                                  "ddd, DD MMM YYYY"
                                )}
                              </span>
                            </div>
                            <div className="HASL_Content">
                              <span className="HASLC_Name">Doctor: </span>
                              <span className="HASLC_Value">
                                {routedata && routedata.doctors && routedata.doctors[0] && routedata.doctors[0].full_name}
                              </span>
                            </div>
                            <div
                              className="HASL_Read"
                              onClick={() => {
                                // setPatientRiskNote(true)
                                // setReadmore(item)
                                handleRiskReport(item.uuid);
                              }}
                            >
                              <span>
                                Read more{" "}
                                <img
                                  className="BC_Img"
                                  src={DGArrow}
                                  crossorigin="anonymous"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="HAS_Middle">
                            <div className="HASM_Div"></div>
                          </div>
                          <div className="HAS_Right">
                            <div className="PHP_Name">Risk Area</div>
                            {item.risk_area && item.risk_area.length > 0 ? (
                              <div className="PHP_Value">{item.risk_area}</div>
                            ) : (
                              <div className="PHP_Value">
                                {item.others_note && item.others_note.length >= 69
                                  ? item.others_note.slice(0, 70) + "..."
                                  : item.others_note}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="HAR_Space"></div>
              </div>
            </div>
          </div>
          <div className="CD_SCont_2">
            <div className="HA_Title">Patient Health Profile</div>
            <div className="HA_Cont_style">
              <div className="HA_Tab_Container">
                <AntTabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="ant example"
                >
                  <AntTab label="Personal" />
                  <AntTab label="Medical" />
                  <AntTab label="Lifestyle" />
                  {/* <AntTab label="Observations" />  */}
                </AntTabs>
              </div>
              {phpData && (
                <div className="PHP_Container">
                  {tab == 0 && (
                    <Fragment>
                      <div className="Container-WithHeader">
                        <div className="title">Personal Information</div>
                        <div className="rowdata">
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">First name</div>
                              <div className="PHP_Value">
                                {stData && stData.length !== 0
                                  ? stData[0] && stData[0].first_name
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer centeralign">
                            <div>
                              <div className="PHP_Name">Middle name</div>
                              <div className="PHP_Value">
                                {stData.length !== 0
                                  ? stData[0].middle_name
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer centeralign">
                            <div>
                              <div className="PHP_Name">Last name</div>
                              <div className="PHP_Value">
                                {stData.length !== 0
                                  ? stData[0].last_name
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rowdata">
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">Date of Birth</div>
                              <div className="PHP_Value">
                                {stData.length !== 0
                                  ? moment(stData && stData[0] && stData[0].dob).format("DD-MM-YYYY")
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">Gender</div>
                              <div className="PHP_Value">
                                {stData.length !== 0
                                  ? stData && stData[0] && stData[0].gender
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">Marital status</div>
                              <div className="PHP_Value">
                                {stData.length !== 0
                                  ? stData && stData[0] && stData[0].marital_status
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rowdata">
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">Blood group</div>
                              <div className="PHP_Value">
                                {stData.length !== 0
                                  ? stData && stData[0] && stData[0].blood_group
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">Height</div>
                              <div className="PHP_Value">
                                {stData && stData.length !== 0 && stData[0].height ? (
                                  <div>
                                    {stData && stData[0] && stData[0].height && stData[0].height[0] && stData[0].height[0].value}
                                    &nbsp;&nbsp;&nbsp;
                                    {stData && stData[0] && stData[0].height && stData[0].height[0] && stData[0].height[0].unit}{" "}
                                  </div>
                                ) : (
                                  "No Data"
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer">
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div className="Container-WithHeader">
                        <div className="title">Weight</div>
                        {stData && stData.length !== 0 && stData && stData[0] && stData[0].weight && Array.isArray(stData[0].weight)
                          ? stData[0].weight.map((data, index) => (
                            <div className="rowdata">
                              <div style={{ width: "100%" }}>
                                <div
                                  style={{ display: "flex", width: "100%" }}
                                >
                                  <div className="PHP_Suncontainer">
                                    <div>
                                      <div className="PHP_Name">Weight</div>
                                      <div className="PHP_Value">
                                        {data.value}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="PHP_Suncontainer centeralign">
                                    <div>
                                      <div className="PHP_Name">Date</div>
                                      <div className="PHP_Value">
                                        {moment(data.date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="PHP_Suncontainer centeralign">
                                    <div>
                                      <div className="PHP_Name">
                                        Body mass index
                                      </div>
                                      <div className="PHP_Value">
                                        {data.bmi}&nbsp;&nbsp;{data.unit}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="PHP_OB_BTN"
                                  style={{
                                    marginTop: "30px",
                                    display: "block",
                                    marginLeft: "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    className="PHP_OB_BTN_TXT"
                                    onClick={handleWeignt}
                                  >
                                    View History
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                          : <div className="rowdata">
                            <div style={{ width: "100%" }}>
                              <div
                                style={{ display: "flex", width: "100%" }}
                              >
                                <div className="PHP_Suncontainer">
                                  <div>
                                    <div className="PHP_Name">Weight</div>
                                    <div className="PHP_Value">
                                      {stData.length !== 0 && stData && stData[0] && stData[0].weight ? stData[0].weight : "No Data"}
                                    </div>
                                  </div>
                                </div></div></div></div>}
                      </div>
                      <div className="Container-WithHeader">
                        <div className="title">Contact information</div>
                        <div className="rowdata">
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">Phone</div>
                              <div className="PHP_Value">
                                {stData && stData.length !== 0
                                  ? stData[0] && stData[0].mobile
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer centeralign">
                            <div>
                              <div className="PHP_Name">Other Phone</div>
                              <div className="PHP_Value">
                                {stData.length !== 0
                                  ? stData[0] && stData[0].other_mobile
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer centeralign">
                            <div>
                              <div className="PHP_Name">Email</div>
                              <div className="PHP_Value">
                                {stData.length !== 0
                                  ? stData[0] && stData[0].email
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rowdata">
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">Secondary email</div>
                              <div className="PHP_Value">
                                {stData && stData.length !== 0
                                  ? stData[0] && stData[0].secondary_email
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">
                                Residential Address
                              </div>
                              <div className="PHP_Value">
                                {stData && stData.length !== 0 &&
                                  stData[0] && stData[0].residential_Address ? (
                                  <div>
                                    {stData[0] && stData[0].residential_Address.door_no}
                                    &nbsp;&nbsp;&nbsp;
                                    {stData[0] && stData[0].residential_Address.street}
                                    &nbsp;&nbsp;&nbsp;
                                    {stData[0] && stData[0].residential_Address.area}
                                  </div>
                                ) : (
                                  "No Data"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rowdata">
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">State</div>
                              <div className="PHP_Value">
                                {stData && stData.length !== 0 &&
                                  stData[0] && stData[0].residential_Address && stData[0].residential_Address.state && stData[0].residential_Address.state.name
                                  ? stData[0].residential_Address.state.name
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer">
                            <div>
                              <div className="PHP_Name">City</div>
                              <div className="PHP_Value">
                                {stData && stData.length !== 0 &&
                                  stData[0] && stData[0].residential_Address && stData[0].residential_Address.state && stData[0].residential_Address.city && stData[0].residential_Address.city.name
                                  ? stData[0].residential_Address.city.name
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                          <div className="PHP_Suncontainer">
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div className="Container-WithHeader">
                        <div className="title">Other information</div>
                        <div className="Container-WithHeader">
                          {stData.length !== 0 ? (
                            <>
                              <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                <div className="PHP_MD_HEADING">
                                  {stData && stData[0] && stData[0].personalQuestions && stData[0].personalQuestions[0] && stData[0].personalQuestions[0].question}
                                </div>
                                <div className="PHP_MD_TXT">
                                  {stData && stData[0] && stData[0].personalQuestions && stData[0].personalQuestions[0] && stData[0].personalQuestions[0].answer && stData[0].personalQuestions[0].answer.value}
                                </div>
                              </div>
                              <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                <div className="PHP_MD_HEADING">
                                  {stData && stData[0] && stData[0].personalQuestions && stData[0].personalQuestions[1] && stData[0].personalQuestions[1].question}
                                </div>
                                <div className="PHP_MD_TXT">
                                  {stData && stData[0] && stData[0].personalQuestions && stData[0].personalQuestions[0] && stData[0].personalQuestions[1].answer && stData[0].personalQuestions[1].answer.value}
                                </div>
                              </div>
                              <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                <div className="PHP_MD_HEADING">
                                  {stData && stData[0] && stData[0].personalQuestions && stData[0].personalQuestions[2] && stData[0].personalQuestions[2].question}
                                </div>
                                <div className="PHP_MD_TXT">
                                  {stData && stData[0] && stData[0].personalQuestions && stData[0].personalQuestions[2] && stData[0].personalQuestions[2].answer.map(
                                    (i) => i
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            "No Data"
                          )}
                        </div>
                      </div>
                    </Fragment>
                  )}
                  {tab == 1 && (
                    <Fragment>
                      <div className="Container-WithHeader">
                        {phpDataValidation(phpData && phpData)
                          ? phpData && phpData[0] && phpData[0].medicalQuestions && phpData[0].medicalQuestions.map((medical, index) =>
                            medical.question ==
                              "Have you had any surgeries in the past?" ||
                              medical.question ==
                              "Have you had any serious injuries in the past?" ? (
                              <div
                                className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER"
                                key={index}
                              >
                                <div className="PHP_MD_HEADING">
                                  {trimDate(medical.question && medical.question)}
                                </div>
                                <div
                                  className="PHP_MD_TXT"
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  {medical.answer != "" &&
                                    medical.answer.toLowerCase() != "no" &&
                                    JSON.parse(medical.answer).map(
                                      (item, index) => {
                                        return Object.keys(item).map(
                                          (rec, i) => {
                                            return (
                                              <div
                                                className="Medical"
                                                key={"Medical" + index + i}
                                              >
                                                <div className="mtitle">
                                                  {rec.replace(/_/g, " ")}
                                                </div>
                                                <div className="mvalue">
                                                  {item[rec] && item[rec]}
                                                </div>
                                              </div>
                                            );
                                          }
                                        );
                                      }
                                    )}
                                  {medical.answer.toLowerCase() == "no" && (
                                    <div className="PHP_MD_TXT">
                                      {trimDate(medical.answer && medical.answer)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div
                                className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER"
                                key={index}
                              >
                                <div className="PHP_MD_HEADING">
                                  {trimDate(medical.question && medical.question)}
                                </div>
                                <div className="PHP_MD_TXT">
                                  {trimDate(medical.answer && medical.answer)}
                                </div>
                              </div>
                            )
                          )
                          : "No Data"}
                      </div>
                    </Fragment>
                  )}
                  {tab == 2 && (
                    <Fragment>
                      <div className="Container-WithHeader">
                        {phpDataValidation(phpData)
                          ? phpData && phpData[0] && phpData[0].lifestyleQuestions && phpData[0].lifestyleQuestions.map(
                            (lifestyleQuestions, index) => (
                              <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                <div className="PHP_MD_HEADING">
                                  {trimDate(lifestyleQuestions.question)}
                                </div>
                                <div className="PHP_MD_TXT">
                                  {trimDate(lifestyleQuestions.answer)}
                                </div>
                              </div>
                            )
                          )
                          : "No Data"}
                      </div>
                    </Fragment>
                  )}
                  {/* {
                                    tab == 3 && <Fragment>
                                        <div className="Container-WithHeader">


                                            <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                                <div className="PHP_MD_HEADING">
                                                    Blood Pressure
                                                </div>
                                                {phpDataValidation(phpData) ? <div className="PHP_OB">
                                                    <div className="PHP_OB_TXT" style={{ width: '104.91px' }}>
                                                        {phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) && phpDataValidation(phpData[0].observation.bloodPressure) ? phpData[0].observation.bloodPressure[0].value : ""}&nbsp;&nbsp;&nbsp;{phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) && phpDataValidation(phpData[0].observation.bloodPressure) ? phpData[0].observation.bloodPressure[0].unit : 'No Data'}
                                                    </div>
                                                    <div className="PHP_OB_DIV"></div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) && phpDataValidation(phpData[0].observation.bloodPressure) ? moment(phpData[0].observation.bloodPressure[0].date).format("ddd, DD MMM YYYY") : 'No Data'
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) && phpDataValidation(phpData[0].observation.bloodPressure) ? phpData[0].observation.bloodPressure[0].time : 'No Data'
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_BTN">
                                                        <p className="PHP_OB_BTN_TXT" onClick={handleBloodPressure}>View History</p>
                                                    </div>

                                                </div> : 'No Data'}
                                            </div>

                                            <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                                <div className="PHP_MD_HEADING">
                                                    Temperature
                                                </div>
                                                {phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) ? <div className="PHP_OB">
                                                    <div className="PHP_OB_TXT" style={{ width: '104.91px' }} >
                                                        {phpDataValidation(phpData[0].observation.temperature) ? phpData[0].observation.temperature[0].value : ""}&nbsp;&nbsp;&nbsp;{phpDataValidation(phpData[0].observation.temperature) ? phpData[0].observation.temperature[0].unit : "No Data"}

                                                    </div>
                                                    <div className="PHP_OB_DIV"></div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].observation.temperature) ? moment(phpData[0].observation.temperature[0].date).format("ddd, DD MMM YYYY") : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_TXT" >
                                                        {
                                                            phpDataValidation(phpData[0].observation.temperature) ? phpData[0].observation.temperature[0].time : ""

                                                        }
                                                    </div>
                                                    <div className="PHP_OB_BTN">
                                                        <p className="PHP_OB_BTN_TXT" onClick={handleTemperature} >View History</p>
                                                    </div>

                                                </div> : 'No Data'}


                                            </div>

                                            <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                                <div className="PHP_MD_HEADING">
                                                    Heart Rate / Pulse Rate
                                                </div>
                                                {phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) ? <div className="PHP_OB">
                                                    <div className="PHP_OB_TXT" style={{ width: '104.91px' }} >
                                                        {phpDataValidation(phpData[0].observation.heartRate) ? phpData[0].observation.heartRate[0].value : ""}&nbsp;&nbsp;&nbsp;{phpDataValidation(phpData[0].observation.heartRate) ? phpData[0].observation.heartRate[0].unit : "No Data"}
                                                    </div>
                                                    <div className="PHP_OB_DIV"></div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].observation.heartRate) ? moment(phpData[0].observation.heartRate[0].date).format("ddd, DD MMM YYYY") : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].observation.heartRate) ? phpData[0].observation.heartRate[0].time : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_BTN">
                                                        <p className="PHP_OB_BTN_TXT" onClick={handleHeartRate}>View History</p>
                                                    </div>

                                                </div> : 'No Data'}


                                            </div>

                                            <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                                <div className="PHP_MD_HEADING">
                                                    Blood Sugar Levels &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fasting (Before breakfast)
                                                </div>
                                                {phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) ? <div className="PHP_OB">
                                                    <div className="PHP_OB_TXT" style={{ width: '104.91px' }} >
                                                        {phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.fasting) ? phpData[0].observation.sugarLevel.fasting[0].value : ""}&nbsp;&nbsp;&nbsp;{phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.fasting) ? phpData[0].observation.sugarLevel.fasting[0].unit : "No Data"}
                                                    </div>
                                                    <div className="PHP_OB_DIV"></div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.fasting) ? moment(phpData[0].observation.sugarLevel.fasting[0].date).format("ddd, DD MMM YYYY") : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.fasting) ? phpData[0].observation.sugarLevel.fasting[0].time : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_BTN">
                                                        <p className="PHP_OB_BTN_TXT" onClick={handleFasting}>View History</p>
                                                    </div>

                                                </div> : 'No Data'}


                                            </div>

                                            <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                                <div className="PHP_MD_HEADING">
                                                    Blood Sugar Levels &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Post Pandrial (2 hours post meal)
                                                </div>
                                                {phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) ? <div className="PHP_OB">
                                                    <div className="PHP_OB_TXT" style={{ width: '104.91px' }} >
                                                        {phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.postPandrial) ? phpData[0].observation.sugarLevel.postPandrial[0].value : ""}&nbsp;&nbsp;&nbsp;{phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.postPandrial) ? phpData[0].observation.sugarLevel.postPandrial[0].unit : "No Data"}
                                                    </div>
                                                    <div className="PHP_OB_DIV"></div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.postPandrial) ? moment(phpData[0].observation.sugarLevel.postPandrial[0].date).format("ddd, DD MMM YYYY") : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.postPandrial) ? phpData[0].observation.sugarLevel.postPandrial[0].time : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_BTN">
                                                        <p className="PHP_OB_BTN_TXT" onClick={handlePostPandrial} >View History</p>
                                                    </div>

                                                </div> : 'No Data'}


                                            </div>

                                            <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                                <div className="PHP_MD_HEADING">
                                                    Blood Sugar Levels &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Random Blood Sugar
                                                </div>
                                                {phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) ? <div className="PHP_OB">
                                                    <div className="PHP_OB_TXT" style={{ width: '104.91px' }} >
                                                        {phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.random) ? phpData[0].observation.sugarLevel.random[0].value : ""}&nbsp;&nbsp;&nbsp;{phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.random) ? phpData[0].observation.sugarLevel.random[0].unit : "No Data"}
                                                    </div>
                                                    <div className="PHP_OB_DIV"></div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.random) ? moment(phpData[0].observation.sugarLevel.random[0].date).format("ddd, DD MMM YYYY") : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].sugarLevel ? Object.keys(phpData[0].sugarLevel) : false) && phpDataValidation(phpData[0].observation.sugarLevel.random) ? phpData[0].observation.sugarLevel.random[0].time : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_BTN">
                                                        <p className="PHP_OB_BTN_TXT" onClick={handleRandom}>View History</p>
                                                    </div>

                                                </div> : 'No Data'}


                                            </div>

                                            <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                                <div className="PHP_MD_HEADING">
                                                    Oxygen Saturation Level
                                                </div>
                                                {phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) ? <div className="PHP_OB">
                                                    <div className="PHP_OB_TXT" style={{ width: '104.91px' }} >
                                                        {phpDataValidation(phpData[0].observation.oxygenLevel) ? phpData[0].observation.oxygenLevel[0].value : ""}&nbsp;&nbsp;&nbsp;{phpDataValidation(phpData[0].observation.oxygenLevel) ? phpData[0].observation.oxygenLevel[0].unit : "No Data"}
                                                    </div>
                                                    <div className="PHP_OB_DIV"></div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].observation.oxygenLevel) ? moment(phpData[0].observation.oxygenLevel[0].date).format("ddd, DD MMM YYYY") : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].observation.oxygenLevel) ? phpData[0].observation.oxygenLevel[0].time : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_BTN">
                                                        <p className="PHP_OB_BTN_TXT" onClick={oxygenLevel}>View History</p>
                                                    </div>

                                                </div> : 'No Data'}


                                            </div>

                                            <div className="PHP_MD_CONTAINER PHP_MD_CONTAINER_BORDER">
                                                <div className="PHP_MD_HEADING">
                                                    Peak Expiratory Flow
                                                </div>
                                                {phpDataValidation(phpData) && phpDataValidation(phpData[0].observation ? Object.keys(phpData[0].observation) : false) ? <div className="PHP_OB">
                                                    <div className="PHP_OB_TXT" style={{ width: '104.91px' }} >
                                                        {phpDataValidation(phpData[0].observation.peakExpiratoryFlow) ? phpData[0].observation.peakExpiratoryFlow[0].value : ""}&nbsp;&nbsp;&nbsp;{phpDataValidation(phpData[0].observation.peakExpiratoryFlow) ? phpData[0].observation.peakExpiratoryFlow[0].unit : "No Data"}
                                                    </div>
                                                    <div className="PHP_OB_DIV"></div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].observation.peakExpiratoryFlow) ? moment(phpData[0].observation.peakExpiratoryFlow[0].date).format("ddd, DD MMM YYYY") : ""

                                                        }
                                                    </div>
                                                    <div className="PHP_OB_TXT">
                                                        {
                                                            phpDataValidation(phpData[0].observation.peakExpiratoryFlow) ? phpData[0].observation.peakExpiratoryFlow[0].time : ""
                                                        }
                                                    </div>
                                                    <div className="PHP_OB_BTN">
                                                        <p className="PHP_OB_BTN_TXT" onClick={peakExpiratoryFlow}>View History</p>
                                                    </div>

                                                </div> : 'No Data'}


                                            </div>


                                        </div>
                                    </Fragment>
                                } */}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="CD_Subcontainer">
          <div className="CD2_SCont_2">
            <div className="HA_Tab_Container_1">
              <AntTabs
                value={tab2}
                onChange={handleTabChange2}
                aria-label="ant example"
              >
                <AntTab2 label="Create Case Notes" />
                <AntTab2 label="Psychiatry Case Notes" />
              </AntTabs>
            </div>
            <div className="HA_Cont_style1">
              {tab2 == 0 && (
                <Fragment>
                  {caseN == "" || caseN.payload == "" ? (
                    <>
                      {" "}
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Chief complaint:</div>
                        <div className="PHP_Value"></div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          History of patient complaint:
                        </div>
                        <div className="PHP_Value"></div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Diagnosis:</div>
                        <div className="PHP_Value"></div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Plan/Action:</div>
                        <div className="PHP_Value"></div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Other information:</div>
                        <div className="PHP_Value"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Chief complaint:</div>
                        <div className="PHP_Value">
                          {caseN.payload[0].chief_complaint}
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          History of patient complaint:
                        </div>
                        <div className="PHP_Value">
                          {caseN.payload[0].patient_complaint}
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Diagnosis:</div>
                        <div className="PHP_Value">
                          {caseN.payload[0].diagnosis}
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Plan/Action:</div>
                        <div className="PHP_Value">
                          {caseN.payload[0].plan_action}
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Other information:</div>
                        <div className="PHP_Value">
                          {caseN.payload[0].others}
                        </div>
                      </div>{" "}
                    </>
                  )}
                  {checkChaproene() != "local" ? (
                    <>
                      {routedata.status === "booked" ? (
                        <div
                          className="CCN_Editbtn"
                          onClick={() => handleCasePopup()}
                        >
                          Create Case Notes
                        </div>
                      ) : (
                        <div
                          className="CCN_Editbtn"
                          style={{ opacity: "50%" }}
                          onClick={() => alert("booking as been changed")}
                        >
                          Create Case Notes
                        </div>
                      )}
                    </>
                  ) : null}
                  <Dialog
                    maxWidth={"lg"}
                    open={addCasePopup}
                    scroll={"body"}
                    onClose={handleCloseCasePopup}
                  >
                    <div className="ContainerWrapper">
                      <img
                        src={Close}
                        alt="Close"
                        className="Closepopup"
                        onClick={() => setCaseAddPopup(false)}
                        crossorigin="anonymous"
                      />
                      <DoctoreCaseNotes
                        data={docCase}
                        handleCloseCasePopup={handleCloseCasePopup}
                        setLoader={setLoader}
                        handleSuccessPopup={handleSuccessPopup}
                      />
                    </div>
                  </Dialog>
                </Fragment>
              )}

              {tab2 == 1 && (
                <Fragment>
                  {!caseP ||
                    caseP == "" ||
                    !caseP.payload ||
                    caseP.payload == "" ? (
                    <>
                      {" "}
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Presenting complaints:</div>
                        <div className="PHP_Value"></div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">History:</div>
                        <div className="PHP_Value"></div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Past Psychiatry history:
                        </div>
                        <div className="PHP_Value"></div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Past Medical history:</div>
                        <div className="PHP_Value"></div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Family history:</div>
                        <div className="PHP_Value"></div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Personal history:</div>
                        <div className="PHP_Value"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Presenting complaints:</div>
                        <div className="PHP_Value">
                          {caseP && caseP.payload && caseP.payload[0] && caseP.payload[0].present_comp}
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">History:</div>
                        <div className="PHP_Value">
                          {caseP && caseP.payload && caseP.payload[0] && caseP.payload[0].his_of_present_comp}
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">
                          Past Psychiatry history:
                        </div>
                        <div className="PHP_Value">
                          {caseP && caseP.payload && caseP.payload[0] && caseP.payload[0].past_psy_his}
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Past Medical history:</div>

                        <div className="PHP_Value">
                          {caseP && caseP.payload && caseP.payload[0] && caseP.payload[0].past_medi_his}
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Family history:</div>

                        <div className="PHP_Value">
                          {caseP && caseP.payload && caseP.payload[0] && caseP.payload[0].family_his}
                        </div>
                      </div>
                      <div className="PHP_Suncontainer1">
                        <div className="PHP_Name">Personal history:</div>

                        <div className="PHP_Value">
                          {caseP && caseP.payload && caseP.payload[0] && caseP.payload[0].personal_his}
                        </div>
                      </div>
                    </>
                  )}

                  {checkChaproene() !== "local" ? (
                    <>
                      {routedata.status === "booked" ? (
                        <div
                          className="CCN_Editbtn"
                          onClick={() => handleaddPsychiatry()}
                        >
                          Create Case Notes
                        </div>
                      ) : (
                        <div
                          className="CCN_Editbtn"
                          style={{ opacity: "50%" }}
                          onClick={() => alert("booking as been changed")}
                        >
                          Create Case Notes
                        </div>
                      )}
                    </>
                  ) : null}
                  <Dialog
                    maxWidth={"lg"}
                    open={addPsychiatry}
                    scroll={"body"}
                    onClose={handleCloseaddPsychiatry}
                  >
                    <div className="ContainerWrapper">
                      <img
                        src={Close}
                        alt="Close"
                        className="Closepopup"
                        onClick={() => setAddPshychiatry(false)}
                        crossorigin="anonymous"
                      />
                      <PsychiatryCaseNotes
                        setLoader={setLoader}
                        handleCloseaddPsychiatry={handleCloseaddPsychiatry}
                        handlePhySuccessPopup={handlePhySuccessPopup}
                      />
                    </div>
                  </Dialog>
                </Fragment>
              )}
            </div>
          </div>
          <div className="CD2_SCont_1">
            <div className="HA_Title">
              {checkChaproene() == "local" ? "View" : "Write"} Prescription/Lab
              Test Advice
            </div>

            {checkChaproene() == "local" ? (
              <div style={{ height: 515, overflow: "auto" }}>
                {chapMedPre && chapMedPre != undefined &&
                  chapMedPre != "" &&
                  chapMedPre.length > 0
                  ? chapMedPre.map((val, ind) => {
                    return (
                      <div
                        className="LocalPrescription"
                        key={"localper" + ind}
                      >
                        <div className="ContOne">
                          <img src={PDF} crossorigin="anonymous" />
                          <div className="CCND_Text">
                            {val.type == "medicine"
                              ? "Medicine Prescription"
                              : "Lab Test"}
                          </div>
                        </div>
                        <div className="ContTwo">
                          <div
                            className="ButtonLocal"
                            onClick={() => {
                              val.type == "medicine"
                                ? handlechapReviewMedPop(val)
                                : handlechapReviewLabPop(val);
                            }}
                          >
                            Review
                            {val.type == "medicine"
                              ? " Prescription"
                              : " Lab"}
                          </div>
                          <div
                            className={
                              val.approve_status === true
                                ? "disable_rev"
                                : "ButtonLocal"
                            }
                            onClick={() => handlechapQueryPop(val)}
                          >
                            Respond Query
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : null}


                <Dialog
                  open={chapReviewMedPop}
                  onClose={handlechapReviewMedPopClose}
                  classes={{
                    paper: classes.dialogPaper,
                  }}
                  scroll={"body"}
                  fullWidth={true}
                  maxWidth={"xl"}
                >
                  <div className="ContainerWrapper">
                    <img
                      src={Close}
                      alt="Close"
                      className="Closepopup"
                      onClick={() => setChapReviewMedPop(false)}
                      crossorigin="anonymous"
                    />
                    <PrescriptionChaperone
                      selected={selectedPresc}
                      routedata={routedata}
                      openQuery={(val) => {
                        handlechapReviewMedPopClose();
                        setTimeout(() => handlechapQueryPop(val));
                      }}
                      close={() => {
                        handlechapReviewMedPopClose();
                      }}
                      chapdet={selectedPresc}
                    />
                  </div>
                </Dialog>
                <Dialog
                  open={chapReviewLabPop}
                  onClose={handlechapReviewLabPopClose}
                  classes={{
                    paper: classes.dialogPaper,
                  }}
                  scroll={"body"}
                  fullWidth={true}
                  maxWidth={"xl"}
                >
                  <div className="ContainerWrapper">
                    <img
                      src={Close}
                      alt="Close"
                      className="Closepopup"
                      onClick={() => setChapReviewLabPop(false)}
                      crossorigin="anonymous"
                    />
                    <LabChaperonePop
                      selected={selectedPresc}
                      routedata={routedata}
                      openQuery={(val) => {
                        handlechapReviewLabPopClose();
                        setTimeout(() => handlechapQueryPop(val));
                      }}
                      close={() => {
                        handlechapReviewLabPopClose();
                      }}
                      chapdet={selectedPresc}
                    />
                  </div>
                </Dialog>
                <Dialog
                  open={chapQueryPop}
                  onClose={handlechapQueryPopClose}
                  classes={{
                    paper: classes.dialogPaper,
                  }}
                  PaperProps={{
                    style: {
                      borderRadius: "0px",
                    },
                  }}
                  scroll={"body"}
                  fullWidth={true}
                  maxWidth={"xl"}
                >
                  <div className="ContainerWrapper">
                    <img
                      src={Close}
                      alt="Close"
                      className="Closepopup"
                      onClick={() => setChapQueryPop(false)}
                      crossorigin="anonymous"
                    />
                    <ChaperonePop
                      epId={selectedPresc.uuid}
                      type={selectedPresc.type}
                      docId={routedata.doctor_uuid}
                      localDoc={routedata.local_doctor_uuid}
                      bookingId={routedata.uuid}
                      patientId={routedata.patient_uuid}
                      createdId={userdata.uuid}
                      routedata={routedata}
                    />
                  </div>
                </Dialog>
              </div>
            ) : (
              <div className="HA_Cont_style1">
                <>
                  {routedata && routedata.status && routedata.status === "booked" && disablestatus === false && createPrescriptionDisable === false ? (
                    <div
                      className="CCN_Search"
                      onClick={() => handleMedicalPrescriptionOpen()}
                    >
                      <div className="CCNS_Text1">
                        Create Medicine Prescription
                      </div>
                      <div className="CCNS_LI">
                        <img src={Plus} crossorigin="anonymous" />
                      </div>
                    </div>
                  ) : (
                    <div className="CCN_Search" style={{ opacity: "50%" }}>
                      <div className="CCNS_Text1">
                        Create Medicine Prescription
                      </div>
                      <div className="CCNS_LI">
                        <img src={Plus} crossorigin="anonymous" />
                      </div>
                    </div>
                  )}
                </>
                <>

                  {routedata && routedata.status && routedata.status === "booked" && disablestatus === false && createLabDisable === false ? (
                    <div className="CCN_Search" onClick={() => handleLabOpen()}>
                      <div className="CCNS_Text1">Create Lab Test Advice</div>
                      <div className="CCNS_LI">
                        <img src={Plus} crossorigin="anonymous" />
                      </div>
                    </div>
                  ) : (
                    <div className="CCN_Search" style={{ opacity: "50%" }}>
                      <div className="CCNS_Text1">Create Lab Test Advice</div>
                      <div className="CCNS_LI">
                        <img src={Plus} crossorigin="anonymous" />
                      </div>
                    </div>
                  )}

                  {/* New UI */}
                  <div className="HAs_Container_Wraper">
                    <div className="HAs_History">
                      {hist === undefined ||
                        hist === [] ||
                        hist === "" ||
                        hist.length === 0 ? (
                        "No History of apointment Found"
                      ) : (
                        <>
                          <div className="title">Medicine report</div>
                          {/* <div ></div> */}
                          {prescriptionList && prescriptionList.length !== 0 ? (
                            prescriptionList
                              .concat()
                              .reverse()
                              .map((i, index) => (
                                <>
                                  {i.booking_uuid === routedata.uuid && <div className="value">
                                    <div className="name">
                                      <div className="text">
                                        Medicine report <br />
                                        <small>
                                          {moment(i.createdAt).format(
                                            "ddd, DD MMM YYYY"
                                          )}
                                        </small>
                                      </div>
                                      <div
                                        className="btn"
                                        id={i.uuid}
                                        onClick={handleMedicineReport}
                                      >
                                        View
                                      </div>
                                      {disablestatus === false && <div
                                        className="btn"
                                        id={i.uuid}
                                        onClick={() => handleMedicalPrescriptionOpen(i.uuid)}
                                      >
                                        Edit
                                      </div>}
                                    </div>
                                  </div>}
                                </>
                              ))
                          ) : (
                            <div className="value">
                              <div className="name">
                                <div className="text">No Past data found</div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="HAs_Container_Wraper">
                    <div className="HAs_History">
                      {hist === undefined ||
                        hist === [] ||
                        hist === "" ||
                        hist.length === 0 ? (
                        "No History of apointment Found"
                      ) : (
                        <>
                          <div className="title">Lab report</div>
                          {/* <div class="HASC_Dot history"></div> */}
                          {labHistoryList && labHistoryList.length !== 0 ? (
                            labHistoryList
                              .concat()
                              .reverse()
                              .map((i, index) => (
                                <>
                                  {i.booking_uuid === routedata.uuid && <div className="value">
                                    <div className="name">
                                      <div className="text">
                                        Lab report
                                        <br />
                                        <small>
                                          {moment(i.createdAt).format(
                                            "ddd, DD MMM YYYY"
                                          )}
                                        </small>
                                      </div>
                                      <div
                                        className="btn"
                                        id={i.uuid}
                                        onClick={handleHistoryLabNote}
                                      >
                                        View
                                      </div>

                                      {disablestatus === false && <div
                                        className="btn"
                                        id={i.uuid}
                                        onClick={() => handleLabOpen(i.uuid)}
                                      >
                                        Edit
                                      </div>}
                                    </div>
                                  </div>}
                                </>
                              ))
                          ) : (
                            <div className="value">
                              <div className="name">
                                <div className="text">No Past data found</div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {/*  */}

                </>

                {/* Create Lab Pop up */}

                <Dialog
                  open={labPopup}
                  onClose={handleLabClose}
                  maxWidth={"lg"}
                  fullWidth={true}
                  scroll={"body"}
                  disableBackdropClick
                >
                  <div className="ContainerWrapper">
                    <img
                      src={Close}
                      alt="Close"
                      className="Closepopup"
                      onClick={() => setLabpopup(false)}
                      crossorigin="anonymous"
                    />
                    <LabPres data={routedata} id={labAdviceId} />
                  </div>
                </Dialog>

                {/*  */}


                <Dialog
                  open={medicalPrescriptionPopup}
                  onClose={handleMedicalPrescriptionClose}
                  maxWidth={"lg"}
                  scroll={"body"}
                  disableBackdropClick
                >
                  <div className="ContainerWrapper">
                    <img
                      src={Close}
                      alt="Close"
                      className="Closepopup"
                      onClick={() => setMedicalPrescriptionPopup(false)}
                      crossorigin="anonymous"
                    />
                    <MedicalPrescription
                      data={routedata}
                      closefn={setMedicalPrescriptionPopup}
                      medlist={chapMedPre}
                      id={medicineReportId}
                    />
                  </div>
                </Dialog>
                {/* chaproan QueryView Code */}
                
                {chapMedPre && chapMedPre != undefined &&
                  chapMedPre != "" &&
                  chapMedPre.length > 0
                  ? chapMedPre.map((val, ind) => {
                    return (
                      <>
                        <div className="CCN_Doct">
                          <div className="ccn_wrap">
                            <div className="CCND_LI">
                              <img src={PDF} crossorigin="anonymous" />
                            </div>
                            <div className="CCND_Text">
                              {val.type === "medicine"
                                ? "Medicine Prescription"
                                : "Lab Test"}
                            </div>

                            {val.type === "medicine" ? (
                              <div
                                className="CCND1_Rev"
                                style={{ cursor: "pointer" }}
                                id={val.uuid}
                                onClick={handleMedicineReport}
                              >
                                Review
                              </div>
                            ) : (
                              <div
                                className="CCND1_Rev"
                                style={{ cursor: "pointer" }}
                                id={val.uuid}
                                onClick={handleHistoryLabNote}
                              >
                                Review
                              </div>
                            )}
                          </div>

                          <div className="ccn_wrap_one">
                            {val.query_status && val.query_status ? (
                              <div
                                // className=""
                                className={
                                  val.approve_status && val.approve_status === true
                                    ? "int_btnn_dis"
                                    : "int_btnn"
                                }
                                onClick={() =>
                                  handleinternationalQueryPop(val)
                                }
                              // style={}
                              >
                                <span>View and Respond to Queries</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    );
                  })
                  : ""}
                <Dialog
                  open={internationalQueryPop}
                  onClose={handleinternationalQueryPopClose}
                  classes={{
                    paper: classes.dialogPaper,
                  }}
                  PaperProps={{
                    style: {
                      borderRadius: "0px",
                    },
                  }}
                  scroll={"body"}
                  fullWidth={true}
                  maxWidth={"xl"}
                >
                  <div className="ContainerWrapper">
                    <img
                      src={Close}
                      alt="Close"
                      className="Closepopup"
                      onClick={() => handleinternationalQueryPopClose()}
                      crossorigin="anonymous"
                    />
                    <ChaperonePop
                      epId={selectedPresc && selectedPresc.uuid && selectedPresc.uuid}
                      type={selectedPresc && selectedPresc.type && selectedPresc.type}
                      docId={routedata && routedata.doctor_uuid && routedata.doctor_uuid}
                      localDoc={routedata && routedata.local_doctor_uuid && routedata.local_doctor_uuid}
                      bookingId={routedata && routedata.uuid && routedata.uuid}
                      patientId={routedata && routedata.patient_uuid && routedata.patient_uuid}
                      createdId={userdata && userdata.uuid && userdata.uuid}
                      setLoader={props && props.setLoader && props.setLoader}
                      routedata={routedata && routedata}
                    />
                  </div>
                </Dialog>
              </div>
            )}
          </div>
          <div className="CD2_SCont_1">
            <div className="HA_Tab_Container_1">
              <AntTabs
                value={tab3}
                onChange={handleTabChange3}
                aria-label="ant example"
              >
                <AntTab label="Appointment Status" />
                <AntTab label="Chat" />
              </AntTabs>
            </div>
            <div className="HA_Cont_style1">
              {tab3 == 0 && (
                <Fragment>
                  <div
                    className={appoint == "Extent" ? "CCN_Doc_Sel" : "CCN_Doc1"}
                    onClick={() => {
                      checkChaproene() != "local"
                        ? setAppoint(appoint == "Extent" ? "" : "Extent")
                        : console.log("");
                    }}
                  >
                    <div className="CCND1_Text1">
                      Extend Appointment{" "}
                      {` ${extentState && extentState != undefined &&
                        extentState.status != undefined &&
                        extentState.status == "success"
                        ? extenttext
                        : ""
                        }`}
                    </div>
                    <div className="CCND1_Down">
                      <img
                        src={appoint == "Extent" ? DGUpArrow : DGArrow}
                        crossorigin="anonymous"
                      />
                    </div>
                  </div>
                  {appoint == "Extent" && (
                    <div className="CCN_Doc_Des_extent">
                      <div className="CCNDS_Head">
                        Enter number of Months or weeks till Follow up
                        appointment
                      </div>
                      <div className="CCNDS_Text">
                        NOTE: Set a time period after which you would like to
                        see the patient for a follow-up appointment
                      </div>
                      <div
                        style={{ display: "inline-block" }}
                        className={
                          routedata && routedata.status == "extended"
                            ? "timedropdown disable_drop"
                            : "timedropdown"
                        }
                      >
                        <DurationDrop
                          setTimeValue={(e) => {
                            setExtentPop(e);
                          }}
                          timevalue={extentpop && extentpop}
                          routedata={routedata && routedata}
                          showextent={showextent && showextent}
                        />
                      </div>

                      <div
                        className={
                          routedata && routedata.status && routedata.status == "booked" &&
                            extentpop !== "" &&
                            disablestatus == false
                            ? "CCNS_Submit"
                            : "CCNS_submit_disable"
                        }
                        style={{ display: "inline-block" }}
                        onClick={() => handleExtentAppointment()}
                      >
                        <span>Submit</span>
                      </div>
                      <Dialog
                        maxWidth={"lg"}
                        open={extendFailure}
                        scroll={"body"}
                        onClose={handleExtendFailureClosePop}
                      >
                        <div className="ContainerWrapper">
                          <img
                            src={Close}
                            alt="Close"
                            className="Closepopup"
                            onClick={() => setExtendFailure(false)}
                            crossorigin="anonymous"
                          />
                          <FailureStatus />
                        </div>
                      </Dialog>
                      <Dialog
                        maxWidth={"lg"}
                        open={extendSuccess}
                        scroll={"body"}
                        onClose={handleExtendSuccessClosePop}
                      >
                        <div className="ContainerWrapper">
                          <img
                            src={Close}
                            alt="Close"
                            className="Closepopup"
                            onClick={() => setExtendSuccess(false)}
                            crossorigin="anonymous"
                          />
                          <ExtendSuccess />
                        </div>
                      </Dialog>
                    </div>
                  )}
                  <div
                    className={
                      appoint == "SetFollow" ? "CCN_Doc_Sel" : "CCN_Doc1"
                    }
                    onClick={() => {
                      checkChaproene() != "local" &&
                        setAppoint(appoint == "SetFollow" ? "" : "SetFollow");
                    }}
                  >
                    <div className="CCND1_Text1">Set follow up status</div>
                    <div className="CCND1_Down">
                      <img
                        src={appoint == "SetFollow" ? DGUpArrow : DGArrow}
                        crossorigin="anonymous"
                      />
                    </div>
                  </div>
                  {appoint == "SetFollow" && (
                    <div className="CCN_Doc_DesFollow">
                      <div className="CCNDS_Head">
                        Enter number of Months or weeks till Follow up
                        appointment
                      </div>
                      <div className="CCNDS_Text">
                        NOTE: Set a time period after which you would like to
                        see the patient for a follow-up appointment
                      </div>
                      <div>
                        <Dropdownfn
                          setFollowup={(e) => {
                            setFollowup(e);
                            setFollowvalue(true);
                          }}
                          state={followup}
                          routedata={routedata}
                          followchange={setFollowchange}
                        />
                      </div>

                      <div
                        className={
                          routedata && routedata.status && routedata.status == "booked" &&
                            disablestatus == false &&
                            followchange == true
                            ? "CCNS_Submit"
                            : "CCNS_submit_disable"
                        }
                        style={{ width: "88px" }}
                        onClick={() => handleFollowupStatus()}
                      >
                        <span>Submit</span>
                      </div>
                      <Dialog
                        maxWidth={"lg"}
                        open={followSuccessPopup}
                        scroll={"body"}
                        onClose={handlefollowSuccessPopupClose}
                      >
                        <div className="ContainerWrapper">
                          <img
                            src={Close}
                            alt="Close"
                            className="Closepopup"
                            onClick={() => setFollowSuccessPopup(false)}
                            crossorigin="anonymous"
                          />
                          <CreateFollowSuccess />
                        </div>
                      </Dialog>
                      <Dialog
                        maxWidth={"lg"}
                        open={followFailurePopup}
                        scroll={"body"}
                        onClose={handlefollowFailurePopupClose}
                      >
                        <div className="ContainerWrapper">
                          <img
                            src={Close}
                            alt="Close"
                            className="Closepopup"
                            onClick={() => setFollowFailurePopup(false)}
                            crossorigin="anonymous"
                          />
                          <CreateFollowFailure />
                        </div>
                      </Dialog>
                    </div>
                  )}
                  <div
                    className="CCN_Search"
                    onClick={() => {
                      checkChaproene() != "local"
                        ? setReffPop(true)
                        : console.log("not working");
                    }}
                  >
                    <div className="CCNS_Text1">Create Referral</div>
                    <div className="CCNS_LI">
                      <img src={Plus} crossorigin="anonymous" />
                    </div>
                  </div>
                  {reffListing && reffListing.length !== 0
                    ? reffListing.map((v, i) => (
                      <div className="reff_listing" key={i}>
                        Referred Speciality: {v.referred_speciality}
                        <div
                          className="review_btn"
                          onClick={() => {
                            setuniqReferral(v.uuid);
                            setUniqReffopup(true);
                          }}
                        >
                          Review
                        </div>
                      </div>
                    ))
                    : ""}

                  <div
                    className={appoint && appoint == "Status" ? "CCN_Doc_Sel" : "CCN_Doc1"}
                    onClick={() => {
                      checkChaproene() != "local" &&
                        setAppoint(appoint && appoint == "Status" ? "" : "Status");
                    }}
                  >
                    <div className="CCND1_Text1">
                      {"Consultation Status" +
                        (changeStatus && changeStatus == "booked"
                          ? ": Open"
                          : ": " +
                          (changeStatus == ""
                            ? routedata && routedata.status && routedata.status == "booked"
                              ? "open"
                              : routedata && routedata.status
                            : changeStatus))}
                    </div>
                    <div className="CCND1_Down">
                      <img
                        src={appoint == "Status" ? DGUpArrow : DGArrow}
                        crossorigin="anonymous"
                      />
                    </div>
                  </div>
                  {appoint == "Status" && (
                    <div className="CCN_Doc_Des">
                      <div
                        className={
                          changeStatus == "open" || changeStatus == "booked"
                            ? "change_status_text disabledType change_status_foucs"
                            : "change_status_text disabledType"
                        }
                        onClick={() => handleChangeStatus("open")}
                      >
                        Open
                      </div>
                      <div
                        className={
                          (routedata.status !== "booked" ||
                            disablestatus == true ||
                            (changeStatus != "open" &&
                              changeStatus != "booked" &&
                              changeStatus != "")
                            ? "disabledType "
                            : " ") +
                          (changeStatus == "missed"
                            ? "change_status_text  change_status_foucs"
                            : "change_status_text")
                        }
                        onClick={() => handleChangeStatus("missed")}
                      >
                        Missed
                      </div>
                      <div
                        className={
                          (routedata.status !== "booked" ||
                            disablestatus == true ||
                            (changeStatus != "open" &&
                              changeStatus != "booked" &&
                              changeStatus != "")
                            ? "disabledType "
                            : " ") +
                          (changeStatus == "closed"
                            ? "change_status_text  change_status_foucs"
                            : "change_status_text")
                        }
                        onClick={() => {
                          handleChangeStatus("closed");
                        }}
                      >
                        Closed
                      </div>
                    </div>
                  )}
                  <Dialog
                    maxWidth={"lg"}
                    open={missedpopup}
                    scroll={"body"}
                    onClose={handleMissedpopupClose}
                  >
                    <div className="ContainerWrapper">
                      <img
                        src={Close}
                        alt="Close"
                        className="Closepopup"
                        onClick={() => setMissedpopup(false)}
                        crossorigin="anonymous"
                      />
                      <AppointmentStatusMissed
                        yesfn={handleConfirmatonMissed}
                        nofn={handleConfirmationMissedNo}
                      />
                    </div>
                  </Dialog>
                  <Dialog
                    maxWidth={"lg"}
                    open={closepopup}
                    scroll={"body"}
                    onClose={handleClosepopupClose}
                  >
                    <div className="ContainerWrapper">
                      <img
                        src={Close}
                        alt="Close"
                        className="Closepopup"
                        onClick={() => setClosepopup(false)}
                        crossorigin="anonymous"
                      />
                      <AppointmentStatusClose
                        yesfn={handleConfirmatonClosed}
                        nofn={handleConfirmationClosedNo}
                      />
                    </div>
                  </Dialog>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={phySuccess}
        onClose={() => { setPhySuccess(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setPhySuccess(false)}
            crossorigin="anonymous"
          />
          <CreatecaseSuccess value="PsychiatryCaseNotes" />
        </div>
      </Dialog>
      <Dialog
        open={caseSuccess}
        onClose={() => { setCaseSuccess(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setCaseSuccess(false)}
            crossorigin="anonymous"
          />
          <CreatecaseSuccess value="CaseNote" />
        </div>
      </Dialog>
      <Dialog
        open={phySuccess}
        onClose={() => {
          setPhySuccess(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setPhySuccess(false)}
            crossorigin="anonymous"
          />
          <CreatecaseSuccess value="PsychiatryCaseNotes" />
        </div>
      </Dialog>
      <Dialog
        open={caseSuccess}
        onClose={() => {
          setCaseSuccess(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setCaseSuccess(false)}
            crossorigin="anonymous"
          />
          <CreatecaseSuccess value="CaseNote" />
        </div>
      </Dialog>
      <Dialog
        open={phySuccess}
        onClose={() => {
          setPhySuccess(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setPhySuccess(false)}
            crossorigin="anonymous"
          />
          <CreatecaseSuccess value="PsychiatryCaseNotes" />
        </div>
      </Dialog>
      <Dialog
        open={caseSuccess}
        onClose={() => {
          setCaseSuccess(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setCaseSuccess(false)}
            crossorigin="anonymous"
          />
          <CreatecaseSuccess value="CaseNote" />
        </div>
      </Dialog>
      <Dialog
        open={phySuccess}
        onClose={() => {
          setPhySuccess(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setPhySuccess(false)}
            crossorigin="anonymous"
          />
          <CreatecaseSuccess value="PsychiatryCaseNotes" />
        </div>
      </Dialog>
      <Dialog
        open={caseSuccess}
        onClose={() => {
          setCaseSuccess(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setCaseSuccess(false)}
            crossorigin="anonymous"
          />
          <CreatecaseSuccess value="CaseNote" />
        </div>
      </Dialog>
      <Dialog
        open={dialogopen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setdialogopen(false)}
            crossorigin="anonymous"
          />
          <BloodPressure dialogData={dialogData} popupTitle={popupTitle} />
        </div>
      </Dialog>

      <Dialog open={dialogweignt} onClose={handleWeigntClose}>
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setDialogWeight(false)}
            crossorigin="anonymous"
          />
          <WeightPopUP weightData={weightData} />
        </div>
      </Dialog>
      <Dialog
        maxWidth={"lg"}
        open={patientRiskNote}
        scroll={"body"}
        onClose={handlepatientRiskNote}
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setPatientRiskNote(false)}
            crossorigin="anonymous"
          />
          <PatientRiskNotesPopupListing readmore={readmore} />
        </div>
      </Dialog>
      <Dialog
        maxWidth={"lg"}
        open={sendSms}
        scroll={"body"}
        onClose={handlepatientRiskNote}
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setSendSms(false)}
            crossorigin="anonymous"
          />
          <SendSms mobile={routedata && routedata.patient && routedata.patient.mobile && routedata.patient.mobile} />
        </div>
      </Dialog>
      <Dialog
        maxWidth={"lg"}
        open={reffPop}
        scroll={"body"}
        onClose={handlereffpopup}
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => handlereffpopup()}
            crossorigin="anonymous"
          />
          <ReferralPost
            routedata={routedata}
            handlereferralSuccessPopup={handlereferralSuccessPopup}
          />
        </div>
      </Dialog>
      <Dialog
        maxWidth={"lg"}
        open={reffsucc}
        scroll={"body"}
        onClose={handlereffSucc}
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => handlereffSucc()}
            crossorigin="anonymous"
          />
          <RefferalSuccPopup />
        </div>
      </Dialog>
      <Dialog
        maxWidth={"lg"}
        open={uniqreffpoup}
        scroll={"body"}
        onClose={handleuniqreff}
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => handleuniqreff()}
            crossorigin="anonymous"
          />
          <ReferralGet uniqReferral={uniqReferral} />
        </div>
      </Dialog>

      {/* <div className="loadingcontainer">
                    <div>Loading..</div>
                </div> */}
    </>
  );
};

const DurationDrop = (props) => {
  const ref = React.useRef();
  const [timepop, setTimePop] = React.useState(false);
  useOnClickOutside(ref, () => {
    setTimePop(false);
  });

  return (
    <div
      className="dropdown"
      ref={ref}
      onClick={() => {
        setTimePop(!timepop);
      }}
    >
      <div className="ddown">
        {props && props.routedata && props.routedata.status == "extended" ? (
          <span style={{ pointerEvents: "none", cursor: "not-allowed" }}>
            {" "}
            {props && props.showextent && props.showextent !== ""
              ? props.showextent
              : ""}{" "}
            {props && props.showextent && props.showextent > 1 ? "Days" : props.showextent <= 1 ? "Day" : ""}
          </span>
        ) : (
          <span>
            {" "}
            {props.timevalue && props.timevalue}{" "}
            {props.timevalue > 1
              ? "Days"
              : props.timevalue && props.timevalue != ""
                ? "Day"
                : "Please Select"}
          </span>
        )}

        <img src={DGArrow} crossorigin="anonymous" />
        {timepop ? (
          <div className="popUp">
            <div
              onClick={() => {
                props.setTimeValue("1");
              }}
            >
              1 Day
            </div>
            <div
              onClick={() => {
                props.setTimeValue("2");
              }}
            >
              2 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("3");
              }}
            >
              3 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("4");
              }}
            >
              4 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("5");
              }}
            >
              5 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("6");
              }}
            >
              6 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("7");
              }}
            >
              7 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("8");
              }}
            >
              8 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("9");
              }}
            >
              9 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("10");
              }}
            >
              10 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("11");
              }}
            >
              11 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("12");
              }}
            >
              12 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("13");
              }}
            >
              13 Days
            </div>
            <div
              onClick={() => {
                props.setTimeValue("14");
              }}
            >
              14 Days
            </div>
          </div>
        ) : null}
      </div>

    </div>
  );
};
