import React, { useState, useEffect } from 'react';
import Union from './icons/Union.svg';
import popupDesign from "./popupDesign.scss";
import Closebutton from './icons/close.svg';
import Grid from '@material-ui/core/Grid';
import { makeStyles, TextField, Typography, Divider, Button } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { getQueryHistory,getQueryHistoryRequest } from "../../redux/actions/cockpitAction";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  font_one: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "48px",
    /* identical to box height */

    display: "flex",
    alignItems: "center",
    marginTop: "86px",
    marginLeft: "481px",
    letterSpacing: "-0.333333px",
    color: "#046673"
  },
  font_two: {
    fontFamily: "VisueltMedium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "25px",
    lineHeight: "32px",

    marginLeft: "66px",
    color: "#013C44"
  },
  font_two2: {
    fontFamily: "VisueltMedium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "25px",
    lineHeight: "32px",
    textAlign:"right",
    marginRight: "66px",
    color: "#013C44"
  },
  font_three: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "36px",
    /* identical to box height */
    letterSpacing: "-0.333333px",
    color: "#046673"
  },
  font_four: {
    fontFamily: "VisueltRegular",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "19px",
    /* identical to box height */

    // display: "flex",
    // alignItems: "center",
    color: "#00839B"
  },
  font_four2: {
    fontFamily: "VisueltRegular",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "19px",
    textAlign:"right",
    color: "#00839B"
  },
  container_query_one: {
    marginTop: "71px",
    marginLeft: "122px",
    marginRight: "205px",
    width: "931px",
    height: "463px"
  },

  multilineInputField: {
    borderRadius: "30px",
    // border: '1px solid #A0DFC4',
    backgroundColor: "rgba(209, 232, 237, 0.3)",
    width: "931px",
    height: "463px",
    paddingTop: "48px",
    paddingLeft: "102px",
    paddingRight: "5px"
  },
  outlinedInputField: {
    // borderRadius: "30px",
    border: "rgba(209, 232, 237, 0.3)",
    // boxSizing: "border-box", 
    // width: "931px",
    // height: "463px",
    borderColor: "rgba(209, 232, 237, 0.3) !important"
  },
  textField_font: {
    fontFamily: "VisueltMedium",
    fontStyle: "normal",
    fontWeight: "normal",
    fontsize: "25px",
    lineHeight: "32px",
    /* identical to box height */

    // display: flex;
    // align-items: center;

    color: "#046673"

  },
  container_query_two: {
    width: "931px",
    backgroundColor: "#D8EDE3",
    border: "1px solid #A0DFC4",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "25px",
    marginLeft: "162px",
    marginRight: "165px",
    marginBottom: "25px"
  },
  container_query_three: {
    width: "931px",
    backgroundColor: "#FAFAFA",
    border: "1px solid #A0DFC4",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "25px",
    marginLeft: "162px",
    marginRight: "165px",
    marginBottom: "25px"
  },
  container_query_four: {
    width: "931px",
    height: "142px",
    backgroundColor: "#D8EDE3",
    border: "1px solid #A0DFC4",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "47px",
    marginLeft: "162px",
    marginRight: "165px",
    marginBottom: "104px"
  },
  

}));

function ChaperonePop(props) {
  const classes = useStyles();
  const [queryData, setQueryData] = useState("")
  const[historyLoad,setHistoryLoad]=useState(false)
  const dispatch = useDispatch();

  const userdata = useSelector(state => state.loginReducer.payload)
  const queryHist = useSelector(state => state.queryHistoryReducer)
  const queryHistLoad = useSelector(state => state.queryHistoryReducer)
  useEffect(() => {
    dispatch(getQueryHistory(props.epId,props.type))
  }, [])
  useEffect(()=>{
    handleSendQuery()
  },[])
  useEffect(()=>{
    if (queryHist.isError==false) {
      setHistoryLoad(false)
    }
    if (queryHist.payload!=undefined&&queryHist.payload.length>0) {
      setHistoryLoad(false)
    }
  },[queryHist])
  const handleSendQuery = (val) => {
    setHistoryLoad(true)
    var data = {
      "doctor_uuid": props.docId,
      "local_doctor_uuid": props.localDoc,
      "booking_uuid": props.bookingId,
      "patient_uuid": props.patientId,
      ...props.type=="medicine"&&{"eprescriptions_uuid":props.epId},
      ...props.type=="lab"&&{"eprescriptions_lab_uuid":props.epId},
      "content": val,
      "created_by": props.createdId,
      "received_by":userdata.uuid != props.localDoc?props.localDoc:props.docId
    }

    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/epquery/addEpresQuery',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userdata.token
      },
      data: JSON.stringify(data)
    };

    axios(config)
      .then(function (response) {
        dispatch(getQueryHistoryRequest(props.epId,props.type))
        setQueryData("")
      })
      .catch(function (error) {
        // console.log(error)
      });
      if (queryHist.isError==false) {
      setHistoryLoad(false)
    }
    if (queryHist.payload!=undefined&&queryHist.payload.length>0) {
      setHistoryLoad(false)
    }
  }
  const ClickRefresh=()=>{
    dispatch(getQueryHistoryRequest(props.epId,props.type))
  }
  return (
    <>
      <Grid container style={{ paddingBottom: 30,backgroundColor:"#FFFFFF",border: "1px solid rgba(102, 181, 195, 0.5)" }}>
        <Grid item xs={12} >
          <Typography className={classes.font_one}>Add a query</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginRight: "auto", display: "flex", marginTop: "29px", marginLeft: "162px" }} >
          <textarea className={"addquery"} placeholder="Please enter here" name="query" value={queryData} onChange={(e) => setQueryData(e.target.value)} />
        </Grid>
        <Grid item style={{ marginRight: "auto", display: "flex", marginTop: "29px", marginLeft: "162px" }}>
          <Button style={{
            backgroundColor: "#00839B", borderRadius: "10px", color: "white", width: "234px",
            height: "79px",
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: "bold",
            lineHeight: "30px",
            textTransform: 'capitalize',
            fontFamily: [
              'GreycliffCFBold'
            ].join(',')
          }} size="large"
            onClick={() => handleSendQuery(queryData)}
          >
            send
       </Button>
        </Grid>
       
        {queryHist != undefined && queryHist.length >=0 && <Grid item xs={12} style={{display:"flex",alignItems:"flex-end"}}>
          <Typography className={classes.font_one} style={{ marginTop: "81px", marginLeft: "162px" }}>Query history</Typography> <div className="clickrefresh" onClick={()=>{ClickRefresh()}}>Click to refresh</div>
        </Grid>}
         <div id="out" style={{overflowY:"auto",overflowX:"hidden",maxHeight:540}} >
         {
        queryHist && queryHist.payload!=undefined&&queryHist.payload.length >= 0 ? [...queryHist.payload].reverse().map((val, ind) => {
             return (
               <>
                 {val.created_by==props.createdId?  <Grid item xs={12} className={classes.container_query_three}>
                   <Grid container direction="row" justify="space-between" alignItems="center" style={{ paddingTop: 25, paddingBottom: 30 }}>
                     <Grid item style={{
                       width: "85px", height: "85px", backgroundColor: "#FFFFFF", border: "1px solid #00BE6D", boxSizing: "border-box", borderRadius: "50px",
                       marginLeft: "32px"
                     }}>
                       <Typography className={classes.font_three} style={{ justifyContent: "center", alignItems: "center", display: "flex", padding: "20px" }}>
                         {val.created_details.map((e) => e.firstName != undefined && e.lastName != undefined ? `${e.firstName.substring(0, 1).toUpperCase()}${e.lastName.substring(0, 1).toUpperCase()}` : "")}</Typography>
                     </Grid>
                     <Grid item >
                       <Typography className={classes.font_two2}>{val.content}</Typography>
                       <Grid item style={{
                         justifyContent: "flex-start", alignItems: "center",
                         marginRight: "66px", marginTop: 15
                       }}>
                         <Typography className={classes.font_four2}>{moment(val.createdAt).format("DD MMMM YYYY")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{moment(val.createdAt).format("hh:mm a")}</Typography>
                       </Grid>
                     </Grid>
                   </Grid>
                 </Grid> : val.created_by!==props.createdId ?  <Grid item xs={12} className={classes.container_query_two}>
                   <Grid container direction="row" justify="space-between" alignItems="center" style={{ paddingTop: 25, paddingBottom: 30 }}>
                     <Grid item >
                       <Typography className={classes.font_two}>{val.content}</Typography>
                       <Grid item style={{
                         justifyContent: "flex-start", alignItems: "center",
                         marginLeft: "66px", marginTop: 15
                       }}>
                         <Typography className={classes.font_four}>{moment(val.createdAt).format("DD MMMM YYYY")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{moment(val.createdAt).format("hh:mm a")}</Typography>
                       </Grid>
                     </Grid>
                     <Grid item style={{
                       width: "85px", height: "85px", backgroundColor: "#FFFFFF", border: "1px solid #00BE6D", boxSizing: "border-box", borderRadius: "50px",
                       marginRight: "32px"
                     }}>
                       <Typography className={classes.font_three} style={{ justifyContent: "center", alignItems: "center", display: "flex", padding: "20px" }}>
                         {val.created_details.map((e) => e.firstName != undefined && e.lastName != undefined ? `${e.firstName.substring(0, 1).toUpperCase()}${e.lastName.substring(0, 1).toUpperCase()}` : "")}</Typography>
                     </Grid>
                   </Grid>
 
 
                 </Grid>:""
                 }
 
               </>
             )
           })
             :""
         }
         </div>
     
       
       
        {/* <Grid item xs={12} className={classes.container_query_four}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item >
              <Typography className={classes.font_two} style={{ marginTop: "25px" }}>Please look at the prescription again. Thanks</Typography>
            </Grid>
            <Grid item style={{
              width: "85px", height: "85px", backgroundColor: "#FFFFFF", border: "1px solid #00BE6D", boxSizing: "border-box", borderRadius: "50px",
              marginTop: "25px", marginRight: "32px"
            }}>
              <Typography className={classes.font_three} style={{ justifyContent: "center", alignItems: "center", display: "flex", padding: "20px" }}>JS</Typography>
            </Grid>
          </Grid>
          <Grid item style={{
            justifyContent: "flex-start", alignItems: "center",
            marginLeft: "66px",
          }}>
            <Typography className={classes.font_four}>19 April 2020&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12:24pm</Typography>
          </Grid>
        </Grid> */}

      </Grid>
    </>
  )
}

export default ChaperonePop;
