import React, { useState, useEffect } from 'react';
import "./dmyDropdown.scss"



export const Dropdownfn = (props) =>{
  const[year,setYear]=useState("");

   const handleFollowup = (e) =>{
     props.followchange(true)
       let {name,value}=e.target;
    props.setFollowup({...props.state,[name]:[value]});
   }


       useEffect(()=>{
         if(props.routedata.status=="followup"){
           var selectedyear=document.querySelector("#year");
           var selectedmonth=document.querySelector("#month");
           var selectedday=document.querySelector("#day");
           var selectedyearyearElem = document.createElement("option");
           selectedyearyearElem.value = props.routedata.followup_date.substr(0,4);
           selectedyearyearElem.textContent = props.routedata.followup_date.substr(0,4);
           selectedyear.append(selectedyearyearElem);
           let selectedmonthElem = document.createElement("option");
           selectedmonthElem.value = props.routedata.followup_date.substr(5,2);
           selectedmonthElem.textContent = props.routedata.followup_date.substr(5,2);
           selectedmonth.append(selectedmonthElem);
           var selecteddayElem = document.createElement("option");
           selecteddayElem.value = props.routedata.followup_date.substr(8,2);
           selecteddayElem.textContent = props.routedata.followup_date.substr(8,2);
           selectedday.append(selecteddayElem);
         }else{

       
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
        var qntYears =100;
        var selectYear = document.querySelector("#year");
        var selectMonth = document.querySelector("#month");
        var selectDay = document.querySelector("#day");
        let currentYear = new Date().getFullYear();
        for (var y = 0; y < qntYears; y++) {
         var date = new Date(currentYear);
        var yearElem = document.createElement("option");
        yearElem.value = currentYear
        yearElem.textContent = currentYear;
        selectYear.append(yearElem);
        currentYear++;
        }
        for (var m = 0; m < 12; m++) {
            let month = monthNames[m];
            let monthElem = document.createElement("option");
            monthElem.value = m;
            monthElem.textContent = month;
            selectMonth.append(monthElem);
          }
          var d = new Date();
          var month = d.getMonth();
          var year = d.getFullYear();
          var day = d.getDate();
          selectYear.value=year;
          selectYear.addEventListener("change", AdjustDays);
          selectMonth.value=month;
          selectMonth.addEventListener("change", AdjustDays);
          AdjustDays();
          selectDay.value=day
          function AdjustDays() {
            var year = selectYear.value;
            var month = parseInt(selectMonth.value) + 1;
            while (selectDay.firstChild) {
                selectDay.firstChild.remove()
            }
        
            //get the last day, so the number of days in that month
            var days = new Date(year, month, 0).getDate();
        
            //lets create the days of that month
            for (var d = 1; d <= days; d++) {
              var dayElem = document.createElement("option");
              dayElem.value = d;
              dayElem.textContent = d;
              selectDay.append(dayElem);
            }
          }
        }
       },[])
    
    return(
        <>
       
        
        <select className="mySelect" id="year" name="year" value={props.routedata.status=="followup"?props.routedata.followup_date.substr(0,3):props.state.year} onChange={handleFollowup}>
        </select>
      
        <select className="mySelect" id="month" name="month" value={props.routedata.status=="followup"?props.routedata.followup_date.substr(5,2):props.state.month} onChange={handleFollowup}>
        </select>
        <select className="mySelect" id="day" name="day" value={props.routedata.status=="followup"?props.routedata.followup_date.substr(8,2):props.state.day} onChange={handleFollowup}  >
        </select>
        
 
        </>
    )
       
    
}