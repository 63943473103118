import React, { useEffect, useState } from 'react';
import './ProfileUpdatePage.css';
import { useStyle } from '../../Admin/style/style';
import { useHistory } from 'react-router-dom';
import FileUpload from '../../Admin/images/icons/fileUpload.svg'
import successTick from '../../Admin/images/icons/successTick.svg'
import Button from '@mui/material/Button';
import { TextField, makeStyles, Select } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Drimage from '../../assets/images/BackgroundImage.png'
import Layout from "../../Dashboard/extlayout";
import { useDispatch, useSelector } from "react-redux";
import { getSaasDoctorProfile } from '../../redux/actions/ex-appointment-action';
import { editDoctorProfile } from '../services/ServiceHelper'
import moment from 'moment';
import axios from 'axios';
import Toast from '../../components/Toast';
import LoaderComponent from '../../components/Loading/LoaderComponent';
// import { useHistory } from "react-router-dom";

import Calendar from 'react-calendar';

import { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import dateIcon from './../../Admin/images/icons/dateIcon.svg';



import { Form, Formik, Field } from 'formik';

const useStyle2 = makeStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #66B5C3',
            opacity: '0.95',
        },
        "& .MuiTextField-root": {
            borderRadius: '40px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            // textTransform: 'uppercase',

        },
        "& .MuiPaper-root": {
            color: '#00839B',
            cursor: 'pointer',
            lineHeight: '20px'
        },
        "& .MuiIconButton-root": {
            padding: '0'
        }
    }
}))



export default function ProfileUpdatePage() {

    const history = useHistory()
    const dispatch = useDispatch();
    const saasProfile = useSelector((state) => state.PatientSnapshot.doctorProfiles);
    const classes = useStyle();
    const classes2 = useStyle2();

    const userdata = useSelector((state) => state.loginReducer.payload);


    const [profileImage, setProfileImage] = useState();
    const [profileUploaded, setProfileUploaded] = useState(false);
    const [regCertificate, setRegCertificate] = useState();
    const [certificateUploaded, setCertificateUploaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);

    const [errorDob, setErrorDob] = React.useState(false)
    const [dob, setSelectedDate] = React.useState();
    const [address1, setAddress] = React.useState();
    const [language1, setLanguage] = React.useState();
    const [changeDate, setChangeDate] = useState(false)

    const [specialityData, setSpecialityData] = useState([]);
    const [speciality, setSpeciality] = useState([]);


    const handleSpeciality = (event) => {
        setSpeciality(event.target.value)
    }

    console.log(saasProfile, "98989897")
    const { firstName, full_name, group, gender, lastName, middleName, mobile, profile_pic, username, uuid, profile, consultation_fee, email, token, languages } = saasProfile && saasProfile ? saasProfile : {};
    let dob1 = moment(profile && profile.dob).format('ll')
    let Address = profile && profile.address && profile.address.city
    // let language2 = language1 == null ? profile && profile.languages : language1

    let currentDate = new Date()
    var currentYear = currentDate.getFullYear();
    var birthYear = profile && profile.practice_started_year
    let age = currentYear - birthYear;

    useEffect(() => {
        dispatch(getSaasDoctorProfile())
        setLanguage(profile && profile.languages && profile.languages.toString())
        setAddress(profile && profile.address && profile.address.city)
    }, [])

    useEffect(() => {
        console.log(saasProfile)
        if (saasProfile && saasProfile.profile && saasProfile.profile.dob) {
            let date = saasProfile.profile.dob
            let formatedDate = moment(date).format('YYYY-MM-DD')
            setSelectedDate(formatedDate)
        }

    }, [saasProfile])

    useEffect(() => {
        const getData = () => {
            axios.get(process.env.REACT_APP_API_URL + '/metadata/specialities')
                .then(data => setSpecialityData(data.data))
        }

        getData()
    }, [])


    const GoBack = () => {
        history.goBack()
    }


    const handleDate = (event) => {
        setSelectedDate(event.target.value);
        setChangeDate(true)
        setErrorDob(false)
    };

    const handleToast = (visibility, messages, type) => {
        setToast(visibility)
        setToastMessage('' + messages)
        setToastType(type)
    }

    const uploadMedia = async (document, docType) => {
        try {
            const formData = new FormData()
            formData.append('media', document)
            // There is no auth header available it's hadcoded in old registration as well
            const token1 = userdata.token;
            let hardCodedHeader = {
                'Content-Types': 'multipart/form-data',
                'x-auth-token': token1
            }

            const config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/upload/avatar',
                headers: hardCodedHeader,
                data: formData,
            }

            let response = await axios(config)
            if (response.status === 200 || response.status === 201) {
                if (docType === "PIC") {
                    setProfileImage(response.data.url)
                    setProfileUploaded(true)
                } else {
                    setRegCertificate(response.data.url)
                    setCertificateUploaded(true)
                }
                setLoading(false)
            }

        } catch (error) {
            if (error && error.response) {
                handleToast(true, "" + error.response.data, 'error')
            }
            setLoading(false)
            if (docType === "PIC") {
                setProfileUploaded(false)
            } else {
                setCertificateUploaded(false)
            }
        }
    }


    const handleProfilePic = async ({ target: { files } }) => {
        const uploadedImage = files[0];
        setLoading(true)
        await uploadMedia(uploadedImage, "PIC")

    }

    const handleCertificate = async ({ target: { files } }) => {
        const certificates = files[0];
        setLoading(true)
        await uploadMedia(certificates, "CERT")
    }

    const UpdateLanguage = (event) => {
        console.log(event, "78878")
        setLanguage(event && event.target && event.target.value)
    }
    const UpdateLocation = (event) => {
        setAddress(event && event.target && event.target.value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        const { formFirstName, formMiddleName, formLastName, formBirthDate, formGender, formEmail, formPhoneNumber, formLanguage, Language, formAddress, formPStartedYear, formQualification,Mobile } = event.target.elements;
        console.log(event.target.elements, "221122")
        let changedFullName = formFirstName.value + "   " + formMiddleName.value + "   " + formLastName.value;
        const payLoad = {
            'practice_started_year': formPStartedYear.value,
            'gender': formGender.value ? formGender.value : profile && profile.gender ? profile.gender : "",
            'bio': 'I am doctor',
            'folioNumber': profile && profile.folioNumber,
            'dob': dob,
            "address": {
                'city': address1,
            },
            'languages': [language1],
            'speciality': [speciality],

            "professional_detail": {
                "professional": formQualification.value ? formQualification.value : profile && profile.professional_detail && profile.professional_detail.qualification ? profile.professional_detail.qualification : "NA",
                "qualification": formQualification.value ? formQualification.value : profile && profile.professional_detail && profile.professional_detail.qualification ? profile.professional_detail.qualification : "NA",
            },
            'sign_pic': regCertificate ? regCertificate : profile && profile.sign_pic ? profile.sign_pic : "NA",
            'profile_pic': profileImage ? profileImage : profile_pic,
            'full_name': changedFullName ? changedFullName : full_name,
            'profile': {
                'Language': language1,
                'city': address1,
                'speciality': speciality,
            },


        };

        try {
            // if("dob" == null){
            //     setErrorDob(true)
            // }
            let response = await editDoctorProfile(payLoad, uuid, userdata && userdata.token)
            // dispatch(getSaasDoctorProfile())
            handleToast(true, "Successfully Updated Profile", "success")
            setTimeout(() => {
                history.goBack()
            }, 1500);

        } catch (error) {
            console.log(error)
            if (error && error.response) {
                console.log(error.response.data)
                handleToast(true, "" + error.response.data, "error")
            }

        }

    }


    return (
        <Layout>
            {/* <button className='edite_btn' style={{float:"right", width:"8%", marginTop:"2%", marginRight:"2%"}}  onClick={GoBack}>Back</button> */}
            <Formik className={classes2.root}>
                <>
                    <div className=''>
                        <div className='registration_container'>
                            <div className='card'>
                                <div className='head_section'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {/* <div className='signin_button'>
                                        <Button variant="contained">Sign in</Button>
                                    </div> */}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {/* <div className='register_button'>
                                        <Button variant="text">Back</Button>
                                    </div> */}
                                        </Grid>
                                    </Grid>
                                    {/* <h4 className='registration_heading'>Proffessional Details</h4> */}
                                </div>
                                <Grid container>
                                <Grid item xs={6}>
                                                <div style={{ marginLeft: "10%" }}>
                                                    <div>&nbsp;</div>
                                                    <Grid container spacing={1}>
                                                        <label htmlFor="upload-photo2" className={classes.uploadLabel}>
                                                            {certificateUploaded ? <img src={successTick} alt={successTick} style={{ width: '85px', height: '85px', borderRadius: '50%' }} crossorigin="anonymous" /> : <img style={{ width: '22px', height: '21px' }} src={FileUpload} alt="File Upload" crossorigin="anonymous" />}
                                                        </label>
                                                        <Grid item xs={1}>
                                                            <input

                                                                type="file"
                                                                name="certificate"
                                                                as={TextField}
                                                                className={classes.uploadButton}
                                                                id="upload-photo2"
                                                                onChange={handleCertificate}
                                                                accept="image/*"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={7} style={{ marginLeft: "-20px" }}>
                                                            <div style={{ marginTop: "5%" }}>
                                                                <label for="gender" className='lable'>Upload Doctor's Signature </label>
                                                                <h6 style={{ width: "200px" }}>Signature should be in the standerd formate png.jpeg & no more than 2 mb </h6>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div style={{ marginTop: "2%" }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3}>
                                                            <label htmlFor="upload-photo" className={classes.uploadLabel}>
                                                                {profileUploaded ? <img src={successTick} alt={successTick} style={{ width: '85px', height: '85px', borderRadius: '50%' }} crossorigin="anonymous" /> : <img style={{ width: '22px', height: '21px' }} src={FileUpload} alt="File Upload" crossorigin="anonymous" />}
                                                            </label>
                                                            <input
                                                                style={{ height: '20px', width: '20px' }}
                                                                type="file"
                                                                name="profilePic"
                                                                as={TextField}
                                                                className={classes.uploadButton}
                                                                id="upload-photo"
                                                                onChange={handleProfilePic}
                                                                accept="image/*"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <div style={{ marginTop: "5%" }}>
                                                                <label for="gender" className='lable' style={{ marginLeft: "17px" }}>Upload Doctor's Profile Picture </label>
                                                                <h6 style={{ marginLeft: "20px" ,width: "200px"}}>Profile picture should be in the standerd formate png.jpeg & no more than 2 mb </h6>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            </Grid>
                                <div className='reg_form_container'>
                                    <form action="" onSubmit={handleSubmit}>
                                        <Grid container spacing={1.5}>

                                            <Grid item xs={4}>
                                                <label for="fname" className='input_lable1'>First name<span className="star-color">*</span></label>
                                                <input
                                                    className="headersearchtxt"
                                                    autoFocus
                                                    margin="dense"
                                                    id="fname"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    name="formFirstName"
                                                    defaultValue={firstName && firstName ? firstName : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <label for="mname" className='input_lable1'>Middle Name</label>
                                                <input
                                                    className="headersearchtxt"
                                                    name="formMiddleName"
                                                    margin="dense"
                                                    id="mname"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={middleName && middleName ? middleName : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <label for="lname" className='input_lable1'>Last Name<span className="star-color">*</span></label>
                                                <input
                                                    className="headersearchtxt"
                                                    autoFocus
                                                    name="formLastName"
                                                    margin="dense"
                                                    id="lname"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={lastName && lastName ? lastName : ''}
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <label for="dob" className='input_lable1'>Date of Birth</label>
                                                {/* <input
                                            className="headersearchtxt"
                                            autoFocus
                                            margin="dense"
                                            id="dob"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            name="formBirthDate"
                                            // disabled
                                            defaultValue={profile && profile.dob ? moment(profile.dob).format('ll') : ''}
                                        /> */}
                                                <br></br>
                                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Field
                                            as={DatePicker}
                                            inputVariant="outlined"
                                            name="dob"
                                            format="dd/MM/yyyy"
                                            // id="date-picker-inline"
                                            className="headersearchtxt"
                                            value={dob}
                                            placeholder="DD/MM/YYYY"
                                            required={true}
                                            onChange={(event) => handleDate(event)}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><img src={dateIcon} alt="dateIcon" style={{ cursor: 'pointer', }} crossorigin="anonymous" /></InputAdornment>,
                                            }}
                                            
                                            renderInput={props => <TextField {...props} />}
                                            error={errorDob}
                                            helperText={errorDob ? "Required" : ''}
                                            style={{width:"350px"}}
                                        />
                                    </MuiPickersUtilsProvider> */}
                                                <input
                                                    name="dob"
                                                    type="date"
                                                    className="headersearchtxt"
                                                    defaultValue={dob}
                                                    value={dob}
                                                    onChange={(event) => handleDate(event)}
                                                    format="dd/MM/yyyy"
                                                    required
                                                ></input>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <label for="gender" className='input_lable1'>Gender<span className="star-color">*</span></label>
                                                <input
                                                    className="headersearchtxt"
                                                    margin="dense"
                                                    id="gender"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    name="formGender"
                                                    defaultValue={profile && profile.gender ? profile.gender : ''}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={4}>
                                                <div>
                                                    <Grid container spacing={1.5}>
                                                        <Grid item xs={3}>
                                                            <label htmlFor="upload-photo" className={classes.uploadLabel}>
                                                                {profileUploaded ? <img src={successTick} alt={successTick} style={{ width: '85px', height: '85px', borderRadius: '50%' }} crossorigin="anonymous" /> : <img style={{ width: '22px', height: '21px' }} src={FileUpload} alt="File Upload" crossorigin="anonymous" />}
                                                            </label>
                                                            <input
                                                                style={{ height: '20px', width: '20px' }}
                                                                type="file"
                                                                name="profilePic"
                                                                as={TextField}
                                                                className={classes.uploadButton}
                                                                id="upload-photo"
                                                                onChange={handleProfilePic}
                                                                accept="image/*"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <div style={{ marginTop: "5%" }}>
                                                                <label for="gender" className='lable' style={{ marginLeft: "17px" }}>Upload Doctor's Profile Picture </label>
                                                                <h6 style={{ marginLeft: "20px" }}>Profile picture should be in the standerd formate png.jpeg & no more than 2 mb </h6>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid> */}
                                            {/* <Grid item xs={4}>
                                                <label for="Email" className='input_lable'>Email Address<span className="star-color">*</span></label>
                                                <input
                                                    className="headersearchtxt"
                                                    margin="dense"
                                                    id="Email"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    name='formEmail'
                                                    defaultValue={email && email ? email : ''}
                                                />
                                            </Grid> */}
                                             <Grid item xs={4}>
                                                <label for="Mobile" className='input_lable1'>Mobile number<span className="star-color">*</span></label>
                                                <input
                                                    className="headersearchtxt"
                                                    style={{ backgroundColor: "#f5f5f5", cursor: "not-allowed" }}
                                                    margin="dense"
                                                    id="Mobile"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    name="formPhoneNumber"
                                                    disabled
                                                    defaultValue={mobile && mobile ? mobile : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <label for="Email" className='input_lable1'>Email Address<span className="star-color">*</span></label>
                                                <input
                                                    className="headersearchtxt"
                                                    style={{ backgroundColor: "#f5f5f5", cursor: "not-allowed" }}
                                                    margin="dense"
                                                    id="Email"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    name='formEmail'
                                                    disabled
                                                    defaultValue={email && email ? email : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <label for="Email" className='input_lable1'>Location</label>
                                                <input
                                                    className="headersearchtxt"
                                                    margin="dense"
                                                    id="Email"
                                                    type="text"
                                                    fullWidth
                                                    onChange={(event) => UpdateLocation(event)}
                                                    variant="outlined"
                                                    name='formEmail'
                                                    defaultValue={Address && Address}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={2}></Grid> */}
                                            {/* <Grid item xs={2}></Grid> */}
                                            {/* <Grid item xs={4}>
                                                <div style={{ marginLeft: "3%" }}>
                                                    <div>&nbsp;</div>
                                                    <Grid container spacing={1}>
                                                        <label htmlFor="upload-photo2" className={classes.uploadLabel}>
                                                            {certificateUploaded ? <img src={successTick} alt={successTick} style={{ width: '85px', height: '85px', borderRadius: '50%' }} crossorigin="anonymous" /> : <img style={{ width: '22px', height: '21px' }} src={FileUpload} alt="File Upload" crossorigin="anonymous" />}
                                                        </label>
                                                        <Grid item xs={1}>
                                                            <input

                                                                type="file"
                                                                name="certificate"
                                                                as={TextField}
                                                                className={classes.uploadButton}
                                                                id="upload-photo2"
                                                                onChange={handleCertificate}
                                                                accept="image/*"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={7} style={{ marginLeft: "-20px" }}>
                                                            <div style={{ marginTop: "5%" }}>
                                                                <label for="gender" className='lable'>Upload Doctor's Signature </label>
                                                                <h6 style={{ width: "200px" }}>Signature should be in the standerd formate png.jpeg & no more than 2 mb </h6>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid> */}
                                            {/* <Grid item xs={4}>
                                                <label for="Mobile" className='input_lable'>Mobile number<span className="star-color">*</span></label>
                                                <input
                                                    className="headersearchtxt"
                                                    margin="dense"
                                                    id="Mobile"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    name="formPhoneNumber"
                                                    defaultValue={mobile && mobile ? mobile : ''}
                                                />
                                            </Grid> */}
                                            {/* <Grid item xs={6}></Grid> */}
                                            <Grid item xs={4}>
                                                <label for="Language" className='input_lable1'>Qualification</label>
                                                <input
                                                    className="headersearchtxt"
                                                    margin="dense"
                                                    id="Language"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    name="formQualification"
                                                    defaultValue={profile && profile.professional_detail ? profile.professional_detail.qualification && profile.professional_detail.qualification : ''}

                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <label for="Year of exp" className='input_lable1'>Years of Experience</label>
                                                <input
                                                    className="headersearchtxt"
                                                    margin="dense"
                                                    style={{ backgroundColor: "#f5f5f5", cursor: "not-allowed" }}
                                                    id="exp"
                                                    disabled
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    name='formExp'
                                                    defaultValue={age && age ? age : ''}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <label for="Address" className='input_lable1'>Practice Started Year</label>
                                                <input
                                                    className="headersearchtxt"
                                                    margin="dense"
                                                    id="Address"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    name="formPStartedYear"
                                                    defaultValue={profile && profile.practice_started_year ? profile.practice_started_year : ''}
                                                />
                                            </Grid>
                                          
                                            <Grid item xs={4}></Grid>
                                           
                                            {/* <Grid item xs={6}></Grid> */}
                                            
                                            <Grid item xs={6} >
                                                <label style={{ display: 'none' }} for="Address" className='lable'>Address</label>
                                                <input
                                                    className="headersearchtxt"
                                                    margin="dense"

                                                    id="Address"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    // name="formAddress"
                                                    style={{ display: 'none' }}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={4}></Grid> */}
                                          
                                            <Grid item xs={8}>
                                                <label for="Address" className='input_lable1'>speciality</label>
                                                {/* <select
                                                    className="headersearchtxt"
                                                    defaultValue={profile && profile.speciality ? profile.speciality > 0 && profile.speciality.toString() : ''}
                                                >
                                                    <option>

                                                    </option>
                                                </select> */}
                                                <select name="speciality" value={speciality} onChange={handleSpeciality} variant="outlined" required={true} fullWidth className="headersearchtxt">
                                                    {/* <option aria-label="None" value="" disabled selected hidden>Speciality</option> */}
                                                    {
                                                        specialityData.map(data => (
                                                            <option value={data.name} key={data._id} className={classes.dropDown}>{data.name}</option>
                                                        ))
                                                    }
                                                </select>

                                            </Grid>
                                            <Grid item xs={4}>
                                                <label for="Email" className='input_lable1'>Language Spoken</label>
                                                <input
                                                    className="headersearchtxt"
                                                    margin="dense"
                                                    id="Email"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    onChange={(event) => UpdateLanguage(event)}
                                                    name='formEmail'
                                                    defaultValue={profile && profile.languages && profile.languages.length > 0 && profile.languages.toString()}
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Button variant="outlined" style={{ float: "right", marginLeft: '0%',marginTop:"10%",color:"#00839B",background:"white" }} onClick={() => history.goBack()}>Cancel</Button>
                                                <Button type='submit' variant="contained" style={{ float: "left",marginTop:"10%",background:"#00839B" }}>Submit</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Toast
                        text={toastMessage}
                        type={toastType}
                        open={showToast}
                        setClose={() => {
                            setToast(false);
                        }}
                    />
                    {loading && <LoaderComponent />}
                </>
            </Formik>
        </Layout >
    )
};