import React from 'react';
import Union from './icons/Union.svg';

function AppointmentStatusClose(props) {
    return (
        <div style={{ width: '423px', height: '386px', backgroundColor: '#F0F5F5', boxShadow: '0px 0px 50px rgba(38, 43, 82, 0.05)', borderRadius: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '305px', height: 'auto', }}>
                <img src={Union} alt="Union" style={{ display: 'block', margin: '0 auto 20px auto' }} crossorigin="anonymous"/>
                <div style={{
                    display: 'block',
                    margin: 'auto',
                    width: '250px',
                    height: '72px',
                    fontFamily: 'GreycliffCFBold',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    letterSpacing: '-0.3px',
                    color: '#013C44'
                }}>Are you sure you want to change the appointment status to Closed?</div>
                <div style={{ width: 'auto', height: 'auto', display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>

                    <div style={{
                        width: '142px',
                        height: '62px',
                        background: '#08B4D4',
                        borderRadius: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'


                    }} onClick={()=>props.yesfn()}>
                        <div style={{
                            fontFamily: 'GreycliffCFBold', color: '#FFFFFF', fontFamily: 'GreycliffCFBold',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '30px',
                        }}
                        
                        >Yes</div>
                    </div>

                    <div style={{
                        width: '142px',
                        height: '62px',
                        background: '#08B4D4',
                        borderRadius: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'

                    }} onClick={()=>props.nofn()}>
                        <div style={{
                            fontFamily:  'GreycliffCFBold', color: '#FFFFFF', fontFamily: 'GreycliffCFBold',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '30px',
                        }} >No</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointmentStatusClose
