import React, { useState, useEffect } from "react";
import usePagination from "./components/usePagination";
import { Pagination, PaginationItem } from "@material-ui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import Previous from "../../assets/images/previous.svg";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import dots from "../../assets/images/dots.svg";
import DialogLayout from "./components/DialogLayout";
import LabResult from "./LabResults";
import DiagnosticPres from "./DiagnosticPres";
import { getOrders } from "../../redux/actions/ex-appointment-action";

import orders from "./components/Orders";
import OrderDetail from "./OrderDetail";

import styles from "./style";
import axios from "axios";
import FloatingIcon from "./FloatingIcons/FloatingIcon";
const useStyles = makeStyles((theme) => styles(theme));

const OrdersComp = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const orderState = useSelector((state) => state.PatientSnapshot);
  console.log(orderState,"orderState")
  const authState = useSelector((state) => state.loginReducer.payload);
  const [url, setUrl] = useState([]);
  const [url1, setUrl1] = useState();
  console.log(url, "url");
  const [pageNumber, setPageNumber] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  let [page, setPage] = useState(1);
  const [labresult, setLabresult] = useState(false);
  const [orderDetail, setorderDetail] = useState({
    status: false,
    data: {},
  });

  const [diagnostic, setDiagnostic] = useState(false);
  const PER_PAGE = 3;
 const totalCount = orderState && orderState.orderList && orderState.orderList.total
  // const count = Math.ceil(orders.length / PER_PAGE);
  const count = Math.ceil(totalCount / PER_PAGE);
console.log(count,"countcount")

  const _DATA = usePagination(orders, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    setPageNumber(p - 1);
  };

  useEffect(() => {
    dispatch(getOrders(pageNumber, searchQuery));
  }, [pageNumber, searchQuery, "", 10]);

  const handleOrder = (order) => {
    // console.log()
    const OrderId1 =
      order && order.order && order.order[0] && order.order[0].uuid;
    let endPoint = `/order/ordertracking/byOrderId?orderId=${OrderId1}`;

    axios
      .get(process.env.REACT_APP_API_URL + `${endPoint}`, {
        headers: {
          "x-auth-token": authState.token,
        },
      })
      .then((res) => {
        console.log(res, "resss");
        if (res.data) {
          // setLabresult(true)

          setUrl(res && res.data[0] && res.data[0].fullOrderDetails);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOrder1 = (item) => {
    console.log(item.final_action.result[0].url);
    const OrderId1 =
      item &&
      item.final_action &&
      item.final_action.result[0] &&
      item.final_action &&
      item.final_action.result[0].url;

    // handlePdfOpen();
    // setOpen(true)
    window.open(OrderId1, "_blank");
    setUrl1(OrderId1);
  };
  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "20px",
          paddingBottom: "66px",
        }}
      >
        <input
          className={classes.searchField}
          placeholder="search"
          style={{ paddingLeft: "10%" }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Grid
          container
          style={{ marginTop: "23px" }}
          justifyContent="space-between"
        >
          <Grid item className={classes.category}>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "15px",
                color: "#00839B",
              }}
            >
              Order
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" style={{ marginTop: "13px" }}>
          {orderState && orderState.orderList && orderState.orderList.data ? (
            <div>
              {orderState &&
                orderState.orderList &&
                orderState.orderList.data.map((order, index) => (
                  <Grid item container className={classes.patientCard}>
                    <Grid
                      item
                      container
                      style={{
                        height: "70px",
                        borderBottom: "0.5px solid #00839B",
                        paddingLeft: "16px",
                        paddingTop: "12px",
                        paddingRight: "22px",
                      }}
                    >
                      <Grid item container direction="column" sm xs>
                        <Typography
                          style={{
                            fontFamily: "Visuelt Pro",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "18px",
                            lineHeight: "30px",
                            color: "#046673",
                          }}
                        >
                          {order &&
                          order.order &&
                          order.order[0] &&
                          order.order[0].uuid
                            ? order.order[0].uuid
                            : "NA"}
                          {/* { console.log(order,"fsdffs") } */}
                          {/* {order.map((orderItem)=>{

})} */}

                          {/* {order.orderid.slice(0, 9)} */}
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Visuelt",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "18px",
                            color: "#046673",
                          }}
                        >
                          {order.orderdate}
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent="flex-end" sm xs>
                        <Grid
                          item
                          container
                          justifyContent="center"
                          alignItems="center"
                          className={
                            order.order[0].status === "completed"
                              ? classes.completed
                              : classes.pending
                          }
                        >
                          <Typography
                            className={classes.status}
                            style={{
                              color:
                                order.order[0].status === "completed"
                                  ? "#0DA570"
                                  : "#FF6E4E",
                            }}
                          >
                            {order.order[0].status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        paddingTop: "12px",
                        paddingLeft: "16px",
                        paddingRight: "22px",
                        paddingBottom: "16px",
                      }}
                    >
                      <Grid item container justifyContent="space-between">
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="flex-start"
                          sm
                          xs
                        >
                          <Typography className={classes.label}>
                            Name
                          </Typography>
                          <Typography className={classes.value}>
                            {/* {order.name} */}
                            {order &&
                              order.patient &&
                              order.patient[0] &&
                              order.patient[0].full_name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="flex-end"
                          sm
                          xs
                        >
                          <Typography className={classes.label}>
                            Amount
                          </Typography>
                          <Typography className={classes.value}>
                            {/* {order.amount} */}

                            {order &&
                              order.order &&
                              order.order[0] &&
                              order.order[0].amount}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        style={{ marginTop: "21px" }}
                      >
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="flex-start"
                          sm
                          xs
                        >
                          <Typography className={classes.label}>
                            Type of Order
                          </Typography>
                          <Typography className={classes.value}>
                            {/* {order.type} */}

                            {console.log(order.labtype, "orde41342r")}
                            {order && order.labtype == "recommended"
                              ? "DMP"
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="flex-end"
                          sm
                          xs
                        >
                          <Typography className={classes.label}>
                            Amount Earned
                          </Typography>
                          <Typography className={classes.value}>
                            {/* {order.amountearned} */}
                            {order && order.commission}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container style={{ marginTop: "17px" }}>
                        <Grid item>
                          <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              width: "36px",
                              height: "50px",
                              background: "#00839B",
                              borderRadius: "4px",
                              marginRight: "12px",
                            }}
                            // onClick={() =>
                            //   setorderDetail({ status: true, data: order })
                            // }

                            onClick={() => {
                              setorderDetail({ status: true, data: order });
                              if (
                                order &&
                                order.order[0] &&
                                order.order[0].status == "completed"
                              ) {
                                handleOrder(order);
                              }
                            }}
                          >
                            <img src={dots} alt="dots" />
                          </Grid>
                        </Grid>
                        <Grid item>
                          <button
                            className={
                              order &&
                              order.order[0] &&
                              order.order[0].status == "completed"
                                ? classes.addVisit
                                : classes.addVisitDesabled
                            }
                            // onClick={() => setLabresult(true)}
                            onClick={() => {
                              setLabresult(true);
                              if (
                                order &&
                                order.order[0] &&
                                order.order[0].status == "completed"
                              ) {
                                handleOrder(order);
                              }
                            }}
                          >
                            View Result
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </div>
          ) : null}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{
            borderTop: "0.7px solid #E4E7EC",
            marginTop: "32px",
            paddingTop: "15px",
          }}
        >
          <Pagination
            size="small"
            page={page}
            shape="rounded"
            onChange={handleChange}
            count={count}
            defaultPage={1}
            boundaryCount={1}
            className={classes.pagination}
            color="#FFF5F3"
            renderItem={(item) => (
              <PaginationItem
                components={{
                  next: ArrowForwardIcon,
                  previous: ArrowBackIcon,
                }}
                {...item}
              />
            )}
          />
        </Grid>
        {labresult ? (
          <DialogLayout open={labresult}>
            <LabResult closelabresult={() => setLabresult(false)} data={url} />
          </DialogLayout>
        ) : null}
        {orderDetail.status ? (
          <DialogLayout open={orderDetail.status}>
            <OrderDetail
              closeorderDetail={() =>
                setorderDetail({ status: false, data: {} })
              }
              order={orderDetail.data}
              data={url}
            />
          </DialogLayout>
        ) : null}
      </Grid>
      <FloatingIcon/>
    </>
  );
};

export default OrdersComp;
