import React, { useEffect,useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PresStyles from "./PresStyles.scss";
import { makeStyles, TextField, Typography, Divider, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import {getMedPrescription} from "../../../redux/actions/cockpitAction";


const useStyles = makeStyles(theme => ({
  container_one: {
    width: "1096px",
    backgroundColor: "#EFF1F7",
    // background:red;
    marginTop: "48px",
    marginLeft: "30px",
    // marginRight: "82px",
    paddingBottom: "30px",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  font_one: {
    fontFamily: "GreycliffCFBold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "30px",
    /* identical to box height */

    display: "flex",
    alignItems: "center",
    textAlign:"left",
    color: "#013C44"
  },
  container2: {
    backgroundColor: "#EFF1F7",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "28px",
    marginLeft: "30px",
    padding: "17px",
    width: "1096px",
    height: "316px"
  },
  typography: {
    fontFamily: [
      'GreycliffCFBold'
    ].join(','),
    fontWeight: "bold",
    fontSize: 25,
    color: '#013C44'
  },
  multilineInputField: {
    borderRadius: 20,
    background: '#FFFFFF',
    marginLeft: 5,
    fontFamily: "VisueltMedium",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "26px",
    /* identical to box height */


    color: "#013C44"
  },
  outlinedInputField: {
    borderRadius: 20,
    border: '1px solid #A0DFC4',
  },
}));

function PrescriptionChaperoneContent(props) {
  const classes = useStyles();
  const [note, setNotes] = React.useState("");
  const [medication, setMedication] = React.useState([]);
  const[disapprove,setDisapprove]=useState(false)
  const userdata = useSelector(state => state.loginReducer.payload)
  const { uuid, type } = props.data.selected;
   const dispatch=useDispatch();
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "/ep/details/" + type + "/" + uuid, {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userdata.token
      },
    }).then(function (response) {
      if (response && response.data && response.data.length > 0 && response.data[0].medication) {
        if (response.data[0].medication.length > 0) {
          setMedication(response.data[0].medication)
          setNotes(response.data[0].notes)
        }
      }
    })
      .catch(function (error) {
        // console.log(error)
      });
  }, [])
 useEffect(()=>{
if(props.chapdet.approve_status==true){
setDisapprove(true)
}
  },[])
  const handleApprovefn = () => {

    var config = {
      method: 'put',
      url: process.env.REACT_APP_API_URL + '/ep/approveEprescription/' + uuid,
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userdata.token
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response)
        props.data.close()
      })
      .catch(function (error) {
        // console.log(error)
      });
      dispatch(getMedPrescription(props.routedata.uuid))
  }
  return (
    <>

      <Grid container className={classes.container_one} >

        <Grid container direction="row" justify="space-evenly" alignItems="center" style={{ borderBottom: "2px solid rgba(0, 131, 155, 0.3)", height: "68px" }}>
          <div className={classes.font_one} >Medicine</div>
          <div className={classes.font_one} >Quantity Prescribed</div>
          <div className={classes.font_one} style={{marginRight:"40px"}}>Dose</div>
          <div className={classes.font_one} >Duration</div>
        </Grid>
        {
          medication.map((val, ind) => {
            return (
              <>
                <Grid container key={ind} direction="row" justify="space-evenly" alignItems="center" className="font_two" style={{paddingLeft: "48px" ,marginTop: 20, marginBottom: 20 }}>
                  <div>{val.productname}</div>
                  <div className="inner_border" >{val.Quantity}</div>
                  <div className="inner_border" >{val.dose}</div>
                  <div className="inner_border">{val.duration}</div>
                </Grid>
              </>
            )
          })
        }
      </Grid>
      <Grid container md={12} className={classes.container2}>
        <Grid item md={12} style={{ textAlign: "left" }}>
          <Typography className={classes.typography}>Special Advise</Typography>
        </Grid>
        <Grid item md={12} style={{
          width: "1008px",
          height: "200px", marginBottom: "5px"
        }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Take 2 Before and after every meal, Morning and Evening "
            InputLabelProps={{
              shrink: false,
            }}
            disabled
            InputProps={{
              classes: { notchedOutline: classes.outlinedInputField }
            }}
            value={note}
            multiline
            rows={10}
            onChange={(e) => { setNotes(e.target.value) }}
            className={classes.multilineInputField}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" style={{gap:'10px'}}>
        <div className={disapprove==false?"approve_btn":"disable_approve"} onClick={() => handleApprovefn()}><span>Approve prescription</span></div>
        <div className={disapprove==false?"query_btn":"disable_approve"} onClick={() => props.data.openQuery(props.data.selected)}><span>Query prescription</span></div>
      </Grid>
    </>

  )
}

export default PrescriptionChaperoneContent;
