import React, {useState, useEffect} from 'react';
import GreenSlot from "../../../assets/images/GreenSlot.svg"
import moment from 'moment';
const CountDown = (props) => {
    const [time, setTime] = useState({h:"00",m:"00"})
    const [seconds, setSeconds] = useState(0)
    const secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);


        let obj = {
            "h": hours < 0?(-1*hours):hours,
            "m": minutes < 0?(-1*minutes):minutes
        };
        return obj;
    }
    useEffect(() => {
        if(moment(props.routedata.start_time) > moment()){
            const timer=setTimeout(() => {
                countDown()
            }, 60000);
            // Clear timeout if the component is unmounted
            return () => clearTimeout(timer);
        }
      });
    useEffect(() => {
       if(moment(props.routedata.start_time) > moment()){
        let secval =  moment().diff(moment(props.routedata.start_time), "seconds");
        setSeconds(secval)
        let timeLeftVar = secondsToTime(secval);
        setTime(timeLeftVar)
       }
    }, [])
  

    const countDown = () => {
        let secondss = seconds - 60;
        setTime(secondsToTime(secondss))
        setSeconds(secondss)
    }
    return (
        <div style={{ width: 136, height: 37, background: "#046673", borderRadius: 10, position: "relative", marginLeft: 20 }}>
            <img src={GreenSlot} crossorigin="anonymous" />
            <span className="slottime">{`${time.h}:${time.m}`}</span>
        </div>
    )
}
export default React.memo(CountDown);