import {
  Button,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DGArrow from "../../../assets/images/DGArrow.svg";
import Search from "../../../assets/images/search.svg";
import CryptoJS from "crypto-js";
import {
  getAreaForPrescription,
  getCityForPrescription,
  searchGenericNameForPrescription,
  searchProductNameForPrescription,
  getMedAttr,
  getMedPrescription,
  getallprescription,
} from "../../../redux/actions/cockpitAction";
import useOnClickOutside from "./../../../Dashboard/doctorDashboard/OutClickhandler";
import axios from "axios";
// import radioOff from "../../../assets/images/radio_outline.svg";
// import radioOn from "../../../assets/images/radio_checked.svg";
import { Dialog } from "@material-ui/core";
import DocmedFailure from "../../Popup/docindicationpop";
import Close from "../../../assets/images/close.svg";
import "./style.scss";
import { getHistro } from "../../../redux/actions/caseNoteAction";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  root1: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  container: {
    backgroundColor: "#EFF1F7",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "20px",
    padding: "30px",
    maxHeight: "400px",
    overflow: "auto",
    // overflowX:"hidden"
  },
  container2: {
    backgroundColor: "#EFF1F7",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "41px",
    padding: "28px",
  },
  typography: {
    fontFamily: ["GreycliffCFBold"].join(","),
    fontWeight: "bold",
    fontSize: 25,
    color: "#013C44",
  },
  divider: {
    height: 2,
    backgroundColor: "rgba(0, 131, 155, 0.3)",
    marginLeft: -30,
    marginRight: -30,
  },
  multilineInputField: {
    borderRadius: 20,
    background: "#FFFFFF",
    marginLeft: 5,
  },
  outlinedInputField: {
    borderRadius: 20,
    border: "1px solid #A0DFC4",
  },
  tableData_medicine_font: {
    fontFamily: "VisueltRegular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "248.2%",
    color: "#013C44",
  },
  tableDataTypography: {
    fontFamily: ["GreycliffCFBold"].join(","),
    fontWeight: "bold",
    fontSize: 20,
    color: "#013C44",
  },
  deleteIcon: {
    color: "#078CA5",
    display: "inline-flex",
  },
  container_med: {
    backgroundColor: "#EFF1F7",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "59px",
    padding: "30px",
    maxHeight: "400px",
    overflowX: "hidden",
  },
}));

const Prescription = (props) => {

  console.log(props, "ssbfsgfsd")
  const initialPrescriptionData = {
    city: "",
    area: "",
    searchData: undefined,
    searchGenericName: "",
    searchProductName: "",
    homeService: "",
    attribute: "",
  };



  const [prescrip, setPrescrip] = useState([]);
  console.log(prescrip,"prescrip")
  const [prescrip1, setPrescrip1] = useState([]);
  const addPrescrip = () => {
    setPrescrip((i) => [
      ...i,
      {
        GenName: " ",
        DrugName: " ",
        Attr: "",
        Strength: "",
        duration: "",
        Days: " ",

      },
    ]);
  };
  const handlePrescripChange = (e) => {
    const data = [...prescrip];
    data[e.target.dataset.id][e.target.name] = e.target.value;
    setPrescrip(data);
  };
  const handlePrescripDelete = (index) => {
    const currentPrescriptionList = [...prescrip];
    currentPrescriptionList.splice(index, 1);
    setPrescrip(currentPrescriptionList);
  };
  const [presVal, setPresVal] = useState(false)
  const [prescriptionList, setPrescriptionList] = React.useState([]);
  const [prescriptionData, setPrescriptionData] = React.useState(
    initialPrescriptionData
  );
  const [presError, setPresError] = React.useState(false)
  // const [submitPres,setSubmitPres]=React.useState(false)
  const [prescriptionAttrData, setPrescriptionAttrData] = React.useState([]);
  const [addButton, setAddButton] = React.useState(true);
  const [note, setNotes] = React.useState("");
  const [submitButton, setSubmitButton] = React.useState(true);
  const [radio, setRadio] = React.useState(false);
  const [radiovalue, setRadiovalue] = React.useState("");
  const [indexState, setIndexState] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [attribute, setAttribute] = React.useState("");
  const [medAttributeData, setMedAttributeData] = React.useState("");
  const [trueAdd, setTrueAdd] = React.useState(false);
  const [locationuuid, setLocationuuid] = useState("");
  const [homeArray, setHomeArray] = useState([]);
  const [docpopup, setDocpopup] = useState(false);
  const [displaymeddata, setDisplayMedData] = useState("");
  const [products, setProducts] = useState([]);
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [query, setQuery] = useState("");
  const [displayProduct, setDisplayProduct] = useState("");
  const [filterbyattr, setFilterbyattr] = useState("");
  const [filterbyproduct, setFilterbyProduct] = useState("");
  const [searchfilter, setSearchFilter] = useState("");
  const [isGenericdata, setIsGenericdata] = useState(false);
  const [renderGeneric, setRenderGeneric] = useState(false);
  const [genericmsg, setGenericmsg] = useState("");
  const [alredyAddedTest, setAlredyAddedTests] = useState([]);
  const [bookingId, setBookingId] = useState('');
  const [visitId, setVisitId] = useState('');
  const [uuid, setUuid] = useState('')
  const PrescriptionCity = useSelector(
    (state) => state.getCityForPrescription.payload
  );
  const PrescriptionArea = useSelector(
    (state) => state.getAreaForPrescription.payload
  );
  const PrescriptionGenericName = useSelector(
    (state) => state.searchGenericNameForPrescription
  );
  const PrescriptionProductName = useSelector(
    (state) => state.searchProductNameForPrescription
  );
  const PrescriptionAttribute = useSelector(
    (state) => state.medAttribute.payload
  );
  const HomeServiceData = useSelector(
    (state) => state.allPrescriptionDetail.home_service
  );
  const userdata = useSelector((state) => state.loginReducer.payload);
  const storegeneric = useSelector((state) => state.allPrescriptionDetail.data);
  React.useEffect(() => {
    dispatch(getCityForPrescription());
  }, []);

  useEffect(() => {
    setAlredyAddedTests([])
    fetchAddedTests()
    // fetchAddedTests()
  }, [props.id])

  const fetchAddedTests = async () => {
    try {
      console.log(props, "56565")
      // let response = await axios.get(`${process.env.REACT_APP_API_URL}/externalappointment/prescription/visit/ep/${props.visitInfo.uuid}`)
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/ep/visit/${props.visitId}`)
      if (response.status === 200) {
        setAlredyAddedTests(response.data && response.data && response.data[0].medication)
        let bookingID = response.data.data && response.data.data.booking_uuid
        let uuid2 = response.data && response.data && response.data[0].uuid
        let Visit_Id = response.data && response.data && response.data[0].patient_visit_uuid
        setVisitId(Visit_Id)
        setUuid(uuid2)
        setBookingId(bookingID)
      }
    } catch (error) {
      console.log(error)
    }
  }

  console.log(props.visitInfo, "visitInfo")
  const handleTestDelete = async (item) => {
    try {
      let payLoad = {
        "uuid": uuid,
        "patient_visit_uuid": visitId,
        "medic_uuid": item.uuid

      }
      // let response = await axios.delete(`${process.env.REACT_APP_API_URL}/externalappointment/prescription/remove/medication`, { data: payLoad })
      let response = await axios.delete(`${process.env.REACT_APP_API_URL}/ep/remove/prescription`, { data: payLoad })

      if (response.status === 200) {
        fetchAddedTests()
        props.showSuccess(true);
        props.message("1")
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    if (
      PrescriptionAttribute != undefined &&
      PrescriptionAttribute.data != undefined
    ) {
      setMedAttributeData(PrescriptionAttribute.data);
    }
  }, [PrescriptionAttribute]);

  useEffect(() => {
    if (trueAdd) {
      setAddButton(false);
    }
  }, [trueAdd]);

  useEffect(() => {
    if (prescrip && prescrip.length > 0) {
      setSubmitButton(false);
    } else {
      setSubmitButton(true);
    }
  }, [prescrip]);

  useEffect(() => {
    if (displaymeddata.length > 0) {
      let filterAttr = displaymeddata.filter(
        (el) => el.attr == prescriptionData.attribute
      );
      if (prescriptionData.attribute !== "") {
        setFilterbyattr(filterAttr);
      }
    }
  }, [prescriptionData.attribute]);

  useEffect(() => {
    if (displaymeddata.length > 0) {
      let filterProduct = displaymeddata.filter(
        (el) => el.productname == query
      );
      if (query !== "") {
        setFilterbyProduct(filterProduct);
      }
    }
  }, [query]);
  useEffect(() => { }, [homeArray]);

  useEffect(() => {
    genericnamefn();
  }, [storegeneric]);

  useEffect(() => {
    setRenderGeneric(false);
  }, []);
  const handleCity = (e) => {
    setPrescriptionData({
      ...prescriptionData,
      city: e,
    });
    dispatch(getAreaForPrescription(e));
  };
  const handleArea = (e) => {
    setPrescriptionData({
      ...prescriptionData,
      area: e,
    });
    setLocationuuid(e.uuid);
  };
  const handleAttr = async (e) => {
    setPrescriptionData({
      ...prescriptionData,
      attribute: e,
    });
    let filterAttr = displaymeddata.filter((el) => el.attr == e);
    if (prescriptionData.attribute !== "") {
      setFilterbyattr(filterAttr);
    }
  };

  const handleGenericName = (e, data) => {
    setRenderGeneric(true);
    dispatch(getallprescription(e));
    setPrescriptionData({
      ...prescriptionData,
      searchGenericName: e,
      searchData: data,
      // searchProductName: data.productname,
      homeService: "AVAILABLE",
    });

    const timeOutId = setTimeout(
      () =>
        dispatch(
          searchGenericNameForPrescription(prescriptionData.searchGenericName)
        ),
      300
    );
    return () => clearTimeout(timeOutId);
  };

  const handleProductName = (e, data) => {
    let filterProduct;
    setQuery(e);
    if (
      displaymeddata != undefined &&
      displaymeddata != "" &&
      displaymeddata.length > 0
    ) {
      filterProduct = displaymeddata.filter((el) => el.productname == e);
    }

    if (query !== "") {
      setFilterbyProduct(filterProduct);
    }
  };
  const searchGenericName = () => {
    dispatch(
      searchGenericNameForPrescription(prescriptionData.searchGenericName)
    );
  };

  const searchProductName = () => {
    dispatch(
      searchProductNameForPrescription(prescriptionData.searchProductName)
    );
  };

  const addPrescription = () => {
    const currentPrescriptionList = [...prescriptionList];
    currentPrescriptionList.push(prescriptionData);
    setPrescriptionList(currentPrescriptionList);
    setPrescriptionData(initialPrescriptionData);
    setMedAttributeData([]);
    setAddButton(true);
    setTrueAdd(false);
    setRadiovalue("");
    setDisplayMedData("");
    setFilterbyattr([]);
    setFilterbyProduct([]);
    setIsGenericdata(false);
    setRenderGeneric(false);
  };

  const handlePrescriptionDelete = (index) => {
    const currentPrescriptionList = [...prescriptionList];
    currentPrescriptionList.splice(index, 1);
    setPrescriptionList(currentPrescriptionList);
  };

  const randId = (size) => {
    const nums = Array.from({ length: 10 }, (_, i) =>
      String.fromCharCode("0".charCodeAt(0) + i)
    );
    const alphabets = Array.from({ length: 26 }, (_, i) =>
      String.fromCharCode("a".charCodeAt(0) + i)
    );
    const chars = [...nums, ...alphabets];
    const rand = (length) => Math.floor(Math.random() * length);
    return Array.from({ length: size }, () => chars[rand(chars.length)]).join(
      ""
    );
  };

  const handleSubmit = async () => {
    console.log(prescrip, "555")
    console.log(prescrip1, "555")
    // const validation = prescrip.every(item => item.productname && item.Quantity && item.dose && item.duration);
    const validation = prescrip.every(item => item.Strength && item.Attr && item.duration && item.GenName && item.DrugName && item.Days);



    if (validation === true) {
      const fullData = prescrip.map((v) =>
        Object.assign(v, {
          productupc: "",
          uuid: "HLP-" + randId(8).toUpperCase(),
          therapeuticclassification: "",
          genericname: "",
          packsize: "",
          attr: "",
          rrp: "",
          discountofferedforretail: "",
          doctallsexclusivediscountedprice: "",
          location_uuid: "",
          cities: "",
          areas: "",
        })
      );

      console.log(props, "props")
      var data = {
        // patient_uuid: props.data.patient.uuid,
        // user_uuid: props.data.patient.created_by.uuid,
        // doctor_uuid: props.data.doctor_uuid,
        // booking_uuid: props.data.uuid,
        patient_uuid: props.visitInfo.patient_uuid,
        // patient_visit_uuid: props.visitInfo.uuid,
        patient_visit_uuid: props.visitId,
        doctor_uuid: props.visitInfo.doctor_uuid,
        medication: fullData,
        homeDelivery: [
          {
            active: true,
            therapeuticclassification: "OTHER SERVICES",
            uuid: "HLP-" + randId(8).toUpperCase(),
            productname: "HOME SERVICE CHARGE",
            genericname: "Home Service Charge",
            rrp: "1500",
            "10discountedoffrrp": "1500",
            discountofferedforretail: "0",
            doctallsexclusivediscountedprice: "1500",
            location_uuid: "",
            cities: "",
            areas: "",
          },
        ],
        ignorerecommendation: false,
        notes: note,
      };
      var config = {
        method: "post",
        // url: process.env.REACT_APP_API_URL + "/externalappointment/prescription",
        url: process.env.REACT_APP_API_URL + "/ep/advice/create",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": userdata.token,
        },
        data: JSON.stringify(data),
      };

      axios(config)
        .then(function (response) {

          dispatch(
            getHistro(props.data && props.data.uuid)
          );
          props.showSuccess(true);
          props.getApi.getApi()
        })
        .catch(function (error) { });
      setPrescrip([])

      setTimeout(function () {
        dispatch(getMedPrescription(props.data.uuid));
      }, 3000);
      // console.log('worked')
    } else {
      setPresError(true)
    }

  };

  const handleQuantity = (e, index) => {
    prescriptionList[index].searchData.Quantity = e.target.value;
    prescriptionAttrData[index].Quantity = e.target.value;
    setPrescriptionList(prescriptionList);
    setPrescriptionAttrData(prescriptionAttrData);
  };

  const handleDose = (e, index) => {
    prescriptionList[index].searchData.dose = e.target.value;
    prescriptionAttrData[index].dose = e.target.value;
    setPrescriptionList(prescriptionList);
    setPrescriptionAttrData(prescriptionAttrData);
  };

  const handleDuration = (e, index) => {
    prescriptionList[index].searchData.duration = e.target.value;
    prescriptionAttrData[index].duration = e.target.value;
    setPrescriptionList(prescriptionList);
    setPrescriptionAttrData(prescriptionAttrData);
  };
  const handleRadio = (id, val) => {
    setPrescriptionData({
      ...prescriptionData,
      searchData: val,
      searchProductName: val.productname,
    })
    let medicData = {};
    let homeData = "";
    let homeArrayss = [];
    medicData = {
      ...val,
      location_uuid: locationuuid,
      cities: prescriptionData.city,
      areas: prescriptionData.area.areas,
    };
    if (HomeServiceData != undefined && HomeServiceData.length > 0) {
      homeData = HomeServiceData.map((val, ind) => ({
        ...val,
        location_uuid: locationuuid,
        cities: prescriptionData.city,
        areas: prescriptionData.area.areas,
      }));
    }
    homeData.map((val, ind) => {
      return setHomeArray((oldArr) => [...oldArr, val]);
    });
    setPrescriptionAttrData((oldArr) => [...oldArr, medicData]);
    setRadiovalue(id);
    setTrueAdd(true);
  };

  const handleDocpopupClose = () => {
    setDocpopup(false);
  };
  const genericnamefn = async () => {
    let tempProduct = [];
    let tempAttribute = [];
    let result = [];
    let attrResult = [];
    if (storegeneric != undefined && storegeneric.length > 0) {
      setDisplayMedData(storegeneric);
    } else {
      setDisplayMedData("");
    }
    if (storegeneric != undefined && storegeneric.length > 0) {
      result = await storegeneric.filter(
        (ele, ind) =>
          ind ===
          storegeneric.findIndex((elem) => {
            return elem.productname == ele.productname;
          })
      );
      attrResult = await storegeneric.filter(
        (ele, ind) =>
          ind ===
          storegeneric.findIndex((elem) => {
            return elem.attr == ele.attr;
          })
      );
    }
    await result.map((val, ind) => {
      tempProduct.push(val.productname);
    });
    setProducts(tempProduct);
    await attrResult.map((val, ind) => {
      tempAttribute.push(val.attr);
    });
    setAttributeOptions(tempAttribute);
  };

  const genericmsgfn = () => {
    setGenericmsg("Loading");
    setTimeout(
      () =>
        storegeneric != undefined && storegeneric.length <= 0
          ? setGenericmsg("No Data Found")
          : "",
      2000
    );
  };

  return (
    <>
      {/* <Grid container direction="row" justify="space-around">
        <DropDownForCity
          options={PrescriptionCity}
          placeholder={"City"}
          setTimeValue={(e) => {
            handleCity(e);
          }}
          timevalue={prescriptionData.city}
        />
        <DropDownForArea
          options={PrescriptionArea}
          placeholder={"Area"}
          setTimeValue={(e) => {
            handleArea(e);
          }}
          timevalue={prescriptionData.area}
        />
        <DropDownForGenericName
          options={PrescriptionGenericName}
          placeholder={"Generic Name"}
          setTimeValue={(e, data) => {
            handleGenericName(e, data);
          }}
          search={searchGenericName}
          timevalue={prescriptionData.searchGenericName}
          setResult={setIsGenericdata}
          msgFn={genericmsgfn}
        />
        <DropDownForProductName
          options={searchfilter}
          placeholder={"Product Name"}
          setTimeValue={(e) => {
            handleProductName(e);
          }}
          search={searchProductName}
          timevalue={query}
          setfilter={setSearchFilter}
          data={products}
        />

        <DropDownLableAttr
          options={attributeOptions}
          placeholder={"Attribute"}
          setTimeValue={(e) => {
            handleAttr(e);
          }}
          timevalue={prescriptionData.attribute}
        />
        <DropDownLable
          lable={"Home Delivery"}
          options={prescriptionData.homeService}
          placeholder={"Home Delivery"}
        />
      </Grid> */}
      {/* <Grid
        container
        className={classes.container_med}
        direction="row"
        justify="center"
        alignItems="flex-start"
      >
        
        <Grid item xs={4}>
          <Typography
            className={classes.typography}
            style={{ textAlign: "center" }}
          >
            Generic Name{" "}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            className={classes.typography}
            style={{ textAlign: "center" }}
          >
            Product Name{" "}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            className={classes.typography}
            style={{ textAlign: "center" }}
          >
            Attribute
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            className={classes.typography}
            style={{ textAlign: "center" }}
          >
            Pack Size
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            className={classes.typography}
            style={{ textAlign: "center" }}
          >
            Price
          </Typography>
        </Grid>
        
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Divider className={classes.divider} />
        </Grid>
        {filterbyproduct != undefined &&
        filterbyproduct != "" &&
        filterbyproduct.length > 0 &&
        renderGeneric == true
          ? filterbyproduct.map((val, ind) => {
              return (
                <>
                  <Grid
                    container
                    key={ind}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      container
                      item
                      xs={4}
                      direction="row"
                      justify="space-evenly"
                      alignItems="center"
                      style={{ paddingTop: "25px" }}
                    >
                      <Grid item>
                        {radiovalue !== val._id ? (
                          <img
                            src={radioOff}
                            alt="radio_off"
                            onClick={() => handleRadio(val._id, val)}
                            style={{ cursor: "pointer" }}
                            crossorigin="anonymous"
                          />
                        ) : radiovalue == val._id ? (
                          <img
                            src={radioOn}
                            alt="radio_on"
                            onClick={() => handleRadio(val._id, val)}
                            style={{ cursor: "pointer" }}
                            crossorigin="anonymous"
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item style={{ paddingLeft: "20px" }}>
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "11rem",
                          }}
                        >
                          <Typography
                            className={classes.tableData_medicine_font}
                            noWrap
                          >
                            {val.genericname}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ paddingTop: "25px" }}>
                      <Typography
                        className={classes.tableData_medicine_font}
                        style={{ textAlign: "center" }}
                      >
                        {val.productname}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ paddingTop: "25px" }}>
                      <Typography
                        className={classes.tableData_medicine_font}
                        style={{ textAlign: "center" }}
                      >
                        {val.attr}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ paddingTop: "25px" }}>
                      <Typography
                        className={classes.tableData_medicine_font}
                        style={{ textAlign: "center" }}
                      >
                        {val.packsize}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ paddingTop: "25px" }}>
                      <Typography
                        className={classes.tableData_medicine_font}
                        style={{ textAlign: "center" }}
                      >
                        ₦{val["10discountedoffrrp"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <Divider className={classes.divider} />
                    </Grid>
                  </Grid>
                </>
              );
            })
          : ""}

        {filterbyattr != undefined &&
        filterbyattr != "" &&
        filterbyattr.length > 0 &&
        renderGeneric == true
          ? filterbyattr.map((val, ind) => {
              return (
                <>
                  <Grid
                    container
                    key={ind}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      container
                      item
                      xs={4}
                      direction="row"
                      justify="space-evenly"
                      alignItems="center"
                      style={{ paddingTop: "25px" }}
                    >
                      <Grid item>
                        {radiovalue !== val._id ? (
                          <img
                            src={radioOff}
                            alt="radio_off"
                            onClick={() => handleRadio(val._id, val)}
                            style={{ cursor: "pointer" }}
                            crossorigin="anonymous"
                          />
                        ) : radiovalue == val._id ? (
                          <img
                            src={radioOn}
                            alt="radio_on"
                            onClick={() => handleRadio(val._id, val)}
                            style={{ cursor: "pointer" }}
                            crossorigin="anonymous"
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item style={{ paddingLeft: "20px" }}>
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "11rem",
                          }}
                        >
                          <Typography
                            className={classes.tableData_medicine_font}
                            noWrap
                          >
                            {val.genericname}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ paddingTop: "25px" }}>
                      <Typography
                        className={classes.tableData_medicine_font}
                        style={{ textAlign: "center" }}
                      >
                        {val.productname}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ paddingTop: "25px" }}>
                      <Typography
                        className={classes.tableData_medicine_font}
                        style={{ textAlign: "center" }}
                      >
                        {val.attr}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ paddingTop: "25px" }}>
                      <Typography
                        className={classes.tableData_medicine_font}
                        style={{ textAlign: "center" }}
                      >
                        {val.packsize}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ paddingTop: "25px" }}>
                      <Typography
                        className={classes.tableData_medicine_font}
                        style={{ textAlign: "center" }}
                      >
                        ₦{val["10discountedoffrrp"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <Divider className={classes.divider} />
                    </Grid>
                  </Grid>
                </>
              );
            })
          : ""}

        {displaymeddata != undefined &&
        displaymeddata.length > 0 &&
        filterbyattr == "" &&
        filterbyattr.length <= 0 &&
        filterbyproduct.length <= 0 &&
        renderGeneric == true ? (
          displaymeddata.map((val, ind) => {
            return (
              <>
                <Grid
                  container
                  key={ind}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    container
                    item
                    xs={4}
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    style={{ paddingTop: "25px" }}
                  >
                    <Grid item>
                      {radiovalue !== val._id ? (
                        <img
                          src={radioOff}
                          alt="radio_off"
                          onClick={() => handleRadio(val._id, val)}
                          style={{ cursor: "pointer" }}
                          crossorigin="anonymous"
                        />
                      ) : radiovalue == val._id ? (
                        <img
                          src={radioOn}
                          alt="radio_on"
                          onClick={() => handleRadio(val._id, val)}
                          style={{ cursor: "pointer" }}
                          crossorigin="anonymous"
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item style={{ paddingLeft: "20px" }}>
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "11rem",
                        }}
                      >
                        <Typography
                          className={classes.tableData_medicine_font}
                          noWrap
                        >
                          {val.genericname}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: "25px" }}>
                    <Typography
                      className={classes.tableData_medicine_font}
                      style={{ textAlign: "center" }}
                    >
                      {val.productname}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: "25px" }}>
                    <Typography
                      className={classes.tableData_medicine_font}
                      style={{ textAlign: "center" }}
                    >
                      {val.attr}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: "25px" }}>
                    <Typography
                      className={classes.tableData_medicine_font}
                      style={{ textAlign: "center" }}
                    >
                      {val.packsize}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ paddingTop: "25px" }}>
                    <Typography
                      className={classes.tableData_medicine_font}
                      style={{ textAlign: "center" }}
                    >
                      ₦{val["10discountedoffrrp"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <Divider className={classes.divider} />
                  </Grid>
                </Grid>
              </>
            );
          })
        ) : storegeneric != undefined &&
          storegeneric.length <= 0 &&
          isGenericdata == true ? (
          <>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography
                  className={classes.tableData_medicine_font}
                  style={{ textAlign: "center" }}
                >
                  {genericmsg}
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid> */}
      {/* <Grid container style={{ display: "flex" }}>
        <Grid item style={{ marginLeft: "auto", order: 2 }}>
          <Button
            // disabled={addButton}
            onClick={() => {
              // addPrescription();
              addPrescrip()
            }}
            variant="contained"
            style={{
              backgroundColor: "#00839B",
              color: "white",
              borderRadius: "15px",
              height: 60,
              width: 119,
              marginTop: 30,
              fontSize: 20,
              textTransform: "capitalize",
              fontFamily: ["GreycliffCFBold"].join(","),
            }}
          >
            Add
            <AddIcon />
          </Button>
        </Grid>
      </Grid> */}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.container}
        fullWidth
        md={12}
      >
      


        <Grid item md={2}>
          {prescrip.length==0?<Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Generic Name</Typography>:null}
        </Grid>
        <Grid item md={2}>
        {prescrip.length==0?<Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Drug Name</Typography>:null}
        </Grid>
        <Grid item md={2}>
        {prescrip.length==0?<Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Attribute</Typography>:null}
        </Grid>
        <Grid item md={2}>
        {prescrip.length==0? <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Strength</Typography>:null}
        </Grid>
        <Grid item md={2}>
        {prescrip.length==0?<Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Duration</Typography>:null}
        </Grid>
        <Grid item md={2}>
        {prescrip.length==0?<Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Days</Typography>:null}
        </Grid>


        {/* <Grid item md={1}></Grid> */}
        {prescrip.length==0?<Grid item md={12} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>
          <Divider className={classes.divider} />
        </Grid>:null}
    
        {console.log(alredyAddedTest, "fghzxxj1")}

        {alredyAddedTest && alredyAddedTest.length !== 0 ? (
          alredyAddedTest.map((i, index) => {
            console.log(i, "fghzxxj2")
            return (
              <Grid
                key={index}
                container
                style={{ marginTop: "22px" }}
                direction="row"
                alignItems="center"
              >
                <Grid item md={12} style={{ display: "flex" }}>
                  <Grid item md={6}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Generic Name<span className='star-color'>*</span></Typography>
                    <input
                      className={classes.multilineInputField}
                      disabled
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                        width: "500px"
                      }}
                      type="text"
                      // onChange={handlePrescripChange}
                      // placeholder="Please type here"
                      data-id={index}
                      value={i.GenName}
                      name="GenName"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Drug Name<span className='star-color'>*</span></Typography>
                    <input
                      className={classes.multilineInputField}
                      disabled
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                        width: "500px"
                      }}
                      type="text"
                      // onChange={handlePrescripChange}
                      // placeholder="Please type here"
                      data-id={index}
                      value={i.DrugName}
                      name="DrugName"
                    />
                  </Grid>
                </Grid>
                <Grid item md={12} style={{ marginTop: "10px", display: "flex" }}>
                  <Grid item md={3}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Attribute<span className='star-color'>*</span></Typography>
                    <select id="Attribute2" className={classes.multilineInputField}
                      disabled
                      required
                      
                      // disabled={editFlag === false}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                        width: "200px",
                        fontSize: "18px"
                      }}
                      // onChange={handlePrescripChange}
                      data-id={index}
                      defaultValue={i.Attr}
                      name="Attr"
                      
                    >
                      <option value=""></option>
                      <option value="Tabs" className="headersearchtxt">Tabs</option>
                      <option value="Capsules" className="headersearchtxt">Capsules</option>
                      <option value="Ampoule" className="headersearchtxt">Ampoule</option>
                      <option value="Powder" className="headersearchtxt">Powder</option>
                      <option value="Cream" className="headersearchtxt">Cream</option>
                    </select>
                  </Grid>
                  <Grid item md={3}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Strength<span className='star-color'>*</span></Typography>
                    <input
                      className={classes.multilineInputField}
                      disabled
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                      }}
                      type="text"
                      data-id={index}
                      defaultValue={i.Strength}
                      name="Strength"
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Duration<span className='star-color'>*</span></Typography>
                    {/* <input
                    className={classes.multilineInputField}
                    style={{
                      backgroundColor: "#FFFFFF",
                      color: "#013C44",
                      borderRadius: 20,
                      border: "1px solid #A0DFC4",
                      padding: "18.5px 14px",
                    }}
                    type="text"
                    onChange={handlePrescripChange}
                    // placeholder="Please type here"
                    data-id={index}
                    value={i.duration}
                    name="duration"
                  /> */}
                    <select id="Attribute3" className={classes.multilineInputField}
                      required
                      defaultValue={i.duration}
                      disabled
                      // disabled={editFlag === false}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                        width: "200px",
                        fontSize: "18px"
                      }}
                      data-id={index}
                      // value={i.duration}
                      name="duration"
                      onChange={handlePrescripChange}
                    >
                      <option value=""></option>
                      <option value="Once a day- M" className="headersearchtxt">Once a day- M</option>
                      <option value="Once a day- AF" className="headersearchtxt">Once a day- AF</option>
                      <option value="Once a day- N" className="headersearchtxt">Once a day- N</option>
                      <option value="Twice a day - M,N" className="headersearchtxt">Twice a day - M,N</option>
                      <option value="Thrice a day" className="headersearchtxt">Thrice a day</option>
                      <option value="Four times a " className="headersearchtxt">Four times a day </option>
                    </select>
                  </Grid>
                  <Grid item md={3}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Days<span className='star-color'>*</span></Typography>
                    <input
                    disabled
                      className={classes.multilineInputField}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                      }}
                      type="text"
                      onChange={handlePrescripChange}
                      // placeholder="Please type here"
                      data-id={index}
                      defaultValue={i.Days}
                      // value={i.Days}
                      name="Days"
                    />
                  </Grid>
                  <Grid item md={1} style={{ textAlign: "center" }}>
                    <IconButton
                      className={classes.deleteIcon}
                      onClick={() => { handleTestDelete(i) }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <div>{''}</div>
        )}



        {console.log(prescrip && prescrip.length, "varun")}
        {prescrip && prescrip.length !== 0 ? (
          prescrip.map((i, index) => {
            console.log(i, "yfghj")
            return (
              <Grid
                key={index}
                container
                style={{ marginTop: "22px" }}
                direction="row"
                alignItems="center"
              >

                <Grid item md={12} style={{ display: "flex" }}>
                  <Grid item md={6}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Generic Name<span className='star-color'>*</span></Typography>
                    <input
                      className={classes.multilineInputField}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                        width: "500px",
                        fontSize: "18px"
                      }}
                      type="text"
                      onChange={handlePrescripChange}
                      // placeholder="Please type here"
                      data-id={index}
                      value={i.GenName}
                      name="GenName"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Drug Name<span className='star-color'>*</span></Typography>
                    <input
                      className={classes.multilineInputField}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                        width: "500px",
                        fontSize: "18px"
                      }}
                      type="text"
                      onChange={handlePrescripChange}
                      // placeholder="Please type here"
                      data-id={index}
                      value={i.DrugName}
                      name="DrugName"
                    />
                  </Grid>
                </Grid>
                <Grid item md={11} style={{ marginTop: "10px", display: "flex" }}>
                  <Grid item md={3}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Attribute<span className='star-color'>*</span></Typography>
                    {/* <input
                    className={classes.multilineInputField}
                    style={{
                      backgroundColor: "#FFFFFF",
                      color: "#013C44",
                      borderRadius: 20,
                      border: "1px solid #A0DFC4",
                      padding: "18.5px 14px",
                    }}
                    type="text"
                    onChange={handlePrescripChange}
                    // placeholder="Please type here"
                    data-id={index}
                    value={i.dose}
                    name="dose"
                  /> */}
                    <select id="Attribute1" className={classes.multilineInputField}
                      required
                      // defaultValue={details && details.gender}
                      // disabled={editFlag === false}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                        width: "200px",
                        fontSize: "18px"
                      }}
                      // value={i.dose}
                      data-id={index}
                      // value={i.dose}
                      value={i.Attr}
                      name="Attr"
                      onChange={handlePrescripChange}
                    >
                      <option value=""></option>
                      <option value="Tabs" className="headersearchtxt">Tabs</option>
                      <option value="Capsules" className="headersearchtxt">Capsules</option>
                      <option value="Ampoule" className="headersearchtxt">Ampoule</option>
                      <option value="Powder" className="headersearchtxt">Powder</option>
                      <option value="Cream" className="headersearchtxt">Cream</option>
                    </select>
                  </Grid>
                  <Grid item md={3}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Strength<span className='star-color'>*</span></Typography>
                    <input
                      className={classes.multilineInputField}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                        fontSize: "18px"
                      }}
                      type="text"
                      onChange={handlePrescripChange}
                      // placeholder="Please type here"
                      data-id={index}
                      value={i.Strength}
                      name="Strength"

                    />
                  </Grid>
                  <Grid item md={3}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Duration<span className='star-color'>*</span></Typography>
                    {/* <input
                    className={classes.multilineInputField}
                    style={{
                      backgroundColor: "#FFFFFF",
                      color: "#013C44",
                      borderRadius: 20,
                      border: "1px solid #A0DFC4",
                      padding: "18.5px 14px",
                    }}
                    type="text"
                    onChange={handlePrescripChange}
                    // placeholder="Please type here"
                    data-id={index}
                    value={i.dose}
                    name="dose"
                  /> */}
                    <select id="Attribute" className={classes.multilineInputField}
                      required
                      // defaultValue={details && details.gender}
                      // disabled={editFlag === false}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                        width: "200px",
                        fontSize: "18px"
                      }}
                      data-id={index}
                      value={i.duration}
                      name="duration"
                      onChange={handlePrescripChange}
                    >
                      <option value=""></option>
                      <option value="Once a day- M" className="headersearchtxt">Once a day- M</option>
                      <option value="Once a day- AF" className="headersearchtxt">Once a day- AF</option>
                      <option value="Once a day- N" className="headersearchtxt">Once a day- N</option>
                      <option value="Twice a day - M,N" className="headersearchtxt">Twice a day - M,N</option>
                      <option value="Thrice a day" className="headersearchtxt">Thrice a day</option>
                      <option value="Four times a " className="headersearchtxt">Four times a day </option>
                    </select>

                  </Grid>
                  <Grid item md={3}>
                    <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Days<span className='star-color'>*</span></Typography>
                    <input
                      className={classes.multilineInputField}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#013C44",
                        borderRadius: 20,
                        border: "1px solid #A0DFC4",
                        padding: "18.5px 14px",
                        fontSize: "18px"
                      }}
                      type="text"
                      onChange={handlePrescripChange}
                      // placeholder="Please type here"
                      data-id={index}
                      value={i.Days}
                      name="Days"
                    />
                  </Grid>
                </Grid>
                <Grid item md={1} style={{ textAlign: "center", marginTop: "5%" }}>
                  <IconButton
                    className={classes.deleteIcon}
                    onClick={handlePrescripDelete.bind(this, index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <div>{''}</div>

        )}

        {presError === true ? <>
        <p style={{ color: 'red' }}> All Fields Are Required</p></> : null}
        <Grid container >
        <Grid item  
        style={{ marginLeft: "0px",}}
        // align="left"
        >
          <Button
            // disabled={addButton}
            onClick={() => {
              // addPrescription();
              addPrescrip();
            }}
            variant="contained"
            style={{
              backgroundColor: "#00839B",
              background:"#00839B",
              color: "white",
              borderRadius: "15px",
              height: 60,
              width: 119,
              marginTop: 30,
              fontSize: 20,
              textTransform: "capitalize",
              fontFamily:"VisueltRegular",
              fontSize:"26px",
       
              // fontFamily: ["GreycliffCFBold"].join(","),
            }}
          >
            Add
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
      </Grid>
      {/* {presError === true ? <>
        <p style={{ color: 'red' }}> All Fields Are Required</p></> : null} */}
      {/* <Grid container >
        <Grid item  
        style={{ marginLeft: "71px",}}
        // align="left"
        >
          <Button
            // disabled={addButton}
            onClick={() => {
              // addPrescription();
              addPrescrip();
            }}
            variant="contained"
            style={{
              backgroundColor: "#00839B",
              background:"#00839B",
              color: "white",
              borderRadius: "15px",
              height: 60,
              width: 119,
              marginTop: 30,
              fontSize: 20,
              textTransform: "capitalize",
              fontFamily:"VisueltRegular",
              fontSize:"26px",
       
              // fontFamily: ["GreycliffCFBold"].join(","),
            }}
          >
            Add
            <AddIcon />
          </Button>
        </Grid>
      </Grid> */}
      <Grid container md={12} className={classes.container2}>
        <Grid item md={12} style={{ textAlign: "left" }}>
          <Typography className={classes.typography} style={{fontFamily:"VisueltRegular", color:"#00839B", fontSize:"27px"}}>Special Advise</Typography>
        </Grid>
        <Grid item md={12} style={{ marginTop: "28px", marginBottom: "5px" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Please type here"
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              classes: { notchedOutline: classes.outlinedInputField },
            }}
            value={note}
            multiline
            rows={10}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            className={classes.multilineInputField}
          />
        </Grid>
        <Grid
        item
        style={{
          marginRight: "auto",
          display: "flex",
          marginTop: "32px",
          // marginLeft: "71px",
          // marginBottom: "74px",
        }}
      >
        <Button
          disabled={submitButton}
          onClick={() => handleSubmit()}
          style={{
            backgroundColor: "#00839B",
            borderRadius: "20px",
            color: "white",
            width: 141,
            height: 61,
            fontSize:"26px",
            textTransform: "capitalize",
            // fontFamily: ["GreycliffCFBold"].join(","),
            fontFamily:"VisueltRegular",
        

          }}
          size="large"
        >
          submit
        </Button>
      </Grid>
      </Grid>
      {/* <Grid
        item
        style={{
          marginRight: "auto",
          display: "flex",
          marginTop: "32px",
          marginLeft: "71px",
          marginBottom: "74px",
        }}
      >
        <Button
          disabled={submitButton}
          onClick={() => handleSubmit()}
          style={{
            backgroundColor: "#00839B",
            borderRadius: "20px",
            color: "white",
            width: 141,
            height: 61,
            fontSize:"26px",
            textTransform: "capitalize",
            // fontFamily: ["GreycliffCFBold"].join(","),
            fontFamily:"VisueltRegular",
        

          }}
          size="large"
        >
          submit
        </Button>
      </Grid> */}
      <Dialog
        maxWidth={"lg"}
        open={docpopup}
        scroll={"body"}
        onClose={handleDocpopupClose}
      >
        <div className="ContainerWrapper">
          <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => setDocpopup(false)}
            crossorigin="anonymous"
          />
          <DocmedFailure />
        </div>
      </Dialog>
    </>
  );
};

export default Prescription;
