
const PatientSnapshot = (state = { isLoading: true, isError: false, payload: [], visitList: [], orderList: [], bankDetails: {}, statistics: {}, visitByPatients: [], doctorProfiles: {} }, action) => {
    switch (action.type) {
        case "GET_PATIENT":
            return {
                ...state,
                isLoading: true,
                isError: false,
                payload: []
            };
        case "GET_SAAS_DOCTOR_PROFILE":
            return {
                ...state,
                isLoading: true,
                isError: false,
            };

        case "GET_PATIENT_SUCCESS":
            return {
                ...state,
                isLoading: false,
                payload: action.payload,
                isError: false
            };

        case "GET_SAAS_DOCTOR_PROFILE_SUCCESS":
            return {
                ...state,
                isLoading: false,
                doctorProfiles: action.doctorProfiles,
                isError: false
            };




        case "GET_VISITS":
            return {
                ...state,
                isLoading: true,
                isError: false,
            };


        case "GET_ORDERS":
            return {
                ...state,
                isLoading: true,
                isError: false,

            };

        case "GET_VISITS_BY_PATIENT":
            return {
                ...state,
                isLoading: true,
                isError: false,
                visitByPatients: []
            };

        case "GET_VISITS_BY_PATIENT_SUCCESS":
            return {
                ...state,
                isLoading: false,
                visitByPatients: action.visitByPatients,
                isError: false
            };

        case "GET_BANKS":
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case "GET_VISIT_SUCCESS":
            return {
                ...state,
                isLoading: false,
                visitList: action.visitList,
                isError: false
            };

        case "GET_ORDERS_SUCCESS":
            return {
                ...state,
                isLoading: false,
                orderList: action.orderList,
                isError: false
            };
        case "GET_BANKS_SUCCESS":
            return {
                ...state,
                isLoading: false,
                bankDetails: action.bankDetails,
                isError: false
            };
        case "GET_STATISTICS":
            return {
                ...state,
                isLoading: true,
                isError: false,
            };

        case "GET_STATISTICS_SUCCESS":
            return {
                ...state,
                isLoading: false,
                statistics: action.statistics,
                isError: false
            };
        default:
            return state;
    }
}

export { PatientSnapshot }