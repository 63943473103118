import React from 'react'
import { makeStyles } from '@material-ui/core';


const useStyle = makeStyles(theme => ({
    root: {
        width: '497px',
        padding: 50,
        backgroundColor: '#F0F5F5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',


    },
    img: {
        display: 'block',
        width: '108px',
        height: 'auto',
        margin: 'auto',
        [theme.breakpoints.down("sm")]: {
            width: '60px',
        },

    },
    txt: {
        fontFamily: 'GreycliffCFRegular',
        color: 'red',
        letterSpacing: '-0.3px',
        fontSize: '25px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        [theme.breakpoints.down("sm")]: {
            fontSize: '25px'
        },
    }
}))


function ErrorScreen({ err }) {
    const classes = useStyle()
    return (
        <div className={classes.root}>
            <div style={{ padding: '10px' }}>
                <div className={classes.txt}>{err}</div>
            </div>
        </div>
    )
}

export default ErrorScreen
