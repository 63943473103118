import React, { useState } from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";

import dialogclose from "../../assets/images/DialogClose.svg";
import DialogLayout from "./components/DialogLayout";
import LabResult from "./LabResults";
import moment from "moment";

import styles from "./style";
const useStyles = makeStyles((theme) => styles(theme));

const OrderDetail = ({ closeorderDetail, order,data }) => {
  console.log(data,"sdsdad")
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const [labresult, setLabresult] = useState(false);
  console.log(order,"order");
  return (
    <Grid
      container
      direction="column"
      style={{
        padding: "28px 20px 38px 16px",
      }}
    >
      <Grid
        item
        container
        justifyContent="flex-end"
        style={{ marginBottom: "28px" }}
      >
        <img src={dialogclose} alt="close" onClick={() => closeorderDetail()} />
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Order Id</Typography>
          <Typography className={classes.value}>{order && order.order && order.order[0] && order.order[0].uuid ? order.order[0].uuid : "NA"}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>Name</Typography>
          <Typography className={classes.value}>{order && order.patient && order.patient[0] && order.patient[0].full_name}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ marginTop: "19px" }}
      >
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Phone No.</Typography>
          <Typography className={classes.value}>{order && order.patient && order.patient[0] && order.patient[0].mobile}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>Type of Order</Typography>
          <Typography className={classes.value}>{order && order.labtype=="recommended"?"DMP":""}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ marginTop: "19px" }}
      >
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Amount</Typography>
          <Typography className={classes.value}>{order && order.order &&  order.order[0] && order.order[0].amount}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>Amount Earned</Typography>
          <Typography className={classes.value}>
          {order && order.commission}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ marginTop: "19px" }}
      >
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Order date</Typography>
          <Typography className={classes.value}>{order && order.createdAt ? `${moment(order.createdAt).format('DD/MM/YYYY')}` : "NA"}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-end" sm xs>
          <Typography className={classes.label}>Referred by</Typography>
          <Typography className={classes.value}>{order && order.doctor_uuid ? "SELF" : "NA"}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ marginTop: "19px" }}
      >
        <Grid item container direction="column" alignItems="flex-start" sm xs>
          <Typography className={classes.label}>Status</Typography>
          <Typography className={classes.value}>{order.status}</Typography>
        </Grid>
      </Grid>
      <button
        className={classes.addVisit}
        style={{ width: "300px", marginTop: "35px" }}
        onClick={() => {
          setLabresult(true);
        }}
      >
        View Result
      </button>
      {labresult ? (
        <DialogLayout open={labresult}>
          <LabResult closelabresult={() => setLabresult(false)} 
            data={data}
          />
        </DialogLayout>
      ) : null}
    </Grid>
  );
};

export default OrderDetail;
