const visits = [
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },

  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },

  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },

  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },

  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },

  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
  {
    name: "Samiat Oba",
    date: "12/09/2022",
    dob: "10/10/1995",
    phoneno: "+234 905 764 9234",
    time: "4:38 PM",
  },
];

export default visits;
