import React from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { useState,useEffect } from "react";
import styles from "./style";
import dialogclose from "../../assets/images/DialogClose.svg";
import checkIcon from "../../assets/images/checkIcon.svg";
import redClose from "../../assets/images/redClose.svg";
import remainder from "../../assets/images/remainder.svg";
import Trash from "../../assets/images/Trash.svg";
import axios from "axios";
import Dropdown from "react-dropdown";
import dropDown from "../../assets/images/dropDown.svg";
import { useSelector, useDispatch } from "react-redux";
import { List } from "antd";
import { getHistro } from "../../redux/actions/caseNoteAction";
import { getMedPrescription } from "../../redux/actions/cockpitAction";

const useStyles = makeStyles((theme) => styles(theme));

const CreateLabTest = ({ closeLabTest, routedata, getApi, sucessPop, setTexts }) => {
  console.log(routedata, "routedata")

  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const token = useSelector((state) => state.loginReducer.payload.token);
  const [Remainder, setRemainder] = useState(false);
  const [partners, setPartners] = useState([]);
  const [partnerName, setPartnerName] = useState([])
  const [typeTest, setTypeTest] = React.useState("");

  const [result, setResult] = React.useState([]);
  const [option, setOption] = React.useState(false);
  const [optionValue, setOptionValue] = React.useState("");
  const [testDiscipline,settestDiscipline]=useState("Test Discipline")


  const [disdropdown, setDisdropdown] = React.useState(false);
  const [addClear, setAddClear] = React.useState(true);
  const [testdis, setTestdis] = React.useState({});
  const [passValuse, setPassValue] = React.useState([]);
  const [advice, setAdvice] = React.useState("");




  useEffect(() => {
    getPaymentData()
  }, [])
  const getPaymentData = () => {
    axios.get(process.env.REACT_APP_API_URL + `/metadata/partner/getpartner`, {
      // headers: {
      //     "Content-Types": "multipart/form-data",
      //     "x-auth-token": token,
      //   }
    })
      .then((res) => {
        // setCode(res.data.referal_code)
        setPartners(res.data)
        console.log(res.data, "setpeyment")

      })
      .catch((err) => {
        // console.log(err)
      })
  }

  const handleSearch = async (value) => {
    setOptionValue(value.target.value);
    if (value.target.value) {
      setOption(true);
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/labtest/epLabTest/labtests/?testtype=${typeTest}&term=${value.target.value}`
        )
        .then((res) => {
          if (typeTest === "pathology") {
            if (res.data.pathology === undefined) {
              setResult(["Not Found"]);
            } else {
              setResult(res.data.pathology.map((v) => v.standardisedtestname));
            }
          } else {

            if (res.data.radiology === undefined) {
              setResult(["Not Found"]);
            } else {
              setResult(res.data.radiology.map((v) => v.standardisedtestname));
            }
          }
        })
        .catch((error) => console.log(error.response));
    } else {
      setOption(false);
      setResult([]);
    }
  };
  const handleTestDiscipline = async (value) => {
    setOptionValue(value);
    setOption(false);
    setDisdropdown(true);
    if (value) {
      await axios.get(
        `${process.env.REACT_APP_API_URL}/labtest/epLabTest/labtests/?testtype=${typeTest}&term=${value}`
      )
        .then((res) => {
          if (typeTest === "pathology") {
            const filter = res.data.pathology.filter(
              (e) => e.standardisedtestname === value
            );

            setPassValue(filter.map((v) => v));
          } else if (typeTest === "radiology") {
            const filter = res.data.radiology.filter(
              (e) => e.standardisedtestname === value
            );
            setPassValue(filter.map((v) => v));
          }
        })
        .catch((error) => console.log(error.response));
    } else {
      setResult([]);
    }
  };

  const handleSubmit = async () => {
    if (addListing.length !== 0) {
      var data = {
        patient_uuid: routedata && routedata.patient_uuid,
        patient_visit_uuid: routedata && routedata.uuid,
        doctor_uuid: routedata && routedata.doctor_uuid,
        labtype: "recommended",
        tests: addListing,
        notes: advice,
        homeDelivery: [],
        recomended_partner_name: partnerName.name,
        recomended_partner_uuid: partnerName.uuid

      };

     console.log(data,"data1");
      axios.post(process.env.REACT_APP_API_URL + "/ep-lab/advice/create", data, {
        // axios.post(process.env.REACT_APP_API_URL + "/externalappointment/ep-lab", data, {
        headers: {
          "x-auth-token": token,
          "Content-Type": 'application/json'
        },
      })
        .then((res) => {
          dispatch(
            getHistro(routedata && routedata.uuid)
          );
          fetchAddedTests()
          closeLabTest()
          setTexts("Labtest Added")
          sucessPop()
          getApi()
        })
        .catch((err) => console.log(err));
    }
    setTimeout(function () {
      dispatch(getMedPrescription(routedata.uuid));
    }, 3000);
  };
  
  const [addListing, setAddListing] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [alredyAddedTest, setAlredyAddedTests] = useState([]);
  const [prescriptionId, setPrescriptionId] = useState();



  useEffect(() => {
    setAlredyAddedTests([])
    fetchAddedTests()
    // if (id !== 'Add') {

    // } else {
    //   console.log("Add Clicked")
    // }
  }, [])


  const fetchAddedTests = async () => {
    try {
      // patient_visit_uuid:routedata.routedata.data.uuid,
      // let response = await axios.get(`${process.env.REACT_APP_API_URL}/externalappointment/ep-lab/visit/epl/${routedata.visitId}`)
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/ep-lab/visit/${routedata && routedata.uuid}`, {
        headers: {
          "x-auth-token": token
        }
      })
      if (response.status === 200) {
        let epId = response.data && response.data[0] && response.data[0].uuid
        setPrescriptionId(epId)
        setAlredyAddedTests(response.data && response.data && response.data[0] && response.data[0].tests)

      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleTestDelete = async (item) => {
    console.log(item,"item")
    try {
      let visitId = routedata && routedata.uuid

      let payLoad = {
        "uuid": prescriptionId,
        "patient_visit_uuid": visitId,
        "test_uuid": item.uuid
      }

      // let response = await axios.delete(`${process.env.REACT_APP_API_URL}/externalappointment/remove/test`, { data: payLoad })
      let response = await axios.delete(`${process.env.REACT_APP_API_URL}/ep-lab/remove/labtest`, { data: payLoad })

      if (response.status === 200) {
        fetchAddedTests()
        setTexts("Labtest Removed")
        sucessPop()
        getApi()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAdd = () => {
    if (testdis && testdis.active) {
      addListing.push(testdis);
      setTestdis();
      setTypeTest("");
      setOptionValue("");
      setDisdropdown("");
      setAddClear(false);
      // setAddStatus(true);
    } else {
      // setAddStatus(false);
    }
  };

  const handleDeleteList = (v) => {
    addListing.splice(
      addListing.findIndex((e) => e._id === v._id),
      1
    );
    setUpdate(!update);
  };

  const handleClinical = (value, e) => {
    let index = addListing.findIndex((e) => e._id === value._id);
    addListing[index].clinicaladvice = e.target.value;
    setUpdate(!update);
  };

  return (
    <Grid container direction="column" style={{ paddingTop: "16px" }}>
      <Grid container justifyContent="space-between">
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "35px",
            color: "#013C44",
            marginLeft: "21px",
          }}
        >
        Create Lab Test Advice
        </Typography>
        <img
          src={dialogclose}
          alt="close button"
          style={{ marginRight: "16px", cursor: "pointer" }}
          onClick={() => closeLabTest()}
        />
      </Grid>
      <hr
        style={{
          height: "0.5px",
          borderWidth: "0px",
          width: "326px",
          background: "#00839B",
          borderColor: "#00839B",
        }}
      />
      <Grid
        container
        style={{ marginTop: "17px", marginBottom: "13px" }}
        justifyContent="flex-end"
      >
        <img
          src={remainder}
          alt="remainder"
          style={{ marginRight: "21px", cursor: "pointer" }}
          onClick={() => setRemainder(!Remainder)}
        />
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ paddingLeft: "16px", paddingRight: "15px" }}
      >
        {Remainder ? (
          <>
            {/* <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "10px",
                lineHeight: "12px",
                color: "#00839B",
                width: "100%",
                marginBottom: "4px",
              }}
              align="left"
            >
              Search for lab test
            </Typography>
            <input className={classes.inputField} /> */}
            <>
            <Grid
              style={{
                width: "301px",
                height: "107px",
                background: "#F0F5FE",
                border: "1px solid #D5DEEF",
                borderRadius: "8px",
                marginBottom: "7px",
                padding: "18px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#00839B",
                }}
              >
                Important Tip
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: "#00839B",
                  marginTop: "11px",
                }}
              >
                Select your preferred Diagnostic partner from which you would
                like your patient to purchase the teest from.
              </Typography>
            </Grid>
          </>
          </>
        ) : (
        null
        )}


        <div 
        // className="type_test_container"
        >
          <Dropdown
              options={partners.map(item=>item.name)}
              placeholder="Partners"
              // className={classes.gender}
              // menuClassName="dropdownMenu"
              // controlClassName="myControlClassName"
              // arrowClassName="myArrowClassName"
              style={{color:"red !importent"}}
              onChange={(value) => setPartnerName(partners.filter(item=>value.value===item.name)[0])}
              arrowClosed={
                <img crossorigin="anonymous" src={dropDown} alt="icon" />
              }
              arrowOpen={
                <img crossorigin="anonymous" src={dropDown} alt="icon" />
              }
            />
             </div>



             <div className="type_test_container">

<br/>

            <Dropdown
              options={["pathology", "radiology"]}
              onChange={(value) => setTypeTest(value.value)}
              value={typeTest}
              placeholder="Test Type"
              menuClassName="dropdownMenu"
              controlClassName="myControlClassName"
              arrowClassName="myArrowClassName"
              arrowClosed={
                <img crossorigin="anonymous" src={dropDown} alt="icon" />
              }
              arrowOpen={
                <img crossorigin="anonymous" src={dropDown} alt="icon" />
              }
            />
          </div>
          <br/>
          {/* <Grid>
          
          <input
                type="text"
                onChange={handleSearch}
                // className="input_text"
                className={classes.inputField}
                value={optionValue}
                disabled={
                  typeTest == "pathology"
                    ? false
                    : typeTest == "radiology"
                      ? false
                      : true
                }
                placeholder="Standardized Test Name "
              />
          </Grid> */}
          <br/>
         
        {/* <Grid>Preferred Lab Partners</Grid>
        <Grid>Pathology</Grid>
        <Grid>Anti Reticulin</Grid>
        <Grid>Auto Immune</Grid> */}
        <div className="test_name_container">
           

            <div className="test_name">
              <input
              className={classes.inputField}
                type="text"
                onChange={handleSearch}
                // className="input_text"
                value={optionValue}
                disabled={
                  typeTest == "pathology"
                    ? false
                    : typeTest == "radiology"
                      ? false
                      : true
                }
                placeholder="Standardized Test Name "
              />
              {option ? (
                <div className="option_container1">
                  {result.map((v, i) => (
                    <div
                      className="options"
                      style={{fontSize:"15px",width:"200px"}}
                      key={i}
                      onClick={() => handleTestDiscipline(v)}
                    >
                      {v}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <br />

          <div 
          className="test_name_container"
          >
           <div 
           className="test_name"
           >
             <input
             className={classes.inputField}
               type="text"
              //  onChange={handleSearch}
               // className="input_text"
               value={testdis && testdis.testdiscipline && addClear
                ? testdis.testdiscipline
                : ""}
               placeholder="Test Discipline"
             />
             {disdropdown ? (
               <div className="option_container">
                 {passValuse.map((v, i) => (
                   <div
                     className="options"
                     style={{fontSize:"15px",width:"200px"}}  
                     key={i}
                     onClick={() => {
                      setDisdropdown(false);
                      setAddClear(true);
                      v.clinicaladvice = "";
                      setTestdis(v);
                    }}
                   >
                     {v.testdiscipline}
                   </div>
                 ))}
               </div>
             ) : (
               ""
             )}
           </div>
         </div>
                  
          <br/>
          <Grid>

          </Grid>
        <button
          className={classes.mainButton}
          style={{
            background: "#00839B",
            width: "296px",
            marginTop: "47px",
            marginBottom: "34px",
          }}
          onClick={handleAdd}
        >
          Add +
        </button>
        {alredyAddedTest && alredyAddedTest.length >= 1 && 
        alredyAddedTest && alredyAddedTest.map((list, i) => {
          return (
        <Grid
          item
          container
          direction="column"
          style={{
            width: "296px",
            minHeight: "304px",
            background: "#EFF1F7",
            border: "1px solid #66B5C3",
            borderRadius: "8px",
            marginBottom: "20px",
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            style={{ padding: "6px 16px", borderBottom: "0.5px solid #00839B" }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#013C44",
              }}
            >
              Test Type
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#046673",
              }}
            >
              {list.uuid.slice(0, 2) === "LP" ? "Pathology" : "Radiology"}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            style={{ padding: "6px 16px", borderBottom: "0.5px solid #00839B" }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",

                color: "#013C44",
                width: "135px",
                height: "37px",
              }}
            >
              Investigation Recommended
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#046673",
              }}
            >
              {list.standardisedtestname}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            style={{ padding: "6px 16px", borderBottom: "0.5px solid #00839B" }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#013C44",
              }}
            >
              Quality Prescribed
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#046673",
              }}
            >
              1
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            style={{ padding: "6px 16px" }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#013C44",
              }}
            >
              Clinical Advice
            </Typography>
            <Grid container alignItems="flex-end">
              <textArea
                className={classes.notesArea}
                style={{ height: "100px", width: "225px", marginRight: "16px" }}
                // onChange={(e) => handleClinical(list, e)}
                value = { list.clinicaladvice }
                defaultValue={list.clinicaladvice}
              />
                  {/* <input
                      type="text"
                      placeholder=" Please type here "
                      onChange={(e) => handleClinical(list, e)}
                    /> */}
              <img src={Trash} alt="trash" onClick={() => handleTestDelete(list)}/>
            </Grid>
          </Grid>
        </Grid>
        )})}
        {addListing && addListing.length >= 1 && 
        addListing && addListing.map((list, i) => {
          return (
        <Grid
          item
          container
          direction="column"
          style={{
            width: "296px",
            minHeight: "304px",
            background: "#EFF1F7",
            border: "1px solid #66B5C3",
            borderRadius: "8px",
            marginBottom: "20px",
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            style={{ padding: "6px 16px", borderBottom: "0.5px solid #00839B" }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#013C44",
              }}
            >
              Test Type
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#046673",
              }}
            >
              {list.uuid.slice(0, 2) === "LP" ? "Pathology" : "Radiology"}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            style={{ padding: "6px 16px", borderBottom: "0.5px solid #00839B" }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",

                color: "#013C44",
                width: "135px",
                height: "37px",
              }}
            >
              Investigation Recommended
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#046673",
              }}
            >
              {list.standardisedtestname}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            style={{ padding: "6px 16px", borderBottom: "0.5px solid #00839B" }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#013C44",
              }}
            >
              Quality Prescribed
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#046673",
              }}
            >
              1
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            style={{ padding: "6px 16px" }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "35px",
                color: "#013C44",
              }}
            >
              Clinical Advice
            </Typography>
            <Grid container alignItems="flex-end">
              <textArea
                className={classes.notesArea}
                style={{ height: "100px", width: "225px", marginRight: "16px" }}
                onChange={(e) => handleClinical(list, e)}
              />
                  {/* <input
                      type="text"
                      placeholder=" Please type here "
                      onChange={(e) => handleClinical(list, e)}
                    /> */}
              <img src={Trash} alt="trash" onClick={() => handleDeleteList(list)}/>
            </Grid>
          </Grid>
        </Grid>
        )})}
        <Grid
          item
          container
          direction="column"
          style={{
            width: "296px",
            height: "401px",
            background: "#F0F5FE",
            border: "1px solid #66B5C3",
            borderRadius: "10px",
            marginBottom: "20px",
            padding: "10px 12px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "35px",
              color: "#66B5C3",
            }}
          >
            Special Advise
          </Typography>
          <textArea
            className={classes.notesArea}
            style={{ height: "255px", width: "273px" }}
            onChange={(e) => setAdvice(e.target.value)}

          />
          <button
            className={classes.mainButton}
            style={{ background: "#00839B", width: "275px", marginTop: "20px" }}
            onClick={handleSubmit}
          >
            Send Message
          </button>
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{
            width: "296px",
            height: "524px",
            background: "#F0F5FE",
            border: "1px solid #66B5C3",
            borderRadius: "10px",

            marginBottom: "63px",
          }}
        >
          <Grid
            item
            style={{
              paddingLeft: "12px",
              paddingTop: "10px",
              borderBottom: "0.5px solid #00839B",
            }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "35px",
                color: "#00839B",
              }}
            >
              Help
            </Typography>
          </Grid>
          <Typography
            style={{
              marginLeft: "30px",
              marginTop: "24px",
              marginRight: "14px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#00839B",
              marginBottom: "4px",
            }}
          >
            You can switch easily between the pathology and radiology tabs.{" "}
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              marginRight: "14px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#00839B",
              marginBottom: "4px",
            }}
          >
            To search for a lab investigation, please use the filters in the
            order in which they have been presented - from left to right.
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              marginRight: "14px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#00839B",
              marginBottom: "4px",
            }}
          >
            In the event that after using the filters you get multiples of the
            same result, clicking on one of the results will suffice.
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              marginRight: "14px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#00839B",
              marginBottom: "4px",
            }}
          >
            You can order tests from Pathgology and Radiology on the same lab
            test advice document.
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              marginRight: "14px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#00839B",
              marginBottom: "11px",
            }}
          >
            In the event that you need to reach the patient for further
            clarification before making a selection, you can move to thelower
            right section of your cockpit, select the chat functionality. You
            can either send a chat message to the patient which gets delivered
            in-app or send an SMS to the patient’s local mobile number by using
            the SMS button just underneath the chat window.
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              marginRight: "14px",
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#00839B",
              marginBottom: "4px",
            }}
          >
            For further assistance, please reach out to us on
            support@doctall.com or call 09010996000
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateLabTest;
