import { Block } from '@material-ui/icons'
import React from 'react'

function BloodPressure({ dialogData, popupTitle }) {

    return (

        <div className="BP_CONTAINER">
            {dialogData.length == 0 ? <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>No Data</div> : <div>
                <div className="BP_HEADING1">History</div>
                <div className="BR_HEADING2">{popupTitle}</div>
                {dialogData.map((data, index) => (
                    <div className="BP_SUB_DIV" key={index}>
                        <div className="BP_SUB_DIV_TXT">{data.value}&nbsp;&nbsp;&nbsp;{data.unit}</div>
                        <div className="PHP_OB_DIV"></div>
                        <div className="BP_SUB_DIV_TXT">{data.date.slice(0, 10)}</div>
                        <div className="PHP_OB_DIV"></div>
                        <div className="BP_SUB_DIV_TXT">{data.time}</div>
                    </div>
                ))}
            </div>}

        </div>
    )
}

export default BloodPressure
