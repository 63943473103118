const customAsyncData = (state="",action) =>{
    switch(action.type){
        case "SET_DATA":
            return {...state,payload:action.payload};
            default:
                return state;
    }

}

const getDoctorDetails = (state=[],action) =>{
    switch(action.type){
        case "SET_DOCTOR":
            // return{...state,payload:action.payload};
            return {...state,payload:action.payload}
            default:
                return state;
    }
}
const getPatientDetails = (state="",action) =>{
   switch(action.type){
       case "SET_PATIENT":
           return {...state,payload:action.payload}
           default:
               return state;
   }
}
const getEpresDetails = (state="",action) =>{
   switch(action.type){
       case "SET_EPRES":
           // return{...state,payload:action.payload};
           return {...state,payload:action.payload}
           default:
               return state;
   }
}
const getLabpresDetails = (state="",action) =>{
    switch(action.type){
        case "SET_LABPRES":
            // return{...state,payload:action.payload};
            return {...state,payload:action.payload}
            default:
                return state;
    }
 }
 const nextAppointmentDetails = (state="",action) =>{
    switch(action.type){
        case "SET_APPOINTMENT":
            return {...state,payload:action.payload}
            default:
                return state;
    }
 }
 const getAppointmentDate = (state="",action) =>{
    switch(action.type){
        case "SET_APPOINTMENT_DATE":
            return {...state,payload:action.payload}
            default:
                return state;
    }
 }
 const getAppointmentSlot = (state="",action) =>{
    switch(action.type){
        case "SET_APPOINTMENT_SLOT":
            return {...state,payload:action.payload}
            default:
                return state;
    }
 }
export  {customAsyncData,getDoctorDetails,getPatientDetails,getEpresDetails,getLabpresDetails,nextAppointmentDetails,getAppointmentDate,getAppointmentSlot};
