// import React, { useState, useEffect } from "react";
// import { ZoomMtg } from '@zoomus/websdk';
// import { useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
// // import "@whereby.com/browser-sdk/embed"
// import { useLocalMedia, VideoView, useRoomConnection, RoomConnection } from "@whereby.com/browser-sdk/react";

// ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();
// // loads language files, also passes any error messages to the ui
// ZoomMtg.i18n.load('en-US');
// ZoomMtg.i18n.reload('en-US');


// const ZoomWebSdK = () => {
//   // let { meetingid, password } = useParams();
//   const meetingid = "85481127071"
//   const password = "976593"
//   const userdata = useSelector((state) => state.loginReducer.payload);

//   useEffect(() => {
//     ZoomMtg.setZoomJSLib('https://source.zoom.us/zoom-meeting-2.17.0/lib', '/av');
//     if (navigator?.mediaDevices?.getUserMedia) {
//       if (typeof navigator?.mediaDevices?.getUserMedia === 'undefined') {
//         navigator?.getUserMedia({ audio: true, video: true }, function (stream) {
//           stream.getTracks().forEach(x => x.stop());
//           // setTimeout(() => { getSignature() }, 1000)
//         }, err => console.log(err));
//       } else {
//         navigator?.mediaDevices?.getUserMedia({
//           audio: true, video: true
//         }).then((stream) => {
//           stream.getTracks().forEach(x => x.stop());
//           // setTimeout(() => { getSignature() }, 1000)
//         }).catch(err => console.log(err));
//       }
//     }

//   }, []);


//   var sdkKey1 = 'S1CwR_PvR9u2tKU85Ri_g'
//   var leaveUrl = leaveUrl

//   function getSignaturesss(e) {

//     ZoomMtg.generateSDKSignature({
//       meetingNumber: meetingid,
//       sdkKey: 'by1cV_2UTgnMoLjjMTOGw', //'S1CwR_PvR9u2tKU85Ri_g',
//       sdkSecret: "qGXIfI3hbI2h6PU5vjTbepQ6bi2ku968",
//       role: 1,
//       // alg: "HS256", // Specify HS256 as the signature algorithm
//       success(res) {
//         startMeeting(res.result)
//       }
//     })
//   }

//   function startMeeting(signature) {
//     document.getElementById('zmmtg-root').style.display = 'block'
//     console.log(signature)

//     ZoomMtg.init({
//       leaveUrl: leaveUrl + `${meetingid}/${password}`,
//       isSupportAV: true,
//       success: (success) => {
//         console.log(success)

//         ZoomMtg.join({
//           signature: signature,
//           sdkKey: 'by1cV_2UTgnMoLjjMTOGw', //'S1CwR_PvR9u2tKU85Ri_g',
//           meetingNumber: meetingid,
//           passWord: password,
//           userName: "Saikiran Reddy",
//           userEmail: "saikiran@gmail.com",
//           // tk: registrantToken,
//           // zak: zakToken,
//           success: (success) => {
//             console.log(success)
//           },
//           error: (error) => {
//             console.log(error)
//           }
//         })

//       },
//       error: (error) => {
//         console.log(error, "ERROR")
//       }
//     })
//   }
//   return (
//     <div
//       className="zoomiframe"
//       style={{ width: "100%", height: "100vh", overflow: "hidden" }}
//     >
//       <div
//         onClick={() => {
//           // getSignature();
//           getSignaturesss();
//         }}
//       >
//         <span>Click to Join</span>
//       </div>
//     </div>
//   );
// };
// export default ZoomWebSdK;

import React, { useState } from "react";
import "@whereby.com/browser-sdk"
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import './styles.css'

function ZoomWebSdK() {

  let { roomUrl } = useParams();
  const userdata = useSelector((state) => state.loginReducer.payload);
  const [join, setJoin] = useState(false);

  const handleJoin = () => {
    setJoin(true)
  }

  var leaveButton = document.getElementById('leaveButton');

  leaveButton?.addEventListener('click', function () {
  console.log("Click...........", "safasfadfad")

    setJoin(false)
  });
  // Embeded Prebuilt View
  return <div className="App" style={{justifyContent:'center',alignItems:"center"}}>
    {join === true ?
    <>
      <whereby-embed floatSelf background='off' displayName={userdata.full_name} room={`${window.atob(roomUrl)}?minimal&chat=on&leaveButton=on`} minimal
        style={{ display: "contents" }} subgridLabels="off" screenshare="off"></whereby-embed>
        {/* <button id="leaveButton">Leave Meeting</button> */}
        </>
      :
      <div
        onClick={() => {
          handleJoin();
        }}
      >
        <span style={{cursor:'pointer',fontWeight:'bold',fontSize:"20px"}} >Click to Join</span>
      </div>
    }
  </div>
}
export default ZoomWebSdK;


