import React from 'react';
import tick from './icons/tick.svg';

function CreateFollowFailure() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '287px', backgroundColor: '#F0F5F5', boxShadow: '0px 0px 24px 5px rgba(218, 218, 218, 0.3)', borderRadius: '30px', border: '1px solid #A0DFC4' }}>
            <div style={{ height: '176' }}>
                <div style={{ color: 'red', textAlign: 'center', fontSize: '20px', lineHeight: '24px', fontWeight: 'bold', fontFamily: 'GreycliffCFBold',marginLeft:"20px",marginRight:"20px" }}>Cannot follow up a booking which is already Followup</div>
            </div>
        </div>
    )
}

export default CreateFollowFailure
