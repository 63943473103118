import React, { useState, useEffect } from "react";
import usePagination from "./components/usePagination";
import { Pagination, PaginationItem } from "@material-ui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Previous from "../../assets/images/previous.svg";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import dots from "../../assets/images/dots.svg";
import DialogLayout from "./components/DialogLayout";
import AddPatientMob from "./AddPatientMob";
import DiagnosticPres from "./DiagnosticPres";
import patients from "./components/Patients";
import PatientDetail from "./PatientDetail";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import styles from "./style";
import { getPatients } from "../../redux/actions/ex-appointment-action";
import FloatingIcon from "./FloatingIcons/FloatingIcon";

const useStyles = makeStyles((theme) => styles(theme));

const PatientCompMob = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const dispatch = useDispatch();
  const patientsState = useSelector((state) => state.PatientSnapshot);
  console.log(patientsState, "patientsState");
  const [pageNumber, setPageNumber] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [addPatientModel, setAddPatientModel] = useState(false);
  const [paginationData, setPaginationData] = useState("1");
  const [addPatient, setAddPatient] = useState(false);
  const [patientData, setPatientData] = useState();
  console.log(patientData, "patientData");

  useEffect(() => {
    console.log(matchesXS);
    dispatch(getPatients(pageNumber, searchQuery));
  }, [addPatientModel, pageNumber, searchQuery, addPatient]);

  let [page, setPage] = useState(1);

  const [patientDetail, setpatientDetail] = useState({
    status: false,
    data: {},
  });
  const [addVisits, setAddVisits] = useState({
    status: false,
    data: {},
  });

  const [diagnostic, setDiagnostic] = useState(false);
  const PER_PAGE = 10;
  const count = Math.ceil(
    patientsState &&
      patientsState.payload &&
      patientsState.payload.total / PER_PAGE
  );
  const _DATA = usePagination(patients, PER_PAGE);

  const handleChange = (e, p) => {
    console.log(e, "pagination");
    setPage(p);
    _DATA.jump(p);
    setPageNumber(p - 1);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "20px",
          paddingBottom: "66px",
        }}
      >
        <input
          className={classes.searchField}
          placeholder="search"
          style={{ paddingLeft: "10%" }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Grid
          container
          style={{ marginTop: "23px" }}
          justifyContent="space-between"
        >
          <Grid item className={classes.category}>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "15px",
                color: "#00839B",
              }}
            >
              Patients
            </Typography>
          </Grid>
          <Grid item>
            <button
              className={classes.addNewButton}
              onClick={() => setAddPatient(true)}
            >
              Add New Patient
            </button>
          </Grid>
        </Grid>
        <Grid container direction="column" style={{ marginTop: "13px" }}>
          {patientsState &&
          patientsState.payload &&
          patientsState.payload.data ? (
            <div>
              {patientsState &&
                patientsState.payload &&
                patientsState.payload.data.map((patient, index) => (
                  <Grid item container className={classes.patientCard}>
                    <Grid
                      item
                      container
                      style={{
                        height: "70px",
                        borderBottom: "0.5px solid #00839B",
                        paddingLeft: "16px",
                        paddingTop: "22px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Visuelt Pro",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "30px",
                          color: "#046673",
                        }}
                      >
                        {patient.date}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        paddingTop: "12px",
                        paddingLeft: "16px",
                        paddingRight: "22px",
                        paddingBottom: "16px",
                      }}
                    >
                      <Grid item container justifyContent="space-between">
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="flex-start"
                          sm
                          xs
                        >
                          <Typography className={classes.label}>
                            Name
                          </Typography>
                          <Typography className={classes.value}>
                            {patient.full_name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="flex-end"
                          sm
                          xs
                        >
                          <Typography className={classes.label}>
                            Date of Birth
                          </Typography>
                          <Typography className={classes.value}>
                            {/* {patient.dob} */}
                            {patient && patient.dob
                              ? `${moment(patient.dob).format("DD/MM/YYYY")}`
                              : "NA"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        style={{ marginTop: "21px" }}
                      >
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="flex-start"
                          sm
                          xs
                        >
                          <Typography className={classes.label}>
                            Email
                          </Typography>
                          <Typography
                            className={classes.value}
                            style={{ fontSize: "12px" }}
                          >
                            {patient.email}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="flex-end"
                          sm
                          xs
                        >
                          <Typography className={classes.label}>
                            Mobile No
                          </Typography>
                          <Typography className={classes.value}>
                            {patient.mobile}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container style={{ marginTop: "17px" }}>
                        <Grid item>
                          <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              width: "36px",
                              height: "50px",
                              background: "#00839B",
                              borderRadius: "4px",
                              marginRight: "12px",
                            }}
                            onClick={() =>
                              setpatientDetail({ status: true, data: patient })
                            }
                          >
                            <img src={dots} alt="dots" />
                          </Grid>
                        </Grid>
                        <Grid item>
                          <button
                            className={classes.addVisit}
                            // onClick={() => setDiagnostic(true)}

                            onClick={() =>
                              setAddVisits({ status: true, data: patient })
                            }
                          >
                            Add Visit
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </div>
          ) : null}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{
            borderTop: "0.7px solid #E4E7EC",
            marginTop: "32px",
            paddingTop: "15px",
          }}
        >
          {patientsState &&
          patientsState.payload &&
          patientsState.payload.total ? (
            <Pagination
              size="small"
              page={page}
              shape="rounded"
              onChange={handleChange}
              count={count}
              defaultPage={1}
              boundaryCount={1}
              className={classes.pagination}
              color="#FFF5F3"
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    next: ArrowForwardIcon,
                    previous: ArrowBackIcon,
                  }}
                  {...item}
                />
              )}
            />
          ) : null}
        </Grid>
        {addPatient ? (
          <DialogLayout open={addPatient}>
            <AddPatientMob closePat={() => setAddPatient(false)} />
          </DialogLayout>
        ) : null}

        {addVisits.status ? (
          <DialogLayout open={addVisits}>
            <DiagnosticPres
              closeDiag={() => setAddVisits({ status: false, data: {} })}
              patient={addVisits.data}
              Vtype="direct"
            />
          </DialogLayout>
        ) : null}
        {patientDetail.status ? (
          <DialogLayout open={patientDetail.status}>
            <PatientDetail
              closePatDetail={() =>
                setpatientDetail({ status: false, data: {} })
              }
              patient={patientDetail.data}
            />
          </DialogLayout>
        ) : null}
      </Grid>
      <FloatingIcon/>
    </>
  );
};

export default PatientCompMob;
