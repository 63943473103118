import { fontWeight, margin, padding } from "@mui/system";

const DoctorDataStyles = (theme) => ({
    DoctorDataConitainer: {
        padding: "20px",
        // fontFamily: 'Visuelt Pro',

    },
    dataCards: {
        margin:"10px",
        padding: "20px",
        border: "1px solid #66B5C3",
        borderRadius: "30px",
        background: "#F3FAFC",

    },
    cardHeading: {
        color: "#667085",
        fontSize: "14px",
        lineHeight: "20px"

    },
    cardSubHeading: {
        fontSize: "30px",
        color: "#101828",
        fontWeight: "700",
        lineHeight: "38px",
    },


    incriment: {
        borderRadius: "16px",
        backgroundColor: "#ECFDF3",
        color: "#027A48",
        padding: "2px 8px 2px 10px",
        fontWeight: "600",
    },


    QuackActionContainer:{
        background:"rgba(230, 230, 230, 0.6)",
        padding:"20px",
    },
    QuickActionCrd:{
    margin:"10px",
    padding:"20px",
    borderRadius:"30px",
    color:"#fff",

},

QAcardHeading:{
    fontSize:"16px",
    fontWeight:"500",
   
    lineHeight:"24px",
},
QAcardSubHeading:{
    fontSize:"14px",
    fontWeight:"500",
  
    lineHeight:"24px",
}
});

export default DoctorDataStyles;
