import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { getPatients } from "../../redux/actions/ex-appointment-action";
import { Dialog, DialogContent } from "@material-ui/core";
import AddPatientComponent from "./AddPatientComponent";
import "../dashboardstyles/dashboardstyle.css";
import { patientColumns } from "../components/TableColumn";
import { customStyles } from "../dashboardstyles/table-style/tableCustomStyle";
import Layout from "../../Dashboard/extlayout";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddIcon from "@mui/icons-material/Add";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Search from "./../../assets/images/search.svg";
import ViewPatientComponent from "./ViewPatientComponent";
import AddPatientComponent1 from "./visits/AddVisitFromPatient";
import PatientCompMob from "../mobileComp/PatientCompMob";
import MobLayout from "../../Dashboard/mobLayout";

export default function PatientComponent() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [maxWidth, setMaxwidth] = React.useState("sm");
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("800px"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const customSM = useMediaQuery("(max-width:800px)");
  const dispatch = useDispatch();
  const patientsState = useSelector((state) => state.PatientSnapshot);
  const [addPatientModel, setAddPatientModel] = useState(false);
  const [addPatientModel1, setAddPatientModel1] = useState(false);
  const [viewPatientModel, setViewPatientModel] = useState(false);
  const [patientDetail, setPatientDetails] = useState();
  const [diffId, setDiffId] = useState("ADD");

  const [pageNumber, setPageNumber] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    console.log(matchesXS);
    dispatch(getPatients(pageNumber, searchQuery));
  }, [addPatientModel, pageNumber]);

  const mergedColumns = patientColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
  });

  const createColumns = useCallback(() => {
    return [
      ...mergedColumns,
      {
        name: "Action",
        allowOverflow: true,
        minWidth: "100px",
        cell: (row) => {
          return (
            <div class="row">
              <div class="col-md-6">
                <RemoveRedEyeIcon
                  style={{ cursor: "pointer", color: "#046673" }}
                  onClick={() => handlePatientsView1(row)}
                >
                  View
                </RemoveRedEyeIcon>
              </div>
              <div class="col-md-6">
                <button
                  className="edite_btn"
                  onClick={() => handlePatientsAdd(row)}
                >
                  Add Visit
                </button>
              </div>
              {/* <AddIcon style={{ cursor: 'pointer' }} onClick={() => handlePatientsAdd(row)} > </AddIcon> */}
            </div>
          );
        },
      },
    ];
  }, [mergedColumns]);

  const paginationComponentOptions = {
    rangeSeparatorText: "of",
    noRowsPerPage: true,
  };

  const handlePageChange = (page) => {
    setPageNumber(page - 1);
  };

  const handleSearchQuery = (event) => {
    // alert("enter")
    setSearchQuery(event.target.value);
    handleSearch();
  };

  const handleSearch = () => {
    dispatch(getPatients(0, searchQuery));
  };

  const handlePatientsView = (patientDetails) => {
    setPatientDetails(patientDetails);
    setDiffId("EDIT");
    setAddPatientModel(true);
  };
  const handlePatientsView1 = (patientDetails) => {
    setPatientDetails(patientDetails);
    setDiffId("EDIT");
    setViewPatientModel(true);
  };
  const handlePatientsAdd = (patientDetails) => {
    setPatientDetails(patientDetails);
    setAddPatientModel1(true);
  };

  const [heightS, setHeightS] = useState("");

  const checkSafari = () => {
    return (
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      )
    );
  };
  useEffect(() => {
    if (!checkSafari()) {
      setHeightS("185px");
    } else {
      setHeightS("80px");
    }
  }, []);

  return customSM ? (
    <>
      <MobLayout>
        <div /* style={{marginTop:heightS}} */>
          <PatientCompMob />
        </div>
      </MobLayout>
    </>
  ) : (
    <>
      <Layout>
        <Grid container spacing={2}>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <div className="search">
              <input
                className="headersearch"
                margin="dense"
                type="text"
                fullWidth
                placeholder="Search"
                variant="outlined"
                onChange={(event) => handleSearchQuery(event)}
              />
              <img
                className="header-search"
                src={Search}
                crossorigin="anonymous"
                onClick={(event) => handleSearchQuery(event)}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <SearchOffIcon
              style={{ cursor: "pointer" }}
              onClick={handleSearch}
            />
          </Grid>

          <Grid item xs={2}>
            <button
              className="add_visit_button"
              onClick={() => {
                setAddPatientModel(true);
                setPatientDetails({});
                setDiffId("Add");
              }}
            >
              Add New Patient
            </button>
          </Grid>
        </Grid>

        <div className="snapcontainer2" style={{ height: "920px !important" }}>
          <div className="table_header">
            <h1 className="table_heading">Patients</h1>
          </div>
          {patientsState &&
          patientsState.payload &&
          patientsState.payload.total > 10 ? (
            <DataTable
              className="snapcontainer2"
              columns={createColumns()}
              data={
                patientsState &&
                patientsState.payload &&
                patientsState.payload.data
              }
              customStyles={customStyles}
              pagination
              paginationServer
              paginationComponentOptions={paginationComponentOptions}
              paginationTotalRows={
                patientsState &&
                patientsState.payload &&
                patientsState.payload.total
              }
              onChangePage={handlePageChange}
            />
          ) : (
            <DataTable
              className="snapcontainer2"
              columns={createColumns()}
              data={
                patientsState &&
                patientsState.payload &&
                patientsState.payload.data
              }
              customStyles={customStyles}
            />
          )}
        </div>

        <Dialog
          disableBackdropClick
          open={addPatientModel}
          onClose={() => setAddPatientModel(false)}
          // fullScreen={fullScreen}
          maxWidth={maxWidth}
          // style={{width:"20px"}}
        >
          <DialogContent>
            <AddPatientComponent
              id={diffId}
              close={(event) => setAddPatientModel(false)}
              details={patientDetail}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          disableBackdropClick
          open={viewPatientModel}
          onClose={() => setViewPatientModel(false)}
          // fullScreen={fullScreen}
          maxWidth={maxWidth}
          // style={{width:"20px"}}
        >
          <DialogContent>
            <ViewPatientComponent
              id={diffId}
              close={(event) => setViewPatientModel(false)}
              details={patientDetail}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          disableBackdropClick
          open={addPatientModel1}
          onClose={() => setAddPatientModel1(false)}
          maxWidth={maxWidth}
        >
          <DialogContent>
            <AddPatientComponent1
              id={diffId}
              close={() => setAddPatientModel1(false)}
              details={patientDetail}
            />
          </DialogContent>
        </Dialog>
      </Layout>
    </>
  );
}
