import { takeLatest,select, put } from "redux-saga/effects";
import axios from "axios";
export const getUser = state => state.loginReducer.payload;

const getAppointmentSnapshot = function* ({appointmentType}) {
    try{
        const user = yield select(getUser);
           let response =  yield axios.get(process.env.REACT_APP_API_URL+`/booking/appointmentsnap/${user.uuid}?type=${appointmentType}`, {headers: {
            'x-auth-token': user.token,
          }})
          console.log(response,"456789")
            if(response.data){
                yield put({ type: "SET_TYPE_SUCCESS", payload: response.data })
            }
            else{
            yield put({ type: "SET_TYPE_REQUEST" })
            }
        }
        catch{
            yield put({ type: "SET_TYPE_ERROR" })
        }
}
const getDoctorIncome = function* () {
    const user = yield select(getUser);
    let typeData;
    yield axios.get(process.env.REACT_APP_API_URL+"/booking/totalPatients/"+user.uuid, 
    {headers: {
        'x-auth-token': user.token,
      }}).then((resp)=>{
        
        if(resp.data){
            typeData=resp.data
        }
    }).catch(err=>{
        typeData=err
    })
    yield put({ type: "SET_STAT", payload: typeData })
}
const getDoctorAppointment = function* () {
    const user = yield select(getUser);

    let appointmentData;
    yield axios.get(process.env.REACT_APP_API_URL+"/booking/totalTodayAppointments/"+user.uuid,
    {headers: {
        'x-auth-token': user.token,
      }}).then((resp)=>{
        if(resp.data){
            appointmentData=resp.data
        }
    })
    yield put({ type: "SET_STAT_TWO", payload: appointmentData })
}
const getDoctorPrescription = function* () {
    const user = yield select(getUser);

    let prescriptionData;
    yield axios.get(process.env.REACT_APP_API_URL+"/ep/totalPrescription/"+user.uuid, 
    {headers: {
        'x-auth-token': user.token,
      }}).then((resp)=>{
        
        if(resp.data){
           
            prescriptionData=resp.data
        }
    })
    yield put({ type: "SET_STAT_THREE", payload: prescriptionData })
}

const getDoctorLabPrescription = function* () {
    const user = yield select(getUser);

    let labPrescriptionData;
    yield axios.get(process.env.REACT_APP_API_URL+"/ep-lab/totalPrescriptionLab/"+user.uuid,
    {headers: {
        'x-auth-token': user.token,
      }}).then((resp)=>{
        
        if(resp.data){
            labPrescriptionData=resp.data
        }
    })
    yield put({ type: "SET_STAT_FOUR", payload: labPrescriptionData })
}

const createSlotfn = function* ({category,createSlotValues,slotDateFormat,dayName}) {
    const user = yield select(getUser);
    const clinicId=user.clinics[0].uuid;
    let header = {
        'x-auth-token': user.token,
      }

    let data={
     "category": category,
     "clinic": clinicId,
     "date": slotDateFormat,
     "day": dayName,
     "doctor_uuid":user.uuid,
     "end_time": createSlotValues.endTime,
     "start_time": createSlotValues.startTime,
     "video": true,
     "time_per_slot":createSlotValues.timePerSlot
    }

    let slotBookingData;
    yield axios.post(process.env.REACT_APP_API_URL+"/slot/doctorCreateSlot",data,
    {headers: header}).then((resp)=>{
        
        if(resp.data){
           slotBookingData=resp.data
        //    slotBookingData=resp.data
        }
    })
    .catch((error) => {

    })
    yield put({ type: "SET_CREATE_SLOT", payload: slotBookingData })
}

function* getAppointmentSnapshotWatcher() {
    yield takeLatest("GET_APPOINTMENT_SNAPSHOT_REQUEST", getAppointmentSnapshot);
}
function* getDoctorStatOneWatcher() {
    yield takeLatest("GET_DOCTOR_STATS", getDoctorIncome);
}
function* getDoctorStatTwoWatcher() {
    yield takeLatest("GET_DOCTOR_STATS_TWO", getDoctorAppointment);
}
function* getDoctorStatThreeWatcher() {
    yield takeLatest("GET_DOCTOR_STATS_THREE", getDoctorPrescription);
}
function* getDoctorStatFourWatcher() {
    yield takeLatest("GET_DOCTOR_STATS_FOUR", getDoctorLabPrescription);
}
function* createSlotWatcher() {
    yield takeLatest("CREATE_SLOT", createSlotfn);
}
export {getAppointmentSnapshotWatcher,getDoctorStatOneWatcher,getDoctorStatTwoWatcher,getDoctorStatThreeWatcher,getDoctorStatFourWatcher,createSlotWatcher}





