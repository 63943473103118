import React, { useState } from "react";
import {
    Grid,
    Button,
    Typography,
    useMediaQuery,
    Box,
    Card,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import styles from "./styles";
import ArrowIcon from "../../assets/images/Icon11.svg";
import Downarrow from "../../assets/images/downward.png";
import infoIcon from "../../assets/images/info_icon.svg";
import FindIcon from "../../assets/images/Featured icon.png";
import messageIcon from "../../assets/images/message_icon.svg";
import add_p_icon from "../../assets/images/Featured icon1.png";
import create_icon from "../../assets/images/create_icon.svg";
import create_d_icon from "../../assets/images/create_d_icon.svg";
import AddPatientComponent from "../components/AddPatientComponent";
import AddPatientComponent1 from "../components/visits/AddVisitFromPatient";
import AddVisitComponent from '../components/visits/AddVisitComponent';
import MessagePop from "./MessagePop";
import { Dialog } from '@material-ui/core';
import DialogContent from '@mui/material/DialogContent';
import { getVisitsByPatientId, getPatients } from "../../redux/actions/ex-appointment-action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Toast from '../../components/Toast';
import Close from "../../assets/images/close.svg";
import './message.scss'
import close from "../../assets/images/close_white-icon.png"


const useStyles = makeStyles((theme) => styles(theme));

const DoctorData = (props) => {

    let history = useHistory();

    console.log(props.count_earned, "ghj")
    const patientsState = useSelector((state) => state.PatientSnapshot);
    const orderState = useSelector((state) => state.PatientSnapshot);
    const visitState = useSelector((state) => state.PatientSnapshot);
    var totalData = props && props.count_earned
    var totalAppointments = visitState && visitState.visitList && visitState.visitList.total
    var totalOrder = orderState && orderState.orderList && orderState.orderList.total
    var totalPatients = patientsState && patientsState.payload && patientsState.payload.total

    const [addPatientModel, setAddPatientModel] = useState(false);
    const [addMessage, setAddMessage] = useState(false);
    const [patientDetail, setPatientDetails] = useState();
    const [diffId, setDiffId] = useState("ADD");
    const [addPatientModel1, setAddPatientModel1] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const [editBankModel, setEditBankModel] = useState(false);
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const [maxWidth, setMaxwidth] = React.useState('sm')

    const [addVisitModel, setAddVisitModel] = useState(false);
    const [visitDetail, setVisitDetails] = useState();
    // const [diffId, setDiffId] = useState("ADD");
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);

    const handlePatientsAdd = (patientDetails) => {
        setPatientDetails(patientDetails)
        setAddPatientModel1(true)
    }
    const handleMessage = () => {
        setAddMessage(true)
    }

    const MoveToPatient = () => {
        history.push({
            pathname: '/patients',
        });
    }
    const MoveToOrder = () => {
        history.push({
            pathname: '/orders',
        });
    }
    const MoveToVisits = () => {
        history.push({
            pathname: '/visits',
        });
    }
    return (
        <>

            <Grid item container className={classes.DoctorDataConitainer}>
                <Grid md={3}>

                    <Card className={classes.dataCards} onClick={MoveToVisits} style={{ cursor: "pointer" }}>
                        <Grid item container>
                            <Grid md={10}>
                                <Typography className={classes.cardHeading}>No. of Appointments</Typography>
                            </Grid>
                            <Grid md={2} align="right">
                                <img src={infoIcon} />
                            </Grid>
                            <Grid md={10}>
                                <Typography className={classes.cardSubHeading}>{totalAppointments}</Typography>
                            </Grid>
                            <Grid md={2} align="right">
                                <Button className={classes.incriment}><img src={ArrowIcon} />10%</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid md={3}>
                    <Card className={classes.dataCards} onClick={MoveToOrder} style={{ cursor: "pointer" }}>
                        <Grid item container>
                            <Grid md={10}>
                                <Typography className={classes.cardHeading}>No. of Orders</Typography>
                            </Grid>
                            <Grid md={2} align="right">
                                <img src={infoIcon} />
                            </Grid>
                            <Grid md={10}>
                                <Typography className={classes.cardSubHeading}>{totalOrder}</Typography>
                            </Grid>
                            <Grid md={2} align="right">
                                <Button className={classes.incriment}><img src={ArrowIcon} />12%</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid md={3}>
                    <Card className={classes.dataCards} onClick={MoveToPatient} style={{ cursor: "pointer" }}>
                        <Grid item container>
                            <Grid md={10}>
                                <Typography className={classes.cardHeading}>No. of Patients</Typography>
                            </Grid>
                            <Grid md={2} align="right">
                                <img src={infoIcon} />
                            </Grid>
                            <Grid md={10}>
                                <Typography className={classes.cardSubHeading}>{totalPatients}</Typography>
                            </Grid>
                            <Grid md={2} align="right">
                                <Button className={classes.incriment}><img src={ArrowIcon} />12%</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid md={3}>
                    <Card className={classes.dataCards}>
                        <Grid item container>
                            <Grid md={10}>
                                <Typography className={classes.cardHeading}>No. of Lab Test Generated</Typography>
                            </Grid>
                            <Grid md={2} align="right">
                                <img src={infoIcon} />
                            </Grid>
                            <Grid md={10}>
                                <Typography className={classes.cardSubHeading}>{totalData && totalData.totalLabTest}</Typography>
                            </Grid>
                            <Grid md={2} align="right">
                                <Button className={classes.incriment} style={{color:"#B42318"}}><img src={Downarrow} />-2%</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>


                <Grid item container style={{ marginTop: "20px" }}>
                    <Grid md={6}>
                        <Card className={classes.dataCards}>
                            <Grid item container>
                                <Grid md={10}>
                                    <Typography style={{ fontSize: "16px", color: "#101828", fontWeight: "600" }}>Earnings</Typography>
                                    <Typography className={classes.cardHeading}>Current balance</Typography>
                                </Grid>
                                <Grid md={2} align="right">
                                    <img src={infoIcon} />
                                </Grid>
                                <Grid md={10}>
                                    <Typography className={classes.cardSubHeading}>₦{totalData && totalData.earned}</Typography>
                                </Grid>
                                <Grid md={2} align="right">
                                    <Button className={classes.incriment}><img src={ArrowIcon} />3.4%</Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid md={6}>
                        <Card className={classes.dataCards}>
                            <Grid item container>
                                <Grid md={10}>
                                    <Typography style={{ fontSize: "16px", color: "#101828", fontWeight: "600" }}>Outstanding Payments</Typography>
                                    <Typography className={classes.cardHeading}>Current balance</Typography>
                                </Grid>
                                <Grid md={2} align="right">
                                    <img src={infoIcon} />
                                </Grid>
                                <Grid md={10}>
                                    <Typography className={classes.cardSubHeading}>₦{totalData && totalData.outstanding}</Typography>
                                </Grid>
                                <Grid md={2} align="right">
                                    <Button className={classes.incriment}><img src={ArrowIcon} />3.4%</Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>




            <Grid item container className={classes.QuackActionContainer}>
                <Grid md={12}>
                    <Typography style={{ color: "#002931", fontSize: "20px", fontWeight: "500" }}>Quick Actions</Typography>
                </Grid>
                <Grid md={4}>
                    <Card className={classes.QuickActionCrd} style={{ background: "#00839B", cursor: "pointer" }}
                        onClick={() => {
                            setAddPatientModel(true);
                            setPatientDetails({});
                            // setDiffId('Add')
                        }}

                    >

                        <Grid item container>
                            <Grid md={2}>
                                <img src={FindIcon} />
                            </Grid>
                            <Grid md={10}>
                                <Typography className={classes.QAcardHeading}>Find a Patient</Typography>
                                <Typography className={classes.QAcardSubHeading}>Search for a patient</Typography>
                            </Grid>
                        </Grid>

                    </Card>

                </Grid>
                <Grid md={4}>
                    <Card className={classes.QuickActionCrd} style={{ background: "#FF5C3C", cursor: "pointer" }}
                        onClick={() => { setEditBankModel(true) }}
                    >
                        <Grid item container>
                            <Grid md={2}>
                                <img src={messageIcon} />
                            </Grid>
                            <Grid md={10}>
                                <Typography className={classes.QAcardHeading}>Message a Patient</Typography>
                                <Typography className={classes.QAcardSubHeading}>Easy access to send a message</Typography>
                            </Grid>
                        </Grid>

                    </Card>
                </Grid>
                <Grid md={4}>
                    <Card className={classes.QuickActionCrd} style={{ background: "#FFA101", cursor: "pointer" }}
                        onClick={() => {
                            setAddPatientModel(true);
                            setPatientDetails({});
                            setDiffId('Add')

                        }}
                    >
                        <Grid item container>
                            <Grid md={2}>
                                <img src={add_p_icon} />
                            </Grid>
                            <Grid md={10}>
                                <Typography className={classes.QAcardHeading}>Add a Patient</Typography>
                                <Typography className={classes.QAcardSubHeading}>Add a patient to your list</Typography>
                            </Grid>
                        </Grid>

                    </Card>
                </Grid>


                <Grid md={6}>
                    <Card className={classes.QuickActionCrd} style={{ background: "#37CC8C", cursor: "pointer" }}
                        onClick={() => {
                            setAddVisitModel(true);
                            setVisitDetails({});
                            setDiffId('Add')
                        }}
                    >
                        <Grid item container>
                            <Grid md={2}>
                                <img src={create_icon} />
                            </Grid>
                            <Grid md={10}>
                                <Typography className={classes.QAcardHeading}>Create a Prescription</Typography>
                                <Typography className={classes.QAcardSubHeading}>Easy way to create prescription</Typography>
                            </Grid>
                        </Grid>

                    </Card>
                </Grid>

                <Grid md={6}>
                    <Card className={classes.QuickActionCrd} style={{ background: "#013C44", cursor: "pointer" }}
                        onClick={() => {
                            setAddVisitModel(true);
                            setVisitDetails({});
                            setDiffId('Add')
                        }}
                    >
                        <Grid item container>
                            <Grid md={2}>
                                <img src={create_d_icon} />
                            </Grid>
                            <Grid md={10}>
                                <Typography className={classes.QAcardHeading}>Create a Diagnostic Order</Typography>
                                <Typography className={classes.QAcardSubHeading}>Easy way to create diagnostic order</Typography>
                            </Grid>
                        </Grid>

                    </Card>
                </Grid>

                <Dialog
                    disableBackdropClick
                    open={addPatientModel}
                    onClose={() => setAddPatientModel(false)}
                >

                    <DialogContent>
                        <AddPatientComponent
                            id={diffId}
                            close={(event) => setAddPatientModel(false)}
                            details={patientDetail}
                            getPnt={() => getPatients(0, "")}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    disableBackdropClick
                    open={addPatientModel1}
                    onClose={() => setAddPatientModel1(false)}
                    maxWidth={maxWidth}
                >
                    <DialogContent>
                        <AddPatientComponent1
                            id={diffId}
                            close={() => setAddPatientModel1(false)}
                            details={patientDetail}
                        />
                    </DialogContent>
                </Dialog>
                {/* / */}
                <Dialog
                    disableBackdropClick
                    open={editBankModel}
                    onClose={() => setEditBankModel(false)}
                    maxWidth='md'
                >

                    <DialogContent>
                        <MessagePop
                            
                            Messagesget = {props.Messagesget}
                            close={(event) => setEditBankModel(false)}
                        />
                    </DialogContent>
                </Dialog>
                {/* ----------------------------------------------------------- */}
               
               

        {/* <Dialog
                    maxWidth={"lg"}
                    open={editBankModel}
                    scroll={"body"}
                    // onClose={handleCloseCasePopup}
                  >
                    <div className="ContainerWrapper1">
                      <img
                        src={close}
                        alt="Close"
                        className="Closepopup1"
                        onClick={() => setEditBankModel(false)}
                        crossorigin="anonymous"
                      />
                      <div style={{background:"White", borderRadius:"20px",minWidth:"500px",
                    minHeight:"600px",
                    padding:"20px"}}>
                     
                        <MessagePop
                            
                            Messagesget = {props.Messagesget}
                            close={(event) => setEditBankModel(false)}
                        />
               
                       
                      </div>
                     
                    </div>
                  </Dialog> */}








                {/* / */}

                <Dialog
                    disableBackdropClick
                    open={addVisitModel}
                    onClose={() => setAddVisitModel(false)}
                    maxWidth='sm'
                >
                    <DialogContent>
                        <AddVisitComponent
                            id={diffId}
                            close={(event) => setAddVisitModel(false)}
                            details={visitDetail}
                            toastVisibility={(event) => setToast(event)}
                            toastText={(event) => setToastMessage(event)}
                            toastType={(event) => setToastType(event)}
                        />
                    </DialogContent>
                </Dialog>
                <Toast
                    text={toastMessage}
                    type={toastType}
                    open={showToast}
                    setClose={() => {
                        setToast(false);
                    }}
                />
            </Grid>


        </>
    );
};

export default DoctorData;