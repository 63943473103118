import React from "react";
import { useHistory } from "react-router-dom";
import regLogo from "../../assets/images/regLogo.svg";
import keyIcon from "../../assets/images/keyIcon.svg";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  Checkbox,
} from "@material-ui/core";
import styles from "./style";

const useStyles = makeStyles((theme) => styles(theme));

const PassResetMob = () => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingLeft: "16px", paddingTop: "48px", paddingRight: "48px" }}
    >
      <img src={keyIcon} alt="logo" />
      <Typography className={classes.regTitle} style={{ marginTop: "28px" }}>
        Change passcode
      </Typography>
      <Typography className={classes.regSubTitle} style={{ marginTop: "8px" }}>
        Change and create new passcode
      </Typography>
      <Grid
        item
        container
        style={{ width: "328px", marginTop: "28px" }}
        direction="column"
      >
        <Typography className={classes.regLabel}>
          {" "}
          Enter new passcode
        </Typography>
        <input type="password" className={classes.regInput} />
        <Typography className={classes.regLabel}>
          Confirm new passcode
        </Typography>
        <input type="password" className={classes.regInput} />

        <button
          className={classes.regButton}
          style={{ marginTop: "20px" }}
          //onClick={() => setScreen("certificate")}
        >
          Reset Password
        </button>
      </Grid>
    </Grid>
  );
};

export default PassResetMob;
