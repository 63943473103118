import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DGArrow from "../../../assets/images/DGArrow.svg";
import Search from "../../../assets/images/search.svg";
import { getAreaLab, getCityLab, getSearchLab,getMedPrescription } from "../../../redux/actions/cockpitAction";
// import useOnClickOutside from "../../doctorDashboard/OutClickhandler";
import labStyles from "./labStyles.scss"
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    root1: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    container: {
      backgroundColor: "#EFF1F7",
      border: "1px solid rgba(4, 102, 115, 0.3)",
      boxSizing: "border-box",
      borderRadius: "30px",
      marginTop: "20px",
      padding: "30px"
    },
    container2: {
      backgroundColor: "#EFF1F7",
      border: "1px solid rgba(4, 102, 115, 0.3)",
      boxSizing: "border-box",
      borderRadius: "30px",
      marginTop: "41px",
      padding: "28px"
    },
    typography: {
      fontFamily: [
        'GreycliffCFBold'
      ].join(','),
      fontWeight: "bold",
      fontSize: 25,
      color: '#013C44'
    },
    divider: {
      height: 2,
      backgroundColor: 'rgba(0, 131, 155, 0.3)',
      marginLeft: -30,
      marginRight: -30
    },
    multilineInputField: {
      borderRadius: 20,
      // border: '1px solid #A0DFC4',
      background: '#FFFFFF'
    },
    outlinedInputField: {
      borderRadius: 20,
      border: '1px solid #A0DFC4',
    },
    tableDataTypography: {
      fontFamily: [
        'GreycliffCFBold'
      ].join(','),
      fontWeight: "bold",
      fontSize: 20,
      color: '#013C44'
    },
    deleteIcon: {
      color: '#078CA5',
      display: 'inline-flex'
    }
  }));


export default function LabChaperoneContentRadiology(props){
    const classes = useStyles();
    const dispatch=useDispatch();
    const [lab, setLab] = React.useState([]);
    const [note, setNotes] = React.useState("");
  const[disapprove,setDisapprove]=React.useState(false)
    const userdata = useSelector(state => state.loginReducer.payload)
    const {uuid,type}=props.selected;
    useEffect(()=>{
      axios.get(process.env.REACT_APP_API_URL+"/ep/details/"+type+"/"+uuid,{
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': userdata.token
        },
      }).then(function (response) {
          if(response && response.data && response.data.length >0 && response.data[0].labtype == "radiology" && response.data[0].tests){
            if(response.data[0].tests.length>0)
            {
              setLab(response.data[0].tests)
              setNotes(response.data[0].notes)
            }
          }
        })
        .catch(function (error) {
          // console.log(error)
        });
    },[])
    useEffect(()=>{

      if(props.chapdet.approve_status==true){
      setDisapprove(true)
      }
        },[])
    const handleApprovefn = () =>{
   
      var config = {
        method: 'put',
        url: process.env.REACT_APP_API_URL + `/ep-lab/updateApprovedStatus/${props.routedata.uuid}/${uuid}`,
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': userdata.token
        },
      };
  
      axios(config)
        .then(function (response) {
          props.close()
        })
        .catch(function (error) {
          // console.log(error)
        });
      dispatch(getMedPrescription(props.routedata.uuid))

    }
    return(
        <>
 <Grid container direction="row" justify="flex-start"
        alignItems="center" className={classes.container}>
        <Grid item md={4}>
          <Typography className={classes.typography}>Investigation recommended</Typography>
        </Grid>
        <Grid item md={3}>
          <Typography className={classes.typography}>Quantity prescribed</Typography>
        </Grid>
        <Grid item md={5}>
          <Typography className={classes.typography}>Clinical Advice</Typography>
        </Grid>
       
        <Grid item md={12} style={{ marginTop: "26px" }}>
          <Divider className={classes.divider} />
        </Grid>
        {lab && lab.map((val, index) => {
          return (
            <Grid key={"chapLab" + index} container style={{ marginTop: "22px" }} direction="row" alignItems="center">
              <Grid item md={4}>
                <Typography className={classes.tableDataTypography}>{val.standardisedtestname}</Typography>
              </Grid>
              <Grid item md={3}>
                <Typography style={{ marginLeft: 5 }} className={classes.tableDataTypography}>1</Typography>
              </Grid>
              <Grid item md={5} >
                <TextField
                  placeholder="Please type here"
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#013C44",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={val.clinicaladvice}
                  disabled
                  InputProps={{
                    classes: {
                      notchedOutline: classes.outlinedInputField
                    }
                  }}
                  className={classes.multilineInputField}
                  multiline
                />
              </Grid>

            </Grid>
          )
        })}

      </Grid>
      <Grid container md={12} className={classes.container2}>
        <Grid item md={12} style={{ textAlign: "left" }}>
          <Typography className={classes.typography}>Special Advise</Typography>
        </Grid>
        <Grid item md={12} style={{ marginTop: "28px", marginBottom: "5px" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Please type here"
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              classes: { notchedOutline: classes.outlinedInputField }
            }}
            disabled
            value={note}
            multiline
            rows={10}
            // onChange={(e) => { props.setPathologyNote(e.target.value) }}
            className={classes.multilineInputField}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" style={{gap:'10px'}}>
        <div className={disapprove==false?"approve_btn":"disable_approve"} onClick={() => { handleApprovefn() }} ><span>Approve prescription</span></div>
        <div className={disapprove==false?"query_btn":"disable_approve"} onClick={() => props.openQuery(props.selected)}><span>Query prescription</span></div>
      </Grid>
        </>
    )
}