import { Grid, Stepper } from '@material-ui/core';
import React from 'react'
import StepperContainer from '../component/StepperContainer/StepperContainer';
import { useStyle } from '../style/style';
import { useHistory } from "react-router-dom";

function DoctorRegistrationByAdmin() {
    const classes = useStyle();
    let history = useHistory();

    return (
        <div className={classes.main}>
            <Grid container className={classes.gridContainer}>
                <Grid item xs={12} md={6} className={classes.rightGrid}>


                    <div style={{ width: '90%', marginTop: '40px' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px', marginBottom: '30px' }}>
                            <div onClick={() => { history.push("/login") }} style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '88px',
                                height: '39px',
                                background: 'rgba(102, 181, 195, 0.15)',
                                boxShadow: '-7px 6px 7px rgba(6, 99, 116, 0.05)',
                                borderRadius: '7px',
                                cursor: "pointer"

                            }}>
                                <p style={{
                                    display: 'block',
                                    margin: 'auto',
                                    fontFamily: 'GreycliffCFBold',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    letterSpacing: '1.71px',
                                    color: '#013C44'
                                }}>Sign in
                                </p>
                            </div>
                            <div
                                style={{
                                    width: '78px',
                                    height: '12px',
                                    fontFamily: 'GreycliffCFBold',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    lineHeight: '19px',
                                    textAlign: 'right',
                                    letterSpacing: '1.71px',
                                    color: '#013C44',
                                    marginRight: '20px'
                                }}
                            >Register</div>
                        </div>
                        <StepperContainer />
                    </div>

                </Grid>
            </Grid>
        </div>
    )
}

export default DoctorRegistrationByAdmin
