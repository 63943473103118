import React, { useState, useEffect } from "react";
import "./App.css";
import { Doctor } from "./Dashboard/doctorProfile/profile";
import Cockpit from "./Dashboard/doctorCockpit/cockpit";
import Dashboard from "./Dashboard/doctorDashboard/dashboard";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";

import ZoomWeb from "./Dashboard/doctorCockpit/ZoomWebSdK";
import ZoomWebRedirect from "./Dashboard/doctorCockpit/ZoomWebRedirect";

import DoctorCreatePasswordScreen from "./Admin/SetDoctoresPassword/DoctorCreatePasswordScreen";
import DoctorRegistrationByAdmin from "./Admin/DoctorRegistrationByAdmin/DoctorRegistrationByAdmin";
import LoginPage from "./Admin/SetDoctoresPassword/LoginScreenWithSuccessful";

import Login from "./Admin/DashboardLogin/DashboardLogin";

import RegisterDoctor from "./Admin/DoctorRegistrationByAdmin/RegisterDoctore";
import Forgot from "./Admin/ForgotPassword/Screen";
import Verfication from "./Admin/ForgotPassword/Screen/Verfication";
import Reset from "./Admin/ForgotPassword/Screen/Reset";
// import Notification from './Notification';
import { useSelector, useDispatch } from "react-redux";
import { getToken, onMessageListener } from "./firebase";
import SnackBarActions from "./redux/actions/snackBarAction";
import ConfirmAvailability from "./Dashboard/ConfirmAvailability/ConfirmAvailability";
import ChatPage from "./Dashboard/support/ChatPage";

import Pubnub from "pubnub";
// import { createPubNubListener } from "pubnub-redux";
import { PubNubProvider } from "pubnub-react";
import keyConfiguration from "../src/config/pubnub-keys.json";
import DashBoardPage from "./doctor-dashboard/DashBoardPage";
import ViewVisitPage from "./doctor-dashboard/visit-view/ViewVisitPage";
import PatientComponent from "./doctor-dashboard/components/PatientComponent";
import VisitComponent from "./doctor-dashboard/components/VisitComponent";
import RegistrationPage from "./doctor-dashboard/sign-up/RegistrationPage";
import DoctorProfilePage from "./doctor-dashboard/pages/DoctorProfilePage";
import ProfileUpdatePage from "./doctor-dashboard/pages/ProfileUpdatePage";
import DoctorOrders from "./doctor-dashboard/pages/DoctorOrders";
import ViewPatient from "./doctor-dashboard/components/ViewPatient";

const pubnubConfig = Object.assign(
  {},
  {
    restore: true,
    heartbeatInterval: 0,
  },
  { ...keyConfiguration }
);

const pubnub = new Pubnub(pubnubConfig);

function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  const dispatch = useDispatch();


  const checkSafari = () => {
    return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
  }
  console.log(checkSafari(), "sbhhjshha")
  useEffect(() => {
    if (!checkSafari())
       getToken(setTokenFound);
  }, []);

  useEffect(() => {
    if (!checkSafari()) {
      onMessageListener()
      .then((message) => {
        // console.log('message listener:', message);
        dispatch(SnackBarActions.showSuccessSnackbar(message.notification.title));
      })
      .catch((err) => {
        // console.log('failed: ', err)
      });
    }
  });


  return (
    <Router>
      <Switch>
        <Route path="/login" exact children={<Login />} />
        <Route
          path="/create_password/:email"
          children={<DoctorCreatePasswordScreen />}
        />
        <Route path="/sign_in" exact children={<LoginPage />} />
        <Route path="/forgot_password" children={<Forgot />} />
        <Route path="/verificaton" children={<Verfication />} />
        <Route path="/reset_password" children={<Reset />} />
        <Router
          path="/confirm_availability/:doctorid/:bookingid"
          children={<ConfirmAvailability />}
        />
        <Route
          path="/doctor_registration"
          children={<DoctorRegistrationByAdmin />}
        />
        <Route path="/create_register" children={<RegisterDoctor />} />
        <ProtectedRoute exact path="/" children={<Dashboard />} />

        <SaasRoutes
          exact
          path="/doctor-dashboard"
          children={<DashBoardPage />}
        />
        <SaasRoutes exact path="/visit-view" children={<ViewVisitPage />} />
        <SaasRoutes exact path="/patients" children={<PatientComponent />} />
        <SaasRoutes exact path="/visits" children={<VisitComponent />} />
        <SaasRoutes
          exact
          path="/doctor-profile"
          children={<DoctorProfilePage />}
        />
        <SaasRoutes
          exact
          path="/doctor-profile-update"
          children={<ProfileUpdatePage />}
        />
        <SaasRoutes exact path="/orders" children={<DoctorOrders />} />
        <SaasRoutes exact path="/view-patient" children={<ViewPatient />} />

        <Route
          exact
          path="/doctor-registration"
          children={<RegistrationPage />}
        />

        <ProtectedRoute
          path="/meeting/:meetingid/:password"
          children={<ZoomWeb />}
        />
        <ProtectedRoute
          path="/meeting/:roomUrl"
          children={<ZoomWeb />}
        />
        <ProtectedRoute path="/redirect" children={<ZoomWebRedirect />} />
        <ProtectedRoute path="/cockpit" children={<Cockpit />} />
        <ProtectedRoute path="/support" children={<ChatPage />} />
        <ProtectedRoute path="/profile" children={<Doctor />} />
        <ProtectedRoute
          exact
          path="/VisitComponent"
          children={<VisitComponent />}
        />
        <ProtectedRoute
          exact
          path="/PatientComponent"
          children={<PatientComponent />}
        />
      </Switch>
    </Router>
  );
}

function ProtectedRoute({ children, ...rest }) {
  const userdata = useSelector((state) => state.loginReducer.payload);
  let hasToken = userdata && userdata.token ? true : false;
  let hasSaasAccess =
    userdata &&
    userdata.profile &&
    userdata.profile.doctor_type &&
    userdata.profile.doctor_type === "advice"
      ? true
      : false;

  return (
    <PubNubProvider client={pubnub}>
      <div>
        <Route
          {...rest}
          render={() => {
            return hasToken && hasSaasAccess === false ? (
              children
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
      </div>
    </PubNubProvider>
  );
}

function SaasRoutes({ children, ...rest }) {
  const userdata = useSelector((state) => state.loginReducer.payload);
  let hasToken = userdata && userdata.token ? true : false;
  let hasSaasAccess =
    userdata &&
    userdata.profile &&
    userdata.profile.doctor_type &&
    userdata.profile.doctor_type === "advice"
      ? true
      : false;

  return (
    <PubNubProvider client={pubnub}>
      <div>
        <Route
          {...rest}
          render={() => {
            return hasToken && hasSaasAccess ? (
              children
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
      </div>
    </PubNubProvider>
  );
}

export default App;
