const getNotifyPopupScreen = (state = "", action) => {
    switch (action.type) {
        case "SET_GET_NOTIFY_POPUP_SCREEN":
            return { ...state, payload: action.payload }
        default:
            return state;
    }
}
// 
const getMedPrescription = (state = "", action) => {
    switch (action.type) {
        case "SET_GET_MED_PRESCRIPTION":
            return { ...state, payload: action.payload }
        default:
            return state;
    }
}
// 




const getCity = (state = "", action) => {
    switch (action.type) {
        case "SET_CITY":
            return { ...state, payload: action.payload };
        case "SET_DOC":
            return { ...state, documentlist: action.payload };
        default:
            return state;
    }

}

const pathologyArea = (state = "", action) => {
    switch (action.type) {
        case "SET_AREA":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}
const pathologySearch = (state = "", action) => {
    switch (action.type) {
        case "SET_SEARCH":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}

const pathologySearchLabname = (state = "", action) => {
    switch (action.type) {
        case "SET_LABNAME":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}

const radiologySearchLabname = (state = "", action) => {
    switch (action.type) {
        case "SET_LABNAME_RADIO":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}



const homeserviceSearch = (state = "", action) => {
    switch (action.type) {
        case "SET_HOME_SERVICE":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}



const extentReducer = (state = { isLoading: false, isError: false }, action) => {

    switch (action.type) {
        case "SET_EXTENT_APPOINTMENT_REQUEST":
            return {
                isLoading: true,
                isError: false
            };
        case "SET_EXTENT_APPOINTMENT_SUCCESS":
            return {
                isLoading: false,
                ...action.payload,
                isError: false
            };
        case "SET_EXTENT_APPOINTMENT_ERROR":
            return {
                isLoading: false,
                isError: true
            };

        default:
            return state;
    }
}
const changeStatusReducer = (state = { isLoading: false, isError: false }, action) => {
    switch (action.type) {
        case "SET_CHANGE_STATUS_REQUEST":
            return {
                isLoading: true,
                isError: false
            };
        case "SET_CHANGE_STATUS_SUCCESS":
            return {
                isLoading: false,
                payload: action.payload,
                isError: false
            };
        case "SET_CHANGE_STATUS_ERROR":
            return {
                isLoading: false,
                isError: true
            };
        default:
            return state;
    }
}


const radiologySearch = (state = "", action) => {
    switch (action.type) {
        case "SET_RA_SEARCH":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}
const followupReducer = (state = { isLoading: false, isError: false }, action) => {
    // console.log(action, 'red')
    switch (action.type) {
        case "SET_FOLLOW_APPOINTMENT_REQUEST":
            return {
                isLoading: true,
                isError: false
            };
        case "SET_FOLLOW_APPOINTMENT_SUCCESS":
            return {
                isLoading: false,
                ...action.payload,
                isError: false
            };
        case "SET_FOLLOW_APPOINTMENT_ERROR":
            return {
                isLoading: false,
                isError: true
            };

        default:
            return state;
    }
}

const getCityForPrescription = (state = "", action) => {
    switch (action.type) {
        case "SET_CITY_FOR_PRESCRIPTION":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}

const getAreaForPrescription = (state = "", action) => {
    switch (action.type) {
        case "SET_AREA_FOR_PRESCRIPTION":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}

const searchGenericNameForPrescription = (state = "", action) => {
    switch (action.type) {
        case "SET_GENERIC_NAME_FOR_PRESCRIPTION":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}

const searchProductNameForPrescription = (state = "", action) => {
    switch (action.type) {
        case "SET_PRODUCT_NAME_FOR_PRESCRIPTION":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}
const medAttribute = (state = "", action) => {
    switch (action.type) {
        case "SET_MED_ATTR":
            return { ...state, payload: action.payload };
        default:
            return state;
    }
}
const getCasePdf =(state='',action)=>{
    switch(action.type){
        case 'SET_CASE_PDF':
            return{...state,payload:action.payload}
        default:
            return state
    }
}
const queryHistoryReducer = (state = { isLoading: false, isError: false, payload: [] }, action) => {
    switch (action.type) {
        case "SET_QUERY_HISTORY_REQUEST":
            return {
                isLoading: true,
                isError: false,
                payload: [],
            };
        case "SET_QUERY_HISTORY_SUCCESS":
            return {
                isLoading: false,
                payload: action.payload,
                isError: false
            };
        case "SET_QUERY_HISTORY_ERROR":
            return {
                isLoading: false,
                isError: true,
                payload: [],

            };
        default:
            return state;
    }
}

const notifyDrReducer = (state = false, action) => {
    switch (action.type) {
        case "SET_NOTIFY_DR":
            return action.payload;
        default:
            return state;
    }
}
const allPrescriptionDetail = (state = "", action) => {
    switch (action.type) {
        case "SET_ALL_DETAIL_FOR_PRESCRIPTION":
            return action.payload;
        default:
            return state;
    }
}
export {
    getCity, pathologyArea, pathologySearch, radiologySearch, extentReducer, changeStatusReducer, followupReducer, getCityForPrescription
    , getAreaForPrescription, searchGenericNameForPrescription, searchProductNameForPrescription,
    homeserviceSearch, pathologySearchLabname, radiologySearchLabname, medAttribute, getMedPrescription, queryHistoryReducer, notifyDrReducer, getNotifyPopupScreen,getCasePdf,allPrescriptionDetail
}
