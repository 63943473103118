import React from 'react'
import { Button, Grid, Stepper } from '@material-ui/core';

import triangleLogo from '../images/icons/triangle.svg'
import { useStyle } from '../style/style';

function RegisterDoctore() {
    const classes = useStyle();
    return (
        <div className={classes.main}>
            <Grid container className={classes.gridContainer}>
                <Grid item xs={12} md={6} className={classes.rightGrid}>


                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <a href="/doctor_registration"><Button className={classes.stepperButton} type="text" >Register Doctor <img src={triangleLogo} alt="logo" className={classes.stepperButtonLogo} crossorigin="anonymous" /></Button></a>
                    </div>


                </Grid>
            </Grid>
        </div>
    )
}

export default RegisterDoctore
