import React from 'react'
import { Button, Grid, Select, TextField, Dialog, makeStyles } from '@material-ui/core';
import { useStyle } from '../../style/style';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
// import Logo
import FileUpload from '../../images/icons/fileUpload.svg'
import triangleLogo from '../../images/icons/triangle.svg'
import axios from 'axios';
import UploadSuccessfull from './UploadSuccessfull';
import ProgressBar from './ProgressBar';
import { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import dateIcon from '../../images/icons/dateIcon.svg';
import InputAdornment from '@material-ui/core/InputAdornment';


const useStyle2 = makeStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #66B5C3',
            opacity: '0.95',
        },
        "& .MuiTextField-root": {
            borderRadius: '40px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            // textTransform: 'uppercase',

        },
        "& .MuiPaper-root": {
            color: '#00839B',
            cursor: 'pointer',
            lineHeight: '20px'
        },
        "& .MuiIconButton-root": {
            padding: '0'
        }
    }
}))





export const PersonalInfo = ({ handleStepperNext, setPersonalinfo, personalInfo }) => {
    const classes = useStyle();
    const classes2 = useStyle2();
    const [gender, setGender] = React.useState('');
    const [profile_pic, setProfile_pic] = React.useState('');
    const [pro, setpro] = React.useState(false);
    const [sign_pic, setSign_pic] = React.useState('');
    const [sign, setSign] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [progressBarComponent, setProgressBarComponent] = React.useState(false)
    const [handleCompleteStatus, setHandleCompleteStatus] = React.useState(false)
    const [year, handleDateChange] = React.useState(null);
    const [error, setError] = React.useState(false);
    const [errorDob, setErrorDob] = React.useState(false)
    const [dob, setSelectedDate] = React.useState(null);

    const handleYearValidation = (value) => {
        handleDateChange(moment(value).format('YYYY'))
        setError(false)
    }

    const handleDate = (date) => {
        setSelectedDate(moment(date).format("ddd, DD MMM, YYYY"));
        setErrorDob(false)
    };

    const handleGender = (event) => {
        setGender(event.target.value)
    }

    // handle progress

    const handleProgressCompleteClose = () => {
        setHandleCompleteStatus(false)
    }

    const UploadProgress = (percentCompleted) => {
        if (percentCompleted === 100) {
            setProgressBarComponent(false)
            setHandleCompleteStatus(true)
            setTimeout(function () { setHandleCompleteStatus(false) }, 3000)
        } else {
            setProgressBarComponent(true)
        }
    }



    const handleProfile = async ({ target: { files } }) => {
        const img = files[0];

        const formData = new FormData()

        formData.append('media', img)

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/upload/avatar',
            headers: {
                'Content-Types': 'multipart/form-data',
                'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDZlOTE3MGQ1ZjVmYjAwMTQ0MmI2NjMiLCJ1dWlkIjoiQ04tQ0M1MjBDODUiLCJncm91cCI6ImNvbnN1bWVyIiwiZnVsbF9uYW1lIjoic3Mgc3NzIiwic3Vic2NyaXB0aW9uIjpmYWxzZSwiaWF0IjoxNjE3ODU4OTI4fQ.29JtWwv8V1Mv9M4nMfAYfr6C-DYKnNKV6Rvv24VLQ-4'

            },
            data: formData,
            onUploadProgress: function (progressEvent) {

                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted)
                UploadProgress(percentCompleted)
            }
        }

        axios(config)
            .then(res => {
                setProfile_pic(res.data.url)
                setpro(true)
            })
            .catch(err => { })
        // console.log("Error" + err)


    }

    const handleSign = async ({ target: { files } }) => {
        const img2 = files[0];

        const formData2 = new FormData()

        formData2.append('media', img2)

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/upload/avatar',
            headers: {
                'Content-Types': 'multipart/form-data',
                'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDZlOTE3MGQ1ZjVmYjAwMTQ0MmI2NjMiLCJ1dWlkIjoiQ04tQ0M1MjBDODUiLCJncm91cCI6ImNvbnN1bWVyIiwiZnVsbF9uYW1lIjoic3Mgc3NzIiwic3Vic2NyaXB0aW9uIjpmYWxzZSwiaWF0IjoxNjE3ODU4OTI4fQ.29JtWwv8V1Mv9M4nMfAYfr6C-DYKnNKV6Rvv24VLQ-4'

            },
            data: formData2,
            onUploadProgress: function (progressEvent) {

                const percentCompleted2 = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted2)
                UploadProgress(percentCompleted2)
            }
        }
        axios(config)
            .then(res => {
                setSign_pic(res.data.url)
                setSign(true)
            })
            .catch(err => { }
                //  console.log("lsdkfn" + err)
            )

    }



    const personalInfo_ValidationSchema = Yup.object().shape({
        // firstName: Yup.string().required('Required'),
        // middleName: Yup.string().required('Required'),
        // lastName: Yup.string().required('Required'),
        // dob: Yup.string().required('Required'),
        // gender: Yup.string().required('Required'),
        // selectedDate: Yup.string().test(value=>)
        // languages: Yup.string().required('Required'),


    })




    return (
        <div style={{ width: '100%' }} className={classes2.root}>
            <Formik
                initialValues={personalInfo}
                onSubmit={(value) => {

                    const { firstName, middleName, lastName, languages, } = value
                    const result = parseInt(year)
                    const selectedDate = isNaN(result) ? 0 : result
                    const avatar = profile_pic
                    const newValue = {
                        firstName, middleName, lastName, gender, dob, languages, profile_pic, sign_pic, selectedDate, avatar
                    }

                    setPersonalinfo(newValue)
                    if (dob === null) {
                        setErrorDob(true)
                    }
                    else if (year === null) {
                        setError(true)
                    }
                    else {
                        setError(false)
                        handleStepperNext()
                    }

                }}
                validationSchema={personalInfo_ValidationSchema}
            >

                {
                    ({ errors, touched, isValid }) => (
                        <Form style={{ width: '70%', margin: 'auto' }} autoComplete="off">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>First name*</div>
                                    <Field
                                        fullWidth
                                        name="firstName"
                                        variant="outlined"
                                        required={true}
                                        className={classes.primaryInput2}
                                        as={TextField}
                                        error={touched.firstName && errors.firstName}
                                        helperText={touched.firstName && errors.firstName}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Middle name</div>
                                    <Field
                                        fullWidth
                                        name="middleName"
                                        variant="outlined"
                                        as={TextField}

                                        className={classes.primaryInput2}
                                        error={touched.middleName && errors.middleName}
                                        helperText={touched.middleName && errors.middleName}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Last name*</div>
                                    <Field
                                        fullWidth
                                        name="lastName"
                                        variant="outlined"
                                        required={true}
                                        as={TextField}
                                        className={classes.primaryInput2}
                                        error={touched.lastName && errors.lastName}
                                        helperText={touched.lastName && errors.lastName}

                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <div className={classes.primaryLabel}>Date of birth*</div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Field
                                            as={DatePicker}
                                            inputVariant="outlined"
                                            name="dob"
                                            format="dd/MM/yyyy"
                                            id="date-picker-inline"
                                            className="date-picker-inline1"
                                            value={dob}
                                            placeholder="DD/MM/YYYY"
                                            required={true}
                                            onChange={handleDate}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><img src={dateIcon} alt="dateIcon" style={{ cursor: 'pointer', }} crossorigin="anonymous" /></InputAdornment>,
                                            }}
                                            renderInput={props => <TextField {...props} />}
                                            error={errorDob}
                                            helperText={errorDob ? "Required" : ''}
                                        />
                                    </MuiPickersUtilsProvider>

                                </Grid>
                                <Grid item xs={6}>
                                    <div className={classes.primaryLabel}>Gender*</div>
                                    <Field
                                        name="gender"
                                        value={gender}
                                        onChange={handleGender}
                                        variant="outlined"
                                        as={Select}
                                        required={true}
                                        fullWidth
                                        className={classes.primaryInput2}
                                        error={touched.gender && errors.gender}
                                        helperText={touched.gender && errors.gender}

                                    >

                                        <option aria-label="None" value="" className={classes.disableDropDown} disabled>Gender</option>
                                        <option value="Male" className={classes.dropDown}>Male</option>
                                        <option value="Female" className={classes.dropDown}>Female</option>
                                        <option value="Other" className={classes.dropDown}>Other</option>
                                    </Field>


                                </Grid>
                                {/* <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Practice Started*</div>
                                    <Field
                                        fullWidth
                                        variant="outlined"
                                        name="practice_started_year"
                                        type="date"
                                        as={TextField}
                                        className={classes.DateInput}
                                        required={true}
                                        openTo="year"
                                        views={["year"]}

                                    />
                                </Grid> */}
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Practice Started Year*</div>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Field
                                            openTo="year"
                                            name="selectedDate"
                                            as={DatePicker}
                                            fullWidth
                                            inputVariant="outlined"
                                            views={["year"]}
                                            required={true}
                                            value={year}
                                            onChange={handleYearValidation}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><img src={dateIcon} alt="dateIcon" style={{ cursor: 'pointer', }} crossorigin="anonymous" /></InputAdornment>,
                                            }}
                                            renderInput={props => <TextField {...props} />}
                                            error={error}
                                            helperText={error ? "Required" : ""}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.primaryLabel}>Language spoken</div>
                                    <Field
                                        fullWidth
                                        name="languages"
                                        variant="outlined"
                                        as={TextField}
                                        className={classes.primaryInput2}
                                        error={touched.languages && errors.languages}
                                        helperText={touched.languages && errors.languages}
                                    />
                                </Grid>
                                {/* first upload button  */}
                                <Grid item xs={12}>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <label htmlFor="upload-photo" className={classes.uploadLabel}>
                                                {pro ? <img src={profile_pic} alt={profile_pic} style={{ width: '85px', height: '85px', borderRadius: '50%' }} crossorigin="anonymous" /> : <img style={{ width: '22px', height: '21px' }} src={FileUpload} alt="File Upload" crossorigin="anonymous" />}
                                            </label>
                                            <Field
                                                type="file"
                                                name="profile"
                                                as={TextField}
                                                className={classes.uploadButton}
                                                id="upload-photo"
                                                onChange={handleProfile}
                                                error={touched.profile && errors.profile}
                                                helperText={touched.profile && errors.profile} />

                                        </div>
                                        <div className={classes.uploadContainer}>
                                            <div className={classes.uploadHeading}>
                                                Upload Doctor's profile picture
                                            </div>
                                            <div className={classes.uploadInstruction}>
                                                Profile picture should be in the standard format png, jpeg & no more than 2mb.
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>

                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <label htmlFor="upload-photo2" className={classes.uploadLabel}>
                                                {sign ? <img src={sign_pic} alt={sign_pic} style={{ width: '85px', height: '85px', borderRadius: '50%' }} crossorigin="anonymous" /> : <img style={{ width: '22px', height: '21px' }} src={FileUpload} alt="File Upload" crossorigin="anonymous" />}
                                            </label>
                                            <Field
                                                type="file"
                                                name="signature"

                                                as={TextField}
                                                className={classes.uploadButton}
                                                onChange={handleSign}
                                                id="upload-photo2"
                                                error={touched.signature && errors.signature}
                                                helperText={touched.signature && errors.signature}
                                            />
                                        </div>
                                        <div className={classes.uploadContainer}>
                                            <div className={classes.uploadHeading}>
                                                Upload Doctor's signature
                                            </div>
                                            <div className={classes.uploadInstruction}>
                                                Signature should be in the standard png format, no more than 2mb.
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{ marginBottom: "62px", marginTop: 30 }}>
                                    <Button className={classes.stepperButton} type="submit" >Next <img src={triangleLogo} alt="logo" className={classes.stepperButtonLogo} crossorigin="anonymous" /></Button>
                                </Grid>
                            </Grid>

                        </Form>
                    )
                }
            </Formik>

            <Dialog
                onClose={handleProgressCompleteClose}
                open={Boolean(handleCompleteStatus)} style={{
                    borderRadius: '25px', border: ' 1px solid #A0DFC4',
                    boxShadow: '0px 0px 24px 5px rgba(218, 218, 218, 0.3)',
                }}>
                <UploadSuccessfull />
            </Dialog>

            <Dialog
                onClose={Boolean(progressBarComponent)}
                open={Boolean(progressBarComponent)}>
                <ProgressBar progress={progress} />
            </Dialog>




        </div >
    )
}


PersonalInfo.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
    handleStepperNext: PropTypes.func.isRequired
};

