import React from "react";
import "./Referral.scss";
import succ from "../../assets/images/success.svg";

function RefferalSuccPopup() {
  return (
    <div className="refferalSuccPopup">
      <img src={succ} alt="icon" />
      <div className="txt">Referral has been successfully created </div>
    </div>
  );
}

export default RefferalSuccPopup;
