import React from 'react';
import backgroundImg from '../../images/BackgroundImage.svg';
import { makeStyles, Grid, TextField, Button } from '@material-ui/core'
import { useStyle } from '../../style/style'
import triangle from '../../images/icons/triangle.svg'
import queryString from 'query-string';

// components
import Logo from '../../component/Logo/Logo'
import * as Yup from 'yup';
import Heading from '../../component/Heading/Heading';
import LabelText from '../../component/LabelText/LabelText';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useHistory } from "react-router-dom";



// Styles

const useStyle2 = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100vh',
        width: '100vw',
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    gridContainer: {
        height: '100%',
        width: '100%',
    },
    rightGrid: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid #00839B',
        borderRadius: '50px 0px 0px 50px',
        margin: '0 0 0 auto',
        background: '#FFFFFF',
        opacity: '0.95',
        [theme.breakpoints.down("sm")]: {
            borderRadius: '3px',
        },
    },
    container: {
        width: '70%',
        height: 'auto',
        marginTop: '20%'

    }
}))



function Screen2() {
    let history = useHistory();

    const classes2 = useStyle2()
    const classes = useStyle()
    const [verified, setVerified] = React.useState(true)

    const { email, mobile } = history.location.state;


    const handleSubmit = (e) => {
        e.preventDefault()
        const code = e.target.elements.code.value


        var data = JSON.stringify({ "mobile": mobile, "otp": code });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/sms/register/verify-otp',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
   
                setVerified(true)
                history.push({ pathname: "/reset_password", state: { email } })
            })
            .catch(function (error) {
                setVerified(error.response.data.verified);
            });

    }
    const initialValues = {
        code: ''
    }

    const validationSchema = Yup.object().shape({
        code: Yup.number().integer(),
    })

    return (
        <div className={classes.main}>
            <Grid container className={classes.gridContainer}>
                <Grid item md={6} xs={12} className={classes.rightGrid}>
                    <div className={classes2.container}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {
                                ({ errors, touched }) => (
                                    <Form onSubmit={handleSubmit} >
                                        <Logo />
                                        <Heading heading="Forgot password" />
                                        <LabelText label="Please enter the code received" marginBottom="3%" />
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            name="code"
                                            variant="outlined"
                                            className={classes.primaryInput}
                                            placeholder="Enter Code"
                                            required={true}
                                            error={touched.code && errors.code}
                                            helperText={touched.code && errors.code}
                                        />
                                        {verified ? <LabelText label="A code has been sent to your email address!" margin="3% 0" /> : <div style={{

                                            fontFamily: 'VisueltRegular',
                                            fontStyle: 'normal',
                                            fontWeight: 'normal',
                                            fontSize: '18px',
                                            lineHeight: '23px',
                                            letterSpacing: '-0.291346px',
                                            color: '#FF6E4E',
                                            marginTop: '15px',
                                        }}>Wrong code entered!</div>}

                                        <div style={{ width: 'auto', height: '22px' }}></div>
                                        <Button className={classes.stepperButton} type="submit" style={{ textTransForm: 'capitalize' }} >Confirm Code <img src={triangle} alt="logo" className={classes.stepperButtonLogo} crossorigin="anonymous" /></Button>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}

export default Screen2







