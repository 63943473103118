import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyle } from '../../Admin/style/style'
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import Toast from '../../components/Toast';
import MuiPhoneNumber from 'material-ui-phone-number';
import { newSearchPatient, newUpdatePatient, addNewPatient, newUpdatePatient1 } from '../services/ServiceHelper';
import { getPatients } from '../../redux/actions/ex-appointment-action';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import PhoneInput from "react-phone-input-2";
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import "./Addpatient.scss"

export default function AddPatientComponent(props) {
    const authState = useSelector((state) => state.loginReducer.payload);
    const dispatch = useDispatch();
    const { id, close, details, toastVisibility, toastText } = props;
    console.log(details,"details")
    const classes = useStyle();
    const [editFlag, setEditFlag] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);
    const [isPatientFound, setPatientFound] = useState(false);
    const [PatientDetails, setDetails] = useState();
    const [existName, setExistingName] = useState()
    const [notFound, setNotFound] = useState(false);

    const [cityList, setCityList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [city, setCity] = useState()
    const [area, setArea] = useState()
    const [door, setDoor] = useState()
    const [pin, setPin] = useState()
    const [street, setStreet] = useState()
    const [dob, setDob] = useState('mm-dd-yyyy')
    const [citytosend, setCitytoSend] = useState()
    const [defaultCityId, setDefaultcityid] = useState()
    const [mob, setMob] = useState()
    const [gender, setGender] = useState()

    const [txt, setTxt] = useState('')
    const [middle, setMiddle] = useState('')
    const [last, setLast] = useState('')
    const [grp, setGrp] = useState('')
    const [mobErr,setMobErr]=useState('')

    console.log(typeof mob ,"mob")
    console.log(mob && mob.length ,"hgd")

    const GrpChange = (e) => {
        const { value } = e.target;
        
        setGrp(value.replace(/[^a-zA-z+-]/ig,''));
        console.log('Input value: ', grp);
    }
    const FirstNameChange = (e) => {
        const { value } = e.target;
        console.log('Input value: ', value);

        const re = /^[A-Za-z]+$/;
        if(value === "" || re.test(value)){
            setTxt(value);
        }
        // setTxt(value.replace(/[^a-zA-z]/ig,''));

    }
    const MiddleChange = (e) => {
        const { value } = e.target;
        console.log('Input value: ', value);
        setMiddle(value.replace(/[^a-zA-z]/ig,''));
    }
    const LastName = (e) => {
        const { value } = e.target;
        console.log('Input value: ', value);

        // const re = /^[A-Za-z]+$/;
        // if (value === "" || re.test(value) === true) {
        //     setLast(value);
        // }
        setLast(value.replace(/[^a-zA-z]/ig,''));

    }

console.log(citytosend, "ds")
    const handleClose = () => {
        props.close(true)
    };
    useEffect(() => {
        if (id == 'Add') {
            setEditFlag(true)
        } else {
            setEditFlag(false)
        }
        // setArea()
        console.log(PatientDetails, "dfghjl")

        // setDoor(PatientDetails && PatientDetails.residential_Address && PatientDetails.residential_Address[0] && PatientDetails.residential_Address[0].door_no )
        // setPin(PatientDetails && PatientDetails.residential_Address && PatientDetails.residential_Address[0] && PatientDetails.residential_Address[0].pincode)
        // setStreet(PatientDetails && PatientDetails.residential_Address && PatientDetails.residential_Address[0] && PatientDetails.residential_Address[0].street)
        // setCitytoSend(PatientDetails && PatientDetails.residential_Address && PatientDetails.residential_Address[0] && PatientDetails.residential_Address[0].city)
    }, [])
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/allcities`)
            .then((res) => {
                setCityList(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])
    function fetcharea(cityId) {
        axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/getArea/${cityId}`)
            .then(res => {
                setAreaList(res.data)
                setArea(res.data[0]._id)
                console.log(area, "area")
                // setLoading(false)
            }).catch(err => {
                console.log(err)
                // setLoading(false)
            })
    }
    const PatientUpdate = async (event) => {
        event.preventDefault()
        if(PatientDetails.created_by_doctor_uuid === authState.uuid)
        {
            const { phoneNumber, mobilenumber, middleName, firstname, lastname, email, bloodgroup, age, address } = event.target.elements
            let res = event && event.target && event.target.elements && event.target.elements[0] && event.target.elements[0].value
            let res1 = res.replace(/\D/g, '');
            console.log(citytosend,"db")
            let payLoad = {
                "first_name": txt,
                "last_name": last,
                "middle_name": middle,
                "blood_group": grp,
                "email": email.value,
                "full_name": txt + " " + middle + " " + last,
                "mobile": res1,
                "dob": dob,
                "gender": gender,
                "residential_Address": {
                    "city": citytosend,
                    "area": area,
                    "door_no": door,
                    "pincode": pin,
                    "street": street
                },
                "created_by_doctor_uuid" : authState.uuid
            }
            try {
                let response = await newUpdatePatient1(payLoad, PatientDetails.uuid, authState.token);
                if (response.status === 200 || response.status === 201) {
                    setToast(true)
                    setToastMessage('' + "New Patient Added Successfully")
                    setToastType('success')
                    dispatch(getPatients(0, ""))
                    setTimeout(() => {
                        close()
                    }, 1500);
                }
            }
            catch (error) {
                if (error && error.response) {
                    handleClose()
                    setToast(true)
                    setToastMessage('' + error.response.data)
                    setToastType('error')
                } else {
                    setToast(false)
                }
            }
        }
        else {
        let payLoad = {
            visited_doctor_uuid: authState.uuid,
        }
        try {
            let response = await newUpdatePatient(payLoad, PatientDetails.uuid, authState.token);
            if (response.status === 200 || response.status === 201) {
                setToast(true)
                setToastMessage('' + "New Patient Added Successfully")
                setToastType('success')
                dispatch(getPatients(0, ""))
                setTimeout(() => {
                    close()
                }, 1500);
            }
        }
        catch (error) {
            if (error && error.response) {
                handleClose()
                setToast(true)
                setToastMessage('' + error.response.data)
                setToastType('error')
            } else {
                setToast(false)
            }
        }
    }
    }
   
    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const { phoneNumber, mobilenumber, middleName, firstname, lastname, email, bloodgroup, age, gender, address } = event.target.elements
            // let res = event && event.target && event.target.elements && event.target.elements.phoneNumber && event.target.elements.phoneNumber.value
            let res = event && event.target && event.target.elements && event.target.elements[0] && event.target.elements[0].value
            let res1 = res.replace(/\D/g, '');
            console.log(res1.length,"123ser")
           
            let validatePhoneNumberRegex = /^\+?[1-9]{3}[0-9]{15}$/;
           


            if (res1.startsWith('91') && res1.length!==12) {
                console.log("true");
                setMobErr("Please Enter Valid Mobile Number")
                return
               }
               else if (res1.startsWith('234') && res1.length!==13){
                console.log("false");
                setMobErr("Please Enter Valid Mobile Number")
                return
             }else if (res1.length<10 || res1.length>15){
                setMobErr("Please Enter Valid Mobile Number")
                return
             }
             




                // if (res1.length<10 || res1.length>15) {

                //  console.log("invalid number") 
                //  setMobErr("Please Enter Valid Mobile Number")
                //  return
                // }
                
                
              

            
            console.log(gender.value)
            console.log(dob)
            console.log(txt + " " + middle + " " + last)

            let payLoad = {
                "first_name": txt,
                "last_name": last,
                "middle_name": middle,
                "blood_group": bloodgroup.value,
                "email": email.value,
                "full_name": txt + " " + middle + " " + last,
                // mobile: mobilenumber.value,
                "mobile": res1,
                "dob": dob,
                "gender": gender.value,
                "residential_Address": {
                    "city": citytosend,
                    "area": area,
                    "door_no": door,
                    "pincode": pin,
                    "street": street
                },
                "visited_doctor_uuid": authState.uuid,
                "created_by_doctor_uuid" : authState.uuid
            }
            let response = await addNewPatient(payLoad, authState.token);
            if (response.status === 200 || response.status === 201) {
                setToast(true)
                setToastMessage('' + "New Patient Added Successfully")
                setToastType('success')
                dispatch(getPatients(0, ""))
                setTimeout(() => {
                    close()
                }, 1500);
            }
        } catch (error) {
            if (error && error.response) {
                setToast(true)
                setToastMessage('' + error.response.data)
                setToastType('error')
            } else {
                setToast(false)
            }
        }
    }
    const handleSearch = async (event) => {
        console.log(event.target.elements,"ev")
        setMob(event && event.target && event.target.elements && event.target.elements.phoneNumber && event.target.elements.phoneNumber.value)
        event.preventDefault()
        try {
            let res = event && event.target && event.target.elements && event.target.elements.phoneNumber && event.target.elements.phoneNumber.value
            // let res1 = res.replace(" ",'');
            // let res2 =  res1.replace("-",'');
            console.log(res,"res123")
            let res1 = res.replace(/\D/g, '');
       
            let payLoad = {
                mobile: parseInt(res1),
                doctor_uuid: authState.uuid
            }
            let response = await newSearchPatient(parseInt(res1), authState.token)
            console.log(response)
            if (response.status == 200) {
                setToast(true)
                setToastMessage('' + "Successfully Fetched Patient Details")
                setToastType('success')
                // handleToast(true, "Successfully Fetched Patient Details", "success")
                setPatientFound(true)
                setNotFound(false)
                setEditFlag(false)

                let mResponse = response && response.data
                let patientName = mResponse.firstName + "    " + mResponse.lastName;

                setDetails(mResponse)
                console.log(mResponse)
                setExistingName(patientName)
                let addressDetails = mResponse.residential_Address[0] && mResponse.residential_Address[0].city && mResponse.residential_Address[0].city;
                
                setDob(mResponse.dob)
                setDoor(mResponse.residential_Address[0] && mResponse.residential_Address[0].city && mResponse.residential_Address[0].door_no)
                setCitytoSend(addressDetails)
                setPin(mResponse.residential_Address[0] && mResponse.residential_Address[0].city && mResponse.residential_Address[0].pincode)
                setStreet(mResponse.residential_Address[0] && mResponse.residential_Address[0].city && mResponse.residential_Address[0].street)
                setGender(mResponse.gender)
                
                cityList.map((item) => {

                    if (item._id == addressDetails) {
                        console.log(item.uuid)
                        setDefaultcityid(item.uuid)
                        fetcharea(item.uuid)
                    }
                })
            } else {
                setToast(true)
                setToastMessage('' + "Patient Not Found With Provided Phone Number")
                setToastType('error')
                // handleToast(true, "Patient Not Found With Provided Phone Number", "error")
                setPatientFound(false)
                setNotFound(true)
                setEditFlag(true)
            }

        } catch (error) {
            setToast(true)
            setToastMessage('' + "Patient Not Found With Provided Phone Number")
            setToastType('error')
            // handleToast(true, "Patient Not Found With Provided Phone Number", "error")
            setPatientFound(false)
            setNotFound(true)
            setEditFlag(true)
        }

    }
    const handleCityChange = (event) => {
        setCity(event && event.target && event.target.value)
        setDefaultcityid(event && event.target && event.target.value)
        cityList.map((item) => {
            if (item.uuid === event.target.value) {
                setCitytoSend(item._id)
            }
        })

        fetcharea(event && event.target && event.target.value)
    }
    const GenderChange = (event) => {
        setGender(event && event.target && event.target.value)
        console.log(gender)
    }
    const handleAreaChange = (event) => {
        setArea(event.target.value)
        console.log(event.target.value)
    }
    const handleDoorChange = (event) => {
        setDoor(event && event.target && event.target.value)
    }
    const handlePinChange = (event) => {
        setPin(event && event.target && event.target.value)
    }
    const handleStreetChange = (event) => {
        setStreet(event && event.target && event.target.value)
    }
    const handleDobChange = (event) => {
  
        setDob(event && event.target && event.target.value)
    }

    return (
        <>
        
        
            <Tooltip title="Close" placement="bottom" arrow>

                <CloseIcon onClick={handleClose} style={{ cursor: "pointer", float: "right", paddingBottom: "10px", fontSize: "35px" }} />

            </Tooltip>
            {isPatientFound === false && <small style={{ fontSize: "15px", color: "#013C44", fontFamily: "VisueltRegular",fontWeight: "bold" }}> <b>Search existing patient by phone number*</b> </small>}
            {isPatientFound === false &&
                <Formik
                    // initialValues={initialValues}
                    onSubmit={handleSearch}
                >
                    <Form action=""
                        onSubmit={handleSearch}
                    >
                        <Grid container>
                            <Grid item xs={8}>
                                {/* <div className={classes.primaryLabel}>Phone Number<span className='star-color'>*</span></div> */}

                                <Field
                                    as={MuiPhoneNumber}
                                    name="phoneNumber"
                                    defaultCountry={'ng'}
                                    required
                                    fullWidth variant="outlined"
                                    className={classes.primaryInput2}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <button className='submit_button' type='submit' style={{ marginLeft: '15%', marginTop: "0%", background: "#00839B", height: "60px" }}>Search</button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            }
            {(isPatientFound === true || (notFound == true && isPatientFound === false)) &&
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <div className='table_heading'>{id === "Add" ? "Add Patient" : "View Patient"}</div>
                        </Grid>
                        <Grid item xs={1}>
                            {/* <div>{id !== "Add" && <Tooltip title="Edit" placement="bottom" arrow>
                        <EditIcon onClick={() => { setEditFlag(!editFlag) }} style={{ cursor: "pointer", float: "right" }} />
                    </Tooltip>}
                    </div> */}

                        </Grid>
                        <Grid item xs={1}>

                            {/* <Tooltip title="Close" placement="bottom" arrow>
                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer", float: "right" }} />
                    </Tooltip> */}

                        </Grid>
                    </Grid>
                    <hr style={{ borderTop: "2px solid #00839B", borderBottom: "none", marginBottom: '5%' }} />
                    <Formik action="" onSubmit={editFlag == true ? handleSubmit : PatientUpdate}>
                        <Form onSubmit={editFlag == true ? handleSubmit : PatientUpdate}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">Mobile Number<span className='star-color'>*</span></h3>
                                    <PhoneInput
                                        className={classes.primaryInput2}
                                        style={{color:"#013C44",fontWeight:"500"}}
                                        country="ng"
                                        value={PatientDetails && PatientDetails.mobile ? PatientDetails.mobile : mob}
                                    />
                                        <p style={{color:"red"}}>{mobErr}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">First Name<span className='star-color'>*</span></h3>
                                    <input
                                        name="firstname"
                                        variant="outlined"
                                        className="headersearchtxt"
                                        style={{ width: "100%" }}
                                        required
                                        defaultValue={txt ? txt : PatientDetails && PatientDetails.first_name }
                                        value={notFound == true && isPatientFound === false ?  txt : (txt ? txt : PatientDetails && PatientDetails.first_name) }
                                        onChange={FirstNameChange}
                                        disabled={editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">Middle Name</h3>
                                    <input
                                        name="middleName"
                                        variant="outlined"
                                        defaultValue={middle ? middle : PatientDetails && PatientDetails.middle_name}
                                        className="headersearchtxt"
                                        value={ notFound == true && isPatientFound === false ?  middle : (middle ? middle : PatientDetails && PatientDetails.middle_name)}
                                        onChange={MiddleChange}
                                        disabled={editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <h3 className="input_lable1">Last Name<span className='star-color'>*</span></h3>
                                    <input
                                        name="lastname"
                                        variant="outlined"
                                        className="headersearchtxt"
                                        required
                                        defaultValue={last ? last : PatientDetails && PatientDetails.last_name}
                                        value={notFound == true && isPatientFound === false ?  last : (last ? last : PatientDetails && PatientDetails.last_name)}
                                        onChange={LastName}
                                        disabled={editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <h3 className="input_lable1">Email ID<span className='star-color'>*</span></h3>
                                    <input
                                        style={{ width: '100%' }}
                                        name="email"
                                        variant="outlined"
                                        className="headersearchtxt"
                                        required
                                        defaultValue={details && details.email ? details.email : PatientDetails && PatientDetails.email}
                                        disabled={editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <h3 className="input_lable1">Blood Group<span className='star-color'>*</span></h3>
                                 {editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid ?
                                    <select
                                        style={{ width: '100%' }}
                                        name="bloodgroup"
                                        variant="outlined"
                                        className="headersearchtxt"
                                        required
                                        defaultValue={details && details.blood_group ? details.blood_group : PatientDetails && PatientDetails.blood_group}
                                        value={notFound == true && isPatientFound === false ? grp : (details && details.blood_group ? details.blood_group : PatientDetails && PatientDetails.blood_group)}
                                        onChange={GrpChange}
                                        disabled
                                    >
                                            <option value="">Select..</option>
                                            <option value="Unknown" key="g">Unknown</option>
                                            <option value="A+" className="headersearchtxt" key="2">A+</option>
                                            <option value="A-" className="headersearchtxt" key="3">A- </option>
                                            <option value="B+" className="headersearchtxt" key="4">B+</option>
                                            <option value="B-" className="headersearchtxt" key="5">B-</option>
                                            <option value="O+" className="headersearchtxt" key="6">O+</option>
                                            <option value="O-" className="headersearchtxt" key="7">O-</option>
                                            <option value="AB+" className="headersearchtxt" key="8">AB+</option>
                                            <option value="AB-" className="headersearchtxt" key="9">AB-</option>
                                    </select>
                                    :
                                    <select
                                        style={{ width: '100%' }}
                                        name="bloodgroup"
                                        variant="outlined"
                                        className="headersearchtxt"
                                        required
                                        defaultValue={details && details.blood_group ? details.blood_group : PatientDetails && PatientDetails.blood_group}
                                        value={notFound == true && isPatientFound === false ? grp : (details && details.blood_group ? details.blood_group : PatientDetails && PatientDetails.blood_group)}
                                    
                                        onChange={GrpChange}
                                        // disabled
                                    >
                                            <option value="">Select..</option>
                                            <option value="Unknown" key="g">Unknown</option>
                                            <option value="A+" className="headersearchtxt" key="2">A+</option>
                                            <option value="A-" className="headersearchtxt" key="3">A- </option>
                                            <option value="B+" className="headersearchtxt" key="4">B+</option>
                                            <option value="B-" className="headersearchtxt" key="5">B-</option>
                                            <option value="O+" className="headersearchtxt" key="6">O+</option>
                                            <option value="O-" className="headersearchtxt" key="7">O-</option>
                                            <option value="AB+" className="headersearchtxt" key="8">AB+</option>
                                            <option value="AB-" className="headersearchtxt" key="9">AB-</option>
                                    </select>
                                    
                                }

                                </Grid>
                                <Grid item xs={4}>
                                    <h3 className="input_lable1">DOB<span className='star-color'>*</span></h3>
                                    {editFlag == false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid ?
                                        <input
                                            type='date'
                                            name="dob"
                                            variant="outlined"
                                            className="headersearchtxt"
                                            style={{ width: "100%" }}
                                            required
                                            // defaultValue={details && Number(details.age)}dob
                                            // defaultValue={moment(PatientDetails && PatientDetails.dob).format("YYYY-MM-DD")}
                                            value={moment(dob).format("YYYY-MM-DD")}
                                            onChange={(event) => handleDobChange(event)}
                                            disabled
                                        />
                                        :
                                        <input
                                            type='date'
                                            name="dob"
                                            variant="outlined"
                                            className="headersearchtxt"
                                            style={{ width: "100%" }}
                                            required
                                            defaultValue={moment(dob ? dob : PatientDetails && PatientDetails.dob).format("YYYY-MM-DD")}
                                            value={moment(dob ? dob : PatientDetails && PatientDetails.dob).format("YYYY-MM-DD")}
                                            onChange={(event) => handleDobChange(event)}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <h3 className="input_lable1">Gender<span className='star-color'>*</span></h3>
                                    {editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid ?
                                        <select name="gender"  className="headersearchtxt"
                                            required
                                            defaultValue={PatientDetails && PatientDetails.gender}
                                            value={PatientDetails && PatientDetails.gender}
                                            onChange={(event) => GenderChange(event)}
                                            disabled
                                        >
                                            <option value="" key="g">Select..</option>
                                            <option value="male" className="headersearchtxt" key="1">Male</option>
                                            <option value="female" className="headersearchtxt" key="2">Female</option>
                                            <option value="other" className="headersearchtxt" key="3">Other</option>
                                        </select>
                                        :
                                        <select  id="cars" className="headersearchtxt" name="gender"
                                            required
                                            defaultValue={gender ? gender : PatientDetails && PatientDetails.gender}
                                            value={gender ? gender : PatientDetails && PatientDetails.gender }
                                            onChange={(event) => GenderChange(event)}
                                        //     onChange={GenderChange}
                                        //    defaultValue={gender}
                                        >
                                            <option value="" >Select..</option>
                                            <option value="male" className="headersearchtxt" >Male</option>
                                            <option value="female" className="headersearchtxt">Female</option>
                                            <option value="other" className="headersearchtxt" >Other</option>
                                        </select>
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">City </h3>
                                    {editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid ?
                                        <select id="cars" className="headersearchtxt" name="city"
                                            // required
                                            defaultValue={defaultCityId}
                                            value={defaultCityId}
                                            onClick={(event) => handleCityChange(event)}
                                            disabled
                                        >
                                            {
                                                cityList && cityList.map((item) => (<option value={item.uuid} >{item.name}</option>))
                                            }
                                        </select>
                                        :
                                        <select id="cars" className="headersearchtxt" name="city"
                                            // required
                                            defaultValue={city ? city : defaultCityId}
                                            // value={city ? city : defaultCityId}
                                            onClick={(event) => handleCityChange(event)}
                                        >
                                            <option value={''}>Select..</option>
                                            {
                                                cityList && cityList.map((item) => (<option value={item.uuid}>{item.name}</option>))
                                            }

                                        </select>
                                    }

                                </Grid>
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">Area </h3>
                                    <select id="cars" className="headersearchtxt" name="area"
                                        // required
                                        onChange={(event) => handleAreaChange(event)}
                                        defaultValue={PatientDetails && PatientDetails.residential_Address[0] && PatientDetails.residential_Address[0].area}
                                        value={PatientDetails && PatientDetails.residential_Address[0] && PatientDetails.residential_Address[0].area}
                                        disabled={editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid}
                                    >
                                        {
                                            areaList && areaList.map((item) => (<option value={item._id}>{item.name}</option>))
                                        }
                                    </select>
                                </Grid>
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">Door No </h3>
                                    <input
                                        style={{ width: '100%' }}
                                        name="door_no"
                                        variant="outlined"
                                        className="headersearchtxt"
                                        // required
                                        onChange={(event) => handleDoorChange(event)}
                                        defaultValue={PatientDetails && PatientDetails.residential_Address[0] && PatientDetails.residential_Address[0].door_no}
                                        disabled={editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid}
                                    />
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <h3 className="input_lable1">Pincode </h3>
                                    <input
                                        style={{ width: '100%' }}
                                        name="pincode"
                                        variant="outlined"
                                        className="headersearchtxt"
                                        // required
                                        onChange={(event) => handlePinChange(event)}
                                        defaultValue={PatientDetails && PatientDetails.residential_Address[0] && PatientDetails.residential_Address[0].pincode}
                                        disabled={editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid}
                                    />
                                </Grid> */}
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">Street </h3>
                                    <input
                                        style={{ width: '100%' }}
                                        name="street"
                                        variant="outlined"
                                        className="headersearchtxt"
                                        // required
                                        onChange={(event) => handleStreetChange(event)}
                                        defaultValue={PatientDetails && PatientDetails.residential_Address[0] && PatientDetails.residential_Address[0].street}
                                        disabled={editFlag === false && PatientDetails && PatientDetails.created_by_doctor_uuid !== authState.uuid}
                                    />
                                </Grid>
                            </Grid>
                            {editFlag == false && <div className="btn_submit">
                                <button className='submit_button' type='submit' >Add</button>
                            </div>
                            }
                            {editFlag == true && <div className="btn_submit">
                                <button className='submit_button' type='submit'>Submit</button>
                            </div>
                            }
                        </Form>
                    </Formik>
                </>
            }
            <Toast
                text={toastMessage}
                type={toastType}
                open={showToast}
                setClose={() => {
                    setToast(false);
                }}
            />

        </>
    );
}