import moment from 'moment';


export const patientColumns = [
    {
        name: 'Date',
        selector: row => row.updatedAt,
        format: row => row && row.updatedAt ? `${moment(row.updatedAt).format('DD/MM/YYYY')}` : "NA"
    },
    {
        name: 'First Name',
        selector: row => row.first_name,
    },
    {
        name: 'Last Name',
        selector: row => row.last_name,
    },
    {
        name: 'Gender',
        selector: row => row.gender,
    },
    {
        name: 'Date of Birth',
        selector: row => moment(row.dob).format('DD/MM/YYYY'),
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
    {
        name: 'Mobile',
        selector: row => row.mobile,
    },

];

export const DashboardpatientColumns = [
    // {
    //     name: 'Id',
    //     selector: row => row.index+1,
    //     // format: row => row && row.updatedAt ? `${moment(row.updatedAt).format('DD/MM/YYYY')}` : "NA"
    // },
    {
        name: 'First Name',
        selector: row => row.first_name,
    },
    {
        name: 'Mobile',
        selector: row => row.mobile,
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
    // {
    //     name: 'Referal',
    //     selector: row => "N/A",
    // },

];
export const DashboardVisitColumns = [

    {
        name: 'Name',
        selector: row => row.name,
    },
    {
        name: 'Date',
        selector: row => `${moment(row.createdAt).format('DD/MM/YYYY')}`
    },

];


export const visitColumns = [
    {
        name: 'Patient Name',
        selector: row => row.name,
    },
    {
        name: 'Date',
        selector: row => row && row.createdAt ? `${moment(row.createdAt).format('DD/MM/YYYY')}` : "NA",
    },
    {
        name: 'Time',
        selector: row => row && row.createdAt ? `${moment(row.createdAt).format('LT')}` : "NA",
    },
    {
        name: 'Date of Birth',
        selector: row => moment(row.age).format('DD/MM/YYYY'),
    },
    {
        name: 'Phone No',
        selector: row => row.mobile,
    },

];



export const orderColumns = [
    {
        name: 'Order Id',
        selector: row => row.uuid,
        format: row => `${row && row.order && row.order[0] && row.order[0].uuid ? row.order[0].uuid : "NA"}`
    },
    {
        name: 'Name',
        selector: row => row && row.patient && row.patient[0] && row.patient[0].full_name ,
        format: row => `${row && row.patient && row.patient[0] && row.patient[0].full_name} `
    },
    {
        name: 'Phone No',
        selector: row => row && row.patient && row.patient[0] && row.patient[0].mobile,
    },
    {
        name: 'Type Of Order',
        selector: row => row.labtype === 'recommended' ? 'DMP' : "",
        format: row => `DMP`
    },
    {
        name: 'Amount',
        selector: row => row && row.order && row.order[0] && row.order[0].amount,
        format: row => `₦  ${row && row.order && row.order[0] && row.order[0].amount}`
    },
    {
        name: 'Order Date',
        selector: row => row && row.order && row.order[0] && row.order[0].createdAt && `${moment(row.order[0].createdAt).format('LL')}`,
    },
    {
        name: 'Referred by',
        selector: row => row && row.doctor_uuid ? "SELF" : "NA",
    },
    {
        name: 'Status',
        selector: row => row && row.order && row.order[0] && row.order[0].order_status ? row.order[0].order_status : "NA",
    },
    {
        name: 'Amount Earned',
        selector: row => row && row.commission,
        format: row => `₦  ${row && row.commission ? row.commission : "NA"}`
    }

];
