import React from "react";

import { useHistory, Link } from "react-router-dom";
import Toast from '../../../components/Toast';
import { Form, Formik, Field } from 'formik';
// import MuiPhoneNumber from 'material-ui-phone-number';

import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  TextField,
} from "@material-ui/core";
import styles from "../style";
import { useState } from "react";
import axios from "axios";
// import { Form, Formik, Field } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
// import {  Grid, Select, TextField, Dialog, makeStyles } from '@material-ui/core';
// import { useHistory } from "react-router-dom";
import successTick from '../../../Admin/images/icons/successTick.svg';
import FileUpload from '../../../Admin/images/icons/fileUpload.svg'
import regLogo from "../../../assets/images/regLogo.svg";

const useStyles = makeStyles((theme) => styles(theme));

const SignUpForm = ({ regComplete }) => {
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const [screen, setScreen] = useState("form");







  const [txt, setTxt] = useState('')
  const [middle, setMiddle] = useState('')
  const [last, setLast] = useState('')
  const [folio, setFolio] = useState('')
  const [phoneErr, setPhoneerror] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNewNumber, setPhoneNewNumber] = useState('')
  console.log(phoneNewNumber, "phoneNewNumber")

  console.log(txt, middle, last, folio, phoneErr, email, phoneNumber, "txt")

  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneNewNumber: "",
    folioNumber: ''
  };


  const [active, setActive] = useState(false);

  const [profileImage, setProfileImage] = useState();
  const [profileUploaded, setProfileUploaded] = useState(false);
  const [regCertificate, setRegCertificate] = useState();
  const [certificateUploaded, setCertificateUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toastType, setToastType] = useState();
  const [showToast, setToast] = useState(false);
  const [country, setCountry] = React.useState("Nigeria");
  const [mobileError, SetMobileError] = React.useState(false);
  const [mobieleErrormsg, setMobileErrormsg] = React.useState('');
  const [mobExist, setmobExist] = React.useState('');
  const [mobile, setMobile] = React.useState('')



  function handleOnChange(value) {
    var res = value.replace(/\D/g, "");
    // const re = /^[0-9]+$/;
    // if (res === "" || re.test(res) === true) {
    //     setMobile(res);
    // }
    setMobile(res);
    SetMobileError(false)
    axios.get(`${process.env.REACT_APP_API_URL}/user/auth/mobileNumExist/${res}`)
      .then(res => {
        setmobExist(res.data.status)
      })
      .catch(err => console.log(err))
  }

  const handleToast = (visibility, messages, type) => {
    console.log(visibility, messages, type, "typesfdfdsf")
    setToast(visibility)
    setToastMessage('' + messages)
    setToastType(type)
  }


  const uploadMedia = async (document, docType) => {
    try {
      const formData = new FormData()
      formData.append('media', document)
      // There is no auth header available it's hadcoded in old registration as well
      let hardCodedHeader = {
        'Content-Types': 'multipart/form-data',
        'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDZlOTE3MGQ1ZjVmYjAwMTQ0MmI2NjMiLCJ1dWlkIjoiQ04tQ0M1MjBDODUiLCJncm91cCI6ImNvbnN1bWVyIiwiZnVsbF9uYW1lIjoic3Mgc3NzIiwic3Vic2NyaXB0aW9uIjpmYWxzZSwiaWF0IjoxNjE3ODU4OTI4fQ.29JtWwv8V1Mv9M4nMfAYfr6C-DYKnNKV6Rvv24VLQ-4'
      }

      const config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/upload/avatar',
        headers: hardCodedHeader,
        data: formData,
      }

      let response = await axios(config)
      if (response.status === 200 || response.status === 201) {
        if (docType === "PIC") {
          setProfileImage(response.data.url)
          setProfileUploaded(true)
        } else {
          setRegCertificate(response.data.url)
          setCertificateUploaded(true)
        }
        setLoading(false)
      }

    } catch (error) {
      if (error && error.response) {
        handleToast(true, "" + error.response.data, 'error')
      }
      setLoading(false)
      if (docType === "PIC") {
        setProfileUploaded(false)
      } else {
        setCertificateUploaded(false)
      }
    }
  }


  const handleProfilePic = async ({ target: { files } }) => {
    const uploadedImage = files[0];
    setLoading(true)
    await uploadMedia(uploadedImage, "PIC")

  }

  const handleCertificate = async ({ target: { files } }) => {
    const certificates = files[0];
    setLoading(true)
    await uploadMedia(certificates, "CERT")
  }


  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      if (!phoneNewNumber || phoneNewNumber.length < 8) {
        handleToast(true, "Please Enter Valid Phone Number", "error")
      }
      // else if (!regCertificate) {
      //     handleToast(true, "Please Upload Registration Certificate", "error")
      // }
      else {
        setToast(false)

        const payLoad = {
          // email: values.email,
          // firstName: values.firstName,
          // middleName: values.middleName,
          // lastName: values.lastName,
          email: email,
          firstName: txt,
          middleName: middle,
          lastName: last,
          group: "doctor",
          profile: {
            doctor_type: "advice",
            speciality: ["life coach"],
            bio: "i am a doctor",
            doctorDocuments: [regCertificate],
            folioNumber: folio,
          },
          profile_pic: profileImage,
          // mobile: phoneNumber
          mobile: phoneNewNumber

        }

        console.log(payLoad, "payLoad")
        setLoading(true)

        var config = {
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/user/auth/ext/Doctorregister',
          headers: {
            'Content-Type': 'application/json'
          },
          data: payLoad
        };
        let response = await axios(config)

        if (response.status === 200 || response.status === 201) {
          setLoading(false)
          handleToast(true, "Your Registration request has been sent successfully to doctall admin", "success")
          setTimeout(() => {
            history.push('/login')

          }, 1500);
        }
      }
    } catch (error) {
      if (error && error.response) {
        handleToast(true, "" + error.response.data, "error")
        setLoading(false)
      }
    }
  }
  const FirstNameChange = (e) => {
    const { value } = e.target;
    console.log('Input value: ', value);

    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value) === true) {
      setTxt(value);
    }
    // setTxt(value.replace(/[^a-zA-z]/ig,''));

  }
  const MiddleChange = (e) => {
    const { value } = e.target;
    console.log('Input value: ', value);

    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value) === true) {
      setMiddle(value);
    }
    // setMiddle(value.replace(/[^a-zA-z]/ig,''));

  }
  const LastName = (e) => {
    const { value } = e.target;
    console.log('Input value: ', value);

    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value) === true) {
      setLast(value);
    }
    // setLast(value.replace(/[^a-zA-z]/ig,''));

  }
  const handlechangeEmail = (e) => {
    const { value } = e.target;
    console.log('Input value: ', value);

    // const re = /^[A-Za-z]+$/;
    // if (value === "" || re.test(value) === true) {
    setEmail(value);
    // }
    // setLast(value.replace(/[^a-zA-z]/ig,''));

  }
  const FolioChange = (e) => {
    const { value } = e.target;
    console.log('Input value: ', value);

    const re = /^[0-9]+$/;
    if (value === "" || re.test(value) === true) {
      setFolio(value);
    }
    // setFolio(value.setFolioreplace(/\D/g,''));

  }
  const handleChangePhone = (e) => {
    console.log(e, "e")
    const value = e
    const noSpecialCharacters = value.replace(/[^a-zA-Z0-9()]/g, '');
    console.log(noSpecialCharacters, "noSpecialCharacters");

    // const re = /[^a-zA-Z0-9 ]/;
    // if (value === "" || re.test(value) === true) {
    setPhoneNewNumber(noSpecialCharacters);
    // }
    // setFolio(value.replace(/\D/g,''));

  }
  //   const str = '1234-77888';

  // const noSpecialCharacters = str.replace(/[^a-zA-Z0-9 ]/g, '');
  // console.log(noSpecialCharacters); // 👉'123477888


  //   const checkSafari = () => {
  //     return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
  //   }
  // const [height, setHeight] = useState("48px")
  // if(!checkSafari()){
  //   () => setHeight("48px")
  // }
  // else{
  //   () => setHeight("100px")
  // }
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingLeft: "16px", paddingTop: "48px", paddingRight: "10px" }}
    >
      {screen === "form" ? (
        <>
          <Link to="/login">
            <img src={regLogo} alt="logo" />
          </Link>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Typography
              className={classes.regTitle}
              style={{ marginTop: "28px" }}
            >
              Sign in
            </Typography>
          </Link>
          <Typography
            className={classes.regSubTitle}
            style={{ marginTop: "8px" }}
          >
            Welcome to Doctall Patient Portal
          </Typography>
          <Grid
            item
            container
            style={{ width: "328px", marginTop: "28px" }}
            direction="column"
          >
            {/* <form action='' 
          // initialValues={initialValues}
                    // onSubmit={(value) => {
                    //     handleSubmit(value)
                    // }}
                     
          > */}
            <form action='' onSubmit={handleSubmit} style={{ minHeight: "115%" }}>

              <Typography className={classes.regLabel}>First name <span style={{ color: "red" }}>*</span></Typography>
              <input
                type="text"
                className={classes.regInput}
                placeholder="Enter First Name"
                name="firstName"
                value={txt}
                onChange={FirstNameChange}
                required
              />
              <Typography className={classes.regLabel}>Middle name</Typography>
              <input
                type="text"
                className={classes.regInput}
                placeholder="Enter Middle Name"
                name="middleName"
                value={middle}
                onChange={MiddleChange}
              />
              <Typography className={classes.regLabel}>Last name <span style={{ color: "red" }}>*</span></Typography>
              <input
                type="text"
                className={classes.regInput}
                placeholder="Enter Last name"
                name="lastName"
                value={last}
                onChange={LastName}
                required
              />
              <Typography className={classes.regLabel}>Email Address <span style={{ color: "red" }}>*</span></Typography>
              <input
                required
                className={classes.regInput}
                placeholder="Enter Email Address"
                name="email"
                type="email"
                value={email}
                onChange={handlechangeEmail}
              />
              <Typography className={classes.regLabel}>Phone number <span style={{ color: "red" }}>*</span></Typography>
              {/* <input
              type="text"
              className={classes.regInput}
              placeholder="Enter Phone number"


              isValid={(value) => {
                                                if (value.length < 12 || value.length > 15) {
                                                   
                                                    if (value.length > 3 && value.length <= 11)
                                                        setPhoneerror(true);
                                                    return false;
                                                } else {
                                                    setPhoneerror(false);
                                                    return true;
                                                }
                                            }}
                                            as={MuiPhoneNumber}
                                            name="phoneNumber"
                                            defaultCountry={'ng'}
                                            required
                                            onChange={handleOnChange}
                                            fullWidth variant="outlined"
                                            
            /> */}
              {/* <input
                type="text"
                className={classes.regInput}
                placeholder="Enter Mobile  number"
                value={phoneNumber}
                onChange={handleChangePhone}
              /> */}
              {/* <Field
                                            isValid={(value) => {
                                                if (value.length < 12 || value.length > 15) {
                                                    // if (value.length >= 3 && value.length <= 11)
                                                    if (value.length > 3 && value.length <= 11)
                                                        setPhoneerror(true);
                                                    return false;
                                                } else {
                                                    setPhoneerror(false);
                                                    return true;
                                                }
                                            }}
                                            as={MuiPhoneNumber}
                                            name="phoneNumber"
                                            defaultCountry={'ng'}
                                            required
                                            onChange={handleOnChange}
                                            fullWidth variant="outlined"
                                            className={classes.primaryInput2}
                                        /> */}

              <MuiPhoneNumber
                defaultCountry={'ng'}
                onChange={handleChangePhone}
                InputProps={{ disableUnderline: true }}
                className={classes.regInput}
                placeholder="Enter Folio number"
              // value={phoneNewNumber}
              />
              <Typography className={classes.regLabel}>Folio number1 <span style={{ color: "red" }}>*</span></Typography>
              <input
                type="text"
                className={classes.regInput}
                placeholder="Enter Folio number"
                value={folio}
                onChange={FolioChange}
                required
              />
              <div style={{ display: 'flex', marginTop: '5%' }}>
                <div>
                  <label htmlFor="upload-photo2" className={classes.uploadLabel}>
                    {certificateUploaded ? <img src={successTick} alt={successTick} style={{ width: '85px', height: '85px', borderRadius: '50%' }} crossorigin="anonymous" /> : <img style={{ width: '22px', height: '21px' }} src={FileUpload} alt="File Upload" crossorigin="anonymous" />}
                  </label>
                  <input
                    type="file"
                    name="certificate"
                    as={TextField}
                    className={classes.uploadButton}
                    id="upload-photo2"
                    onChange={handleCertificate}
                    accept="image/*"
                  // error={touched.profile && errors.profile}
                  // required
                  // helperText={touched.profile && errors.profile}
                  />
                </div>
                <div className={classes.uploadContainer}>
                  <div className={classes.uploadHeading}>
                    Upload registration certificate
                  </div>
                  <div className={classes.uploadInstruction}>
                    {/* Profile picture should be in the standard format png, jpeg & no more than 2mb. */}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '5%' }}>
                <div>
                  <label htmlFor="upload-photo" className={classes.uploadLabel}>
                    {profileUploaded ? <img src={successTick} alt={successTick} style={{ width: '85px', height: '85px', borderRadius: '50%' }} crossorigin="anonymous" /> : <img style={{ width: '22px', height: '21px' }} src={FileUpload} alt="File Upload" crossorigin="anonymous" />}
                  </label>
                  <input
                    type="file"
                    name="profilePic"
                    as={TextField}
                    className={classes.uploadButton}
                    id="upload-photo"
                    onChange={handleProfilePic}
                    accept="image/*"
                  // error={touched.profile && errors.profile}
                  // helperText={touched.profile && errors.profile}
                  />

                </div>
                <div className={classes.uploadContainer}>
                  <div className={classes.uploadHeading}>
                    Upload profile picture
                  </div>
                  <div className={classes.uploadInstruction}>
                    Profile picture should be in the standard format png, jpeg & no more than 2mb.
                  </div>
                </div>
              </div>
              <button
                className={classes.regButton}
                style={{ marginTop: "48px" }}
                type="submit"
              >
                Submit
              </button>
              <br />
            </form>

            <Toast
              style={{ marginTop: "100px" }}
              text={toastMessage}
              type={toastType}
              open={showToast}
              setClose={() => {
                setToast(false);
              }}
            />



          </Grid>
        </>
      ) : screen === "certificate" ? (
        <>
          <img src={regLogo} alt="logo" />
          <Typography
            className={classes.regTitle}
            style={{ marginTop: "48px" }}
          >
            Upload Certificate
          </Typography>
          <Typography
            className={classes.regSubTitle}
            style={{ marginTop: "8px" }}
          >
            Welcome to Doctall Patient Portal
          </Typography>
          <label
            for="file-upload"
            style={{ marginTop: "32px" }}
            className={classes.uploadInput}
          >
            {" "}
          </label>
          <input type="file" id="file-upload" style={{ display: "none" }} />
          <Typography className={classes.uploadInfo}>
            Upload registration certificate
          </Typography>
          <button
            className={classes.regButton}
            style={{ marginTop: "34px" }}
            onClick={() => {
              setScreen("profilepic");
            }}
          >
            Upload file
          </button>
          <Typography
            className={classes.uploadInfo}
            style={{ fontWeight: "500", marginTop: "32px" }}
          >
            Don’t have an account?{" "}
            <span style={{ color: "#B4331A", cursor: "pointer" }}>Sign up</span>
          </Typography>
        </>
      ) : (
        <>
          <img src={regLogo} alt="logo" />
          <Typography
            className={classes.regTitle}
            style={{ marginTop: "48px" }}
          >
            Upload profile picture
          </Typography>
          <Typography
            className={classes.regSubTitle}
            style={{ marginTop: "8px" }}
          >
            Welcome to Doctall Patient Portal
          </Typography>
          <label
            for="file-upload"
            style={{ marginTop: "32px" }}
            className={classes.uploadInput}
          >
            {" "}
          </label>
          <input type="file" id="file-upload" style={{ display: "none" }} />
          <Typography
            className={classes.uploadInfo}
            align="center"
            style={{ width: "253px" }}
          >
            Profile picture should be in the standard format png, jpeg & no more
            than 2mb
          </Typography>
          <button
            className={classes.regButton}
            style={{ marginTop: "34px" }}
            onClick={() => regComplete()}
          >
            Upload file
          </button>
          <Typography
            className={classes.uploadInfo}
            style={{ fontWeight: "500", marginTop: "32px" }}
          >
            Don’t have an account?{" "}
            <span style={{ color: "#B4331A", cursor: "pointer" }}>Sign up</span>
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default SignUpForm;
