import React, { useEffect } from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { useState } from "react";
import styles from "./style";
import dialogclose from "../../assets/images/DialogClose.svg";
import checkIcon from "../../assets/images/checkIcon.svg";
import redClose from "../../assets/images/redClose.svg";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import Toast from '../../components/Toast/index';
import { useSelector, useDispatch } from "react-redux";
import MuiPhoneNumber from 'material-ui-phone-number';
import moment from 'moment';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => styles(theme));


const DiagnosticPres = ({ closeDiag, patient, Vtype }) => {
  console.log(patient, "hgjh")
  const history = useHistory();
  
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const [pExist, setPExist] = useState(null);
  const userdata = useSelector((state) => state.loginReducer.payload);
console.log(userdata.uuid,"userData")

  const [mobile, setMobile] = useState("")
  const [fullName, setfullName] = useState("")
  const [email, setEmail] = useState("")
  const [gender, setGender] = React.useState("");


  console.log(fullName,"fullName")
  const [dob, setDob] = useState("")
  const [bloodGroup, setBloodGroup] = useState("")
  console.log(dob, "gender")
  console.log(mobile, "mobile")
  const [symptoms, setSymptoms] = useState("")
  const [temperature, setTemperature] = useState("")
  const [weight, setWeight] = useState("")
  const [height, setHeight] = useState("")
  const [ChiefComplaint, setChiefComplaint] = useState("")
  const [address, setAddress] = useState("")
  const [additionalInformation, setAdditionalInformation] = useState("")

  const[ispatientFound,setIspatientFound]=useState("patient not found")
 console.log(ispatientFound,"ispatientFound")
  const [toastMessage, setToastMessage] = useState();
  const [toastType, setToastType] = useState();
  const [showToast, setToast] = useState(false);


const[patientId,setPatientUuid]=useState("")
console.log(patientId,"patientId")
  const [Fetchedmobile, setFetchedmobile] = useState("")


  const handleToast = (visibility, messages, type) => {
    setToast(visibility)
    setToastMessage('' + messages)
    setToastType(type)
  }


  console.log(symptoms, temperature, weight, height, ChiefComplaint, address, additionalInformation, "additionalInformation")


  const handleChange = (event) => {
    setGender(event.target.value);
  };



  useEffect(() => {
    if(Vtype === "direct" && handleSearchPatient(patient && patient.mobile))
    setMobile(patient && patient.mobile)
    setfullName(patient && patient.full_name)
    setEmail(patient && patient.email)
    setGender(patient && patient.gender)
    setDob(patient && patient.dob)
    setBloodGroup(patient && patient.blood_group)

  }, [])





  const handleSearchPatient = (event) => {
    // e.preventDefault()
    axios.get(`${process.env.REACT_APP_API_URL}/patient/search/${event}`, {
      headers: {
        "x-auth-token": userdata.token
      }
    })
      .then((res) => {
        console.log(res.data, "redvfgdgfds")
        setfullName(res.data.full_name)
        if (res.data == "") {
          handleToast(true, "Patient Not Found With Provided Phone Number", "error")
          setIspatientFound("patient not found")
        }
        else {
          handleToast(true, "Successfully Fetched Patient Details", "success")
          setIspatientFound("patient found")
          // setIspatientFound(true)
          setFetchedmobile(res && res.data && res.data.mobile)
          setMobile(res && res.data && res.data.mobile)
          setfullName(res && res.data && res.data.full_name)
          setEmail(res && res.data && res.data.email)
          setGender(res && res.data && res.data.gender)
          setDob(res && res.data && res.data.dob)
          setBloodGroup(res && res.data && res.data.blood_group)
          setPatientUuid(res && res.data && res.data.uuid)
        }

      })
      .catch((err) => {
        console.log(err, "err")
        // setPatientFound(true)
      })
  }


 

  const handleChangePhone = (e) => {
    console.log(e, "dsfewvsd")
    const value = e
    const noSpecialCharacters = value.replace(/[^a-zA-Z0-9()]/g, '');
    console.log(noSpecialCharacters, "noSpecialCharacters");

    setMobile(noSpecialCharacters);


  }

  const [fname,setFName]=useState("")
  const [mname,setMName]=useState("")
  const [lname,setLName]=useState("")
  // const [email,setEmail]=useState("")
  // const [dob, setDob]=useState("")
  // const [bloodGroup,setBloodGroup]=useState("")
  const [city , setCity]=useState("Select")
  const [area, setArea]=useState("Select")
  const [doorNo,setDoorNo]=useState("")
  const [street,setStreet]=useState("")
  const [CityId,setCityId]=useState("")
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [SelecctedCityData,setSelecctedCityData]=useState()



  const handleAddPatiient = (e) => {
    e.preventDefault()
    let payload={
    "first_name": fname,
    "last_name": lname,
    "middle_name": mname,
    "blood_group": bloodGroup,
    "created_by_doctor_uuid": "",
    "dob":dob,
    "email": email,
    "full_name": fname+lname,
    "gender": gender,
   "mobile":mobile,
    "residential_Address": {
    "city": CityId, 
    "area": area,
     "door_no": doorNo,
      "street": street
    },
  
  "visited_doctor_uuid": "DR-9BEBE13D"
  
    }
    axios.post(`${process.env.REACT_APP_API_URL}/patient/create/from/doctall/saas`,payload, {
      headers: {
     
        "x-auth-token": userdata.token
      }
    })
      .then((res) => {
        console.log(res.data, "dfdfdfdf")
          // setPatientUuid(res && res.data && res.data.uuid)
          handleToast(true,'' + "New Patient Added Successfully", "success")
          if(res.status==200 || res.status==201){
         
            let payload = {
              "additional_info": additionalInformation,
              "address": address,
              "age": dob,
              "bloodGroup": bloodGroup,
              "doctor_uuid": userdata.uuid,
              "email": email,
              "gender": gender,
              "height": height,
              "temperature" : temperature,
              "mobile": mobile,
              "name": fullName? fullName: fname+lname,
              "patient_uuid": res && res.data && res.data.uuid,
              "reason_for_visit": symptoms,
              "symptoms": symptoms,
              "weight": weight,
        
            }
            axios.post(`${process.env.REACT_APP_API_URL}/externalappointment/addvisit`, payload, {
              headers: {
        
                "x-auth-token": userdata.token
              }
            })
              .then((res) => {
                console.log(res.data, "sdfgdfgdid")
                handleToast(true, "Successfully Submited ", "success")
                // closeDiag()
                setTimeout(() => {
                  closeDiag()
                  handleVisitView(res.data)
                }, 1500);
        
              })
              .catch((err) => {
                console.log(err, "err")
              })
          
        }
      })
      .catch((error) => {
        console.log(error,"erzvxcr")
        handleToast(true, error?error && error.response && error.response.data:null, "error")
      })
  }




  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/allcities`)
        .then((res) => {
          console.log(res,"hfgsdgd");
            setCityList(res.data)
        })
        .catch((err) => {
            console.log(err)
        })
        
  }, [])
  
  useEffect(()=>{
    fetcharea()
  },[city])
  function fetcharea  (){
    axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/getArea/${city}`)
        .then(res => {
            setAreaList(res.data)
            // setArea(res.data[0]._id)
            console.log(area, "area")
            // setLoading(false)
        }).catch(err => {
            console.log(err)
            // setLoading(false)
        })
  }
  
  const handleCityChange = (event) => {
 
    setSelecctedCityData(event && event.target && event.target.value)
  
  }
  useEffect(()=>{
    setCity(SelecctedCityData && SelecctedCityData.uuid)
    setCityId(SelecctedCityData && SelecctedCityData._id)
  },[SelecctedCityData])
  
  // http://test-api.doctall.com/api/v1/patient/search/918374312034






  const handleSbmit = (e) => {
    // e.preventDefault()
    let payload = {
      "additional_info": additionalInformation,
      "address": address,
      "age": dob,
      "bloodGroup": bloodGroup,
      "doctor_uuid": userdata.uuid,
      "email": email,
      "gender": gender,
      "height": height,
      "mobile": Number(mobile),
      "name": fullName? fullName: fname+lname,
      "patient_uuid": patientId,
      "reason_for_visit": symptoms,
      "temperature": temperature,
      "symptoms": symptoms,
      "weight": weight,

    }
    axios.post(`${process.env.REACT_APP_API_URL}/externalappointment/addvisit`, payload, {
      headers: {

        "x-auth-token": userdata.token
      }
    })
      .then((res) => {
        console.log(res.data, "sdfgdfgdid")
        handleToast(true, "Successfully Submited ", "success")
        // closeDiag()
        setTimeout(() => {
          closeDiag()
          handleVisitView(res.data)
        }, 1500);
        
      })
      .catch((err) => {
        console.log(err, "err")
      })
  }

  const handleVisitView = (item) => {
    history.push({
        pathname: "/visit-view",
        state: {
            visitDetails: item,
        },
    });
}

  return (
    <Grid container direction="column" style={{ paddingBottom: "62px" }}>
      <Grid
        item
        container
        direction="column"
        style={{ paddingTop: "17px", paddingLeft: "21px" }}
      >
        <Grid item container justifyContent="space-between">
          <Typography
            style={{
              fontFamily: "Visuelt Pro",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "35px",
              letterSpacing: "0.015em",
              color: "#013C44",
            }}
          >
            Search existing patient appointment 
          </Typography>
          <img
            src={dialogclose}
            alt="close button"
            style={{ marginRight: "26px" }}
            onClick={() => closeDiag()}
          />
        </Grid>
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "12px",
            letterSpacing: "0.015em",
            color: "#00839B",
            marginTop: "6px",
            marginBottom: "6px",
          }}
        >
          Mobile Number <span style={{ color: "red" }}>*</span>
        </Typography>
        <MuiPhoneNumber
          defaultCountry={'ng'}
          onChange={handleChangePhone}
          InputProps={{ disableUnderline: true }}
          className={classes.inputField}
          placeholder="Enter Folio number"
          // value={phoneNewNumber}
          // onChange={ e => setMobile(e)}
          value={patient && patient.mobile}
        />

        {pExist === true ? (
          <Grid container style={{ marginTop: "6px", marginBottom: "26px" }}>
            <img src={checkIcon} alt="check" style={{ marginRight: "8px" }} />
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#08A05F",
              }}
            >
              Patient exist
            </Typography>
          </Grid>
        ) : pExist === false ? (
          <Grid container style={{ marginTop: "6px", marginBottom: "26px" }}>
            <img src={redClose} alt="check" style={{ marginRight: "8px" }} />
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#FF6E4E",
              }}
            >
              Patient exist
            </Typography>
          </Grid>
        ) : (
          <Grid
            style={{ height: "16px", marginTop: "6px", marginBottom: "26px" }}
          ></Grid>
        )}
        <button onClick={() => handleSearchPatient(mobile)} className={classes.searchButton}>Search</button>

      
      </Grid>
      {ispatientFound==="patient found"?

      <>
      <div>
      <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "35px",
            color: "#013C44",
            marginTop: "25px",
            paddingTop: "17px", 
            paddingLeft: "21px" 
          }}
        >
          Add Visit
        </Typography>
      <hr
        style={{
          height: "0.5px",
          borderWidth: "0px",
          width: "308px",
          marginLeft: "10px",
          background: "#00839B",
          borderColor: "#00839B",
        }}
      />
      <Grid
        container
        direction="column"
        style={{
          marginLeft: "10px",
          paddingTop: "12px",
          paddingLeft: "11px",
          paddingBottom: "42px",
          background: "#ECF1FB",
          borderRadius: "8px",
          width: "308px",
          marginBottom: "29px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "35px",
            color: "#013C44",
            marginTop: "25px",
          }}
        >
          Patient Details
        </Typography>
        <hr
          style={{
            height: "0.5px",
            borderWidth: "0px",
            width: "308px",
            marginLeft: "-10px",
            background: "#00839B",
            borderColor: "#00839B",
          }}
        />
        <Typography className={classes.formLabel} align="left">
          Mobile Number
        </Typography>
        <PhoneInput
              className={classes.inputField}
              style={{ color: "#013C44", fontWeight: "500" }}
              country="ng"
              value={Fetchedmobile}
              disabled
            />
        {/* <MuiPhoneNumber
          defaultCountry={'ng'}
          // onChange={handleChangePhone}
          InputProps={{ disableUnderline: true }}
          className={classes.inputField}
          // placeholder="Enter Folio number"
          // value={phoneNewNumber}
          value={Fetchedmobile}
          disabled
        /> */}
        <Typography className={classes.formLabel} align="left">
          Name<span className={classes.mandatory}>*</span>
        </Typography>
        <input
          value={patient && patient.full_name || fullName}
          className={classes.inputField}
          style={{ background: "transparent" }}
          disabled
        />
        <Typography className={classes.formLabel} align="left">
          Email<span className={classes.mandatory}>*</span>
        </Typography>
        <input
          value={patient && patient.email || email}
          className={classes.inputField}
          style={{ background: "transparent" }}
          disabled
        />
        <Grid container>
          <Grid item container direction="column" style={{ width: "88px" }}>
            <Typography className={classes.formLabel} align="left">
              Gender<span className={classes.mandatory}>*</span>
            </Typography>
            <input
              type="text"
              value={gender}
              className={classes.gender}
              style={{ background: "transparent", }}
              disabled
            // onChange={ e => setDob(e.target.value)}
            />
            {/* <Select
              value={gender}
              onChange={handleChange}
              className={classes.gender}
              disableUnderline={true}
            >
              <MenuItem value="male">male</MenuItem>
              <MenuItem value="female">female</MenuItem>
              <MenuItem value="others">others</MenuItem>
            </Select> */}
          </Grid>
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "6px", width: "103px" }}
          >
            <Typography className={classes.formLabel} align="left">
              DOB<span className={classes.mandatory}>*</span>
            </Typography>
            {/* <input
            value={patient.dob}
              className={classes.gender}
              style={{ background: "transparent", width: "103px" }}
            /> */}
            <input
              type="date"
              value={ `${moment(dob).format('YYYY-MM-DD')}`}
              // {visit && visit.age ? `${moment(dob).format('DD/MM/YYYY')}` : "NA"}
              className={classes.gender}
              style={{ background: "transparent", width: "103px" }}
              disabled
              // onChange={e => setDob(e.target.value)}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            style={{ marginLeft: "6px", width: "83px" }}
          >
            <Typography className={classes.formLabel} align="left">
              BloodGroup
            </Typography>
            {/* <Select
              value={gender}
              onChange={handleChange}
              className={classes.gender}
              disableUnderline={true}
              style={{ width: "83px" }}
            >
              <MenuItem value="male">male</MenuItem>
              <MenuItem value="female">female</MenuItem>
              <MenuItem value="others">others</MenuItem>
            </Select> */}
            <input
              type="text"
              value={bloodGroup}
              className={classes.gender}
              style={{ background: "transparent", }}
            // onChange={ e => setDob(e.target.value)}
            disabled
            />
            {/* <Select
              value={bloodGroup}
              onChange={e => setBloodGroup(e.target.value)}
              className={classes.gender}
              disableUnderline={true}
              style={{ width: "83px" }}
            >
              <MenuItem value="">Select..</MenuItem>
              <MenuItem value="Unknown">Unknown</MenuItem>
              <MenuItem value="A+">A+</MenuItem>
              <MenuItem value="A-">A-</MenuItem>
              <MenuItem value="B+">B+</MenuItem>
              <MenuItem value="B-">B-</MenuItem>
              <MenuItem value="O+">O+</MenuItem>
              <MenuItem value="O-">O-</MenuItem>
              <MenuItem value="AB+">AB+</MenuItem>
              <MenuItem value="female">AB-</MenuItem>


            </Select> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        style={{
          marginLeft: "10px",
          paddingTop: "12px",
          paddingLeft: "16px",
          paddingBottom: "53px",
          background: "#ECF1FB",
          borderRadius: "8px",
          width: "308px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "35px",
            color: "#013C44",
            marginTop: "25px",
          }}
        >
          Visit Details
        </Typography>
        <hr
          style={{
            height: "0.5px",
            borderWidth: "0px",
            width: "308px",
            marginLeft: "-10px",
            background: "#00839B",
            borderColor: "#00839B",
          }}
        />
        <Grid container>
          <Grid item container style={{ width: "135px" }}>
            <Typography className={classes.formLabel} align="left">
              Symptoms<span style={{ color: "red" }}>*</span>
            </Typography>

            <OutlinedInput
              onChange={e => setSymptoms(e.target.value)}

              className={classes.inputField}
              style={{ background: "transparent", width: "135px" }}
            // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            />
          </Grid>
          <Grid item container style={{ width: "135px", marginLeft: "6px" }}>
            <Typography className={classes.formLabel} align="left">
              Temperature
            </Typography>
            <OutlinedInput
              onChange={e => setTemperature(e.target.value)}
              className={classes.inputField}
              style={{ background: "transparent", width: "135px" }}
              endAdornment={<InputAdornment position="end">C</InputAdornment>}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item container style={{ width: "135px" }}>
            <Typography className={classes.formLabel} align="left">
              Weight
            </Typography>
            <OutlinedInput
              onChange={e => setWeight(e.target.value)}
              className={classes.inputField}
              style={{ background: "transparent", width: "135px" }}
              endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            />
          </Grid>
          <Grid item container style={{ width: "135px", marginLeft: "6px" }}>
            <Typography className={classes.formLabel} align="left">
              Height
            </Typography>
            <OutlinedInput
              onChange={e => setHeight(e.target.value)}
              className={classes.inputField}
              style={{ background: "transparent", width: "135px" }}
              endAdornment={<InputAdornment position="end">Cm</InputAdornment>}
            />
          </Grid>
        </Grid>
        <Typography className={classes.formLabel} align="left">
          Chief Compliant<span className={classes.mandatory}>*</span>
        </Typography>
        <textarea
          onChange={e => setChiefComplaint(e.target.value)}
          className={classes.textArea}
          style={{ height: "82px", background: "transparent" }}
        />
        <Typography className={classes.formLabel} align="left">
          Address
        </Typography>
        <textarea
          onChange={e => setAddress(e.target.value)}
          className={classes.textArea}
          style={{ height: "82px", background: "transparent" }}
        />
        <Typography className={classes.formLabel} align="left">
          Additional Information
        </Typography>
        <input
          onChange={e => setAdditionalInformation(e.target.value)}
          className={classes.inputField}
          style={{ background: "transparent" }}
        />
        <button
          onClick={handleSbmit}
          // className={classes.disabledButton}
          style={{ marginTop: "22px" }}
          className={classes.searchButton}
        >
          Submit
        </button>
      </Grid>
      </div>
      </>
      :ispatientFound==="patient not found"?
      <>
      <Grid
        container
        direction="column"
        style={{
          marginTop: "30px",
          marginLeft: "10px",
          paddingTop: "12px",
          paddingLeft: "16px",
          paddingBottom: "53px",
          background: "#ECF1FB",
          borderRadius: "8px",
          width: "308px",
        }}
      >
      <div>
      <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "35px",
            color: "#013C44",
            marginTop: "25px",
            paddingTop: "17px", 
            paddingLeft: "21px" 
          }}
        >
          Add Patient
        </Typography>
      <hr
        style={{
          height: "0.5px",
          borderWidth: "0px",
          width: "275px",
          marginLeft: "0px",
          background: "#00839B",
          borderColor: "#00839B",
        }}
      />
    


      <form onSubmit={handleAddPatiient}>
      <Typography className={classes.formLabel} align="left">
        Mobile Number
      </Typography>
      <PhoneInput
              className={classes.inputField}
              style={{ color: "#013C44", fontWeight: "500" }}
              onChange={handleChangePhone}
              country="ng"
              value={mobile}
            />

      {/* <MuiPhoneNumber 
defaultCountry={'ng'} 
onChange={handleChangePhone}
InputProps={{ disableUnderline: true }}
className={classes.inputField}
                placeholder="Enter Folio number"
                // value={phoneNewNumber}
                value={mobile}
/> */}
      <Typography className={classes.formLabel} align="left">
        First Name<span className={classes.mandatory}>*</span>
      </Typography>
      <input
      value={fname}
        className={classes.inputField}
        style={{ background: "transparent" }}
        onChange={ e => setFName(e.target.value)}
      />
      <Typography className={classes.formLabel} align="left">
        Middle Name
      </Typography>
      <input
      value={mname}
        className={classes.inputField}
        style={{ background: "transparent" }}
        onChange={ e => setMName(e.target.value)}
      />
      <Typography className={classes.formLabel} align="left">
        Last Name<span className={classes.mandatory}>*</span>
      </Typography>
      <input
      value={lname}
        className={classes.inputField}
        style={{ background: "transparent" }}
        onChange={ e => setLName(e.target.value)}
      />
      <Typography className={classes.formLabel} align="left">
        Email<span className={classes.mandatory}>*</span>
      </Typography>
      <input
      value={email}
        className={classes.inputField}
        style={{ background: "transparent" }}
        onChange={ e => setEmail(e.target.value)}
      />
      <Grid container>
        <Grid item container direction="column" style={{ width: "88px" }}>
          <Typography className={classes.formLabel} align="left">
            Gender<span className={classes.mandatory}>*</span>
          </Typography>

          <Select
            value={gender}
            onChange={handleChange}
            className={classes.gender}
            disableUnderline={true}
            style={{background: "#ECF1FB"}}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="others">Others</MenuItem>
          </Select>
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{ marginLeft: "6px", width: "103px" }}
        >
          <Typography className={classes.formLabel} align="left">
            DOB<span className={classes.mandatory}>*</span>
          </Typography>
          <input
          type="date"
          value={dob}
            className={classes.gender}
            style={{ background: "transparent", width: "103px" }}
            onChange={ e => setDob(e.target.value)}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{ marginLeft: "6px", width: "83px" }}
        >
          <Typography className={classes.formLabel} align="left">
            BloodGroup<span style={{color:"red"}}>*</span>
          </Typography>
          <Select
            value={bloodGroup}
            onChange={ e => setBloodGroup(e.target.value)}
            className={classes.gender}
            disableUnderline={true}
            style={{ width: "83px" ,background: "#ECF1FB"}}
          >
          <MenuItem value="">Select..</MenuItem>
          <MenuItem value="Unknown">Unknown</MenuItem>
            <MenuItem value="A+">A+</MenuItem>
            <MenuItem value="A-">A-</MenuItem>
            <MenuItem value="B+">B+</MenuItem>
            <MenuItem value="B-">B-</MenuItem> 
            <MenuItem value="O+">O+</MenuItem>
            <MenuItem value="O-">O-</MenuItem>
            <MenuItem value="AB+">AB+</MenuItem>
            <MenuItem value="female">AB-</MenuItem>
            
            
          </Select>
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{ marginLeft: "6px", width: "120px" }}
        >
          <Typography className={classes.formLabel} align="left">
            City
          </Typography>
          <Select
            value={city}
            // onChange={ e => setCity(e.target.value)}
             onClick={(event) => handleCityChange(event)}
            className={classes.gender}
            disableUnderline={true}
            style={{ width: "120px", background: "#ECF1FB",}}
          >
          <MenuItem value="Select">Select..</MenuItem>
          {cityList.map((city)=>{
           return <MenuItem value={city}>{city.name}</MenuItem>
          })}
          
          
            
            
          </Select>
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{ marginLeft: "6px", width: "120px" }}
        >
          <Typography className={classes.formLabel} align="left">
            Area
          </Typography>
          <Select
            value={area}
            onChange={ e => setArea(e.target.value)}
            className={classes.gender}
            disableUnderline={true}
            // style={{ width: "83px" }}
            style={{ width: "120px", background: "#ECF1FB",}}
          >
          <MenuItem value="Select">Select..</MenuItem>
          {areaList.map((area)=>{
           return <MenuItem value={area._id}>{area.name}</MenuItem>
          })}
          
          </Select>
        </Grid>





      </Grid>
      
      <Grid container>
        {/* <Grid item container style={{ width: "135px" }}>
          <Typography className={classes.formLabel} align="left">
            City
          </Typography>
          <input
          value={city}
           onChange={ e => setCity(e.target.value)}
            className={classes.inputField}
            style={{ background: "transparent", width: "135px" }}
            
          />
        </Grid> */}
        {/* <Grid item container style={{ width: "135px", marginLeft: "6px" }}>
          <Typography className={classes.formLabel} align="left">
            Area
          </Typography>
          <input
          value={area}
           onChange={ e => setArea(e.target.value)}
            className={classes.inputField}
            style={{ background: "transparent", width: "135px" }}
          />
        </Grid> */}
      </Grid>
      <Grid container>
        <Grid item container style={{ width: "76px" }}>
          <Typography className={classes.formLabel} align="left">
            Door No
          </Typography>
          <input
          value={doorNo}
           onChange={ e => setDoorNo(e.target.value)}
            className={classes.inputField}
            style={{ background: "transparent", width: "76px" }}
          />
        </Grid>
        <Grid item container style={{ width: "204px", marginLeft: "6px" }}>
          <Typography className={classes.formLabel} align="left">
            Street
          </Typography>
          <input
          value={street}
           onChange={ e => setStreet(e.target.value)}
            className={classes.inputField}
            style={{ background: "transparent", width: "204px" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        // style={{
        //   marginLeft: "10px",
        //   paddingTop: "12px",
        //   paddingLeft: "16px",
        //   paddingBottom: "53px",
        //   background: "#ECF1FB",
        //   borderRadius: "8px",
        //   width: "308px",
        // }}
      >
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "35px",
            color: "#013C44",
            marginTop: "25px",
          }}
        >
          Visit Details
        </Typography>
        <hr
          style={{
            height: "0.5px",
            borderWidth: "0px",
            width: "290px",
            marginLeft: "-10px",
            background: "#00839B",
            borderColor: "#00839B",
          }}
        />
        <Grid container>
          <Grid item container style={{ width: "135px" }}>
            <Typography className={classes.formLabel} align="left">
              Symptoms<span style={{ color: "red" }}>*</span>
            </Typography>

            <OutlinedInput
              onChange={e => setSymptoms(e.target.value)}

              className={classes.inputField}
              style={{ background: "transparent", width: "135px" }}
            // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            />
          </Grid>
          <Grid item container style={{ width: "135px", marginLeft: "6px" }}>
            <Typography className={classes.formLabel} align="left">
              Temperature
            </Typography>
            <OutlinedInput
              onChange={e => setTemperature(e.target.value)}
              className={classes.inputField}
              style={{ background: "transparent", width: "135px" }}
              endAdornment={<InputAdornment position="end">C</InputAdornment>}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item container style={{ width: "135px" }}>
            <Typography className={classes.formLabel} align="left">
              Weight
            </Typography>
            <OutlinedInput
              onChange={e => setWeight(e.target.value)}
              className={classes.inputField}
              style={{ background: "transparent", width: "135px" }}
              endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            />
          </Grid>
          <Grid item container style={{ width: "135px", marginLeft: "6px" }}>
            <Typography className={classes.formLabel} align="left">
              Height
            </Typography>
            <OutlinedInput
              onChange={e => setHeight(e.target.value)}
              className={classes.inputField}
              style={{ background: "transparent", width: "135px" }}
              endAdornment={<InputAdornment position="end">Cm</InputAdornment>}
            />
          </Grid>
        </Grid>
        <Typography className={classes.formLabel} align="left">
          Chief Compliant<span className={classes.mandatory}>*</span>
        </Typography>
        <textarea
          onChange={e => setChiefComplaint(e.target.value)}
          className={classes.textArea}
          style={{ height: "82px", background: "transparent" }}
        />
        <Typography className={classes.formLabel} align="left">
          Address
        </Typography>
        <textarea
          onChange={e => setAddress(e.target.value)}
          className={classes.textArea}
          style={{ height: "82px", background: "transparent" }}
        />
        <Typography className={classes.formLabel} align="left">
          Additional Information
        </Typography>
        <input
          onChange={e => setAdditionalInformation(e.target.value)}
          className={classes.inputField}
          style={{ background: "transparent" }}
        />
        {/* <button
          onClick={handleSbmit}
          // className={classes.disabledButton}
          style={{ marginTop: "22px" }}
          className={classes.searchButton}
        >
          Submit
        </button> */}
      </Grid>












      <button
        className={classes.searchButton}
        style={{ marginTop: "38px", marginBottom: "29px" }}
        type="submit"
      >
        Submit
      </button>
      </form>
      {/* <Grid
        container
        direction="column"
        style={{
          marginLeft: "10px",
          paddingTop: "12px",
          paddingLeft: "16px",
          paddingBottom: "53px",
          background: "#ECF1FB",
          borderRadius: "8px",
          width: "308px",
        }}
      >

        <button
          onClick={handleSbmit}
          // className={classes.disabledButton}
          style={{ marginTop: "22px" }}
          className={classes.searchButton}
        >
          Submit
        </button>
      </Grid> */}
      </div>
      </Grid>
      </>
      :null
      }
     
      <Toast
        text={toastMessage}
        type={toastType}
        open={showToast}
        setClose={() => {
          setToast(false);
        }}
      />
    </Grid>
  );
};

export default DiagnosticPres;
