import React, { useEffect, useState } from "react";
import { SwipeableDrawer } from "@mui/material";
import ICON1 from "../assets/images/icon1.svg";
import ICON11 from "../assets/images/Vector.svg";
import ICON2 from "../assets/images/icon2.svg";
import ICON3 from "../assets/images/icon3.svg";
import ICON9 from "../assets/images/Orders.png";
import ICON99 from "../assets/images/Order.png";
import Visit from "../assets/images/Visit.png";
import Pt from "../assets/images/Patient.png";
import Logo from "../assets/images/logo.svg";
import Down from "../assets/images/DArrow.svg";
import Profile from "../assets/images/profile.svg";
import Notify from "../assets/images/notify.svg";
import Chap from "../assets/images/ChapImage.svg";
import hamburger from "../assets/images/hamburger.svg";
import Chat from "../assets/images/chat.svg";
import Search from "../assets/images/search.svg";
import notification from "../assets/images/notification.svg";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useOnClickOutside from "./doctorDashboard/OutClickhandler";
import { Logout } from "../redux/actions/loginAction";
import moment from "moment";
import Ellipse from "../assets/images/Ellipse.svg";
import Ellipse2 from "../assets/images/Ellipse2.svg";
import doc1 from "../assets/images/doc1.svg";
import { getNotifyDR } from "../redux/actions/cockpitAction";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getNotifyPopupScreen,
  getMedPrescription,
} from "../redux/actions/cockpitAction";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { Tooltip, Button } from "antd";
import { getDoctorDetails } from "../redux/actions/TestAction";
import { pt } from "date-fns/locale";
import { Grid } from "@material-ui/core";
import close from "../assets/images/cross_icon.svg";
import Order from "../assets/images/OrderIcon.png";
import PatientIcon from "../assets/images/PatientIcon.png";
import AppointIcon from "../assets/images/AppointIcon.png";
import Patient from "../assets/images/See Patients.svg";
import Appoint from "../assets/images/See Appointments.svg";
import Orders from "../assets/images/Order Conversions.svg";
import logoD from "../assets/images/logoD.svg";
import profileImg from "../assets/images/profileImg.svg";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Dot from "../../src/assets/images/notification_dote.svg";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import styles from "./style";
import MobNotification from "./MobNotification";
import DialogLayout from "../doctor-dashboard/mobileComp/components/DialogLayout";
import dialogclose from "../assets/images/DialogClose.svg";

const useStyles = makeStyles((theme) => styles(theme));

const Layout = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const ref = React.useRef();
  const dispatch = useDispatch();
  let history = useHistory();
  const [timepop, setTimePop] = React.useState(false);
  const [drop, setDrop] = useState(false);
  const [notifylist, setnotifylist] = useState([]);
  const [notifyPopupScreen, setNotifyPopupScreen] = useState(false);
  const [notificationPopup, setNotificationPopup] = useState(false);
  const userdata = useSelector((state) => state.loginReducer.payload);
  const nofityDr = useSelector((state) => state.notifyDrReducer);
  const GetNotify = useSelector((state) =>
    state.getNotifyPopupScreen != "" &&
    state.getNotifyPopupScreen.payload != undefined &&
    state.getNotifyPopupScreen.payload.length > 0
      ? state.getNotifyPopupScreen.payload
      : []
  );
  const [notificationStatus, setNotificationStatus] = useState(true);
  const [GetNotifyFilter, setGetNotifyFilter] = useState([]);

  const [item1, setItem1] = useState(false);
  const [item2, setItem2] = useState(false);
  const [item3, setItem3] = useState(false);
  const [item4, setItem4] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [mobNotificationOpen, setMobNotificationOpen] = React.useState(false);
  console.log(mobNotificationOpen, "mobNotificationOpen");
  const MobNotificationOpen = () => {
    setMobNotificationOpen(true);
  };

  const MobNotificationClocse = () => {
    setMobNotificationOpen(false);
  };

  const [tempdata, setTempdata] = useState([]);
  let newdata = [];
  let getData = (title, createdAt, message) => {
    newdata = [title, createdAt, message];

    setTempdata(newdata);
    console.log("new data", tempdata);
  };

  const [drawerOpen, setDrawerOpen] = useState(false);

  useOnClickOutside(ref, () => {
    setNotificationPopup(false);
    setNotifyPopupScreen(false);
  });

  useEffect(() => {
    setInterval(function () {
      axios
        .get(
          process.env.REACT_APP_API_URL + `/notification/list/${userdata.uuid}`,
          {
            headers: {
              "x-auth-token": userdata.token,
            },
          }
        )
        .then((res) => {
          setnotifylist(res.data);
          console.log(res.data, "resdfsdfsd");
        })
        .catch((err) => {
          setnotifylist([]);
        });
    }, 30000);
  }, []);

  const handleCountData = () => {
    let filterData = [];
    for (let x of notifylist) {
      filterData.push(x._id);
    }

    const data = JSON.stringify({ id: filterData, status: "read" });

    var config = {
      method: "put",
      url: process.env.REACT_APP_API_URL + "/notification/status/all",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        // console.log(error);
      });
  };

  var filterCount = notifylist.filter((item) => {
    if (item.status == "unread") return item;
  });
  console.log("sdfsf", notifylist);

  const [doctorData, setDoctorData] = useState("");
  console.log(doctorData, "doctorData");
  console.log(
    "resp && resp.data && resp.data.data &&  resp.data.data[0] &&  resp.data.data[0].profile_pic"
  );
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL + "/user/DoctorDetails/" + userdata.uuid
      )
      .then((resp) => {
        setDoctorData(resp && resp.data && resp.data.data && resp.data.data[0]);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, []);

  const HOME = () => {
    setItem1(true);
    setItem2(false);
    setItem3(false);
    setItem4(false);
    history.push("/doctor-dashboard");
  };
  const PATIENTS = () => {
    setItem1(false);
    setItem2(true);
    setItem3(false);
    setItem4(false);
    history.push("/patients");
  };
  const VISITS = () => {
    setItem1(false);
    setItem2(false);
    setItem3(true);
    setItem4(false);
    history.push("/visits");
  };
  const ORDERS = () => {
    setItem1(false);
    setItem2(false);
    setItem3(false);
    setItem4(true);
    console.log(item1, item2, item3, item4);
    history.push("/orders");
  };
  const ORDERS1 = () => {
    history.push("/orders");
  };

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        //classes={{ paper: classes.drawer }}
        style={{ width: "75%" }}
      >
        <div className="ext_navbar_mob">
          <Link to="/doctor-dashboard">
            <img
              src={logoD}
              alt="logo"
              className="logoD"
              style={{
                width: "17px",
                height: "22px",
                marginBottom: "52px",
                marginLeft: "3px",
              }}
            />
          </Link>
          <Tooltip placement="topLeft" title="Home">
            <div
              className={
                history.location.pathname.indexOf("doctor-dashboard") > -1
                  ? "navLinkSelected"
                  : "navLink"
              }
              onClick={HOME}
            >
              {/* <img
                alt=""
                className={
                  history.location.pathname.indexOf("doctor-dashboard") > -1
                    ? "navimageselection"
                    : "navimage"
                }
                src={
                  history.location.pathname.indexOf("doctor-dashboard") > -1
                    ? ICON1
                    : ICON11
                }
                crossorigin="anonymous"
                style={{ marginRight: "16px" }}
              /> */}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginLeft: "12px",
                  marginRight: "16px",
                }}
              >
                <path d="M21.7764 24H17.2022C15.9744 24 14.9786 22.9647 14.9786 21.6868V14.9624C14.9786 14.5517 14.6586 14.2188 14.2638 14.2188H9.78143C9.38663 14.2188 9.06664 14.5517 9.06664 14.9624V21.7385C9.06664 22.9876 8.09346 24 6.89271 24H2.2236C0.99518 24 0 22.9647 0 21.6868V10.0222C0 9.29818 0.32565 8.61606 0.880134 8.17919L10.6565 0.467934C11.4474 -0.155978 12.552 -0.155978 13.3435 0.467934L23.1199 8.17919C23.6712 8.6141 24 9.30276 24 10.0222V15.8323C24 16.2659 23.6624 16.617 23.2456 16.617C22.8288 16.617 22.4912 16.2659 22.4912 15.8323V10.0222C22.4912 9.79064 22.3856 9.56959 22.2083 9.42963L12.4319 1.71903C12.1779 1.51825 11.8227 1.51825 11.5681 1.71903L1.7917 9.43029C1.61442 9.57024 1.5088 9.79129 1.5088 10.0228V21.6868C1.5088 22.0975 1.82879 22.4304 2.2236 22.4304H6.89396C7.26111 22.4304 7.55847 22.1211 7.55847 21.7391V14.9624C7.55847 13.6852 8.55365 12.6493 9.78206 12.6493H14.2645C15.4922 12.6493 16.4881 13.6845 16.4881 14.9624V21.6868C16.4881 22.0975 16.808 22.4304 17.2029 22.4304H21.777C22.1718 22.4304 22.4918 22.0975 22.4918 21.6868C22.4918 21.2663 22.8194 20.9256 23.2236 20.9256H23.2682C23.6725 20.9256 24 21.2663 24 21.6868C23.9994 22.9647 23.0042 24 21.7764 24Z" />
              </svg>
              <span> Home</span>{" "}
            </div>
          </Tooltip>
          <Tooltip placement="topLeft" title="See Patient">
            <div
              className={
                history.location.pathname.indexOf("patients") > -1
                  ? "navLinkSelected"
                  : "navLink"
              }
              onClick={PATIENTS}
            >
              {/* <img
                alt=""
                className={
                  history.location.pathname.indexOf("patients") > -1
                    ? "navimageselection"
                    : "navimage"
                }
                src={
                  history.location.pathname.indexOf("patients") > -1
                    ? Patient
                    : PatientIcon
                }
                crossorigin="anonymous"
                style={{ marginRight: "16px" }}
              /> */}

              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginLeft: "12px",
                  marginRight: "16px",
                  fill: "none",
                }}
              >
                <path
                  d="M13.0127 10.7304C14.8831 10.7304 16.3993 9.21412 16.3993 7.34371C16.3993 5.4733 14.8831 3.95703 13.0127 3.95703C11.1422 3.95703 9.62598 5.4733 9.62598 7.34371C9.62598 9.21412 11.1422 10.7304 13.0127 10.7304Z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.6479 17.2324C19.8746 17.8395 20.0124 18.4761 20.0573 19.1225"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.54443 19.1222C6.6404 17.7693 7.1403 16.4764 7.97942 15.4109C8.81854 14.3453 9.95823 13.5562 11.2509 13.1458C12.5436 12.7353 13.9297 12.7224 15.2299 13.1086C16.53 13.4949 17.6842 14.2626 18.5431 15.3123"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.8087 24.0005C16.2924 24.6626 14.6553 25.003 13.0008 25C6.37335 25 1 19.6289 1 13.0008C1 6.37278 6.37335 1 13.0008 1C19.6283 1 25 6.37335 25 13.0008C25.0029 15.0097 24.4994 16.9869 23.5359 18.7497"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span>View Patient</span>
            </div>
          </Tooltip>
          <Tooltip placement="topLeft" title="See Appointment">
            <div
              className={
                history.location.pathname.indexOf("visits") > -1
                  ? "navLinkSelected"
                  : "navLink"
              }
              onClick={VISITS}
            >
              {/* <img
                alt=""
                className={
                  history.location.pathname.indexOf("visits") > -1
                    ? "navimageselection"
                    : "navimage"
                }
                src={
                  history.location.pathname.indexOf("visits") > -1
                    ? Appoint
                    : AppointIcon
                }
                crossorigin="anonymous"
                style={{ marginRight: "16px" }}
              /> */}
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginLeft: "12px",
                  marginRight: "16px",
                  fill: "none",
                }}
              >
                <path
                  d="M11.3828 8.71289V12.9978L14.6164 16.2311"
                  stroke-width="1.07638"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.6831 18.6553L21.4852 21.4855C20.3722 22.6015 19.0495 23.4864 17.5932 24.0896C16.137 24.6927 14.5759 25.0021 12.9997 25C6.37275 25 1 19.6277 1 13.0006C1 6.37358 6.37275 1 12.9997 1C19.6266 1 25 6.37232 25 12.9987C25.0024 14.7019 24.641 16.386 23.9402 17.9384"
                  stroke-width="1.07638"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.14927 5.96572C10.8206 5.05228 12.7627 4.76424 14.6272 5.15326C16.4917 5.54229 18.1566 6.58292 19.3231 8.08846C20.4896 9.59399 21.0814 11.4659 20.9923 13.3683C20.9033 15.2708 20.1392 17.0792 18.8371 18.4692C17.5351 19.8592 15.7803 20.7398 13.8876 20.9529C11.9949 21.1661 10.0882 20.6979 8.5095 19.6323C6.93084 18.5668 5.78357 16.9736 5.27351 15.1387C4.76346 13.3037 4.92401 11.3471 5.72636 9.6198"
                  stroke-width="1.07638"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span>View Visits</span>
            </div>
          </Tooltip>
          <Tooltip placement="topLeft" title="Order Conversions">
            <div
              className={
                history.location.pathname.indexOf("orders") > -1
                  ? "navLinkSelected"
                  : "navLink"
              }
              onClick={ORDERS}
            >
              {/* <img
                alt=""
                className={
                  history.location.pathname.indexOf("orders") > -1
                    ? "navimageselection"
                    : "navimage"
                }
                src={
                  history.location.pathname.indexOf("orders") > -1
                    ? Orders
                    : Order
                }
                crossorigin="anonymous"
                style={{ marginRight: "16px" }}
              /> */}

              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginLeft: "12px",
                  marginRight: "16px",
                  fill: "none",
                }}
              >
                <path
                  d="M25.9165 18.6777C23.1761 20.3681 20.0198 21.2632 16.8 21.2632C13.5803 21.2632 10.4239 20.3681 7.68359 18.6777"
                  stroke-width="0.987776"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.2619 9.26306C3.95964 9.26306 4.52527 8.69743 4.52527 7.99969C4.52527 7.30196 3.95964 6.73633 3.2619 6.73633C2.56416 6.73633 1.99854 7.30196 1.99854 7.99969C1.99854 8.69743 2.56416 9.26306 3.2619 9.26306Z"
                  stroke-width="0.958143"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.2619 14.9467C3.95964 14.9467 4.52527 14.381 4.52527 13.6833C4.52527 12.9856 3.95964 12.4199 3.2619 12.4199C2.56416 12.4199 1.99854 12.9856 1.99854 13.6833C1.99854 14.381 2.56416 14.9467 3.2619 14.9467Z"
                  stroke-width="0.958143"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.2619 20.6322C3.95964 20.6322 4.52527 20.0666 4.52527 19.3688C4.52527 18.6711 3.95964 18.1055 3.2619 18.1055C2.56416 18.1055 1.99854 18.6711 1.99854 19.3688C1.99854 20.0666 2.56416 20.6322 3.2619 20.6322Z"
                  stroke-width="0.958143"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.68359 7.99805H26.0014"
                  stroke-width="0.958143"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.68359 13.6836H26.0014"
                  stroke-width="0.958143"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span>View Orders</span>
            </div>
          </Tooltip>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
  return (
    <div>
      {drawerOpen ? drawer : null}

      <Grid item container direction="column">
        <Grid item container className={classes.header} alignItems="center">
          <Grid item container style={{ paddingLeft: "18px" }} xs={6}>
            <img
              src={hamburger}
              alt="hamburger"
              style={{ marginRight: "22px" }}
              className="hamburger"
              onClick={() => setDrawerOpen(true)}
            />
            <Link to="/doctor-dashboard">
              <img
                className={classes.headerLogo}
                src={Logo}
                alt="logo"
                crossorigin="anonymous"
              />
            </Link>
          </Grid>
          <Grid
            item
            container
            xs={6}
            justifyContent="space-around"
            alignItems="center"
            style={{ paddingRight: "18px" }}
          >
            <img
              src={notification}
              alt="notification"
              // style={{ marginRight: "22px" }}
              //className="hamburger"
              onClick={MobNotificationOpen}
            />
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                textAlign: "right",
                color: "#00839B",
                width: "68px",
                lineHeight: "18px",
                fontFamily: "Greycliff CF",
              }}
            >
              {/* {doctorData.full_name} */}

              {doctorData.firstName}
              <br />
              {doctorData.lastName}
            </Typography>
            <div
              className="layoutsession"
              ref={ref}
              onClick={() => {
                setTimePop(!timepop);
              }}
            >
              <img
                src={doctorData && doctorData.profile_pic}
                alt="profile"
                style={{
                  borderRadius: "50%",
                  cursor: "pointer",
                  width: "60px",
                }}
                onClick={() => setTimePop(!timepop)}
              />
              {timepop ? (
                <div className="popUp">
                  <div
                    className="contentpopup"
                    onClick={() => history.push("/doctor-profile")}
                  >
                    Profile
                  </div>
                  <div
                    className="contentpopup"
                    onClick={() => {
                      Logout();
                      history.push("/login");
                    }}
                  >
                    Logout
                  </div>
                </div>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        {props.children}
      </Grid>

      {/* <div>
      
      <Dialog open={mobNotificationOpen} onClose={MobNotificationClocse} sx={{width:"312px", marginLeft:"154px"}}>
      
        <DialogActions>
          <Button onClick={MobNotificationClocse}>Cancel</Button>
          <Button onClick={MobNotificationClocse}>Subscribe</Button>
        </DialogActions>
      </Dialog>
    </div> */}

      {mobNotificationOpen ? (
        <DialogLayout
          open={mobNotificationOpen}
          // style={{width:"300px"}}
        >
          <Grid item container>
            <Grid md={11} sm={11} xs={11}>
              <h1 className="notification_heading" style={{ fontSize: "20px" }}>
                Notification{" "}
                <spam className="new_notification">
                  {filterCount.length} new
                </spam>
              </h1>
            </Grid>
            <Grid md={1} sm={1} xs={1}>
              <div onClick={MobNotificationClocse}>
                <img src={dialogclose} />
              </div>
            </Grid>
          </Grid>

          <div
          // className="notificationPopup NotifyPopupScreen"
          // style={{ right: "0px", top: "58px", zIndex: "1000" }}
          >
            {notifylist.map((value, index) => (
              <div className="notificationList">
                <div style={{ display: "flex" }} key={index}>
                  <div className="NOTIFY_POPUP_GREEN_DESIGN"></div>
                  <div style={{ width: "100%", height: "auto" }}>
                    <div className="NOTIFY_POPUP_PRIMARY_TEXT">
                      {value.message}
                    </div>
                    <div className="NOTIFY_POPUP_DATE">
                      {moment(value.createdAt).format("ddd, DD MMM YYYY")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <MobNotification closeNotification={() => MobNotificationClocse(false)}/> */}
          {/* <AddPatientMob closePat={() => MobNotificationClocse(false)} /> */}
        </DialogLayout>
      ) : null}
    </div>
  );
};

export default Layout;
