import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyle } from '../../Admin/style/style'
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import Toast from '../../components/Toast';
import MuiPhoneNumber from 'material-ui-phone-number';
import { addPatient, editPatient } from '../services/ServiceHelper';
import { getPatients } from '../../redux/actions/ex-appointment-action';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import "./Addpatient.scss"

export default function AddPatientComponent(props) {
    const authState = useSelector((state) => state.loginReducer.payload);
    console.log(props)
    const dispatch = useDispatch();
    const { id, close, details } = props;
    const classes = useStyle();
    const [editFlag, setEditFlag] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);

    const [cityList, setCityList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [city, setCity] = useState()
    const [area, setArea] = useState()
    const [door, setDoor] = useState()
    const [pin, setPin] = useState()
    const [street, setStreet] = useState()
    const [dob, setDob] = useState()
    const [citytosend, setCitytoSend] = useState()
    const [defaultCityId, setDefaultcityid] = useState()
    console.log(defaultCityId,"defaultCityId")
    const handleClose = () => {
        props.close(true)
    };

    ;

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/allcities`)
            .then((res) => {
                setCityList(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])
    let addressDetails = details.residential_Address[0]
    useEffect(() => {
        cityList.map((item) => {
            if (item._id == addressDetails.city) {
                setDefaultcityid(item.uuid)
                fetcharea(item.uuid)
            }
        })
    }, [cityList])
    function fetcharea(cityId) {
        console.log(cityId, "city")
        axios.get(`${process.env.REACT_APP_API_URL}/uploadDoc/getArea/${cityId}`)
            .then(res => {
                setAreaList(res.data)

                // setLoading(false)
            }).catch(err => {
                console.log(err)
                // setLoading(false)
            })
    }


    const handleCityChange = (event) => {
        setCity(event && event.target && event.target.value)
        setDefaultcityid(event && event.target && event.target.value)
        cityList.map((item) => {
            if (item.uuid === event.target.value) {
                console.log(item._id)
                setCitytoSend(item._id)
            }
        })
        console.log(event.target)
        fetcharea(event && event.target && event.target.value)

        // console.log(event.target.value.id,"555")
    }
    const handleCityChange1 = (event) => {
        console.log(event.target.value)
        setCity(event && event.target && event.target.value)
        cityList.map((item) => {
            if (item.uuid === event.target.value) {
                console.log(item._id)
                setCitytoSend(item._id)
            }
        })


        console.log(event.target)
        fetcharea(event && event.target && event.target.value)
        console.log(event.target, "555")
        // console.log(event.target.value.id,"555")
    }
    const handleAreaChange = (event) => {
        setArea(event && event.target && event.target.value)
        console.log(event && event.target && event.target.value, "555")
    }
    const handleDoorChange = (event) => {
        setDoor(event && event.target && event.target.value)
        console.log(event && event.target && event.target.value, "door")
    }
    const handlePinChange = (event) => {
        setPin(event && event.target && event.target.value)
        console.log(event && event.target && event.target.value, "555")
    }
    const handleStreetChange = (event) => {
        setStreet(event && event.target && event.target.value)
        console.log(event && event.target && event.target.value, "555")
    }

    const handleDobChange = (event) => {
        setDob(event && event.target && event.target.value)
        console.log(event.target.value, "555")
    }




    useEffect(() => {
        if (id == 'Add') {
            setEditFlag(true)
        } else {
            setEditFlag(false)
        }
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()
        console.log(event.target.elements.phoneNumber.value, "456456")
        try {
            const { phoneNumber, mobilenumber, middleName, firstname, lastname, email, bloodgroup, age, gender, address } = event.target.elements
            let res = event.target.elements.phoneNumber.value
            let res1 = res.replace(/\D/g, '');


            let payLoad = {
                firstName: firstname.value,
                lastName: lastname.value,
                middleName: middleName.value,
                bloodGroup: bloodgroup.value,
                email: email.value,
                // mobile: mobilenumber.value,
                mobile: res1,
                age: dob,
                gender: gender.value,
                // address: address.value,
                doctor_uuid: authState.uuid,
                residential_Address: {
                    city: citytosend,
                    area: area,
                    door_no: door,
                    pincode: pin,
                    street: street,
                }
            }
            let response = await editPatient(payLoad, props.details.uuid, authState.token);
            setToast(true)
            setToastMessage('' + "Edit Details Successfully")
            setToastType('success')
            dispatch(getPatients())
            // props.get112()
            setTimeout(() => {
                close()
            }, 1500);
            // if (id !== 'Add') {
            //     let response = await editPatient(payLoad, props.details.uuid, authState.token);
            //     setToast(true)
            //     setToastMessage('' + "Edit Details Successfully")
            //     setToastType('success')
            //     dispatch(getPatients())
            //     // props.get112()
            //     setTimeout(() => {
            //         close()
            //     }, 1500);
            // } else {
            //     let response = await addPatient(payLoad);
            //     setToast(true)
            //     setToastMessage('' + "New Patient Added Successfully")
            //     setToastType('success')
            //     dispatch(getPatients())
            //     // props.get112()
            //     setTimeout(() => {
            //         close()
            //     }, 1500);

            // }

        } catch (error) {
            if (error && error.response) {
                setToast(true)
                setToastMessage('' + error.response.data)
                setToastType('error')
            } else {
                setToast(false)
            }
        }
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <div className='table_heading'>{id === "Add" ? "Add Patient" : "View Patient"}</div>
                </Grid>
                {/* <Grid item xs={1}>
                    <div>{id !== "Add" && <Tooltip title="Edit" placement="bottom" arrow>
                        <EditIcon onClick={() => { setEditFlag(!editFlag) }} style={{ cursor: "pointer", float: "right" }} />
                    </Tooltip>}
                    </div>

                </Grid> */}
                <Grid item xs={1}>

                    <Tooltip title="Close" placement="bottom" arrow>
                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer", float: "right" }} />
                    </Tooltip>

                </Grid>
            </Grid>
            <hr style={{ borderTop: "2px solid #00839B", borderBottom: "none", marginBottom: '5%' }} />
            <Formik action="" onSubmit={handleSubmit}>
                <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <h3 className="input_lable1">Mobile Number<span className='star-color'>*</span></h3>
                            <Field
                                as={MuiPhoneNumber}
                                name="phoneNumber"
                                defaultCountry={'ng'}
                                required
                                style={{ innerHeight: "50px"}}
                                fullWidth variant="outlined"
                                defaultValue={details && details.mobile}
                                value={details && details.mobile}
                                className={classes.primaryInput2}
                               
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <h3 className="input_lable1">First Name<span className='star-color'>*</span></h3>
                            <input
                                name="firstname"
                                variant="outlined"
                                className="headersearchtxt"
                                style={{ width: "100%", }}
                                required
                                defaultValue={details && details.first_name}
                                disabled={editFlag === false}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <h3 className="input_lable1">Middle Name</h3>
                            <input
                                name="middleName"
                                variant="outlined"
                                defaultValue={details && details.middle_name && details.middle_name ? details.middle_name : ""}
                                className="headersearchtxt"
                                disabled={editFlag === false}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <h3 className="input_lable1">Last Name<span className='star-color'>*</span></h3>
                            <input
                                name="lastname"
                                variant="outlined"
                                className="headersearchtxt"
                                required
                                defaultValue={details && details.last_name}
                                disabled={editFlag === false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <h3 className="input_lable1">Email ID<span className='star-color'>*</span></h3>
                            <input
                                style={{ width: '100%' }}
                                name="email"
                                variant="outlined"
                                className="headersearchtxt"
                                required
                                defaultValue={details && details.email}
                                disabled={editFlag === false}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h3 className="input_lable1">Blood Group<span className='star-color'>*</span></h3>
                            <select
                                style={{ width: '100%' }}
                                name="bloodgroup"
                                variant="outlined"
                                className="headersearchtxt"
                                required
                                defaultValue={details && details.blood_group}
                                disabled={editFlag === false}
                            >
                                            <option value="">Select..</option>
                                            <option value="Unknown" key="g">Unknown</option>
                                            <option value="A+" className="headersearchtxt" key="2">A+</option>
                                            <option value="A-" className="headersearchtxt" key="3">A- </option>
                                            <option value="B+" className="headersearchtxt" key="4">B+</option>
                                            <option value="B-" className="headersearchtxt" key="5">B-</option>
                                            <option value="O+" className="headersearchtxt" key="6">O+</option>
                                            <option value="O-" className="headersearchtxt" key="7">O-</option>
                                            <option value="AB+" className="headersearchtxt" key="8">AB+</option>
                                            <option value="AB-" className="headersearchtxt" key="9">AB-</option>
                       </select>
                        </Grid>
                        <Grid item xs={4}>
                            <h3 className="input_lable1">Dob<span className='star-color'>*</span></h3>
                            <input
                                type='date'
                                name="dob"
                                variant="outlined"
                                className="headersearchtxt"
                                style={{ width: "100%" }}
                                onChange={(event) => handleDobChange(event)}
                                required
                                // defaultValue={details && Number(details.age)}dob
                                defaultValue={moment(details && details.dob).format("YYYY-MM-DD")}
                                value={moment(details && details.dob ? details.dob : dob).format("YYYY-MM-DD")}
                                disabled={editFlag === false}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <h3 className="input_lable1">Gender<span className='star-color'>*</span></h3>
                            <select id="cars" className="headersearchtxt" name="gender"
                                required
                                defaultValue={details && details.gender}
                                disabled={editFlag === false}
                                style={{color:"#013C44"}}>
                                <option value="">Select..</option>
                                <option value="male" className="headersearchtxt" style={{color:"#013C44"}}>Male</option>
                                <option value="female" className="headersearchtxt" style={{color:"#013C44"}}>Female</option>
                                <option value="other" className="headersearchtxt" style={{color:"#013C44"}}>Other</option>

                            </select>
                        </Grid>
                        {/* <Grid item xs={12}>
                        <h3 className="input_lable1">Address</h3>
                        <input
                            name="address"
                            variant="outlined"
                            className="headersearchtxt"
                            style={{ width: '100%' }}
                            defaultValue={details && details.address}
                            disabled={editFlag === false}
                        />
                    </Grid> */}
                        {editFlag === false &&
                            <>
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">City<span className='star-color'>*</span></h3>
                                    <select id="cars" className="headersearchtxt" name="city"
                                        required
                                        defaultValue={defaultCityId}
                                        value={defaultCityId}
                                        disabled={editFlag === false}
                                        onClick={(event) => handleCityChange(event)}
                                    >
                                        {
                                            cityList && cityList.map((item) => (<option value={item.uuid}>{item.name}</option>))
                                        }

                                    </select>
                                </Grid>
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">Area<span className='star-color'>*</span></h3>
                                    {/* <select name="gender" id="cars" className="Dropdown-control" name="area"
                                        required
                                        onChange={(event) => handleAreaChange(event)}
                                        defaultValue={details && details.residential_Address[0] && details.residential_Address[0].area}
                                        value={details && details.residential_Address[0] && details.residential_Address[0].area}
                                        disabled={editFlag === false}
                                    >
                                        {
                                            areaList && areaList.map((item) => (<option value={item._id}>{item.name}</option>))
                                        }
                                    </select> */}
                                    <select  id="cars" className="headersearchtxt" name="area"
                                        required
                                        onChange={(event) => handleAreaChange(event)}
                                        defaultValue={details && details.residential_Address[0] && details.residential_Address[0].area}
                                        value={details && details.residential_Address[0] && details.residential_Address[0].area}
                                        disabled={editFlag === false}
                                    >
                                        {
                                            areaList && areaList.map((item) => (<option value={item._id}>{item.name}</option>))
                                        }
                                    </select>

                                </Grid>
                            </>
                        }
                        {editFlag === true &&
                            <>
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">City<span className='star-color'>*</span></h3>
                                    <select id="cars" className="headersearchtxt" name="city"
                                        required
                                        // defaultValue={citytosend}
                                        // value={citytosend}
                                        disabled={editFlag === false}
                                        onClick={(event) => handleCityChange1(event)}
                                    >
                                        {
                                            cityList && cityList.map((item) => (<option value={item.uuid}>{item.name}</option>))
                                        }

                                    </select>
                                </Grid>
                                <Grid item xs={6}>
                                    <h3 className="input_lable1">Area<span className='star-color'>*</span></h3>
                                    <select id="cars" className="headersearchtxt" name="area"
                                        required
                                        onChange={(event) => handleAreaChange(event)}
                                        // defaultValue={details && details.residential_Address[0] && details.residential_Address[0].area}
                                        // value={details && details.residential_Address[0] && details.residential_Address[0].area}
                                        disabled={editFlag === false}
                                    >
                                        {
                                            areaList && areaList.map((item) => (<option value={item._id}>{item.name}</option>))
                                        }
                                    </select>
                                </Grid>
                            </>
                        }
                        <Grid item xs={6}>
                            <h3 className="input_lable1">Door No<span className='star-color'>*</span></h3>
                            <input
                                style={{ width: '100%' }}
                                name="door_no"
                                variant="outlined"
                                className="headersearchtxt"
                                required
                                onChange={(event) => handleDoorChange(event)}
                                defaultValue={details && details.residential_Address[0] && details.residential_Address[0].door_no}
                                disabled={editFlag === false}
                            />
                        </Grid>
                        {/* <Grid item xs={4}>
                            <h3 className="input_lable1">Pincode<span className='star-color'>*</span></h3>
                            <input
                                style={{ width: '100%' }}
                                name="pincode"
                                variant="outlined"
                                className="headersearchtxt"
                                required
                                onChange={(event) => handlePinChange(event)}
                                defaultValue={details && details.residential_Address[0] && details.residential_Address[0].pincode}
                                disabled={editFlag === false}
                            />
                        </Grid> */}
                        <Grid item xs={6}>
                            <h3 className="input_lable1">Street<span className='star-color'>*</span></h3>
                            <input
                                style={{ width: '100%' }}
                                name="street"
                                variant="outlined"
                                className="headersearchtxt"
                                required
                                onChange={(event) => handleStreetChange(event)}
                                defaultValue={details && details.residential_Address[0] && details.residential_Address[0].street}
                                disabled={editFlag === false}
                            />
                        </Grid>
                    </Grid>
                    {id === 'Add' && <div className="btn_submit">
                        <button className='submit_button' type='submit' >Submit</button>
                    </div>}

                    {id !== 'Add' && editFlag === true && <div className="btn_submit">
                        <button className='submit_button' type='submit' >Update</button>
                    </div>}
                </Form>
            </Formik>

            <Toast
                text={toastMessage}
                type={toastType}
                open={showToast}
                setClose={() => {
                    setToast(false);
                }}
            />

        </>
    );
}