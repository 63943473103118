import { takeLatest, takeEvery, put, all, select, call } from "redux-saga/effects";
import axios from "axios";

export const getUser = state => state.loginReducer.payload;


const getNotifyPopupScreenFunction = function* ({ values }) {
    const user = yield select(getUser);
    let getNotifyPopup;
    yield axios.get(process.env.REACT_APP_API_URL + `/api/v1/notification/list/${user.uuid}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {


        if (resp) {
            getNotifyPopup = resp.data
        }
    })
        .catch(err => {
            getNotifyPopup = err.response
        })
    yield put({ type: "SET_GET_NOTIFY_POPUP_SCREEN", payload: getNotifyPopup })
}


const getmedPrescriptionFunction = function* ({ values }) {
    const user = yield select(getUser);

    let getpresdata;
    yield axios.get(process.env.REACT_APP_API_URL + `/ep/getPresDetails/${values}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {


        if (resp) {

            getpresdata = resp.data
        }
    })
        .catch(err => {
            getpresdata = err
        })
    yield put({ type: "SET_GET_MED_PRESCRIPTION", payload: getpresdata })
}


const getCityFn = function* () {

    let cityData;
    yield axios.get(process.env.REACT_APP_API_URL + "/uploadDoc/allcities").then((resp) => {


        if (resp) {
            cityData = resp.data
        }
    })
        .catch(err => {
            cityData = err
        })
    yield put({ type: "SET_CITY", payload: cityData })
}


const getDocfn = function* ({ data }) {
    const user = yield select(getUser);
    let docData;
    yield axios.post(process.env.REACT_APP_API_URL + "/upload/health-records/" + data, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {


        if (resp) {
            docData = resp.data
        }
    })
        .catch(err => {
            docData = err
        })
    yield put({ type: "SET_DOC", payload: docData })
}

const getAreaFn = function* ({ uuid }) {
    let areaData;
    yield axios.get(process.env.REACT_APP_API_URL + `/uploadDoc/getArea/${uuid}`).then((resp) => {

        if (resp) {
            areaData = resp.data
        }
    })
        .catch(err => {

            areaData = err
        })
    yield put({ type: "SET_AREA", payload: areaData })
}
const getSearchFn = function* ({ params, searchValue }) {
    const user = yield select(getUser);
    let searchData;
    // http://test-api.doctall.com/api/v1/labtest/epLabTest/pathology?areaRef=AR-35271B1F&term=11
    // yield axios.get(process.env.REACT_APP_API_URL + `/labtest/pathology/search?areaRef=${params}&term=${searchValue}`, {
    yield axios.get(process.env.REACT_APP_API_URL + `/labtest/epLabTest/pathology/?areaRef=${params}&term=${searchValue}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            searchData = resp.data
        }
    })
        .catch(err => {

            searchData = err
        })
    yield put({ type: "SET_SEARCH", payload: searchData })
}

const getsearchlabname = function* ({ params, searchValue }) {
    const user = yield select(getUser);
    let labname;
    yield axios.get(process.env.REACT_APP_API_URL + `/labtest/pathology/search?areaRef=${params}&term=${searchValue}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            labname = resp.data
        }
    })
        .catch(err => {

            labname = err
        })
    yield put({ type: "SET_LABNAME", payload: labname })
}


const getsearchlabRadioname = function* ({ params, searchValue }) {
    const user = yield select(getUser);
    let radiologydata;
    yield axios.get(process.env.REACT_APP_API_URL + `/labtest/radiology/search?areaRef=${params}&term=${searchValue}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            radiologydata = resp.data
        }
    })
        .catch(err => {

            radiologydata = err
        })
    yield put({ type: "SET_LABNAME_RADIO", payload: radiologydata })
}


const getHomeserviceFn = function* ({ params }) {
    const user = yield select(getUser);
    let homeServicedata;
    // http://test-api.doctall.com/api/v1/labtest/epLabTest/pathology/homeservice/AR-35271B1F
    // yield axios.get(process.env.REACT_APP_API_URL + `/labtest/pathology/homecharge?areaRef=${params}`, {
    yield axios.get(process.env.REACT_APP_API_URL + `/labtest/epLabTest/pathology/homeservice/${params}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            homeServicedata = resp.data
        }
    })
        .catch(err => {

            homeServicedata = err
        })
    yield put({ type: "SET_HOME_SERVICE", payload: homeServicedata })
}







const extentfn = function* ({ days, id, slot, uuid }) {
    try {

        const user = yield select(getUser);

        let extentData;
        let header = {
            'x-auth-token': user.token,
        }
        let data = {
            "booking_date": id,
            "extended_date": days,
            "slot_id": slot
        }

        let response = yield axios.put(process.env.REACT_APP_API_URL + `/booking/extend/${uuid}`, data,
            { headers: header })
        if (response.data) {
            yield put({ type: "SET_EXTENT_APPOINTMENT_SUCCESS", payload: response.data })
        }
    }
    catch {
        yield put({ type: "SET_EXTENT_APPOINTMENT_ERROR" })
    }




}
const changeStatusfn = function* ({ statusid, stype }) {
    try {
        const user = yield select(getUser);
        var config = {
            method: 'put',
            url: process.env.REACT_APP_API_URL + `/booking/${statusid}/${stype}`,
            headers: {
                'x-auth-token': user.token
            }
        };
        let response = yield axios(config)
        if (response.data) {

            yield put({ type: "SET_CHANGE_STATUS_SUCCESS", payload: response.data ? response.data.status : "error" })
        }
    }
    catch {
        yield put({ type: "SET_CHANGE_STATUS_ERROR" })
    }



}


const getSearchRaFn = function* ({ params, searchValue }) {
    const user = yield select(getUser);
    let searchData;
    // yield axios.get(process.env.REACT_APP_API_URL + `/labtest/radiology/search?areaRef=${params}&term=${searchValue}`, {
    // http://test-api.doctall.com/api/v1/labtest/epLabTest/radiology?areaRef=AR-35271B1F&term=de
    yield axios.get(process.env.REACT_APP_API_URL + `/labtest/epLabTest/radiology/?areaRef=${params}&term=${searchValue}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            searchData = resp.data
        }
    })
        .catch(err => {

            searchData = err
        })
    yield put({ type: "SET_RA_SEARCH", payload: searchData })
}

const followupfn = function* ({ followupdate, bookeddate, slotid, followuuid }) {
    try {
        const user = yield select(getUser);

        let followupData;

        let datas = {
            "booking_date": bookeddate,
            "followup_date": followupdate,
            "slot_id": slotid
        }
        var config = {
            method: 'put',
            url: process.env.REACT_APP_API_URL + `/booking/followup/${followuuid}`,
            data: datas,
            headers: {
                'x-auth-token': user.token
            }
        };
        let response = yield axios(config)
        if (response.data) {
            yield put({ type: "SET_FOLLOW_APPOINTMENT_SUCCESS", payload: response.data })
        }
    }
    catch {
        yield put({ type: "SET_FOLLOW_APPOINTMENT_ERROR" })
    }

}

const getCityForPrescriptionFn = function* () {
    const user = yield select(getUser);
    let cityData;
    yield axios.get(process.env.REACT_APP_API_URL + "/healthplus/location/getcity", {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            cityData = resp.data
        }
    })
        .catch(err => {
            cityData = err
        })
    yield put({ type: "SET_CITY_FOR_PRESCRIPTION", payload: cityData })
}

const getAreaForPrescriptionFn = function* ({ city }) {
    const user = yield select(getUser);
    let areaData;
    yield axios.get(process.env.REACT_APP_API_URL + `/healthplus/location/getarea?city=${city}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            areaData = resp.data
        }
    })
        .catch(err => {
            areaData = err
        })
    yield put({ type: "SET_AREA_FOR_PRESCRIPTION", payload: areaData })
}

const searchGenericNameForPrescriptionFn = function* ({ search }) {
    const user = yield select(getUser);
    let searchData;
    yield axios.get(process.env.REACT_APP_API_URL + `/healthplus/search/genericname?genericname=${search}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            searchData = resp.data
        }
    })
        .catch(err => {
            searchData = err
        })
    yield put({ type: "SET_GENERIC_NAME_FOR_PRESCRIPTION", payload: searchData })
}

const searchProductNameForPrescriptionFn = function* ({ search }) {
    const user = yield select(getUser);
    let searchData;
    yield axios.get(process.env.REACT_APP_API_URL + `/healthplus/search/productname?productname=${search}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            searchData = resp.data
        }
    })
        .catch(err => {
            searchData = err
        })
    yield put({ type: "SET_PRODUCT_NAME_FOR_PRESCRIPTION", payload: searchData })
}
const medAttrfn = function* ({ pro, gen, eattr }) {
    const user = yield select(getUser);
    let attrData;
    yield axios.get(process.env.REACT_APP_API_URL + `/healthplus/search/productname/attribute?productname=${pro}&attribute=${eattr}&genericname=${gen}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            attrData = resp.data
        }
    })
        .catch(err => {
            attrData = err
        })
    yield put({ type: "SET_MED_ATTR", payload: attrData })
}




const getPdfCaseNote = function* ({ caseId }) {
    const user = yield select(getUser);
    // http://test-api.doctall.com/api/v1/ep/preview/EP-B50CA3E96CBE
    let PdfData;
    yield axios.get(process.env.REACT_APP_API_URL + `/ep/preview/EP-B50CA3E96CBE`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {
            PdfData = resp.data
        }
    })
        .catch(err => {
            PdfData = err
        })
    yield put({ type: "SET_CASE_PDF", payload: PdfData })

}




const getqueryHistoryFunction = function* ({ id, stype }) {
    try {
        const user = yield select(getUser);
        let datas = {
            uuid: id,
            doctor_id: user.uuid,
            type: stype
        }
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/epquery/fetchEpresQuery/`,
            data: datas,
            headers: {
                'x-auth-token': user.token
            }
        };
        let response = yield axios(config)
        if (response.data) {
            yield put({ type: "SET_QUERY_HISTORY_SUCCESS", payload: response.data })
        }
    }
    catch {
        yield put({ type: "SET_QUERY_HISTORY_ERROR" })
    }
}
const getNotifyCount = function* () {
    const userdata = yield select(getUser);
    let queryData;

    var config = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + `/epquery/queryCount/${userdata.uuid}`,
        headers: {
            'x-auth-token': userdata.token,
            'Content-Type': 'application/json'
        }
    };

    yield axios(config)
        .then(function (response) {
            if (response.data) {
                queryData = response.data.status
            }
        })
        .catch(function (error) {
            queryData = false

        });
    yield put({ type: "SET_NOTIFY_DR", payload: queryData })

}
const getAllPrescriptionFunction = function* ({ e }) {
    const user = yield select(getUser);
    let allpresData;
    yield axios.get(process.env.REACT_APP_API_URL + `/healthplus/genericBasedProdDetails?genericname=${e}`, {
        headers: {
            'x-auth-token': user.token,
        }
    }).then((resp) => {
        if (resp) {

            allpresData = resp.data
        }
    })
        .catch(err => {
            allpresData = err
        })
    yield put({ type: "SET_ALL_DETAIL_FOR_PRESCRIPTION", payload: allpresData })
}
function* getDocWatcher() {

    yield takeLatest("GET_DOC", getDocfn);
}
function* getCityLabWatcher() {

    yield takeLatest("GET_CITY_LAB", getCityFn);
}
function* getAreaLabWatcher() {

    yield takeLatest("GET_AREA_LAB", getAreaFn);
}
function* getSearchLabWatcher() {
    yield takeLatest("GET_SEARCH_LAB", getSearchFn);
}
function* getSearchLabnameWatcher() {
    yield takeLatest("GET_SEARCH_LAB_FINAL", getsearchlabname);
}
function* getSearchLabPathnameWatcher() {
    yield takeLatest("GET_SEARCH_LAB_FINAL_RADIO", getsearchlabRadioname);
}

function* getNotification() {
    yield takeLatest("GET_NOTIYDR", getNotifyCount);
}

function* getHomeserviceWatcher() {
    yield takeLatest("GET_HOME_SERVICE_PATHOOGY", getHomeserviceFn);
}


function* extentAppointmentWatcher() {
    yield takeLatest("PUT_EXTENT_APPOINTMENT", extentfn);
}
function* changeStatusWatcher() {
    yield takeLatest("CHANGE_STATUS_REQUEST", changeStatusfn);
}

function* getSearchRaLabWatcher() {
    yield takeLatest("GET_SEARCH_LABRA", getSearchRaFn);
}
function* getFollowupWatcher() {
    yield takeLatest("GET_FOLLOW_UP_REQUEST", followupfn);
}
function* getCityForPrescription() {
    yield takeLatest("GET_CITY_FOR_PRESCRIPTION", getCityForPrescriptionFn);
}
function* getAreaForPrescription() {
    yield takeLatest("GET_AREA_FOR_PRESCRIPTION", getAreaForPrescriptionFn);
}
function* searchGenericNameForPrescription() {
    yield takeLatest("SEARCH_GENERIC_NAME_FOR_PRESCRIPTION", searchGenericNameForPrescriptionFn);
}
function* searchProductNameForPrescription() {
    yield takeLatest("SEARCH_PRODUCT_NAME_FOR_PRESCRIPTION", searchProductNameForPrescriptionFn);
}
function* medAttrSaga() {
    yield takeLatest("GET_MED_ATTR", medAttrfn);
}

function* getMedPrescriptionSaga() {
    yield takeLatest("GET_MED_PRESCRIPTION", getmedPrescriptionFunction);
}
function* getQueryHistorySaga() {
    yield takeLatest("GET_QUERY_HISTORY_LIST_REQUEST", getqueryHistoryFunction);
}
function* getRequestSaga() {
    yield takeLatest("GET_EXTEND_REQUEST", extentfn);
}

function* getNotifyPopupScreenSagas() {
    yield takeLatest('GET_NOTIFY_POPUP_SCREEN', getNotifyPopupScreenFunction)
}
function* getPdfCaseNoteSaga() {
    yield takeLatest('GET_CASE_PDF', getPdfCaseNote)
}
function* getAllPrescriptionSagas() {
    yield takeLatest('GET_ALL_PRESCRIPTION_GENERIC', getAllPrescriptionFunction)
}
export {
    getCityLabWatcher, getAreaLabWatcher, getSearchLabWatcher, getDocWatcher, getSearchRaLabWatcher, extentAppointmentWatcher, changeStatusWatcher, getFollowupWatcher, getNotification
    , getCityForPrescription, getAreaForPrescription, searchGenericNameForPrescription, searchProductNameForPrescription,
    getHomeserviceWatcher, getSearchLabnameWatcher, getSearchLabPathnameWatcher, medAttrSaga, getMedPrescriptionSaga, getQueryHistorySaga, getRequestSaga, getNotifyPopupScreenSagas, getPdfCaseNoteSaga, getAllPrescriptionSagas
}
