import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import "./style.scss"
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from 'react-redux';
import { getCityLab } from "../../../redux/actions/cockpitAction";
import { getAreaLab } from "../../../redux/actions/cockpitAction";
import { getSearchLabRa,getSearchLabFinalRadiology,getMedPrescription } from "../../../redux/actions/cockpitAction";
import useOnClickOutside from "../../../Dashboard/doctorDashboard/OutClickhandler";
import Search from "../../../assets/images/search.svg"
import axios from 'axios'
import { useHistory } from "react-router-dom";
import DocmedFailure from "../../Popup/docindicationpop";
import Close from '../../../assets/images/close.svg';
import { Dialog } from '@material-ui/core';
import DGArrow from "../../../assets/images/DGArrow.svg"


const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971' },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
]




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  root1: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  container: {
    backgroundColor: "#EFF1F7",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "20px",
    padding: "30px"
  },
  container2: {
    backgroundColor: "#EFF1F7",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "41px",
    padding: "28px"
  },
  typography: {
    fontFamily: [
      'GreycliffCFBold'
    ].join(','),
    fontWeight: "bold",
    fontSize: 25,
    color: '#013C44'
  },
  divider: {
    height: 2,
    backgroundColor: 'rgba(0, 131, 155, 0.3)',
    marginLeft: -30,
    marginRight: -30
  },
  multilineInputField: {
    borderRadius: 20,
    // border: '1px solid #A0DFC4',
    background: '#FFFFFF'
  },
  outlinedInputField: {
    borderRadius: 20,
    border: '1px solid #A0DFC4',
  },
  tableDataTypography: {
    fontFamily: [
      'GreycliffCFBold'
    ].join(','),
    fontWeight: "bold",
    fontSize: 20,
    color: '#013C44'
  },
  deleteIcon: {
    color: '#078CA5',
    display: 'inline-flex'
  }
}));


const Radiology = (props) => {
  const initialLabData = {
    city: "",
    area: "",
    testDiscipline: "",
    searchTest: undefined,
    search: "",
    homeService: ""
  };
  let history = useHistory();
  const routedata = history.location.state;

  const classes = useStyles();
  const theme = useTheme();
  const [labHeader, setLabHeader] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [note, setNotes] = React.useState("");
  const [addbutton, setaddbutton] = React.useState(true);
  const [submitButton, setSubmitButton] = React.useState(true);
  const [docpopup,setDocpopup]=useState(false)
  const [labList, setlabList] = React.useState([]);
  const [labData, setLabData] = React.useState(initialLabData);

  const userdata = useSelector(state => state.loginReducer.payload)

  const dispatch = useDispatch();

  const LabCity = useSelector(state => state.getCity.payload != undefined ? state.getCity.payload : "")
  const AreaCity = useSelector(state => state.pathologyArea.payload)
  const SearchPathology = useSelector(state => state.radiologySearch)

  const labName = useSelector(state =>state.radiologySearchLabname)
  const labnameAll = labName && labName.payload.length>0 ?  labName.payload :[]


  useEffect(() => {
    dispatch(getCityLab())
  }, [])

  useEffect(() => {
    if (props.radiologyData.city && props.radiologyData.area && props.radiologyData.searchTest && props.radiologyData.testDiscipline && props.radiologyData.homeService) {
      setaddbutton(false);
    }
  }, [props.radiologyData])

  useEffect(() => {
    if (props.radiologyList && props.radiologyList.length > 0) {
      setSubmitButton(false);
    } else {
      setSubmitButton(true);
    }
  }, [props.radiologyList])


  const handleChangeSubHeader = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const AddItem = () => {
    props.radiologyData.searchTest.HomeDelivery = props.radiologyData.homeService == "NOT AVAILABLE" ?  ["NOT AVAILABLE"] :[]
    props.setRadiologyList([...props.radiologyList, props.radiologyData]);
    props.setRadiologyData(props.initialRadiologyData);
    setaddbutton(true);

  }

  const Submit = () => {
    var data = {
      "patient_uuid": props.data.patient.uuid,
      "user_uuid": routedata.patient.created_by.uuid,
      "doctor_uuid": props.data.doctor_uuid,
      "booking_uuid": props.data.uuid,
      "local_doctor_uuid":"",
      "labtype":"radiology",
      "tests": props.radiologyList.map((e) => { return e.searchTest }),
      // "homeDelivery":["not available"],
      // "tests":labnameAll,
      "ignorerecommendation": false,
      "notes":props.radiologyNote
    }

    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL+'/ep-lab',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userdata.token
      },
      data: JSON.stringify(data)
    };

    axios(config)
      .then(function (response) {
        if(response.data.status=="failure"){
          setDocpopup(true)
        }
        else{
          props.showSuccess(true);
        }
      })
      .catch(function (error) {
        // console.log(error)
      });
      setTimeout(function(){
        dispatch(getMedPrescription(props.data.uuid)) 
       }, 3000);
  }
  const handleChange = (event, newValue) => {
    setLabHeader(newValue);
  };
  const handleNotes = (e, index) => {
    props.radiologyList[index].searchTest.clinicaladvice = e.target.value;
    props.setRadiologyList(props.radiologyList);
  }

  const handleCity = (e) => {
    props.setRadiologyData({
      ...props.radiologyData,
      city: e
    });
    dispatch(getAreaLab(e.uuid))
  }

  const handleArea = (e) => {
    props.setRadiologyData({
      ...props.radiologyData,
      area: e
    });
  }
  const handleTest = (e, data) => {
    props.setRadiologyData({
      ...props.radiologyData,
      search: e,
      searchTest: data,
      testDiscipline: data.testdiscipline,
      homeService: data.homeserviceavailability
    });
    const timeOutId = setTimeout(() =>dispatch(getSearchLabRa(props.radiologyData.area.uuid, props.radiologyData.search))  , 100);
    return () => clearTimeout(timeOutId);
  }
  const SearchTest = () => {
    dispatch(getSearchLabRa(props.radiologyData.area.uuid, props.radiologyData.search))
  }

  const handleLabDelete = (index) => {
    const currentLabList = [...props.radiologyList];
    currentLabList.splice(index, 1);
    props.setRadiologyList(currentLabList);
  };
  const handleDocpopupClose = () =>{
    setDocpopup(false)
  }
  return (
    <>
      <Grid container direction="row" justify="space-evenly">
        <DropDown options={LabCity} placeholder={"City"} setTimeValue={(e) => { handleCity(e) }} timevalue={props.radiologyData.city} />
        <DropDown options={AreaCity} placeholder={"Area"} setTimeValue={(e) => { handleArea(e) }} timevalue={props.radiologyData.area} />
        <DropDownTxt options={SearchPathology} placeholder={"Standardized Test Name"} setTimeValue={(e, data) => { handleTest(e, data) }} search={() => { SearchTest() }} timevalue={props.radiologyData.search} />

        <DropDownLable lable={"testdiscipline"} options={props.radiologyData.searchTest ? props.radiologyData.searchTest.testdiscipline : false} placeholder={"Test Discipline"} />
        <DropDownLable lable={"homeserviceavailability"} options={props.radiologyData.searchTest ? props.radiologyData.searchTest.homeserviceavailability : false} placeholder={"Home Service"} />

      </Grid>
      <Grid container style={{ display: "flex" }}>
        <Grid item style={{ marginLeft: "auto", order: 2, }}>
          <Button disabled={addbutton} onClick={() => { AddItem() }} variant="contained" style={{
            backgroundColor: "#00839B",
            color: "white",
            borderRadius: "15px",
            height: 60,
            width: 119,
            marginTop: 30,
            fontSize: 20,
            textTransform: 'capitalize',
            fontFamily: [
              'GreycliffCFBold'
            ].join(',')
          }}>
            Add
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start"
        alignItems="center" className={classes.container}>
        <Grid item md={3}>
          <Typography className={classes.typography}>Investigation recommended</Typography>
        </Grid>
        <Grid item md={3}>
          <Typography className={classes.typography}>Quantity prescribed</Typography>
        </Grid>
        <Grid item md={3}>
          <Typography className={classes.typography}>Clinical Advice</Typography>
        </Grid>
        <Grid item md={3}>
        </Grid>
        <Grid item md={12} style={{ marginTop: "26px" }}>
          <Divider className={classes.divider} />
        </Grid>
        {props.radiologyList && props.radiologyList.filter((e) => e.searchTest).map((item, index) => {
          return (
            <Grid key={index} container style={{ marginTop: "22px" }} direction="row" alignItems="center">
              <Grid item md={3}>
                <Typography className={classes.tableDataTypography}>{item.searchTest.standardisedtestname}</Typography>
              </Grid>
              <Grid item md={3}>
                <Typography style={{ marginLeft: 5 }} className={classes.tableDataTypography}>1</Typography>
              </Grid>
              <Grid item md={5} >
                <TextField
                  placeholder="Please type here"
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#013C44",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.outlinedInputField
                    }
                  }}
                  value={item.searchTest.clinicaladvice}
                  className={classes.multilineInputField}
                  onChange={(e) => { handleNotes(e, index) }}
                  multiline
                />
              </Grid>
              <Grid item md={1} style={{ textAlign: 'center' }}>
                <IconButton className={classes.deleteIcon} onClick={handleLabDelete.bind(this, index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          )
        })}

      </Grid>
      <Grid container md={12} className={classes.container2}>
        <Grid item md={12} style={{ textAlign: "left" }}>
          <Typography className={classes.typography}>Special Advise</Typography>
        </Grid>
        <Grid item md={12} style={{ marginTop: "28px", marginBottom: "5px" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Please type here"
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              classes: { notchedOutline: classes.outlinedInputField }
            }}
            value={props.radiologyNote}
            multiline
            rows={10}
            onChange={(e) => { props.setRadiologyNote(e.target.value) }}
            className={classes.multilineInputField}
          />
        </Grid>
      </Grid>
      <Grid item style={{ marginRight: "auto", display: "flex", marginTop: "32px" }}>
        <Button disabled={submitButton} onClick={() => Submit()} style={{
          backgroundColor: "#04A9C8", borderRadius: "20px", color: "white", width: 323,
          height: 63,
          fontSize: 30,
          textTransform: 'capitalize',
          fontFamily: [
            'GreycliffCFBold'
          ].join(',')
        }} size="large">
          submit
       </Button>
      </Grid>
      <Dialog
                                            maxWidth={"lg"}
                                            open={docpopup}
                                            scroll={"body"}
                                            onClose={handleDocpopupClose}
                                        >
                                            <div className="ContainerWrapper">
                                                <img src={Close} alt="Close" className="Closepopup" onClick={() => setDocpopup(false)} crossorigin="anonymous" />
                                                <DocmedFailure />
                                            </div>

                                        </Dialog>
    </>
  )
}



const DropDown = (props) => {
  const ref = React.useRef();
  const [timepop, setTimePop] = React.useState(false);
  useOnClickOutside(ref, () => {
    setTimePop(false);
  });
  return (
    <div className="dropsession" ref={ref} onClick={() => { setTimePop(!timepop) }}>
      <span>{props.timevalue && props.timevalue != "" ? props.timevalue.name : props.placeholder}</span>
      <img src={DGArrow} crossorigin="anonymous" />
      {timepop ? <div className="popUp">
        {props.options && props.options.map((e, i) => {
          return <div onClick={() => { props.setTimeValue(e) }}>{e.name}</div>

        })}
      </div> : null}
    </div>
  )
}

const DropDownLable = (props) => {
  const ref = React.useRef();
  const [timepop, setTimePop] = React.useState(false);
  const [data, setDAta] = React.useState(false);
  useOnClickOutside(ref, () => {
    setTimePop(false);
  });
  React.useEffect(() => {
    if (props.options) {
      setDAta([props.options])

    }
  }, [props.options])
  return (
    <div className="dropsession" ref={ref} onClick={() => { setTimePop(!timepop) }}>
      <span>{props.options ? props.options : props.placeholder}</span>
      <img src={DGArrow} crossorigin="anonymous" />
      {timepop ? <div className="popUp">
        {data && data.map((e, i) => {
          return <div onClick={() => { setTimePop(false) }}>{e}</div>

        })}
      </div> : null}
    </div>
  )


}
const DropDownTxt = (props) => {
  const ref = React.useRef();
  const [timepop, setTimePop] = React.useState(false);
  useOnClickOutside(ref, () => {
    setTimePop(false);
  });
  useEffect(()=>{
    if(props.timevalue==""){
      setTimePop(false)
    } 
      },[props.timevalue])
  return (
    <div className="dropsession" ref={ref} >
      <input type="text" value={props.timevalue} onChange={(e) => { props.setTimeValue(e.target.value, false);setTimePop(true);  }} placeholder={props.placeholder} className="headersearchtxt" />
      <img src={Search} onClick={() => { props.search(); setTimePop(true) }} crossorigin="anonymous" />
      {props.options.payload && props.options.payload.length > 0 && timepop ? <div className="popUp">
        {props.options.payload && props.options.payload.map((e, i) => {
          return <div onClick={() => { props.setTimeValue(e.standardisedtestname, e); setTimePop(false) }}>{e.standardisedtestname}</div>

        })}
      </div> :  props.options.payload&&props.options.payload.length<=0&&timepop?
      <div className="popUp">
       <div >Loading...</div>
        </div>:null}
    </div>
  )
}
export default Radiology;
