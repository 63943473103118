import React from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  Fab,
} from "@material-ui/core";
import { useState } from "react";
import styles from "../style";
const useStyles = makeStyles((theme) => styles(theme));

const DialogLayout = ({ open, close, children, height }) => {
  const [openDialog, setOpen] = React.useState(open);
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    close();
    console.log("Close invoked");
  };
  return (
    <>
      <Dialog
        open={openDialog}
        //onClose={handleClose}
        className={classes.dialogLayout}
        sx={{
          "& .MuiDialog-container .MuiDialog-paper": {
            height: "556px",
          },
        }}
      >
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default DialogLayout;
