import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyle } from '../../Admin/style/style'
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import Toast from '../../components/Toast';
import { addPatient, editPatient } from '../services/ServiceHelper';
import { getPatients } from '../../redux/actions/ex-appointment-action';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { searchPatient } from './../../../src/doctor-dashboard/services/ServiceHelper';

export default function AddPatientComponent(props) {
    const authState = useSelector((state) => state.loginReducer.payload);
    console.log(props)
    const dispatch = useDispatch();
    const { id, close, details } = props;
    const classes = useStyle();
    const [editFlag, setEditFlag] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);
    const [isPatientFound, setPatientFound] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [mResponse, setResponse] = useState()
    console.log(mResponse && mResponse.firstName)
    const handleClose = () => {
        props.close(true)
    };




    const handleSearch = async (event) => {
        event.preventDefault()
        try {
            let payLoad = {
                mobile: event.target.elements.phoneNumber.value,
                doctor_uuid: authState.uuid
            }
            console.log(payLoad)
            let response = await searchPatient(payLoad)
            if (response && response.status === 200 || response.status === 201) {
                setPatientFound(true)
                setNotFound(false)

                let Response = response.data.data
                setResponse(response && response.data && response.data.data)
                console.log(response)

            } else {
                setToastMessage(true, "Patient Not Found With Provided Phone Number", "error")
                setPatientFound(false)
                setNotFound(true)
            }

        } catch (error) {
            setPatientFound(false)
            setNotFound(true)
            if (error && error.response) {
                setToastMessage(true, "" + error.response.data, "error")
            } else {
                setToastMessage(true, "Patient Not Found With Provided Phone Number", "error")
            }
        }

    }


    useEffect(() => {
        if (id == 'Add') {
            setEditFlag(true)
        } else {
            setEditFlag(false)
        }
    }, [])



    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const { mobilenumber, firstname, lastname, email, bloodgroup, age, gender, address } = event.target.elements
            let payLoad = {
                firstName: firstname.value,
                lastName: lastname.value,
                bloodGroup: bloodgroup.value,
                email: email.value,
                mobile: mobilenumber.value,
                age: age.value,
                gender: gender.value,
                address: address.value,
                doctor_uuid: authState.uuid
            }

            if (id !== 'Add') {
                let response = await editPatient(payLoad, props.details.uuid);
                dispatch(getPatients())
                props.close()
            } else {
                let response = await addPatient(payLoad);
                dispatch(getPatients())
                props.close()
            }

        } catch (error) {
            if (error && error.response) {
                setToast(true)
                setToastMessage('' + error.response.data)
                setToastType('error')
            } else {
                setToast(false)
            }

        }

    }
    const handleGet = (event) => {
        axios.get(`${process.env.REACT_APP_API_URL}/patient/edit/`)
            .then((response) => {
                setResponse(response && response.data && response.data.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    return (
        <>


            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <div className='table_heading'>{id === "Add" ? "Add Patient" : "View Patient"}</div>
                </Grid>
                {/* <Grid item xs={1}>
                    <div>{id !== "Add" && <Tooltip title="Edit" placement="bottom" arrow>
                        <EditIcon onClick={() => { setEditFlag(!editFlag) }} style={{ cursor: "pointer", float: "right" }} />
                    </Tooltip>}
                    </div>

                </Grid> */}
                <Grid item xs={1}>

                    <Tooltip title="Close" placement="bottom" arrow>
                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer", float: "right" }} />
                    </Tooltip>

                </Grid>
            </Grid>
            <hr style={{ borderTop: "2px solid #00839B", borderBottom: "none", marginBottom: '5%' }} />
            <form action="" onSubmit={handleSubmit}>

                <Grid container spacing={2}>
                    <Grid item xs={3}> <h3 className="input_lable">Mobile No : </h3></Grid>
                    <Grid item xs={9}>
                        <input
                            className="text_field"
                            margin="dense"
                            id="mobile"
                            label="Mobile no :"
                            type="number"
                            fullWidth
                            variant="outlined"
                            name="mobilenumber"
                            placeholder="Mobile Number"
                            required
                            defaultValue={mResponse && mResponse.mobile}
                            value={mResponse && mResponse.mobile}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Patient Name </h3></Grid>
                    <Grid item xs={3}>
                        <input
                            // className="text_field"
                            autoFocus
                            margin="dense"
                            id="fname"
                            type="text"
                            fullWidth
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            placeholder='First Name'
                            required
                            defaultValue={mResponse && mResponse.firstName}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <input
                            className="text_field"
                            autoFocus
                            margin="dense"
                            id="fname"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="middleName"
                            placeholder="Middle Name"
                        // required
                        // defaultValue={mResponse && mResponse.lastName}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <input
                            className="text_field"
                            autoFocus
                            margin="dense"
                            id="fname"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="lastname"
                            required
                            defaultValue={mResponse && mResponse.lastName}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Email ID</h3></Grid>
                    <Grid item xs={9}>
                        <input
                            className="text_field"
                            autoFocus
                            margin="dense"
                            id="fname"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="email"
                            required
                            defaultValue={mResponse && mResponse.email}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Blood Group</h3></Grid>
                    <Grid item xs={9}>
                        <input
                            className="text_field"
                            autoFocus
                            margin="dense"
                            id="fname"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="bloodgroup"
                            required
                            defaultValue={mResponse && mResponse.bloodGroup}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Age</h3></Grid>
                    <Grid item xs={3}>
                        <input
                            className="text_field"
                            autoFocus
                            margin="dense"
                            id="fname"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="age"
                            required
                            defaultValue={mResponse && mResponse.age}
                        />
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Gender</h3></Grid>
                    <Grid item xs={3}>
                        <select  id="cars" className="text_field" name="gender"
                            defaultValue={mResponse && mResponse.gender}
                        >
                            <option value="">Select..</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </Grid>
                    <Grid item xs={3}> <h3 className="input_lable">Address</h3></Grid>
                    <Grid item xs={9}>
                        <input
                            className="text_field"
                            autoFocus
                            margin="dense"
                            id="fname"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="address"
                            required
                            defaultValue={mResponse && mResponse.address}
                        />
                    </Grid>
                </Grid>
                {id === 'Add' && <div className="btn_submit">
                    <button className='submit_button' type='submit' >Submit</button>
                </div>}
                {id !== 'Add' && editFlag === true && <div className="btn_submit">
                    <button className='submit_button' type='submit' >Update</button>
                </div>}

            </form>

            <Toast
                text={toastMessage}
                type={toastType}
                open={showToast}
                setClose={() => {
                    setToast(false);
                }}
            />
        </>
        // }
        // </>
    );
}


