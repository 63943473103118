import React, { useEffect, useState } from 'react'
import style from './PatientRiskNote.scss'
import opencaratol from './icons/opencaratol.svg';
import { Checkbox } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import radio_off from './icons/radio_off.svg';
import radio_on from './icons/radio_on.svg'
import DGUpArrow from '../../assets/images/DGUpArrow.svg'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { getRiskarea, createRiskNote, getRisknote, createRiskNoteRequest } from '../../redux/actions/caseNoteAction';
import { useDispatch, useSelector } from "react-redux";
import CreatecaseSuccess from './CreatecaseSuccess';

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #66B5C3',
            opacity: '0.95',
        },
        "& .MuiTextField-root": {
            borderRadius: '40px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px',
            // opacity: '0.95'
        },
        "& .MuiInputBase-input": {
            color: '#00839B',
            // textTransform: 'uppercase',

        },
        "& .MuiCheckbox-root": {
            color: '#00839B',
        },
        "& .MuiCheckbox-colorSecondary.Mui-checked": {
            color: '#00839B',
        },
    }
}))

function PatientRiskNotesPopup(props) {


    const classes = useStyles();
    const [radio, setRadio] = React.useState(false)
    const [riskArea, setRiskArea] = React.useState('');
    const [des, setDes] = React.useState('')
    const [dropdown, setDropdown] = React.useState(false)
    const [othersbox, setothersbox] = useState(false)
    const [a, seta] = useState([])
    const [othersvalue, setOthersvalue] = useState('');
    const [reasonConcern, setreasonConcernvalue] = useState('')
    const [actionBoolean, setactiontakenvalueboolean] = useState(false)
    const [actiontakenvalue, setactiontakenvalue] = useState('')
    const [show, setshow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [errmsg, seterrmsg] = useState('');
    const tokeen = useSelector(state=>state.loginReducer.payload.token)


    const dispatch = useDispatch();
    let history = useHistory();

    const routedata = history.location.state;
    const riskareas = useSelector(state => state.getRISKarearedus)
    const createRiskForpopup = useSelector(state => state.createRiskreducer)


    useEffect(() => {
        dispatch(getRiskarea())
    }, [])



    const riskAreaSelection = (e) => {
        if (e.target.checked) {

            a.push(e.target.value)


            if (e.target.value == "Others[Please specify]") {
                setothersbox(true)
                for (var i = 0; i < a.length; i++) {

                    if (a[i] === e.target.value) {

                        a.splice(i, 1);
                    }

                }

            }

        }
        else {

            if (e.target.value == "Others[Please specify]") {
                setothersbox(false)
            }
            for (var i = 0; i < a.length; i++) {

                if (a[i] === e.target.value) {

                    a.splice(i, 1);
                }

            }




        }
    }







    const handleSubmit = (e) => {
        e.preventDefault()
        if(a.length>0 || othersvalue.length>0 ){
            props.setLoader(true)
            const data = {

                "patient_uuid": routedata.patient_uuid,
                "doctor_uuid": routedata.doctor_uuid,
                "booking_uuid": routedata.uuid,
                "consumer_uuid": routedata.patient.created_by.uuid,
                "risk_area": a,
                "others_note": othersvalue,
                "reason_concern": reasonConcern,
                "action_taken": actionBoolean,
                "action_taken_notes": actiontakenvalue
            }
            axios.post(process.env.REACT_APP_API_URL + "/doctor/observation/createriskNotes" , data, {
                headers: {
                    'x-auth-token': tokeen,
                }
            }).then((resp) => {
                if(resp.status == "201")
                setshow(true)
        
        
               
            })
                .catch(err => {
                    console.log("errerr",err)
        
                })
    
    
            // dispatch(
            //     createRiskNoteRequest(data))
           
    
            // setshow(true)
            if (createRiskForpopup && createRiskForpopup.isLoading != undefined && createRiskForpopup.isLoading == false) {
                props.setLoader(false)
                dispatch(getRisknote(routedata.uuid))
            }
            if (createRiskForpopup.isError) {
                props.setLoader(false)
            }
        }
        else{
            seterrmsg("please fill the risk area");
        }
        setTimeout(function(){
            dispatch(getRisknote(routedata.uuid))
           
           }, 3000);
    }

    return (

        <form onSubmit={handleSubmit}>
            <>
                {
                    // createRiskForpopup && createRiskForpopup.payload != undefined && createRiskForpopup.payload.statusText != undefined && createRiskForpopup.payload.statusText == "Created" && 
                    
                    show ? <CreatecaseSuccess setLoader={props.setLoader} value="RiskNote" /> :
                        <div className="PatientRiskNote" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <div >
                                {/* left container */}
                                <div style={{ display: 'flex' }} className={classes.root} >
                                    <div>
                                        <p className="patientriskheading">Patient Risk Note</p>

                                        <div className="patientRiskNotesDropdown">
                                            <p className="patientRiskNotesDropdownText">Risk Area</p>
                                            {dropdown ? <img src={opencaratol} alt="DGUpArrow" style={{ cursor: 'pointer' }} onClick={() => setDropdown(!dropdown)} crossorigin="anonymous" /> : <img src={DGUpArrow} alt="opencaratol" style={{ cursor: 'pointer' }} onClick={() => setDropdown(!dropdown)} crossorigin="anonymous"/>}
                                        </div>

                                        {
                                            dropdown ? '' : <div className="patientRiskSelectField" >




                                                {
                                                    riskareas && riskareas.payload.length > 0 && riskareas.payload.map((item, index) => {

                                                        return (

                                                            <div className="checkboxContainer checkboxContainerBodder">
                                                                <FormControlLabel
                                                                    control={<Checkbox value={item.RiskArea} onClick={riskAreaSelection} icon={<RadioButtonUncheckedIcon style={{ width: 30, height: 30, color: "#00839B" }} />} checkedIcon={<CheckCircleOutlineIcon style={{ width: 30, height: 30, color: "#00839B" }} />} style={{ transform: "scale(0.8)" }} id="check" />}
                                                                    label={item.RiskArea}
                                                                />

                                                                {/* <label className="radiobuttonlabel">{item.RiskArea}</label> */}
                                                            </div>
                                                        )


                                                    })

                                                }







                                            </div>
                                        }




                                        {othersbox ? <textarea onChange={(e) => { setOthersvalue(e.target.value) }} className="patientRishNotsInputfield" name="other_notes" placeholder="Please type here"></textarea>
                                            : ""
                                        }


                                    </div>
                                    {/* right container */}
                                    <div style={{ width: '50px', height: 'auto' }}></div>
                                    <div>
                                        <p className="patientriskheading">Reason for concern:</p>
                                        <textarea className="patientRishNotsInputfield" onChange={(e) => { setreasonConcernvalue(e.target.value) }} name="reason_concern" placeholder="Please type here" style={{ height: '205px', marginBottom: '10px' }}></textarea>
                                        <p className="patientriskheading">Action Taken:</p>
                                        <div className="actiontakenButton" style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginLeft: '20px' }}>
                                                <div className="radiocontainer" >
                                                    {radio == false ? <img src={radio_on} alt="radio_on" onClick={() => setRadio(!radio)} style={{ cursor: 'pointer' }} /> : <img src={radio_off} alt="radio_off" onClick={() => setRadio(!radio)} style={{ cursor: 'pointer' }} />}
                                                    <label className="radiobuttonlabel" onClick={() => setRadio(!radio)} >No Action taken</label>
                                                </div>
                                                <div className="radiocontainer">
                                                    {radio == false ? <img src={radio_off} alt="radio_on" onClick={() => setRadio(!radio)} style={{ cursor: 'pointer' }} crossorigin="anonymous"/> : <img src={radio_on} alt="radio_off" onClick={() => setRadio(!radio)} style={{ cursor: 'pointer' }} crossorigin="anonymous"/>}
                                                    <label className="radiobuttonlabel" onClick={() => setRadio(!radio)} >Action taken</label>
                                                </div>
                                            </div>
                                        </div>
                                        {radio == false ? "" : <textarea onChange={(e) => { setactiontakenvalue(e.target.value); setactiontakenvalueboolean(true) }} className="patientRishNotsInputfield" name="desc" placeholder="Please describe action taken here" style={{ height: '252px', }}></textarea>}

                                    </div>
                                </div>
                                <span style={{ color: "red" }}>{errmsg}</span>
                                <button className="patientRiskNotesButton" type="submit" style={{ cursor: 'pointer' }}>
                                    <p className="patientRiskNotesButtonTxt" style={{ margin: 'auto' }}>Submit</p>
                                </button>
                            </div>

                        </div>

                }
            </>
        </form>
    )



}
export default PatientRiskNotesPopup
