import search from "../../assets/images/searchIcon.svg";
import confirmBg from "../../assets/images/confirmBg.svg";
const Style = () => ({
  dashboardContainer: {
    paddingLeft: "16px",
  },
  dataCard: {
    width: "156px",
    height: "149px",
    borderRadius: "10px",
    padding: "14px 20px 14px 24px",
    background: "#F3FAFC",
    border: "1px solid rgba(102, 181, 195, 0.3)",
    boxShadow:
      "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
  },
  earningsCard: {
    width: "320px",
    height: "186px",
    padding: "24px",
    marginLeft:"4px",

    background: "#F3FAFC",
    border: "1px solid rgba(102, 181, 195, 0.3)",
    /* Shadow/sm */

    boxShadow:
      "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    borderRadius: "10px",
  },
  sideHeading: {
    padding: "8px 10px",
    gap: "2px",
    width: "82px",
    height: "31px",
    marginTop: "20px",
    background: "#F3FAFC",
    border: "1px solid #96CCD6",
    borderRadius: "6px",
  },
  statisticsCard: {
    width: "328px",
    height: "151px",
    left: "16px",
    top: "718px",
    background: "#F3FAFC",
    border: "1px solid #66B5C3",
    borderRadius: "8px",
    marginTop: "13px",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "15px",
    fontFamily: "Visuelt Pro",
    color: "#00839B",
  },
  weekIncomeCard: {
    width: "157px",
    height: "127px",
    background: "#FFFBFA",
    border: "0.628713px solid #FFBEB2",
    boxShadow:
      "0px 0.628713px 1.88614px rgba(16, 24, 40, 0.1), 0px 0.628713px 1.25743px rgba(16, 24, 40, 0.06)",
    borderRadius: "6.28713px",
    marginRight: "15px",
  },
  monthIncomeCard: {
    width: "157px",
    height: "127px",
    background: "#F8FFFC",
    border: "0.628713px solid #37CC8C",
    boxShadow:
      "0px 0.628713px 1.88614px rgba(16, 24, 40, 0.1), 0px 0.628713px 1.25743px rgba(16, 24, 40, 0.06)",
    borderRadius: "6.28713px",
  },
  incomeHeading: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "15px",
    fontFamily: "Visuelt Pro",
    textAlign: "center",
    marginTop: "15px",
    marginBottom: "15px",
    height: "15px",
  },
  incomeMain: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "25px",
    lineHeight: "24px",
    fontFamily: "Visuelt Pro",
    marginTop: "15px",
    textAlign: "center",
  },
  menuCont: {
    height: "32px",
    padding: "8px 14px",
    background: "#013C44",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
  },
  menuText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "Visuelt Pro",
    color: "#FFFFFF",
  },
  menuIcon: {
    width: "48px",
    height: "45px",
    borderRadius: "9px",
    marginLeft: "14px",
  },
  menuIconInner: {
    width: "33px",
    height: "33px",
    borderRadius: "16px",
  },
  dialogLayout: {
    "& .MuiDialog-container .MuiDialog-paper": {
      minWidth: "0px",
      width: "340px",
      height: "auto",
      minHeight: "auto",
      maxHeight: "600px",
      overflowX: "hidden",
      background: "#F3FAFC",
      border: "1px solid #66B5C3",
      borderRadius: "8px",
    },
    "& .MuiDialogContent-root": {
      background: "#F3FAFC !important",
      padding: "0px",
    },
    "& .MuiDialog-scrollPaper": {
      display: "block",
    },
    "& .MuiDialog-paper": {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "86px",
    },
  },
  inputField: {
    width: "286px",
    height: "46px",
    left: "0px",
    top: "0px",
    background: "#FFFFFF",
    border: "1px solid #66B5C3",
    boxShadow: "0px 0px 20px rgba(191, 197, 245, 0.15)",
    borderRadius: "6px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: "1px",
    },
  },
  searchButton: {
    width: "286px",
    height: "50px",
    background: "#00839B",
    borderRadius: "6px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    color: "#ffffff",
    fontFamily: "Visuelt Pro",
    marginTop: "20px",
  },
  disabledButton: {
    width: "286px",
    height: "50px",
    background: "#BAD3D7",
    borderRadius: "6px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    color: "#5E919A",
    fontFamily: "Visuelt Pro",
  },
  formLabel: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "35px",
    letterSpacing: "0.015em",
    color: "#00839B",
    marginTop: "11px",
  },
  mandatory: {
    fontFamily: "Visuelt",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "21px",

    color: "#FF0000",
  },
  gender: {
    width: "88px",
    height: "46px",
    background: "#F3FAFC",
    border: "1px solid #A0DFC4",
    boxShadow: "0px 0px 20px rgba(191, 197, 245, 0.15)",
    borderRadius: "10px",
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
  textArea: {
    width: "286px",
    height: "255px",
    background: "#FFFFFF",
    border: "1px solid #A0DFC4",
    boxShadow: "0px 0px 20px rgba(191, 197, 245, 0.15)",
    borderRadius: "10px",
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      color: "#667085",
    },
    "& .Mui-selected": {
      background: "#FFF5F3",
      color: "#EC4A0A",
    },
    "& .Mui-selected:hover": {
      background: "#FFF5F3",
      color: "#EC4A0A",
    },
  },
  searchField: {
    background: "#FFFFFF",
    border: "1px solid #A0DFC4",
    boxShadow: "0px 0px 20px rgba(191, 197, 245, 0.15)",
    borderRadius: "10px",
    // width: "328px",
    height: "40px",
    backgroundImage: `url(${search})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "5% center",
    backgroundSize: "13px",
  },
  category: {
    height: "31px",
    background: "rgba(0, 131, 155, 0.1)",
    border: "1px solid #66B5C3",
    borderRadius: "4px",
    padding: "8px 10px",
  },
  addNewButton: {
    width: "114px",
    height: "31px",
    padding: "8px 10px",
    background: "#00839B",
    borderRadius: "4px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#FFFFFF",
  },
  patientCard: {
    height: "262px",
    background: "#F3FAFC",
    border: "1px solid #66B5C3",
    borderRadius: "8px",
    marginBottom: "16px",
  },
  label: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#66B5C3",
    letterSpacing: "0.015em",
  },
  value: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#046673",
  },
  addVisit: {
    height: "50px",
    background: "#00839B",
    borderRadius: "6px",
    padding: "10px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#FFFFFF",
    width: "100%",
    minWidth: "228px",
  },
  addVisitDesabled: {
    height: "50px",
    background: "#00839B",
    borderRadius: "6px",
    padding: "10px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#FFFFFF",
    width: "100%",
    minWidth: "228px",
    opacity: 0.5,
  },
  completed: {
    width: "76px",
    height: "27px",
    background: "rgba(55, 204, 140, 0.15)",
    border: "1px solid rgba(13, 165, 87, 0.54)",
    borderRadius: "4px",
    marginTop: "10px",
  },
  pending: {
    width: "68px",
    height: "27px",
    background: "rgba(255, 110, 78, 0.21)",
    border: "1px solid #FF6E4E",
    borderRadius: "4px",
    marginTop: "10px",
  },
  status: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "15px",
  },
  testResult: {
    width: "296px",
    minHeight: "72px",

    background: "#FAFFFF",
    border: "1.69762px solid #D9EDF1",
    boxShadow: "0px 3px 2px rgba(0, 131, 155, 0.03)",
    borderRadius: "8px",
    paddingLeft: "24px",
    paddingRight: "12px",
    paddingTop: "11px",
  },
  testTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "21px",
    color: "#00839B",
    width: "50%",
  },
  reportButton: {
    width: "107px",
    height: "42px",
    background: "#00839B",
    borderRadius: "8px",
    padding: "6px 10px",
    fontFamily: "Visuelt",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#FFFFFF",
  },
  regTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#101828",
  },
  regSubTitle: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#667085",
  },
  regLabel: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#344054",
    marginTop: "20px",
  },
  regInput: {
    padding: "10px 14px",
    width: "328px",
    height: "44px",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    marginTop: "6px",
  },
  uploadInput: {
    width: "328px",
    height: "317px",
    background: "rgba(217, 226, 238, 0.5)",
    border: "1px solid #00839B",
    borderRadius: "10px",
  },
  uploadInfo: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#344054",
    marginTop: "23px",
  },
  regButton: {
    padding: "10px 18px",
    width: "328px",
    height: "44px",
    background: "#00839B",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    cursor: "pointer",
  },
  presContainer: {
    height: "553px",
    width: "328px",
    background: "#F3FAFC",
    border: "1px solid #66B5C3",
    borderRadius: "8px",
  },
  mainButton: {
    width: "296px",
    height: "50px",
    borderRadius: "6px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#FFFFFF",
    marginBottom: "12px",
    cursor: "pointer",
  },
  subButton: {
    width: "155px",
    height: "50px",
    borderRadius: "6px",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#FFFFFF",
    marginBottom: "12px",
  },
  caseNotes: {
    marginTop: "33px",
    width: "360px",
    height: "653px", //261 px
    background: "#F3FAFC",
  },
  selectedNotes: {
    padding: "20px 16px",
    borderBottom: "2px solid #00839B",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#00839B",
    width: "180px",
    cursor: "pointer",
  },
  notes: {
    padding: "20px 16px",
    borderBottom: "2px solid #E4EEF0",
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#899DA2",
    width: "180px",
    cursor: "pointer",
  },
  notesArea: {
    width: "286px",
    height: "82px",
    background: "#FFFFFF",
    border: "1px solid #66B5C3",
    boxShadow: "0px 0px 20px rgba(191, 197, 245, 0.15)",
    borderRadius: "10px",
  },
  customSelect: {
    background: "#F3FAFC",
    border: "1px solid #66B5C3",
    boxShadow: "0px 0px 20px rgba(191, 197, 245, 0.15)",
    borderRadius: "10px 10px 0px 0px",
    width: "135px !important",
    height: "46px !important",
  },
  profLabel: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#66B5C3",
    marginBottom: "4px",
  },
  profData: {
    fontFamily: "Visuelt Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#013C44",
  },
  uploadCont: {
    width: "286px",
    height: "248px",
    background: "rgba(221, 228, 240, 0.15)",
    border: "1px solid rgba(0, 131, 155, 0.2)",
    boxShadow: "0px 0px 20px rgba(191, 197, 245, 0.15)",
    borderRadius: "10px",
  },
  confirm: {
    backgroundImage: `url(${confirmBg})`,
    height: "100vh",
    width: "100%",
  },
  uploadLabel: {
    display: 'flex',
    fontFamily: "VisueltMedium",
    justifyContent: 'center',
    alignItems: 'center',
    width: '91px',
    height: '91px',
    cursor: 'pointer',
    border: '1px solid #00839B',
    background: '#DBE4F1',
    borderRadius: '50%',
},
uploadButton: {
    position: 'absolute',
    opacity: '0',
    zIndex: -1,
},
uploadContainer: {
    width: '100%',
    marginLeft: '60px',
    marginTop: 'auto',
    marginBottom: 'auto'
},
uploadHeading: {
    fontFamily: "VisueltRegular",
    color: '#013C44',
    fontSize: '20px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: '-0.291346px',
    marginBottom: '10px'
},
uploadInstruction: {
    textAlign: 'justify',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '19px',
    fontWeight: 'normal',
    letterSpacing: '-0.291346px',
    color: 'rgba(4, 102, 115, 0.5)'
},
});

export default Style;
