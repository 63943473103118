import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import CreatecaseSuccess from '../../Popup/CreatecaseSuccess';
import MedicalPrescriptionHelp from './medicalPrescriptionHelp';
import Prescription from './prescription';

function LabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

LabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11ysProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  root1: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  container: {
    backgroundColor: "#EFF1F7",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "20px",
    padding: "30px"
  },
  container2: {
    backgroundColor: "#EFF1F7",
    border: "1px solid rgba(4, 102, 115, 0.3)",
    boxSizing: "border-box",
    borderRadius: "30px",
    marginTop: "41px",
    padding: "30px"
  },

}));

const AntTabs = withStyles({
  root: {
    borderBottom: '2px solid rgba(0, 131, 155, 0.5)',
    flexGrow: 1,
    marginTop: 16
  },
  indicator: {
    backgroundColor: '#A0DFC4',
    height: 7
  },
  flexContainer: {
    justifyContent: 'space-evenly'
  }
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: "20%",
    fontWeight: "bold",
    marginRight: theme.spacing(4),
    fontFamily: [
      'GreycliffCFBold'
    ].join(','),
    '&:hover': {
      color: '#046673',
      opacity: 1,
    },
    '&$selected': {
      color: '#046673',
    },
    '&:focus': {
      color: '#046673',
    },
    color: '#046673',
    maxWidth: 'none'
  },
  selected: {},
  wrapper: {
    fontSize: 30
  }
}))((props) => <Tab disableRipple {...props} />);

export default function MedicalPrescription(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [labHeader, setLabHeader] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState();

  const handleChange = (event, newValue) => {
    setLabHeader(newValue);
  };

  return (
    <>
      {/* <CreatecaseSuccess value="Medical Prescription" /> */}
      {
        show ?
          <CreatecaseSuccess value={showMessage == 1 ? " You Have Successfully Deleted Medical Prescription" : " You Have Successfully Created Medical Prescription"} otherMessage={true} />
          : <div>
            <Grid container xs={12} style={{ background: '#FFFFFF', border: '1px solid rgba(102, 181, 195, 0.5)', borderRadius: '30px' }} >
              <Grid item xs={12}>
                <div className={classes.root}>
                  <AntTabs
                    value={labHeader}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="green"
                    variant="standard"
                  >
                    <AntTab label="Create Prescription Medicine"  {...a11ysProps(0)} />
                    {/* <AntTab label="Help"  {...a11ysProps(1)} /> */}
                  </AntTabs>
{console.log(props,"svhvjas")}
                  <LabPanel value={labHeader} index={0}>
                    <Prescription data={props.data} showSuccess={setShow} message={(event) => setShowMessage(event)} setLoader={props.setLoader} closefn={props.closefn} medlist={props.medlist} id={props.id} />
                  </LabPanel>
                  <LabPanel value={labHeader} index={1}>
                    <MedicalPrescriptionHelp />
                  </LabPanel>
                </div>
              </Grid>
            </Grid>
          </div>
      }
    </>
  );
}
