import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField } from '@material-ui/core';
import { useStyle } from '../../Admin/style/style'
import { editBanks } from "../services/ServiceHelper";
import { getBankDetails } from "../../redux/actions/ex-appointment-action";
import Toast from "../../components/Toast";
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import NumberFormat from 'react-number-format';



export default function UpdateBankDetails(props) {
    let dispatch = useDispatch()
    const authState = useSelector((state) => state.loginReducer.payload);
    const bankState = useSelector((state) => state.PatientSnapshot.bankDetails);
    const classes = useStyle();

    const [toastMessage, setToastMessage] = useState();
    const [toastType, setToastType] = useState();
    const [showToast, setToast] = useState(false);

    const handleClose = () => {
        props.close(true)
    };


    const handleSubmit = async (event) => {
        event.preventDefault()

        try {
            const { accNumber, accName, accBankName, accBranchName, accAccountType } = event.target.elements
            let bankSwiftId = bankState && bankState.bank_detail && bankState.bank_detail.bankSwiftIcd ? bankState.bank_detail.bankSwiftIcd : "N/A"
            let bankIba = bankState && bankState.bank_detail && bankState.bank_detail.bankIban ? bankState.bank_detail.bankIban : "N/A"

            let payLoad = {
                "acc_name": accName.value,
                "bank_name": accBankName.value,
                "branch": accBranchName.value,
                "acc_number": accNumber.value,
                "acc_type": accAccountType.value,
                "bankSwiftIcd": bankSwiftId,
                "bankIban": bankIba
            }

            let response = await editBanks(payLoad, authState.uuid)
            dispatch(getBankDetails())
            props.close(false)

        } catch (error) {
            if (error && error.response) {
                setToast(true)
                setToastMessage('' + error.response.data)
                setToastType('error')
            } else {
                setToast(false)
            }
        }

    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <div className='table_heading'>Update Bank Details</div>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip title="Close" placement="bottom" arrow>

                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer", float: "right" }} />
                    </Tooltip>
                </Grid>
            </Grid>


            <hr style={{ borderTop: "2px solid #00839B", borderBottom: "none", marginBottom: '5%' }} />

            <form action="" onSubmit={handleSubmit}>

                <Grid container spacing={2}>
                    {/* <Grid item xs={3}> <h3 className="input_lable">Account Number<span style={{color:"red"}}>*</span> : </h3></Grid>
                    <Grid item xs={9}>
                        <TextField
                            type='number'
                            style={{ width: '100%' }}
                            name="accNumber"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            required
                            defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.acc_number ? bankState.bank_detail.acc_number : "N/A"}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                                    <h3 className="input_lable">Account Name<span className='star-color'>*</span></h3>
                                    <TextField
                                    style={{ width: '100%' }}
                                    name="accName"
                                    variant="outlined"
                                    className={classes.primaryInputAddPatient}
                                    required
                                    defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.acc_name ? bankState.bank_detail.acc_name : ''}

                                />
                    </Grid>
                    <Grid item xs={6}>
                                    <h3 className="input_lable">Branch Name<span className='star-color'>*</span></h3>
                                    <TextField
                                    name="accBranchName"
                                    variant="outlined"
                                    className={classes.primaryInputAddPatient}
                                    style={{ width: '100%' }}
                                    required
                                    defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.branch ? bankState.bank_detail.branch : ''}
                                />
                    </Grid>
                    <Grid item xs={6}>
                                    <h3 className="input_lable">Bank Name<span className='star-color'>*</span></h3>
                                    <TextField
                                    style={{ width: '100%' }}
                                    name="accBankName"
                                    variant="outlined"
                                    className={classes.primaryInputAddPatient}
                                    required
                                    defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.bank_name ? bankState.bank_detail.bank_name : ''}

                                />
                    </Grid>
                    <Grid item xs={6}>
                                    <h3 className="input_lable">Account Type<span className='star-color'>*</span></h3>
                                    <TextField
                                    name="accAccountType"
                                    variant="outlined"
                                    className={classes.primaryInputAddPatient}
                                    style={{ width: '100%' }}
                                    required
                                    defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.acc_type ? bankState.bank_detail.acc_type : ''}
                                />
                    </Grid>
                    <Grid item xs={6}>
                                    <h3 className="input_lable">Account Number<span className='star-color'>*</span></h3>
                                    {/* <NumberFormat
                                    // type='number'
                                    style={{ width: '100%', fontSize:"22px",color:"#013C44", fontFamily:"VisueltRegular",fontWeight:"400" }}
                                    name="accNumber"
                                    variant="outlined"
                                    // className={classes.primaryInputAddPatient}
                                    className=" headersearchtxt"
                                    required
                                    defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.acc_number ? bankState.bank_detail.acc_number : "N/A"}
                        /> */}
                         <input
              className="headersearchtxt"
              type="number"
              name="accNumber"
            //   maxLength="15"
            //   pattern="\d{4}"
            //   value={values.receivedCode}
              defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.acc_number ? bankState.bank_detail.acc_number : "N/A"}
            //   onChange={handleChange}

            />
                    </Grid>

                    {/* <Grid item xs={3}> <h3 className="input_lable">Account Name<span style={{color:"red"}}>*</span> :</h3></Grid>
                    <Grid item xs={9}>
                        <TextField
                            style={{ width: '100%' }}
                            name="accName"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            required
                            defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.acc_name ? bankState.bank_detail.acc_name : ''}

                        />
                    </Grid> */}
                    {/* <Grid item xs={3}> <h3 className="input_lable">Bank Name<span style={{color:"red"}}>*</span> :</h3></Grid>
                    <Grid item xs={9}>
                        <TextField
                            style={{ width: '100%' }}
                            name="accBankName"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            required
                            defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.bank_name ? bankState.bank_detail.bank_name : ''}

                        />
                    </Grid> */}
                    {/* <Grid item xs={3}> <h3 className="input_lable">Branch Name<span style={{color:"red"}}>*</span> :</h3></Grid>
                    <Grid item xs={9}>
                        <TextField
                            name="accBranchName"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            style={{ width: '100%' }}
                            required
                            defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.branch ? bankState.bank_detail.branch : ''}
                        />
                    </Grid> */}
                    {/* <Grid item xs={3}> <h3 className="input_lable">Account Type<span style={{color:"red"}}>*</span> :</h3></Grid>
                    <Grid item xs={9}>
                        <TextField
                            name="accAccountType"
                            variant="outlined"
                            className={classes.primaryInputAddPatient}
                            style={{ width: '100%' }}
                            required
                            defaultValue={bankState && bankState.bank_detail && bankState.bank_detail.acc_type ? bankState.bank_detail.acc_type : ''}
                        />
                    </Grid> */}
                </Grid>

                <div className="btn_submit">
                    <button className='submit_button' type='submit' >Submit</button>
                </div>

            </form>

            <Toast
                text={toastMessage}
                type={toastType}
                open={showToast}
                setClose={() => {
                    setToast(false);
                }}
            />

        </>
    )
}

