import React from 'react';
import tick from './icons/tick.svg';

function CreateFollowSuccess() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '287px', backgroundColor: '#F0F5F5', boxShadow: '0px 0px 24px 5px rgba(218, 218, 218, 0.3)', borderRadius: '30px', border: '1px solid #A0DFC4' }}>
            <div style={{ height: '176' }}>
                <img src={tick} alt="tick" style={{ display: 'block', margin: '0 auto 30px auto' }} crossorigin="anonymous" />
                <div style={{ color: '#013C44', textAlign: 'center', fontSize: '20px', lineHeight: '24px', fontWeight: 'bold', fontFamily: 'GreycliffCFBold',marginLeft:"20px",marginRight:"20px"  }}>You have sucessfully follow up the Appointment.</div>
            </div>
        </div>
    )
}

export default CreateFollowSuccess
