import React from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import styles from "./style";
import { useState, useEffect } from "react";
import DialogLayout from "./components/DialogLayout";
import DefaultCaseNotes from "./DefaultCaseNotes";
import CreatePrescription from "./CreatePrescription";
import CreateLabTest from "./CreateLabTest";
import CaseNoteConf from "./CaseNoteConf";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import { width } from "@mui/system";
const useStyles = makeStyles((theme) => styles(theme));

const ViewVisit = (data) => {
  console.log(data, "datsvxcxa")
  let patientId = data && data.data && data.data.detail && data.data.detail.patient_uuid

  let history = useHistory();
  const location = useLocation()
  console.log(patientId, "patientId")
  const customSM = useMediaQuery("(max-width:800px)");
  let info =
    data && data.data && data.data.detail
      ? data.data.detail
      : data && data.data && data.data.visitDetails
        ? data.data.visitDetails
        : {};
  let visitInfo = info;
  console.log(info, "dddd");
  console.log(data, "data3434")
  // let info =
  // data && data.detail
  //   ? data && data.detail
  //   : {};
  // let visitInfo = info;
  const classes = useStyles();
  const theme = useTheme();
  const [caseNotes, setCaseNotes] = useState("default");
  const [defaultNotes, setDefaultNotes] = useState(false);
  const [createPres, setcreatePres] = useState(false);
  const [createLabTest, setcreateLabTest] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [visitList, setVisitList] = useState([]);
  const [visitId, setVisitId] = useState();
  console.log(visitList, "visitList")
  const user = useSelector((state) => state.loginReducer.payload);


  const [caseNotesList, setCaseNoteList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [closepdf, setClosePdf] = useState(false);
  const [openpdf, setOpenPdf] = useState(false);

  const [prescription, setPrescription] = useState([]);
  const [prescriptionlabs, setPrescriptionlabs] = useState();
  const [prescriptionCheck, setPrescriptionCheck] = useState([]);
  const [labCheck, setLabCheck] = useState([]);
  const [eprescriptionId, setEprescriptionid] = useState();
  const [labTestId, setLabtestId] = useState();
  const [labtest, setLabtes] = useState();
  const [issue, setIssue] = useState([]);
  const [note, setNote] = useState([]);
  const [caseNotesone, setCaseNotesone] = useState();
  const [epsychiatrycasenotesList, setepsychiatrycasenotesList] = useState([]);
  const [epsychiatrycaseNotes, setepsychiatrycasenotes] = useState();
  const [refresh, setRefresh] = useState(1);
  const [text, setText] = useState('');

  useEffect(() => {
    const VisitList =
      process.env.REACT_APP_API_URL +
      `/patient/patientvisit/allist?patient_uuid=${patientId ? patientId : visitInfo.patient_uuid
      }&limit=10&page=0`;
    const token = user.token;
    axios
      .get(VisitList, { headers: { "x-auth-token": `${token}` } })
      .then((response) => {
        setVisitList(response.data[0]);
      });
  }, []);

  console.log(visitInfo, "visitInfo")
  useEffect(() => {
    setVisitId(visitInfo.uuid);
  }, [visitInfo]);






  const handlePdf = (item, type) => {
    console.log(item, type, "yuyutu")
    let endPoint = "";
    if (type === "PRESCRIPTION") {
      endPoint = `/ep/prescription/preview/${eprescriptionId}`;
      // endPoint = `/externalappointment/prescription/preview/${item.uuid}`
    } else if (type === "LABTEST") {
      endPoint = `/ep-lab/labetst/preview/${labTestId}`;
      // endPoint = `/externalappointment/ep-lab/preview/${item.uuid}`
    } else if (type === "Psychiatry") {
      endPoint = `/externalappointment/psychiatryNotePreview/${item.uuid}`;
    } else {
      endPoint = `/externalappointment/caseNotePreview/${item.uuid}`;
    }

    setLoader(true);
    let dt;
    let url;
    function getpdf(id) {
      axios.get(process.env.REACT_APP_API_URL + `${endPoint}`, {
        headers: {
          "x-auth-token": user.token,
        },
      })
        .then((res) => {
          console.log(res, "linkSource")
          if (res.data) {
            dt = res.data;

            const linkSource = `data:application/pdf;base64,${res.data}`;
            const downloadLink = document.createElement("a");
            const fileName = `${id.uuid}.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          }
        });
    }
    // async function getpdf(item) {
    //   try {
    //     await axios
    //       .get(process.env.REACT_APP_API_URL + `${endPoint}`, {
    //         headers: {
    //           "x-auth-token": user.token,
    //         },
    //       })
    //       .then((res) => {
    //         if (res.data) {
    //           dt = res.data;
    //           setLoader(false);
    //         }
    //       });

    //     handlePdfOpen();
    //     await fetch("data:application/pdf;base64," + dt)
    //       .then((res) => res.blob())
    //       .then((blob) => {
    //         url = window.URL.createObjectURL(blob);
    //       });
    //     const iframe = document.querySelector("#pdf");
    //     iframe.setAttribute("src", url);
    //   } catch (error) {
    //     failurePdfOpen();
    //   }
    // }

    getpdf(item);
  };

  const failurePdfOpen = () => {
    setClosePdf(true);
  };

  const failurePdfClose = () => {
    setClosePdf(false);
  };

  const handlePdfOpen = () => {
    setOpenPdf(true);
  };

  const handlePdfClose = () => {
    setOpenPdf(false);
  };



  const DynamicListByVisitId = () => {
    // Case notes list dynamic visit id
    try {
      setCaseNoteList([]);
      // const VisitList = `${process.env.REACT_APP_API_URL}/externalappointment/patientvisit/${visitId ? visitId : visitInfo && visitInfo.uuid}`;
      const VisitList = `${process.env.REACT_APP_API_URL
        }/externalappointment/patientvisit/details/${visitId ? visitId : visitInfo && visitInfo.uuid
        }`;
      const token = user.token;
      axios.get(VisitList).then((response) => {
        let length =
          response &&
          response.data &&
          response.data.extdoctorcasenotes &&
          response.data.extdoctorcasenotes.length - 1;
        setIssue(response.data && response.data.issues);
        //caseNotes
        setNote(
          response &&
          response.data &&
          response.data.notes &&
          response.data.notes[0]
        );
        setCaseNoteList(
          response && response.data && response.data.extdoctorcasenotes
        );
        setCaseNotesone(
          response &&
          response.data &&
          response.data.extdoctorcasenotes &&
          response.data.extdoctorcasenotes[length]
        );
        //psypsychiatrycasenotes
        let psylength =
          response &&
          response.data &&
          response.data.extpsychiatrycasenotes &&
          response.data.extpsychiatrycasenotes.length - 1;
        setepsychiatrycasenotesList(
          response &&
          response.data &&
          response &&
          response.data.extpsychiatrycasenotes
        );
        setepsychiatrycasenotes(
          response &&
          response.data &&
          response &&
          response.data.extpsychiatrycasenotes &&
          response.data.extpsychiatrycasenotes[psylength]
        );
        //Prescription Labs
        setPrescriptionlabs(
          response &&
          response.data &&
          response.data.ext_prescriptionlabs &&
          response.data.ext_prescriptionlabs[0] &&
          response.data.ext_prescriptionlabs[0].createdAt
        );
        setPrescription(
          response &&
          response.data &&
          response.data.exteprescriptions &&
          response.data.exteprescriptions[0]
        );
        // setPrescription(response && response.data && response.data.exteprescriptions[0] && response.data.exteprescriptions[0].createdAt)
        //check prescription and lb pdf avialable or not
        setPrescriptionCheck(
          response &&
          response.data &&
          response.data.exteprescriptions &&
          response.data.exteprescriptions[0] &&
          response.data.exteprescriptions[0].medication
        );
        setLabCheck(
          response &&
          response.data &&
          response.data.ext_prescriptionlabs &&
          response.data.ext_prescriptionlabs[0] &&
          response.data.ext_prescriptionlabs[0].tests
        );
        setLabtestId(
          response &&
          response.data &&
          response.data.ext_prescriptionlabs &&
          response.data.ext_prescriptionlabs &&
          response.data.ext_prescriptionlabs[0] &&
          response.data.ext_prescriptionlabs[0].uuid
        );
        // setLabtestId(response && response.data && response.data.ext_prescriptionlabs)
        //id of prescription and labtest and psychiatry
        setLabtes(
          response &&
          response.data &&
          response.data.ext_prescriptionlabs &&
          response.data.ext_prescriptionlabs[0] &&
          response.data.ext_prescriptionlabs[0].uuid
        );
        setEprescriptionid(
          response &&
          response.data &&
          response.data.exteprescriptions &&
          response.data.exteprescriptions[0] &&
          response.data.exteprescriptions[0].uuid
        );
        // setEprescriptionid(response && response.data && response.data.exteprescriptions.uuid)
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    DynamicListByVisitId();
  }, [refresh, visitId]);




  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ padding: "16px" }}
      >
        <Grid
          item
          container
          direction="column"
          className={classes.presContainer}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{ borderBottom: "0.5px solid #00839B", height: "66px" }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "26px",
                color: "#00839B",
              }}
            >
              Prescription/Lab Test Advice
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            style={{ padding: "20px 16px" }}
          >
            {prescriptionCheck && prescriptionCheck.length > 0 ?
                      <button
                      className={classes.mainButton}
                      style={{ background: "#1CB2CD"}}
                      onClick={() => setcreatePres(true)}
                      disabled
                    >
                      Create Medicine Prescription
                    </button>

                    :
                    <>
                    <button
                    className={classes.mainButton}
                    style={{  background: "#00839B" }}
                    onClick={() => setcreatePres(true)}
                  >
                    Create Medicine Prescription
                  </button>

                  </> 
          }
          {labCheck && labCheck.length > 0 ? 
                                      <button
                                      className={classes.mainButton}
                                      style={{  background: "#1CB2CD" }}
                                      disabled={labCheck && labCheck.length > 0}
                                    >
                                      Create Lab Test Advice
                                    </button>
                                    :
                                    <button
                                    className={classes.mainButton}
                                    style={{ background: "#00839B" }}
                                    onClick={() => setcreateLabTest(true)}
                                  >
                                    Create Lab Test Advice
                                  </button> 
        }


            {/* <button
              className={classes.mainButton}
              style={{ background: `${prescriptionCheck && prescriptionCheck.length > 0}` ? "#1CB2CD" : "#00839B" }}
              onClick={() => setcreatePres(true)}
              disabled={prescriptionCheck && prescriptionCheck.length > 0}
            >
              Create Medicine Prescription
            </button>
            <button
              className={classes.mainButton}
              style={{ background: `${labCheck && labCheck.length > 0}` ? "#1CB2CD" : "#00839B" }}
              onClick={() => setcreateLabTest(true)}
              disabled={labCheck && labCheck.length > 0}
            >
              Create Lab Test Advice
            </button> */}
            <button
              className={classes.subButton}
              style={{ background: "#00839B" }}
            >
              Medicine Report
            </button>
            <button
              className={classes.mainButton}
              style={{ background: "#FFFFFF", color: "#00839B" }}
            >
              {/* {prescriptionCheck} */}
              {prescriptionCheck && prescriptionCheck.length > 0 ? (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.mainButton}
                  style={{
                    background: "#FFFFFF",
                    cursor: "none",
                    padding: "16px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: "Visuelt",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "19px",
                        color: "#00839B",
                      }}
                    >
                      {moment(prescription && prescription.updatedAt).format("LL")}{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <button
                      style={{
                        width: "47px",
                        height: "27px",
                        background: "#E7FAF2",
                        borderRadius: "5px",
                        fontFamily: "Visuelt",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "12px",
                        lineHeight: "15px",
                        color: "#00839B",
                        marginRight: "10px",
                      }}
                      onClick={() => handlePdf("item", "PRESCRIPTION")}
                    >
                      View
                    </button>
                    <button
                      style={{
                        width: "47px",
                        height: "27px",
                        background: "#E7FAF2",
                        borderRadius: "5px",
                        fontFamily: "Visuelt",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "12px",
                        lineHeight: "15px",
                        color: "#00839B",
                      }}
                      onClick={() => setcreatePres(true)}
                    >
                      Edit
                    </button>
                  </Grid>
                </Grid>
              ) : (
                <div className="DCLCLB_Iptitle2">No Past Data Found</div>
              )}
            </button>
            <button
              className={classes.subButton}
              style={{ background: "#00839B", marginTop:"35px" }}

            >
              LabTest Report
            </button>
            {labCheck && labCheck.length > 0 ? (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.mainButton}
                style={{
                  background: "#FFFFFF",
                  cursor: "none",
                  padding: "16px",
                }}
              >
                <Grid item>
                  <Typography
                    style={{
                      fontFamily: "Visuelt",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "15px",
                      lineHeight: "19px",
                      color: "#00839B",
                    }}
                  >
                    {moment(labtest && labtest.updatedAt).format("LL")}{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <button
                    style={{
                      width: "47px",
                      height: "27px",
                      background: "#E7FAF2",
                      borderRadius: "5px",
                      fontFamily: "Visuelt",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#00839B",
                      marginRight: "10px",
                    }}
                    onClick={() => handlePdf("item", "LABTEST")}
                  >
                    View
                  </button>
                  <button
                    style={{
                      width: "47px",
                      height: "27px",
                      background: "#E7FAF2",
                      borderRadius: "5px",
                      fontFamily: "Visuelt",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#00839B",
                    }}
                    onClick={() => setcreateLabTest(true)}
                  >
                    Edit
                  </button>
                </Grid>
              </Grid>
            ) : (
              <div className="DCLCLB_Iptitle2">No Past Data Found</div>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.caseNotes}
        >
          <Grid item container>
            {/* {caseNotesone && caseNotesone.chief_complaint} */}
            <button
              className={
                caseNotes === "default" ? classes.selectedNotes : classes.notes
              }
              onClick={() => setCaseNotes("default")}
            >
              Create Case Notes
            </button>

            <button
              className={
                caseNotes === "psychiatry"
                  ? classes.selectedNotes
                  : classes.notes
              }
              onClick={() => setCaseNotes("psychiatry")}
              style={{ fontSize: "12px" }}
            >
              Psychiatry Case Notes
            </button>
          </Grid>
          {caseNotes === 'default' && <>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: "290px",
                minHeight: "33px",
                padding: "6px 15px 6px 16px",
                background: "#E4EEF0",
                borderRadius: "15px",
                marginTop: "26px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#00839B",
                }}
              >
                Chief Complaint:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#00839B",
                }}
              >
                {caseNotesone && caseNotesone.chief_complaint}
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: "290px",
                minHeight: "33px",
                padding: "6px 15px 6px 16px",
                background: "#E4EEF0",
                borderRadius: "15px",
                marginTop: "26px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#00839B",
                }}
              >
                History of patient complaint:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#00839B",
                }}
              >
                {caseNotesone && caseNotesone.patient_complaint}
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: "290px",
                minHeight: "33px",
                padding: "6px 15px 6px 16px",
                background: "#E4EEF0",
                borderRadius: "15px",
                marginTop: "26px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#00839B",
                }}
              >
                Diagnosis:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#00839B",
                }}
              >
                {caseNotesone && caseNotesone.diagnosis}
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: "290px",
                minHeight: "33px",
                padding: "6px 15px 6px 16px",
                background: "#E4EEF0",
                borderRadius: "15px",
                marginTop: "26px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#00839B",
                }}
              >
                Plan/Action:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#00839B",
                }}
              >
                {caseNotesone && caseNotesone.plan_action}
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: "290px",
                minHeight: "33px",
                padding: "6px 15px 6px 16px",
                background: "#E4EEF0",
                borderRadius: "15px",
                marginTop: "26px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#00839B",
                }}
              >
                Other information:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#00839B",
                }}
              >
                {caseNotesone && caseNotesone.others}
              </Typography>
            </Grid>
            <button
              className={classes.mainButton}
              style={{
                background: "#00839B",
                width: "323px",
                marginTop: "27px",
              }}
              onClick={() => setDefaultNotes(true)}
            >
              Create Case Notes
            </button>
            <Grid
            //  style={{ height: "180px", overflowY: 'scroll' }}
             >
              {caseNotesList && caseNotesList.length > 0 ? (
                caseNotesList.map((i) => (
                  <>
                  <Grid style={{ height: "180px", overflowY: 'scroll' }}>
                    {console.log(i, "wdjgvdhfAU")}
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.mainButton}
                      style={{
                        background: "#FFFFFF",
                        cursor: "none",
                        padding: "16px",
                        width: "323px",
                      }}
                    >
                      <Grid item>
                        <Typography
                          style={{
                            fontFamily: "Visuelt",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "15px",
                            lineHeight: "19px",
                            color: "#00839B",
                          }}
                        >
                          {moment(i.createdAt).format(
                            "ddd, DD MMM YYYY"
                          )}{" "}
                        </Typography>

                      </Grid>
                      <Grid item>
                        <button
                          style={{
                            width: "86px",
                            height: "27px",
                            background: "#E7FAF2",
                            borderRadius: "5px",
                            fontFamily: "Visuelt",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                            color: "#00839B",
                          }}
                          // id={i.uuid}
                          onClick={() => handlePdf(i, "CASE")}
                        >
                          View
                        </button>
                      </Grid>
                    </Grid>
                    </Grid>
                  </>
                ))
              ) : (
                <div className="value">
                  <div className="name">
                    <div className="text">No Past data found</div>
                  </div>
                </div>
              )}
            </ Grid>
          </>
          }
          {caseNotes === 'psychiatry' && <>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: "290px",
                minHeight: "33px",
                padding: "6px 15px 6px 16px",
                background: "#E4EEF0",
                borderRadius: "15px",
                marginTop: "26px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#00839B",
                }}
              >
                Presenting complaints:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#00839B",
                }}
              >
                {epsychiatrycaseNotes &&
                  epsychiatrycaseNotes.present_comp}
                  
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: "290px",
                minHeight: "33px",
                padding: "6px 15px 6px 16px",
                background: "#E4EEF0",
                borderRadius: "15px",
                marginTop: "26px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#00839B",
                }}
              >
                History:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#00839B",
                }}
              >
                {epsychiatrycaseNotes &&
                  epsychiatrycaseNotes.his_of_present_comp}
                 
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: "290px",
                minHeight: "33px",
                padding: "6px 15px 6px 16px",
                background: "#E4EEF0",
                borderRadius: "15px",
                marginTop: "26px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#00839B",
                }}
              >
                Past Psychiatry history:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#00839B",
                }}
              >
                {epsychiatrycaseNotes &&
                  epsychiatrycaseNotes.past_medi_his}
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: "290px",
                minHeight: "33px",
                padding: "6px 15px 6px 16px",
                background: "#E4EEF0",
                borderRadius: "15px",
                marginTop: "26px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#00839B",
                }}
              >
                Past Medical history:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#00839B",
                }}
              >
                {epsychiatrycaseNotes &&
                  epsychiatrycaseNotes.past_medi_his}
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                width: "290px",
                minHeight: "33px",
                padding: "6px 15px 6px 16px",
                background: "#E4EEF0",
                borderRadius: "15px",
                marginTop: "26px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#00839B",
                }}
              >
                Family history:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#00839B",
                }}
              >
                {epsychiatrycaseNotes &&
                  epsychiatrycaseNotes.family_his}
              </Typography>
            </Grid>
            <button
              className={classes.mainButton}
              style={{
                background: "#00839B",
                width: "323px",
                marginTop: "27px",
              }}
              onClick={() => setDefaultNotes(true)}
            >
              Create Case Notes
            </button>
            <Grid 
            // style={{ height: "180px", overflowY: 'scroll' }}
            >
              {epsychiatrycasenotesList && epsychiatrycasenotesList.length > 0 ? (
                epsychiatrycasenotesList.map((i) => (
                  <>
                  <Grid style={{ height: "180px", overflowY: 'scroll' }}>
                    {console.log(i, "wdjgvdhfAU")}
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.mainButton}
                      style={{
                        background: "#FFFFFF",
                        cursor: "none",
                        padding: "16px",
                        width: "323px",
                      }}
                    >
                      <Grid item>
                        <Typography
                          style={{
                            fontFamily: "Visuelt",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "15px",
                            lineHeight: "19px",
                            color: "#00839B",
                          }}
                        >
                          {moment(i.createdAt).format(
                            "ddd, DD MMM YYYY"
                          )}{" "}
                        </Typography>

                      </Grid>
                      <Grid item>
                        <button
                          style={{
                            width: "86px",
                            height: "27px",
                            background: "#E7FAF2",
                            borderRadius: "5px",
                            fontFamily: "Visuelt",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "15px",
                            color: "#00839B",
                          }}
                          // id={i.uuid}
                          onClick={() => handlePdf(i, "Psychiatry")}
                        >
                          View
                        </button>
                      </Grid>
                    </Grid>
                    </Grid>
                  </>
                ))
              ) : (
                <div className="value">
                  <div className="name">
                    <div className="text">No Past data found</div>
                  </div>
                </div>
              )}
            </ Grid>
          </>
          }
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          style={{
            width: "328px",
            height: "221px",
            border: "1px solid #66B5C3",
            borderRadius: "8px",
            padding: "14px 20px",
            marginTop: "36px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Greycliff CF",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "24px",
              color: "#00839B",
              alignSelf: "flex-start",
            }}
          >
            Current Issues
          </Typography>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              width: "290px",
              minHeight: "33px",
              padding: "6px 15px 6px 16px",
              background: "#E4EEF0",
              borderRadius: "15px",
              marginTop: "26px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "21px",
                color: "#00839B",
              }}
            >
              Chief Complaint:
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#00839B",
              }}
            >
              {/* Chief Complaint */}

              {visitInfo && visitInfo.reason_for_visit}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              width: "290px",
              minHeight: "33px",
              padding: "6px 15px 6px 16px",
              background: "#E4EEF0",
              borderRadius: "15px",
              marginTop: "14px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "21px",
                color: "#00839B",
              }}
            >
              Symptoms:
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#00839B",
              }}
            >
              {/* Cough */}
              {visitInfo && visitInfo.symptoms}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              width: "290px",
              minHeight: "33px",
              padding: "6px 15px 6px 16px",
              background: "#E4EEF0",
              borderRadius: "15px",
              marginTop: "14px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "21px",
                color: "#00839B",
              }}
            >
              Temperature:
            </Typography>
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#00839B",
              }}
            >
              {visitInfo && visitInfo.temperature}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          style={{
            width: "328px",
            height: "292px",
            border: "1px solid #66B5C3",
            background: "#F3FAFC",
            borderRadius: "8px",
            marginTop: "26px",
            marginBottom: "16px",
          }}
        >
          <Grid
            container
            style={{
              height: "58px",
              borderBottom: "0.5px solid #00839B",
              paddingTop: "16px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "35px",
                color: "#013C44",
                marginLeft: "21px",
              }}
            >
              Patient Details
            </Typography>
          </Grid>
          <Grid item container style={{ padding: "11px 21px" }}>
            <Grid
              item
              container
              style={{ height: "35px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#66B5C3",
                }}
              >
                {" "}
                Name:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#046673",
                }}
              >
                {" "}
                {/* Samiat12 */}
                {visitInfo && visitInfo.name}
                {/* {console.log(data.data.detail, "fsdfdsf")} */}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ height: "35px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#66B5C3",
                }}
              >
                {" "}
                Phone:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#046673",
                }}
              >
                {" "}

                {visitInfo && visitInfo.mobile}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ height: "35px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#66B5C3",
                }}
              >
                {" "}
                Gender:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#046673",
                }}
              >
                {" "}
                {visitInfo && visitInfo.gender}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ height: "35px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#66B5C3",
                }}
              >
                {" "}
                Date:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#046673",
                }}
              >
                {" "}
                {/* Thu. 09 Jun 2022 */}
                {moment(visitInfo && visitInfo.createdAt).format("ddd, DD MMM YYYY")}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ height: "35px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#66B5C3",
                }}
              >
                {" "}
                Time:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#046673",
                }}
              >
                {" "}
                {/* 03:51 PM */}
                {moment(visitInfo && visitInfo.createdAt).format("hh:mm A")}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ height: "35px" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#66B5C3",
                }}
              >
                {" "}
                Date of Birth:
              </Typography>
              <Typography
                style={{
                  fontFamily: "Visuelt Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "35px",
                  color: "#046673",
                }}
              >
                {" "}
                {/* 09 Jun 2022
                 */}
                {moment(visitInfo && visitInfo.age).format("DD MMM YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="column"
          alignItems="center"
          style={{
            width: "328px",
            height: "388px",
            border: "1px solid #66B5C3",
            background: "#F3FAFC",
            borderRadius: "8px",
            marginTop: "16px",
            marginBottom: "93px",
          }}
        >
          <Grid
            container
            style={{
              height: "58px",
              borderBottom: "0.5px solid #00839B",
              paddingTop: "16px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Visuelt Pro",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "35px",
                color: "#00839B",
                marginLeft: "21px",
              }}
            >
              View History
            </Typography>
          </Grid>
          <Grid style={{ height: "300px", overflowY: 'scroll' }}>
            {visitList &&
              visitList.extpatientvisit &&
              visitList.extpatientvisit.map((item, index) => (
                <div >
                  <button
                    className={classes.mainButton}
                    style={{
                      background: "#00839B",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      paddingLeft: "21px",
                      marginTop: "20px",
                    }}
                  >
                    Visit {index + 1}
                  </button>


                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.mainButton}
                    style={{
                      background: "#FFFFFF",
                      cursor: "none",
                      padding: "16px",
                      width: "296px",
                    }}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontFamily: "Visuelt",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "19px",
                          color: "#00839B",
                        }}
                      >
                        {moment(item.createdAt).format("ddd, DD MMM YYYY")}{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <button
                        style={{
                          width: "86px",
                          height: "27px",
                          background: "#E7FAF2",
                          borderRadius: "5px",
                          fontFamily: "Visuelt",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "15px",
                          color: "#00839B",
                        }}
                        onClick={() => setVisitId(item.uuid)}
                      >
                        Select
                      </button>
                    </Grid>
                  </Grid>
                  {/* <div className="DCLCLB_Iptitle1">
                            {"Visit" + " "}
                            {index + 1}
                          </div> */}
                  {/* <div class="HASC_Dot1 history"></div> */}
                  {/* <div className="DCLCLB_Iptitle2">
                            {moment(item.createdAt).format("ddd, DD MMM YYYY")}{" "}
                            <button
                              className="HA_Title_Content_button1"
                              onClick={() => setVisitId(item.uuid)}
                            >
                              Select
                            </button>
                          </div> */}
                </div>
              ))}
          </ Grid>
          {/* <button
            className={classes.mainButton}
            style={{
              background: "#00839B",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft: "21px",
              marginTop: "20px",
            }}
          >
            Visit 
          </button>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.mainButton}
            style={{
              background: "#FFFFFF",
              cursor: "none",
              padding: "16px",
              width: "296px",
            }}
          >
            <Grid item>
              <Typography
                style={{
                  fontFamily: "Visuelt",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "19px",
                  color: "#00839B",
                }}
              >
                Thur, 09 Jun 2022
              </Typography>
            </Grid>
            <Grid item>
              <button
                style={{
                  width: "86px",
                  height: "27px",
                  background: "#E7FAF2",
                  borderRadius: "5px",
                  fontFamily: "Visuelt",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#00839B",
                }}
              >
                Select
              </button>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
      {defaultNotes ? (
        <DialogLayout open={defaultNotes}>
          <DefaultCaseNotes
            setTexts={(event) => setText(event)}
            sucessPop={() => setShowConfirm(true)}
            getApi={() => DynamicListByVisitId()}
            closeDNotes={() => setDefaultNotes(false)}
            category={caseNotes}
            props={visitInfo}
            confirm={() => {
              setDefaultNotes(false);
              setShowConfirm(true);
            }}
          />
        </DialogLayout>
      ) : null}
      {createPres ? (
        <DialogLayout open={createPres}>
          <CreatePrescription setTexts={(event) => setText(event)} sucessPop={() => setShowConfirm(true)} closePres={() => setcreatePres(false)} data={visitInfo} getApi={() => DynamicListByVisitId()} />
        </DialogLayout>
      ) : null}
      {createLabTest ? (
        <DialogLayout open={createLabTest}>
          <CreateLabTest setTexts={(event) => setText(event)} sucessPop={() => setShowConfirm(true)} closeLabTest={() => setcreateLabTest(false)} routedata={visitInfo} getApi={() => DynamicListByVisitId()} />
        </DialogLayout>
      ) : null}
      {showConfirm ? (
        <DialogLayout open={showConfirm}>
          <CaseNoteConf closeConfirm={() => setShowConfirm(false)} text={text} />
        </DialogLayout>
      ) : null}



      <Dialog
        style={{ borderRadius: "0px" }}
        maxWidth={"xs"}
        fullWidth={true}
        open={openpdf}
        scroll={"body"}
        onClose={handlePdfClose}
      >
        <div
          className="ContainerWrapper"
        >
          {/* <img
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => handlePdfClose()}
            crossorigin="anonymous"
          /> */}
          <CancelIcon
            onClick={() => handlePdfClose(false)}
            style={{
              cursor: "pointer",
              marginRight: "0px",
              float: "right",
              marginLeft: "auto",
              width: "600px"

            }}
          />
          <DialogContent>
            <iframe src="" className="pdfiframe" id="pdf"></iframe>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default ViewVisit;
