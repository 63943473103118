import React from "react";
import {
  Grid,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  useTheme,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { useState } from "react";
import styles from "./style";
import dialogclose from "../../assets/images/DialogClose.svg";

const useStyles = makeStyles((theme) => styles(theme));

const LabResult = ({ closelabresult ,data}) => {
  console.log(data,"datadfsd")
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const [url1, setUrl1] = useState();
  const handleOrder1 = (item) => {
    // console.log(item.final_action.result[0].url);
    const OrderId1 =
      item &&
      item.final_action &&
      item.final_action.result[0] &&
      item.final_action &&
      item.final_action.result[0].url;

    // handlePdfOpen();
    // setOpen(true)
    window.open(OrderId1, "_blank");
    setUrl1(OrderId1);
  };

  return (
    <Grid
      container
      direction="column"
      style={{ paddingTop: "16px", paddingLeft: "21px" }}
    >
      <Grid container justifyContent="space-between">
        <Typography
          style={{
            fontFamily: "Visuelt Pro",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "35px",
            color: "#013C44",
          }}
        >
          Lab Results
        </Typography>
        <img
          src={dialogclose}
          alt="close button"
          style={{ marginRight: "26px" }}
          onClick={() => closelabresult()}
        />
      </Grid>
      <hr
        style={{
          height: "0.5px",
          borderWidth: "0px",
          width: "338px",
          marginLeft: "-21px",
          background: "#00839B",
          borderColor: "#00839B",
        }}
      />
      <Grid style={{ height: "424px" }}>
        
        {data && data.map((row)=>{
          return(<>
            <Grid
          item
          container
          justifyContent="space-between"
          style={{ marginTop: "40px" }}
          className={classes.testResult}
        >
          {console.log(row,"row")}
            <Typography className={classes.testTitle}>
            {/* {row.details} */}
            {row.details.standardisedtestname}
            {/* Urine Microscopy */}
          </Typography>
          <button className={classes.reportButton}
          onClick={() => handleOrder1(row)}
          >View Report</button>
</Grid>
          </>)
        
        })}
         
      
      </Grid>
    </Grid>
  );
};

export default LabResult;
