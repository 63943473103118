import React, { useState, useEffect } from 'react';
import style from './PsychiatryCaseNotes.scss'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import CreatecaseSuccess from './CreatecaseSuccess';
import axios from 'axios';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { createPhysacNote, getphyNote, createPsyRequest } from '../../redux/actions/caseNoteAction';

function PsychiatryCaseNotes(props) {
    console.log(props)
    let history = useHistory();
    const [show, setshow] = useState(false);
    const tokeen = useSelector(state => state.loginReducer.payload.token)
    const routedata = history.location.state;

    const [present_comp, setpresent_comp] = useState('');
    const [his_of_present_comp, sethis_of_present_comp] = useState('');
    const [past_psy_his, setpast_psy_his] = useState('');
    const [past_medi_his, setpast_medi_his] = useState('');
    const [family_his, setfamily_his] = useState('');
    const [personal_his, setpersonal_his] = useState('');
    const [childhood_his, setchildhood_his] = useState('');
    const [Edu_his, setEdu_his] = useState('');
    const [occupational_his, setoccupational_his] = useState('');
    const [psycosexual_his, setpsycosexual_his] = useState('');
    const [present_liv_con, setpresent_liv_con] = useState('');
    const [substance_use_his, setsubstance_use_his] = useState('');
    const [appearance_nd_behavi, setappearance_nd_behavi] = useState('');
    const [speech, setspeech] = useState('');
    const [mood, setmood] = useState('');
    const [affect, setaffect] = useState('');
    const [stream, setstream] = useState('');
    const [form, setform] = useState('');
    const [content, setcontent] = useState('');
    const [possession, setpossession] = useState('');
    const [perceptual_disturb, setperceptual_disturb] = useState('');
    const [orientation, setorientation] = useState('');
    const [attention_nd_concen, setattention_nd_concen] = useState('');
    const [memory, setmemory] = useState('');
    const [judgement, setjudgement] = useState('');
    const [insight, setinsight] = useState('');
    const [formualtion, setformualtion] = useState('');
    const [diagnosis, setdiagnosis] = useState('');
    const [errmsg, seterrmsg] = useState('');


    const dispatch = useDispatch();

    const createphypopup = useSelector(state => state.createPhyCaseNotereducer)



    onsubmit = () => {
        if (present_comp == "") {
            seterrmsg("please fill the psychiatry case notes");
        }

        else {

            const data = {
                "patient_visit_uuid": props.data.uuid,
                "patient_uuid": props.data.patient_uuid,
                "doctor_uuid": props.data.doctor_uuid,
                "present_comp": present_comp,
                "his_of_present_comp": his_of_present_comp,
                "past_psy_his": past_psy_his,
                "past_medi_his": past_medi_his,
                "family_his": family_his,
                "personal_his": personal_his,
                "childhood_his": childhood_his,
                "Edu_his": Edu_his,
                "occupational_his": occupational_his,
                "psycosexual_his": psycosexual_his,
                "present_liv_con": present_liv_con,
                "substance_use_his": substance_use_his,
                "appearance_nd_behavi": appearance_nd_behavi,
                "speech": speech,
                "mood": mood,
                "affect": affect,
                "stream": stream,
                "form": form,
                "content": content,
                "possession": possession,
                "perceptual_disturb": perceptual_disturb,
                "orientation": orientation,
                "attention_nd_concen": attention_nd_concen,
                "memory": memory,
                "judgement": judgement,
                "insight": insight,
                "formualtion": formualtion,
                "diagnosis": diagnosis,
                "created_by": "DR-352BEDAF"
            }
            // dispatch(

            //             createPsyRequest({
            //                 "booking_uuid":routedata.uuid,
            //                 "patient_uuid":routedata.patient_uuid,
            //                 "doctor_uuid":routedata.doctor_uuid,
            //                 "consumer_uuid":routedata.patient.created_by.uuid,
            // "present_comp": present_comp,
            // "his_of_present_comp": his_of_present_comp,
            // "past_psy_his": past_psy_his,
            // "past_medi_his": past_medi_his,
            // "family_his": family_his,
            // "personal_his": personal_his,
            // "childhood_his": childhood_his,
            // "Edu_his": Edu_his,
            // "occupational_his": occupational_his, 
            // "psycosexual_his": psycosexual_his,
            // "present_liv_con": present_liv_con,
            // "substance_use_his": substance_use_his,
            // "appearance_nd_behavi": appearance_nd_behavi,
            // "speech": speech,
            // "mood": mood,
            // "affect": affect,
            // "stream": stream,
            // "form": form,
            // "content": content,
            // "possession": possession,
            // "perceptual_disturb": perceptual_disturb,
            // "orientation": orientation,
            // "attention_nd_concen": attention_nd_concen,
            // "memory": memory,
            // "judgement": judgement,
            // "insight": insight,
            // "formualtion": formualtion,
            // "diagnosis": diagnosis,
            // "created_by": "DR-352BEDAF"

            //                     })

            // )

            axios.post(process.env.REACT_APP_API_URL + "/externalappointment/psychiatryCaseNotes", data, {
                headers: {
                    'x-auth-token': tokeen,
                }
            }).then((resp) => {
                if (resp.status == "201")
                    props.handlePhySuccessPopup()

                //    setTimeout(() => {
                props.handleCloseaddPsychiatry()
                // }, 1500);
                props.getApi()
            })
                .catch(err => {
                    console.log("errerr", err)
                })
            setpresent_comp('');
            sethis_of_present_comp('');
            setpast_psy_his('');
            setpast_medi_his('');
            setfamily_his('');
            setpersonal_his('');
            setfamily_his('');
            setchildhood_his('');
            setEdu_his('');
            setoccupational_his('');
            setpsycosexual_his('');
            setsubstance_use_his('');
            setappearance_nd_behavi('');
            setspeech('');
            setmood('');
            setaffect('');
            setstream('');
            setform('');
            setcontent('');
            setpossession('');
            setperceptual_disturb('');
            setorientation('');
            setattention_nd_concen('');
            setmemory('');
            setjudgement('');
            setinsight('');
            setformualtion('');
            setdiagnosis('');
            dispatch(getphyNote(routedata.uuid))
            setTimeout(() => {
                dispatch(getphyNote(routedata.uuid))
            }, 1000);
        }
        // if(createphypopup&&createphypopup.isLoading!=undefined&&createphypopup.isLoading==false){
        //     props.setLoader(false)
        // }
        // if(createphypopup.isError){
        //     props.setLoader(false)
        // }
    }
    const closePopup = () => {
        props.handleCloseaddPsychiatry()
    }
    return (
        <>
            {/* {
       createphypopup &&createphypopup.payload!=undefined&&createphypopup.payload.statusText!=undefined&&  createphypopup.payload.statusText =="Created" &&show ? <CreatecaseSuccess setLoader={props.setLoader}  value="PsychiatryCaseNotes"/> :  */}


            <div className="PsychiatryCaseNotes">
                <div style={{ margin: '20px 50px' }}>
                    <div className="psycheading">Psychiatry Case Notes
                        <label style={{ marginRight: "0px", float: "right", marginLeft: "auto" }}>
                            <CancelIcon onClick={closePopup} style={{ cursor: "pointer" }} />
                        </label>
                    </div>
                </div>

                <div className="psycdivider"></div>

                <div style={{ margin: '30px 50px' }}>
                    <div className="psycheading">Psychiatry Case Notes <span style={{ color: "red" }}>*</span></div>
                    <textarea type="text" className="psycinputs" value={present_comp} name="present_comp" onChange={e => setpresent_comp(e.target.value)} />

                    <div className="psycheading">History of Presenting Complaints:</div>
                    <textarea type="text" className="psycinputs" value={his_of_present_comp} name="his_of_present_comp" onChange={e => sethis_of_present_comp(e.target.value)} style={{ marginBottom: '25px' }} />
                    <div className="psycheading" >History</div>
                    <div style={{ margin: '10px 0' }}>
                        <label className="secondarylabel">Past Psychiatric history:</label>
                        <textarea type="text" className="psycinputs" value={past_psy_his} name="past_psy_his" onChange={e => setpast_psy_his(e.target.value)} />
                        <label className="secondarylabel">Past Medical history::</label>
                        <textarea type="text" className="psycinputs" value={past_medi_his} name="past_medi_his" onChange={e => setpast_medi_his(e.target.value)} />
                        <label className="secondarylabel">Family history:</label>
                        <textarea type="text" className="psycinputs" value={family_his} name="family_his" onChange={e => setfamily_his(e.target.value)} />
                        <label className="secondarylabel">Personal history:</label>
                        <textarea type="text" className="psycinputs" value={personal_his} name="personal_his" onChange={e => setpersonal_his(e.target.value)} />
                        <label className="secondarylabel">Childhood history:</label>
                        <textarea type="text" className="psycinputs" value={childhood_his} name="childhood_his" onChange={e => setchildhood_his(e.target.value)} />
                        <label className="secondarylabel">Educational history:</label>
                        <textarea type="text" className="psycinputs" value={Edu_his} name="Edu_his" onChange={e => setEdu_his(e.target.value)} />
                        <label className="secondarylabel">Occupational history:</label>
                        <textarea type="text" className="psycinputs" value={occupational_his} name="occupational_his" onChange={e => setoccupational_his(e.target.value)} />
                        <label className="secondarylabel">Psychosexual history:</label>
                        <textarea type="text" className="psycinputs" value={psycosexual_his} name="psycosexual_his" onChange={e => setpsycosexual_his(e.target.value)} />
                        <label className="secondarylabel">Present living conditions:</label>
                        <textarea type="text" className="psycinputs" value={present_liv_con} name="present_liv_con" onChange={e => setpresent_liv_con(e.target.value)} />
                        <label className="secondarylabel">Substance Use history:</label>
                        <textarea type="text" className="psycinputs" value={substance_use_his} name="substance_use_his" onChange={e => setsubstance_use_his(e.target.value)} />

                        <div className="psycheading" >Mental State Examination</div>

                        <label className="secondarylabel">Appearance and behavior:</label>
                        <textarea type="text" className="psycinputs" value={appearance_nd_behavi} name="appearance_nd_behavi" onChange={e => setappearance_nd_behavi(e.target.value)} />
                        <label className="secondarylabel">Speech:</label>
                        <textarea type="text" className="psycinputs" value={speech} name="speech" onChange={e => setspeech(e.target.value)} />
                        <label className="secondarylabel">Mood:</label>
                        <textarea type="text" className="psycinputs" value={mood} name="mood" onChange={e => setmood(e.target.value)} />
                        <label className="secondarylabel">Affect:</label>
                        <textarea type="text" className="psycinputs" value={affect} name="affect" onChange={e => setaffect(e.target.value)} />
                        <label className="secondarylabel">Stream:</label>
                        <textarea type="text" className="psycinputs" value={stream} name="stream" onChange={e => setstream(e.target.value)} />
                        <label className="secondarylabel">Form:</label>
                        <textarea type="text" className="psycinputs" value={form} name="form" onChange={e => setform(e.target.value)} />
                        <label className="secondarylabel">Content:</label>
                        <textarea type="text" className="psycinputs" value={content} name="content" onChange={e => setcontent(e.target.value)} />
                        <label className="secondarylabel">Possession:</label>
                        <textarea type="text" className="psycinputs" value={possession} name="possession" onChange={e => setpossession(e.target.value)} />
                        <label className="secondarylabel">Perceptual disturbances:</label>
                        <textarea type="text" className="psycinputs" value={perceptual_disturb} name="perceptual_disturb" onChange={e => setperceptual_disturb(e.target.value)} />
                        <label className="secondarylabel">Orientation:</label>
                        <textarea type="text" className="psycinputs" value={orientation} name="orientation" onChange={e => setorientation(e.target.value)} />
                        <label className="secondarylabel">Attention and Concentration:</label>
                        <textarea type="text" className="psycinputs" value={attention_nd_concen} name="attention_nd_concen" onChange={e => setattention_nd_concen(e.target.value)} />
                        <label className="secondarylabel">Memory:</label>
                        <textarea type="text" className="psycinputs" value={memory} name="memory" onChange={e => setmemory(e.target.value)} />
                        <label className="secondarylabel">Judgement:</label>
                        <textarea type="text" className="psycinputs" value={judgement} name="judgement" onChange={e => setjudgement(e.target.value)} />
                        <label className="secondarylabel">Insight:</label>
                        <textarea type="text" className="psycinputs" value={insight} name="insight" onChange={e => setinsight(e.target.value)} />

                        <div className="psycheading" >Formulations:</div>
                        <textarea type="text" className="psycinputs" value={formualtion} name="formualtion" onChange={e => setformualtion(e.target.value)} />
                        <div className="psycheading" >Diagnosis:</div>
                        <textarea type="text" className="psycinputs" value={diagnosis} name="diagnosis" onChange={e => setdiagnosis(e.target.value)} />
                        {/* <div className="button">
                        <p className="buttonTxt"  onClick={onsubmit }>Create Case Notes</p>
                    </div> */}
                        <div className="docButton" style={{background:"#046673"}}>
                            <div className="buttontxt" onClick={onsubmit} style={{background:"#046673"}}>
                                Create Case Notes
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>


                    </div>
                    <span style={{ color: "red" }}>{errmsg}</span>

                </div>


            </div>
            {/* } */}
        </>
    )
}

export default PsychiatryCaseNotes
